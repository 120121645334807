import { Component } from '@angular/core';

@Component({
  selector: 'app-manager-channel-passbook',
  templateUrl: './manager-channel-passbook.component.html',
  styleUrls: ['./manager-channel-passbook.component.scss']
})
export class ManagerChannelPassbookComponent {

}
