<div class="l-main">
  <app-navigations></app-navigations>
  <div class="l-content">
    <app-breadcrumb></app-breadcrumb>
    <div class="l-content__inner">
      <div class="c-content-header">
        <div class="c-content-header__title" translate>修了証設定</div>
      </div>
      <div class="l-templates">
        <div class="p-templates">
          <div class="p-templates__content">
            <div class="p-profile">

              <div class="p-profile__row">
                <label class="c-form-label" translate>修了証発行</label>
                <div class="c-form-radio">
                  <div class="c-form-radio__wrap">
                    <input type="radio" name="issue" id="issue-true" [value]="">
                    <label class="c-form-label" for="issue-true" translate>発行する</label>
                  </div>
                  <div class="c-form-radio__wrap">
                    <input type="radio" name="issue" id="issue-false" [value]="">
                    <label class="c-form-label" for="issue-false" translate>発行しない</label>
                  </div>
                </div>
              </div>
              <div class="p-profile__row">
                <label class="c-form-label" translate>オリジナル修了証設定</label>
                <div class="c-form-radio">
                  <div class="c-form-radio__wrap">
                    <input type="radio" name="certificateSettings" id="certificateSettings-true" [value]="">
                    <label class="c-form-label" for="certificateSettings-true" translate>する</label>
                  </div>
                  <div class="c-form-radio__wrap">
                    <input type="radio" name="certificateSettings" id="certificateSettings-false" [value]="">
                    <label class="c-form-label" for="certificateSettings-false" translate>しない</label>
                  </div>
                </div>
              </div>
              <div class="p-profile__row">
                <label class="c-form-label" translate>向き</label>
                <div class="c-form-radio">
                  <div class="c-form-radio__wrap">
                    <input type="radio" name="direction" id="direction-true" [value]="">
                    <label class="c-form-label" for="direction-true" translate>縦書き</label>
                  </div>
                  <div class="c-form-radio__wrap">
                    <input type="radio" name="direction" id="direction-false" [value]="">
                    <label class="c-form-label" for="direction-false" translate>横書き</label>
                  </div>
                </div>
              </div>

              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="title">
                    <span translate>タイトル</span>
                    <span class="c-form-required" translate>必須</span>
                  </label>
                  <input class="c-form-input" type="text" name="title" id="title" placeholder="タイトルタイトルタイトル">
                  <div class="c-form-error" translate>タイトルを入力してください。</div>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="titleOfname">
                    <span translate>敬称</span>
                    <span class="c-form-required" translate>必須</span>
                  </label>
                  <select class="c-form-input" name="titleOfname" id="titleOfname">
                    <option value="" translate>選択してください</option>
                    <option value="" translate>殿</option>
                    <option value="" translate>様</option>
                    <option value="" translate>さん</option>
                    <option value="" translate>ちゃん</option>
                    <option value="" translate>君</option>
                  </select>
                  <div class="c-form-error" translate>敬称を選択してください。</div>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="issuerName">
                    <span translate>発行者名</span>
                    <span class="c-form-required" translate>必須</span>
                  </label>
                  <input class="c-form-input" type="text" name="issuerName" id="issuerName" placeholder="山田　太郎">
                  <div class="c-form-error" translate>発行者名を入力してください。</div>
                </div>
              </div>

              <div class="p-profile__row">
                <label class="c-form-label">
                  <span translate>背景画像</span>
                  <span class="c-form-required" translate>必須</span>
                </label>
                <div class="c-form-images">
                  <div class="c-form-images__image">
                    <img
                      src="{{eyeCatchThumbUrl}}" alt="">
                  </div>
                </div>
                <div
                  class="c-form-error" translate>画像を選択してください。</div>
                <div class="p-profile__row__buttons">
                  <div class="p-profile__row__buttons__button">
                    <button
                      class="c-button c-button-primary"
                      id="eyeCatch"
                    ><span translate>画像をアップロード</span></button>
                    <button
                      class="c-button c-button-cancel"
                      type="button"
                    ><span translate>画像を削除する</span></button>
                  </div>
                </div>
              </div>

              <div class="p-profile__row">
                <label class="c-form-label">
                  <span translate>PDFダウンロード</span>
                  <span class="c-form-required" translate>必須</span>
                </label>
                <div class="c-form-images">
                  <div class="c-form-images__image">
                    <img
                      src="{{eyeCatchThumbUrl}}" alt="">
                  </div>
                </div>
                <div class="p-profile__row__buttons">
                  <div class="p-profile__row__buttons__button">
                    <button
                      class="c-button c-button-primary"
                      id="eyeCatch"
                    ><span translate>ダウンロード</span></button>
                  </div>
                </div>
              </div>


              <div class="p-profile__row">
                <div class="p-profile__row__buttons">
                  <div class="p-profile__row__buttons__button">
                    <button
                      class="c-button c-button-cancel"
                    ><span translate>戻る</span></button>
                  </div>
                  <div class="p-profile__row__buttons__button">
                    <button
                      class="c-button c-button-primary"
                    ><span translate>保存</span></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
