import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PublicImage} from "../../models/public-image";
import {ToastrService} from "ngx-toastr";
import {Store} from "@ngrx/store";
import {UiState} from "../../store/ui/ui.reducer";
import {switchFalseLoading, switchTrueLoading} from "../../store/ui/ui.actions";
import {ImageConverterService} from "../../services/image-converter/image-converter.service";
import {VideoUploaderService} from "../../services/video-uploader/video-uploader.service";
import {AuthService} from "../../services/auth/auth.service";
import {VideoFrame} from "../../models/course-frames";
import {Subscription} from "rxjs";
import {FileObject} from "../../models/file-object";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-file-upload-modal',
  templateUrl: './file-upload-modal.component.html',
  styleUrls: ['./file-upload-modal.component.scss']
})
export class FileUploadModalComponent  implements OnInit {
  @Input() isOpenModal = false;
  @Input() uid: string;
  @Input() accountId: string;
  @Output() isOpenModalEmitter = new EventEmitter<boolean>();
  @Output() imageEmitter = new EventEmitter<PublicImage>();
  @Output() videoEmitter = new EventEmitter<VideoFrame>();
  @Output() fileEmitter = new EventEmitter<FileObject>();
  publicImage: PublicImage;
  videoFrame: VideoFrame;
  uploading: boolean = false;
  converting: boolean = false;
  fileSizeVideoSubscription: Subscription;
  bytesTransferredVideoSubscription: Subscription;
  fileSizeImageSubscription: Subscription;
  bytesTransferredImageSubscription: Subscription;
  fileSize: number = 0;
  progress: number;
  constructor(
    private authService: AuthService,
    private toasterService: ToastrService,
    private uiStore: Store<{ ui: UiState}>,
    private imageConverterService: ImageConverterService,
    private videoUploaderService: VideoUploaderService,
    private translateService: TranslateService,
  ) {}
  async ngOnInit(): Promise<void> {
     this.fileSizeVideoSubscription = this.videoUploaderService.fileSize.pipe().subscribe(fileSize => {
      this.fileSize = fileSize;
    });
    this.bytesTransferredVideoSubscription = this.videoUploaderService.bytesTransferred.pipe().subscribe(bytesTransferredVideo => {
      if (!bytesTransferredVideo) {
        return;
      }
      this.progress =  bytesTransferredVideo / this.fileSize;
      if (this.progress >= 1) {
        this.converting = true;
      }
    });
    this.fileSizeImageSubscription = this.imageConverterService.fileSize.pipe().subscribe(fileSize => {
      this.fileSize = fileSize;
    });
    this.bytesTransferredImageSubscription = this.imageConverterService.bytesTransferred.pipe().subscribe(bytesTransferredImage => {
      if (!bytesTransferredImage) {
        return;
      }
      this.progress =  bytesTransferredImage / this.fileSize;
      if (this.progress >= 1) {
        this.converting = true;
      }
    });
  }
  closeModal(): void {
    this.isOpenModal = false;
    this.isOpenModalEmitter.emit(false);
  }
  async upload(evt: any): Promise<void> {
    this.resetProgress();
    const file: File = evt.target.files[0];
    const imageType = ['image/jpeg', 'image/png'];
    const videoType: string[] = [];
    const fileType: string[] = [];
    let isImage = false;
    let isVideo = false;
    if (imageType.indexOf(file.type) !== -1) {
      isImage = true;
    }
    if (videoType.indexOf(file.type) !== -1) {
      isVideo = true;
    }
    if (!isImage && !isVideo) {
      this.toasterService.warning(this.translateService.instant('画像はjpg形式、またはpng形式をアップロードしてください。'));
      return;
    }
    if (isImage) {
      this.uiStore.dispatch(switchTrueLoading());
      await this.uploadImage(file);
      this.toasterService.success(this.translateService.instant('アップロードしました。'));
      this.resetProgress();
      this.uiStore.dispatch(switchFalseLoading());
    } else if (isVideo) {
      if (file && file.size > 100000000) {
        this.toasterService.warning(this.translateService.instant('ファイルのサイズは最大100MBです。長さは60秒程度で解像度は720pを目安にしてください。'));
        this.uiStore.dispatch(switchFalseLoading());
        return;
      }
      this.uiStore.dispatch(switchTrueLoading());
      await this.uploadVideo(file);
      this.resetProgress();
      this.uiStore.dispatch(switchFalseLoading());
    } else {
      this.toasterService.warning(this.translateService.instant('アップロード出来ないファイルです。'));
      return;
    }
    this.imageEmitter.emit(this.publicImage);
    this.videoEmitter.emit(this.videoFrame);
    this.isOpenModalEmitter.emit(false);
    this.closeModal();
    return;
  }
  async uploadImage(file: File): Promise<void> {
    this.uploading = true;
    this.publicImage = await this.imageConverterService.uploadPublicImage(
      this.uid,
      this.accountId,
      file,
      this.authService.currentSession.currentUser.id,
      this.authService.currentSession.idToken,
      false,
      true,
      false,
    );
  }
  async uploadVideo(file: File): Promise<void> {
    this.uploading = true;
    this.videoFrame = await this.videoUploaderService.uploadVideo(
      this.uid,
      this.accountId,
      'chat',
      file,
    );
  }
  complete(): void {
    this.isOpenModal = false;
    this.isOpenModalEmitter.emit(false);
  }
  clickUploadFile(domId: string): void {
    const file = document
      .getElementById(domId);
    file.click();
  }
  resetProgress(): void {
    this.uploading = false;
    this.converting = false;
    this.fileSize = 0;
    this.progress = 0;
  }
}
