<div
  [ngClass]="showNavi ? 'l-navigations-open': ''"
  class="l-navigations">
  <div class="p-navigations">
    <div class="p-navigations__logo">
      <img
        routerLink="/"
        src="../../../assets/images/logo_sideMenu_head.png" alt="" height="30" width="192">
    </div>
    <ng-container *ngIf="showMenuInSideNavi">
      <!--<div class="p-navigations__search">
        <input type="text">
      </div>-->
      <div class="p-navigations__menu">
        <div class="p-navigations__menu__accordion">


          <div class="p-navigations__menu__accordion__inner">
            <input class="p-navigations__menu__accordion__inner__opener" type="checkbox" id="sideMenu2" checked>
            <label
              *ngIf="channelId"
              class="p-navigations__menu__accordion__inner__categoryTitle"
              for="sideMenu2"
            >
              <span class="p-navigations__menu__accordion__inner__categoryTitle__icon p-navigations__menu__accordion__inner__categoryTitle__icon-live"></span>
              <span translate>コンテンツ</span>
            </label>
            <div class="p-navigations__menu__accordion__inner__links">
              <div
                *ngIf="channelId"
                routerLink="/manager/channel/{{channelId}}"
                class="p-navigations__menu__accordion__inner__links__item"
                [ngClass]="isCurrent('/manager/channel/' + channelId ) ? 'p-navigations__menu__accordion__inner__links__item-current' : ''"
              >
                <span translate>トップ</span>
              </div>
              <div
                *ngIf="slug"
                routerLink="/channel/{{slug}}"
                class="p-navigations__menu__accordion__inner__links__item"
              >
                <span translate>公開ページ表示</span>
              </div>
              <div
                *ngIf="channelId"
                routerLink="/manager/channel/meta/{{channelId}}"
                class="p-navigations__menu__accordion__inner__links__item"
                [ngClass]="isCurrent('/manager/channel/meta/' + channelId ) ? 'p-navigations__menu__accordion__inner__links__item-current' : ''"
              >
                <span translate>チャンネルトップ編集</span>
              </div>
              <div
                routerLink="/manager/channel/courses/{{channelId}}/all"
                class="p-navigations__menu__accordion__inner__links__item"
                [ngClass]="isCurrent('/manager/channel/courses/' + channelId + '/all') ? 'p-navigations__menu__accordion__inner__links__item-current' : ''"
              >
                <span translate>コース一覧</span>
              </div>
            </div>
          </div>


          <div
            *ngIf="channelId"
            class="p-navigations__menu__accordion__inner">
            <input class="p-navigations__menu__accordion__inner__opener" type="checkbox" id="sideMenu3" checked>
            <label
              class="p-navigations__menu__accordion__inner__categoryTitle"
              for="sideMenu3"
            >
              <span class="p-navigations__menu__accordion__inner__categoryTitle__icon p-navigations__menu__accordion__inner__categoryTitle__icon-member"></span>
              <span translate>受講者</span>
            </label>
            <div class="p-navigations__menu__accordion__inner__links">
              <div
                *ngIf="channelId"
                routerLink="/manager/channel/users/{{channelId}}"
                class="p-navigations__menu__accordion__inner__links__item"
                [ngClass]="isCurrent('/manager/channel/users/' + channelId ) ? 'p-navigations__menu__accordion__inner__links__item-current' : ''"
              >
                <span translate>チャンネル受講者</span>
              </div>
              <div
                *ngIf="channelId"
                (click)="moveToCourseUsers()"
                class="p-navigations__menu__accordion__inner__links__item"
                [ngClass]="isCurrent('/manager/channel/course/users/' + channelId + '/' + courseId ) ? 'p-navigations__menu__accordion__inner__links__item-current' : ''"
              >
                <span translate>コース受講者</span>
              </div>
            </div>
          </div>


          <div
            class="p-navigations__menu__accordion__inner">
            <input class="p-navigations__menu__accordion__inner__opener" type="checkbox" id="sideMenu8"  checked>
            <label
              class="p-navigations__menu__accordion__inner__categoryTitle"
              for="sideMenu8"
            >
              <span class="p-navigations__menu__accordion__inner__categoryTitle__icon p-navigations__menu__accordion__inner__categoryTitle__icon-channel"></span>
              <span translate>チャンネル管理</span>
            </label>
            <div class="p-navigations__menu__accordion__inner__links">
              <div
                routerLink="/manager/channels"
                class="p-navigations__menu__accordion__inner__links__item"
                [ngClass]="isCurrent('/manager/channels') ? 'p-navigations__menu__accordion__inner__links__item-current' : ''"
              >
                <span translate>チャンネル切替</span>
              </div>
              <div
                routerLink="/manager/channel/create"
                class="p-navigations__menu__accordion__inner__links__item"
                [ngClass]="isCurrent('/manager/channel/create') ? 'p-navigations__menu__accordion__inner__links__item-current' : ''"
              >
                <span translate>チャンネル作成</span>
              </div>
            </div>
          </div>

          <!--<div
            *ngIf="channelId"
            class="p-navigations__menu__accordion__inner">
            <input class="p-navigations__menu__accordion__inner__opener" type="checkbox" id="sideMenu1">
            <label
              class="p-navigations__menu__accordion__inner__categoryTitle p-navigations__menu__accordion__inner__categoryTitle-active"
              for="sideMenu1"
            >
              <span class="p-navigations__menu__accordion__inner__categoryTitle__icon p-navigations__menu__accordion__inner__categoryTitle__icon-management"></span>
              コース管理
            </label>
            <div class="p-navigations__menu__accordion__inner__links">
              <div
                routerLink="/manager/channel/courses/{{channelId}}/all"
                class="p-navigations__menu__accordion__inner__links__item">登録コース一覧</div>
              <div
                routerLink="/manager/channel/course/create/{{channelId}}"
                class="p-navigations__menu__accordion__inner__links__item">コース作成</div>
            </div>
          </div>-->




          <!--<div class="p-navigations__menu__accordion__inner">
            <input class="p-navigations__menu__accordion__inner__opener" type="checkbox" id="sideMenu4">
            <label
              class="p-navigations__menu__accordion__inner__categoryTitle"
              for="sideMenu4"
            >
              <span class="p-navigations__menu__accordion__inner__categoryTitle__icon p-navigations__menu__accordion__inner__categoryTitle__icon-certificate"></span>
              NFT受講修了証
            </label>
            <div class="p-navigations__menu__accordion__inner__links">
              <div class="p-navigations__menu__accordion__inner__links__item">メニュー</div>
              <div class="p-navigations__menu__accordion__inner__links__item">メニュー</div>
            </div>
          </div>-->
          <!--<div class="p-navigations__menu__accordion__inner">
            <input class="p-navigations__menu__accordion__inner__opener" type="checkbox" id="sideMenu5">
            <label
              class="p-navigations__menu__accordion__inner__categoryTitle"
              for="sideMenu5"
            >
              <span class="p-navigations__menu__accordion__inner__categoryTitle__icon p-navigations__menu__accordion__inner__categoryTitle__icon-enterprise"></span>
              エンタープライズ
            </label>
            <div class="p-navigations__menu__accordion__inner__links">
              <div class="p-navigations__menu__accordion__inner__links__item">受講者CSV管理</div>
              <div class="p-navigations__menu__accordion__inner__links__item">受講データCSV管理</div>
            </div>
          </div>-->
          <!--<div class="p-navigations__menu__accordion__inner">
            <input class="p-navigations__menu__accordion__inner__opener" type="checkbox" id="sideMenu6">
            <label
              class="p-navigations__menu__accordion__inner__categoryTitle"
              for="sideMenu6"
            >
              <span class="p-navigations__menu__accordion__inner__categoryTitle__icon p-navigations__menu__accordion__inner__categoryTitle__icon-live"></span>
              動画外部URL管理
            </label>
            <div class="p-navigations__menu__accordion__inner__links">
              <div class="p-navigations__menu__accordion__inner__links__item">URL発行</div>
              <div class="p-navigations__menu__accordion__inner__links__item">URL管理</div>
            </div>
          </div>
        </div>-->
        <div class="p-navigations__menu__accordion__inner">
          <input class="p-navigations__menu__accordion__inner__opener" type="checkbox" id="sideMenu7" checked>
          <label
            class="p-navigations__menu__accordion__inner__categoryTitle"
            for="sideMenu7"
          >
            <span class="p-navigations__menu__accordion__inner__categoryTitle__icon p-navigations__menu__accordion__inner__categoryTitle__icon-enterprise"></span>
            <span translate>アカウント管理</span>
          </label>
          <div class="p-navigations__menu__accordion__inner__links">
            <div
              routerLink="/manager/business"
              class="p-navigations__menu__accordion__inner__links__item"
              [ngClass]="isCurrent('/manager/business') ? 'p-navigations__menu__accordion__inner__links__item-current' : ''"
              translate
            >アカウント情報</div>
            <!--<div class="p-navigations__menu__accordion__inner__links__item">アカウント通帳</div>-->
            <div
              routerLink="/manager/users"
              class="p-navigations__menu__accordion__inner__links__item"
              [ngClass]="isCurrent('/manager/users') ? 'p-navigations__menu__accordion__inner__links__item-current' : ''"
              translate
            >管理者一覧</div>
            <div
              routerLink="/manager/accounts"
              class="p-navigations__menu__accordion__inner__links__item"
              [ngClass]="isCurrent('/manager/accounts') ? 'p-navigations__menu__accordion__inner__links__item-current' : ''"
              translate
            >アカウント切替</div>
          </div>
        </div>
        <!--<div class="p-navigations__menu__normal">
          <div class="p-navigations__menu__normal__inner">
            <label class="p-navigations__menu__normal__inner__categoryTitle">
              <span class="p-navigations__menu__normal__inner__categoryTitle__icon p-navigations__menu__normal__inner__categoryTitle__icon-channel"></span>
              ●●●◯◯●●●◯◯
            </label>
            <div class="p-navigations__menu__normal__inner__links">
              <div
                routerLink="/"
                class="p-navigations__menu__normal__inner__links__item">●●●◯◯</div>
              <div
                routerLink="/"
                class="p-navigations__menu__normal__inner__links__item">●●●◯◯</div>
              <div
                routerLink="/"
                class="p-navigations__menu__normal__inner__links__item">●●●◯◯</div>
            </div>
          </div>-->
        </div>
      </div>
    </ng-container>
    <div class="p-navigations__openToggle">
      <div [ngClass]="showNavi ? 'p-navigations__openToggle__opened': 'p-navigations__openToggle__closed'">
        <button (click)="toggleNavi()"></button>
      </div>
    </div>
  </div>
</div>
<div [ngClass]="showNavi ? 'mask-on': 'mask-off'"></div>
