import { Component } from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-manager-channel-course-cert',
  templateUrl: './manager-channel-course-cert.component.html',
  styleUrls: ['./manager-channel-course-cert.component.scss']
})
export class ManagerChannelCourseCertComponent {
  eyeCatchThumbUrl: string = environment.noImageThumb;
}
