import {LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage, IMAGE_LOADER, ImageLoader, ImageLoaderConfig} from "@angular/common";
import { RouterModule } from '@angular/router';
import {environment} from "../environments/environment";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {AngularFireModule} from "@angular/fire/compat";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {ToastrModule} from "ngx-toastr";
import {CdkDropList} from "@angular/cdk/drag-drop";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DatetimeFormatFirestorePipe} from "./pipes/datetime-format-firestore/datetime-format-firestore.pipe";
import {MatMenuModule} from "@angular/material/menu";
import {DigestPipe} from "./pipes/digest/digest.pipe";
import {Nl2brPipe} from "./pipes/nl2br/nl2br.pipe";
import {TruncatePipe} from "./pipes/truncate/truncate.pipe";
import {DateFormatPipe} from "./pipes/date-format/date-format.pipe";
import {DateFormatFirestorePipe} from "./pipes/date-format-firestore/date-format-firestore.pipe";
import {SafePipe} from "./pipes/safe/safe.pipe";
import {TimeFormatPipe} from "./pipes/time-format/time-format.pipe";
import { NotificationComponent } from './components/notification/notification.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavigationsComponent } from './components/navigations/navigations.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ModalLoginComponent } from './components/modal-login/modal-login.component';
import {HeaderContentsChannelComponent} from "./components/header-contents-channel/header-contents-channel.component";
import {BreadcrumbFrontComponent} from "./components/breadcrumb-front/breadcrumb-front.component";
import {ModalPaymentCreditComponent} from "./components/modal-payment-credit/modal-payment-credit.component";
import {ModalSearchComponent} from "./components/modal-search/modal-search.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {FavoritesComponent} from "./components/favorites/favorites.component";
import {VideoPlayerComponent} from "./components/video-player/video-player.component";
import {ConfirmDialogComponent} from "./components/confirm-dialog/confirm-dialog.component";
import {HeaderContentsPublicComponent} from "./components/header-contents-public/header-contents-public.component";
import {ChatComponent} from "./components/chat/chat.component";
import {AddOemUserModalComponent} from "./components/add-oem-user-modal/add-oem-user-modal.component";
import {EditOemUserModalComponent} from "./components/edit-oem-user-modal/edit-oem-user-modal.component";
import {FooterBusinessComponent} from "./components/footer-business/footer-business.component";
import {FileUploadModalComponent} from "./components/file-upload-modal/file-upload-modal.component";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {ForbiddenComponent} from "./components/forbidden/forbidden.component";
import {BlankComponent} from "./components/blank/blank.component";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {SsoComponent} from "./sso/sso/sso.component";
import {LoginByEntraComponent} from "./sso/login-by-entra/login-by-entra.component";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ToastrModule.forRoot(
      {
        positionClass: 'toast-bottom-center',
        preventDuplicates: false
      }
    ),
    ReactiveFormsModule,
    NgOptimizedImage,
    MatMenuModule,
    FormsModule,
    CdkDropList,
    MatTooltipModule,
    MatProgressBarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      defaultLanguage: "ja",
    }),
  ],
  declarations: [
    Nl2brPipe,
    DigestPipe,
    TruncatePipe,
    TimeFormatPipe,
    DateFormatPipe,
    DateFormatFirestorePipe,
    SafePipe,
    TimeFormatPipe,
    DatetimeFormatFirestorePipe,
    NotFoundComponent,
    ForbiddenComponent,
    BlankComponent,
    HeaderComponent,
    HeaderContentsChannelComponent,
    HeaderContentsPublicComponent,
    FooterComponent,
    FooterBusinessComponent,
    NavigationsComponent,
    BreadcrumbComponent,
    BreadcrumbFrontComponent,
    NotificationComponent,
    ConfirmDialogComponent,
    ModalLoginComponent,
    ModalPaymentCreditComponent,
    ModalSearchComponent,
    FavoritesComponent,
    VideoPlayerComponent,
    ConfirmDialogComponent,
    ChatComponent,
    AddOemUserModalComponent,
    EditOemUserModalComponent,
    FileUploadModalComponent,
    SsoComponent,
    LoginByEntraComponent,
  ],
  providers: [
    {provide: 'googleTagManagerId', useValue: environment.GTM_ID},
    { provide: LOCALE_ID, useValue: 'ja-JP' },
    AngularFireAuth,
    AngularFireModule,
    AngularFirestore,
    {
      provide: IMAGE_LOADER,
      useValue: ((config: ImageLoaderConfig) => {
        const width = config.width ?? 300;
        if (config.src === 'no-image') {
          return environment.noImageThumb;
        } else {
          // https://storage.googleapis.com/firerocket-dev-assets/public/lms/5bf7f8a88405456daa6d/37ikDxtSev4922goFyFH/YyaiZwXr8KzrZ2O63GDjX/1280
          // https://storage.cloud.google.com/firerocket-dev-assets/{appId}/{accountId}/{any Id}/${width}?authuser=0
          return `https://storage.googleapis.com/${environment.publicAssetsBucketName}/${config.src}/${width}`;
        }
      }) as ImageLoader,
    },
  ],
  exports: [
    Nl2brPipe,
    DigestPipe,
    TruncatePipe,
    TimeFormatPipe,
    DateFormatPipe,
    DateFormatFirestorePipe,
    SafePipe,
    TimeFormatPipe,
    NotFoundComponent,
    ForbiddenComponent,
    HeaderComponent,
    HeaderContentsChannelComponent,
    FooterComponent,
    FooterBusinessComponent,
    NavigationsComponent,
    BreadcrumbComponent,
    BreadcrumbFrontComponent,
    DatetimeFormatFirestorePipe,
    TimeFormatPipe,
    NotificationComponent,
    ModalLoginComponent,
    FavoritesComponent,
    VideoPlayerComponent,
    // modules
    CommonModule,
    ToastrModule,
    ReactiveFormsModule,
    MatMenuModule,
    FormsModule,
    CdkDropList,
    ModalPaymentCreditComponent,
    // image
    NgOptimizedImage,
    ConfirmDialogComponent,
    HeaderContentsPublicComponent,
    ChatComponent,
    AddOemUserModalComponent,
    EditOemUserModalComponent,
    BlankComponent,
    // SSO
    SsoComponent,
    LoginByEntraComponent,
  ],
})
export class SharedModule {
  constructor(translate: TranslateService) {
    translate.use("ja");
  }
}
