<app-header-contents-channel></app-header-contents-channel>
<div class="l-content-front">
  <app-breadcrumb-front></app-breadcrumb-front>
  <div class="l-content-front__inner">
    <div class="c-content-header">
      <div class="c-content-header__title" translate>購入履歴</div>
    </div>
    <div class="l-myPage">
      <div class="p-myPage">
        <div class="c-tab">
          <input type="radio" id="tab_selector-1" name="tab_selector" class="c-tab__selector" checked>
          <label for="tab_selector-1" class="c-tab__label">
            <div class="c-tab__label__icon c-tab__label__icon-subscribe"></div>
            <span translate>購読中</span>
          </label>
          <input type="radio" id="tab_selector-2" name="tab_selector" class="c-tab__selector">
          <label for="tab_selector-2" class="c-tab__label">
            <div class="c-tab__label__icon c-tab__label__icon-buyCourse"></div>
            <span translate>コース購入</span>
          </label>
          <hr>
          <!--　1　購読中タブ　-->
          <div class="c-tab__content" id="tab_selector-1_content">
            <p translate>ご加入されているプランの詳細の確認、解約のお手続きをします。</p>


            <div class="c-card-purchase">
              <div class="c-card-purchase__head">
                <div class="c-card-purchase__head__planName" translate>プラン名</div>
                <div class="c-card-purchase__head__update" translate>次回契約更新</div>
              </div>
              <div class="c-card-purchase__item">
                <div class="c-card-purchase__item__plan">
                  <div class="c-card-purchase__item__plan__title">プラン名プラン名プラン名プラン名プラン名プラン名プラン名</div>
                  <div class="c-card-purchase__item__plan__price">
                    1234<span translate>円</span>
                  </div>
                </div>
                <div class="c-card-purchase__item__update">
                  2023<span translate>年</span><br>
                  02<span translate>月</span>
                  22<span translate>日</span>
                </div>
                <div class="c-card-purchase__item__buttons">
                  <button
                    class="c-card-purchase__item__buttons__button c-card-purchase__item__buttons__button-edit"
                  ><span translate>詳細</span></button>
                  <button
                    class="c-card-purchase__item__buttons__button c-card-purchase__item__buttons__button-delete"
                  ><span translate>解約</span></button>
                </div>
              </div>

            </div>
            <div class="c-card-purchase">
              <div class="c-card-purchase__head">
                <div class="c-card-purchase__head__planName" translate>プラン名</div>
                <div class="c-card-purchase__head__update" translate>次回契約更新</div>
              </div>
              <div class="c-card-purchase__item">
                <div class="c-card-purchase__item__plan">
                  <div class="c-card-purchase__item__plan__title">プラン名プラン名プラン名プラン名プラン名プラン名プラン名</div>
                  <div class="c-card-purchase__item__plan__price">
                    1234<span translate>円</span>
                  </div>
                </div>
                <div class="c-card-purchase__item__update">
                  2023<span translate>年</span><br>
                  02<span translate>月</span>
                  22<span translate>日</span>
                </div>
                <div class="c-card-purchase__item__buttons">
                  <button
                    class="c-card-purchase__item__buttons__button c-card-purchase__item__buttons__button-edit"
                  ><span translate>詳細</span></button>
                  <button
                    class="c-card-purchase__item__buttons__button c-card-purchase__item__buttons__button-delete"
                  ><span translate>解約</span></button>
                </div>
              </div>

            </div>
            <div class="c-card-purchase">
              <div class="c-card-purchase__head">
                <div class="c-card-purchase__head__planName" translate>プラン名</div>
                <div class="c-card-purchase__head__update" translate>次回契約更新</div>
              </div>
              <div class="c-card-purchase__item">
                <div class="c-card-purchase__item__plan">
                  <div class="c-card-purchase__item__plan__title">プラン名プラン名プラン名プラン名プラン名プラン名プラン名</div>
                  <div class="c-card-purchase__item__plan__price">
                    1234<span translate>円</span>
                  </div>
                </div>
                <div class="c-card-purchase__item__update">
                  2023<span translate>年</span><br>
                  02<span translate>月</span>
                  22<span translate>日</span>
                </div>
                <div class="c-card-purchase__item__buttons">
                  <button
                    class="c-card-purchase__item__buttons__button c-card-purchase__item__buttons__button-edit"
                  ><span translate>詳細</span></button>
                  <button
                    class="c-card-purchase__item__buttons__button c-card-purchase__item__buttons__button-delete"
                  ><span translate>解約</span></button>
                </div>
              </div>

            </div>


          </div>
          <!--　2　コース購入タブ　-->
          <div class="c-tab__content" id="tab_selector-2_content">


            <div class="c-card-purchase">
              <div class="c-card-purchase__head">
                <div class="c-card-purchase__head__planName" translate>コース名</div>
                <div class="c-card-purchase__head__history" translate>購入日</div>
              </div>
              <div class="c-card-purchase__item">
                <div class="c-card-purchase__item__plan">
                  <div class="c-card-purchase__item__plan__title">コース名コース名コース名コース名コース名コース名</div>
                  <div class="c-card-purchase__item__plan__price">
                    1234<span translate>円</span>
                  </div>
                </div>
                <div class="c-card-purchase__item__update">
                  2023<span translate>年</span><br>
                  02<span translate>月</span>
                  22<span translate>日</span>
                </div>
              </div>

            </div>
            <div class="c-card-purchase">
              <div class="c-card-purchase__head">
                <div class="c-card-purchase__head__planName" translate>コース名</div>
                <div class="c-card-purchase__head__history" translate>購入日</div>
              </div>
              <div class="c-card-purchase__item">
                <div class="c-card-purchase__item__plan">
                  <div class="c-card-purchase__item__plan__title">コース名コース名コース名コース名コース名コース名</div>
                  <div class="c-card-purchase__item__plan__price">
                    1234<span translate>円</span>
                  </div>
                </div>
                <div class="c-card-purchase__item__update">
                  2023<span translate>年</span><br>
                  02<span translate>月</span>
                  22<span translate>日</span>
                </div>
              </div>

            </div>
            <div class="c-card-purchase">
              <div class="c-card-purchase__head">
                <div class="c-card-purchase__head__planName" translate>コース名</div>
                <div class="c-card-purchase__head__history" translate>購入日</div>
              </div>
              <div class="c-card-purchase__item">
                <div class="c-card-purchase__item__plan">
                  <div class="c-card-purchase__item__plan__title">コース名コース名コース名コース名コース名コース名</div>
                  <div class="c-card-purchase__item__plan__price">
                    1234<span translate>円</span>
                  </div>
                </div>
                <div class="c-card-purchase__item__update">
                  2023<span translate>年</span><br>
                  02<span translate>月</span>
                  22<span translate>日</span>
                </div>
              </div>

            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
