import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Router, Routes} from "@angular/router";
import {Store} from "@ngrx/store";
import {BreadcrumbState} from "../../store/breadcrumb/breadcrumb.reducer";
import {routes} from "../../app-routing.module";
import {breadCrumbs, BreadCrumbList} from "../../models/breadcrumb-list";

@Component({
  selector: 'app-breadcrumb-front',
  templateUrl: './breadcrumb-front.component.html',
  styleUrls: ['./breadcrumb-front.component.scss']
})
export class BreadcrumbFrontComponent {
  private breadcrumbSubscription: Subscription;
  path: string;
  title: string;
  parent: BreadCrumbList;
  current: BreadCrumbList;
  tree: BreadCrumbList[] = [];
  isShow = true;
  pageList: Routes = routes;
  breadCrumbs = breadCrumbs;
  @Input() currentPageId: string;
  constructor(
    private router: Router,
    private breadcrumbStore: Store<{ breadcrumb: BreadcrumbState}>,
  ) { }
  ngOnInit(): void {
    this.breadcrumbSubscription = this.breadcrumbStore.select('breadcrumb').pipe().subscribe( (breadcrumbState) => {
      this.isShow = breadcrumbState.showBreadcrumb;
      this.path = location.pathname.replace('/', '');
      const tree = [];
      this.current = this.breadCrumbs.find(b => b.path === this.path);
      const route = this.pageList.find(r => r.path === this.path);
      if (this.current) {
        const split = route.title.toString().split('|');
        this.current.title = split[0];
      }
      this.pageList.map(route => {
        if (this.path.indexOf(route.path) === 0) {
        }
      });
      if (this.current) {
        tree.push(this.current);
        if (this.current.parentPath !== undefined) {
          if (this.current.parentPath !== '') {
            console.log(this.current.parentPath);
            const parent2 = breadCrumbs.find(item => item.path === this.current.parentPath);
            const route = this.pageList.find(r => r.path === this.current.parentPath);
            if (route) {
              console.log(route);
              const split = route.title.toString().split('|');
              parent2.title = split[0];
              tree.push(parent2)
              if (parent2.parentPath !== '') {
                const parent3 = breadCrumbs.find(item => item.path === parent2.parentPath);
                const route = this.pageList.find(r => r.path === parent2.parentPath);
                const split = route.title.toString().split('|');
                parent3.title = split[0];
                tree.push(parent3)
              }
            }
          }
        }
        this.tree = tree.reverse();
      }
    })
  }
  ngOnDestroy(): void{
    if (this.breadcrumbSubscription) {
      this.breadcrumbSubscription.unsubscribe();
    }
  }
  move(page: any): void {
    if (page.path !== this.current.path) {
      this.router.navigate([page.path]);
    }
  }
}
