import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {
  Account,
  AccountType, BankAccount, BankAccountTypes,
  JuridicalPersonalities,
  JuridicalPersonalityPositions,
  TypeOfBusinesses
} from "../../models/account";
import {ToastrService} from "ngx-toastr";
import {AuthService} from "../../services/auth/auth.service";
import {Store} from "@ngrx/store";
import {UiState} from "../../store/ui/ui.reducer";
import {
  switchFalseLoading,
  switchTrueLoading,
  switchTrueShowMenuInSideNavi,
  switchTrueShowSideNavigation
} from "../../store/ui/ui.actions";
import {AccountsService} from "../../services/accounts/accounts.service";
import {ImageConverterService} from "../../services/image-converter/image-converter.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-manager-business',
  templateUrl: './manager-business.component.html',
  styleUrls: ['./manager-business.component.scss']
})
export class ManagerBusinessComponent implements OnInit {
  forbidden: boolean = false;
  account: Account = new Account();
  isOpenLogoImageModal = false;
  constructor(
    private formBuilder: FormBuilder,
    private toasterService: ToastrService,
    private authService: AuthService,
    private uiStore: Store<{ ui: UiState }>,
    private accountService: AccountsService,
    private imageConverterService: ImageConverterService,
    private translateService: TranslateService,
  )
  {
    window.scrollTo(0, 0);
    this.uiStore.dispatch(switchTrueShowSideNavigation());
    this.uiStore.dispatch(switchTrueShowMenuInSideNavi());
  }
  protected readonly juridicalPersonalityPositions = JuridicalPersonalityPositions;
  protected readonly juridicalPersonalities = JuridicalPersonalities;
  protected readonly typeOfBusinesses = TypeOfBusinesses;
  protected readonly accountType = AccountType;
  typeOfBusiness = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  juridicalPersonality = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  juridicalPersonalityPosition = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  companyName = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  representativeTitle = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  representativeName = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  zip = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
      Validators.minLength(7),
      Validators.maxLength(7)
    ]
  });
  pref = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  address1 = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  address2 = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  address3 = new FormControl<string|null>(null, {
    validators: [
      // Validators.required,
    ]
  });
  divisionName = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  personInCharge = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  personInChargePhonetic = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  email = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  emailSignature = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  phoneNumber = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  protectByIpAddress = new FormControl<boolean>(false, {
    validators: [
      Validators.required,
    ]
  });
  acceptedIpAddresses = new FormControl<string|null>(null, {
    validators: []
  });
  bankCode = new FormControl<string|null>(null, {});
  bankName = new FormControl<string|null>(null, {});
  branchCode = new FormControl<string|null>(null, {});
  branchName = new FormControl<string|null>(null, {});
  transferTargetName = new FormControl<string|null>(null, {});
  type = new FormControl<BankAccountTypes|null>(null, {});
  form = this.formBuilder.group({
    typeOfBusiness: this.typeOfBusiness,
    juridicalPersonality: this.juridicalPersonality,
    juridicalPersonalityPosition: this.juridicalPersonalityPosition,
    companyName: this.companyName,
    representativeTitle: this.representativeTitle,
    representativeName: this.representativeName,
    zip: this.zip,
    pref: this.pref,
    address1: this.address1,
    address2: this.address2,
    address3: this.address3,
    divisionName: this.divisionName,
    personInCharge: this.personInCharge,
    personInChargePhonetic: this.personInChargePhonetic,
    email: this.email,
    phoneNumber: this.phoneNumber,
    bankCode: this.bankCode,
    bankName: this.bankName,
    branchCode: this.branchCode,
    branchName: this.branchName,
    transferTargetName: this.transferTargetName,
    type: this.type,
    protectByIpAddress: this.protectByIpAddress,
    acceptedIpAddresses: this.acceptedIpAddresses
  });
  async ngOnInit(): Promise<void> {
    await this.authService.refresh();
    this.uiStore.dispatch(switchTrueLoading());
    await new Promise(resolve => setTimeout(resolve, 200));
    this.account = await this.accountService.fetchAccount(this.authService.currentSession.currentUser.currentAccountId);
    if (!await this.accountService.isAcceptByClientIp(this.account)) {
      this.uiStore.dispatch(switchFalseLoading());
      this.forbidden = true;
      return;
    }
    this.uiStore.dispatch(switchFalseLoading());
    this.formReset();
    console.log(this.account);
  }
  formReset(): void {
    this.typeOfBusiness.setValue(this.account.typeOfBusiness);
    this.juridicalPersonality.setValue(this.account.juridicalPersonality);
    this.juridicalPersonalityPosition.setValue(this.account.juridicalPersonalityPosition);
    this.companyName.setValue(this.account.companyName);
    this.representativeTitle.setValue(this.account.representativeTitle);
    this.representativeName.setValue(this.account.representativeName);
    this.zip.setValue(this.account.zip);
    this.pref.setValue(this.account.pref);
    this.address1.setValue(this.account.address1);
    this.address2.setValue(this.account.address2);
    this.address3.setValue(this.account.address3);
    this.branchName.setValue(this.account.branchName);
    this.divisionName.setValue(this.account.divisionName);
    this.personInCharge.setValue(this.account.personInCharge);
    this.personInChargePhonetic.setValue(this.account.personInChargePhonetic);
    this.email.setValue(this.account?.email);
    this.emailSignature.setValue(this.account?.emailSignature);
    this.phoneNumber.setValue(this.account.phoneNumber);
    this.protectByIpAddress.setValue(this.account.protectByIpAddress);
    this.acceptedIpAddresses.setValue(this.account?.acceptedIpAddresses?.join('\n'));
  }
  async save(): Promise<void> {
    this.account.typeOfBusiness = this.typeOfBusiness.value;
    this.account.juridicalPersonality = this.juridicalPersonality.value;
    this.account.juridicalPersonalityPosition = this.juridicalPersonalityPosition.value;
    this.account.companyName = this.companyName.value;
    this.account.representativeTitle = this.representativeTitle.value;
    this.account.representativeName = this.representativeName.value;
    this.account.branchName = this.branchName.value;
    this.account.zip = this.zip.value;
    this.account.pref = this.pref.value;
    this.account.address1 = this.address1.value;
    this.account.address2 = this.address2.value;
    this.account.address3 = this.address3.value;
    this.account.divisionName = this.divisionName.value;
    this.account.personInCharge = this.personInCharge.value;
    this.account.personInChargePhonetic = this.personInChargePhonetic.value;
    this.account.email = this.email.value;
    this.account.emailSignature = this.emailSignature.value;
    this.account.phoneNumber = this.phoneNumber.value;
    if (!this.account.bankAccount) {
      this.account.bankAccount = new BankAccount();
    }
    this.account.bankAccount.bankCode = this.bankCode.value;
    this.account.bankAccount.bankName = this.bankName.value;
    this.account.bankAccount.branchCode = this.branchCode.value;
    this.account.bankAccount.branchName = this.branchName.value;
    this.account.bankAccount.transferTargetName = this.transferTargetName.value;
    this.account.bankAccount.type = this.type.value;
    this.account.protectByIpAddress = this.protectByIpAddress.value;
    this.account.acceptedIpAddresses = this.acceptedIpAddresses?.value?.split('\n');
    this.account.acceptedIpAddresses = this.account?.acceptedIpAddresses?.filter(i => i);
    if (this.account.protectByIpAddress && this.account.acceptedIpAddresses.length === 0) {
      const ipAddress = await this.accountService.getMyClientIp();
      this.account.acceptedIpAddresses.push(ipAddress);
    }
    this.account.updatedAt = new Date();
    this.account.updatedBy = this.authService.currentSession.currentUser.id;
    await this.accountService.update(this.account.id, this.account);
    this.toasterService.success(this.translateService.instant('保存しました。'));
  }
  async uploadLogoImage($event: any): Promise<void> {
    const file: File = $event.target.files[0];
    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      this.toasterService.warning(this.translateService.instant('jpg形式、またはpng形式の画像をアップロードしてください。'));
      return;
    }
    this.uiStore.dispatch(switchTrueLoading());
    this.account.logoImage = await this.imageConverterService.uploadPublicImage(
      this.account.id,
      this.account.id,
      file,
      this.authService.currentSession.currentUser.id,
      this.authService.currentSession.idToken,
      false,
      false,
      true
    );
    this.toasterService.success(this.translateService.instant('アップロードしました。'));
    await this.save();
    this.isOpenLogoImageModal = false;
    this.uiStore.dispatch(switchFalseLoading());
  }
  async removeLogoImage(): Promise<void> {
    if (!window.confirm(this.translateService.instant('ロゴ画像を削除します。よろしいですか？'))) {
      return;
    }
    this.uiStore.dispatch(switchTrueLoading());
    await this.authService.checkIdTokenExpire();
    await this.imageConverterService.removePublicImage(
      this.account.logoImage,
      this.authService.currentSession.idToken
    );
    this.account.logoImage = null;
    await this.save();
    this.toasterService.success(this.translateService.instant('削除しました。'));
    this.uiStore.dispatch(switchFalseLoading());
  }
  openLogoImageUploadModal() :void {
    this.isOpenLogoImageModal = true;
  }
  closeLogoImageModalModal(): void {
    this.isOpenLogoImageModal = false;
  }
  clickUploadFile(domId: string): void {
    const file = document
      .getElementById(domId);
    file.click();
  }
}
