import { Pipe, PipeTransform } from '@angular/core';
import {DateFormat} from "../../models/date-format";
import firebase from 'firebase/compat';
import Timestamp = firebase.firestore.Timestamp;

@Pipe({
  name: 'dateFormatFirestore'
})
export class DateFormatFirestorePipe implements PipeTransform {

  transform(value: Timestamp | string | Date | { seconds: number, nanoseconds: number  }, ...args: unknown[]): string {
    const df = new DateFormat();
    if (typeof value === 'string') {
      // const d = new Date(value.replace(/-/g, '/'));
      const d = new Date(value);
      return df.getDateStringJa(d);
    } else if (typeof value === 'object') {
      if (value instanceof Date) {
        return df.getDateStringJa(value);
      } else {
        if (value === null) {
          return '';
        }
        if (value.seconds === undefined) {
          return '';
        }
        if (value.seconds === null) {
          return '';
        }
        const d = new Date(value.seconds * 1000);
        const year = d.getFullYear();
        const month = (`0${d.getMonth() + 1}`).slice(-2);
        const day = (`0${d.getDate()}`).slice(-2);
        return `${year}年${month}月${day}日`;
      }
    } else {
      return '';
    }
  }

}
