import { createAction, union } from '@ngrx/store';
import {UrlState} from "./ui.reducer";
export const switchTrueShowSideNavigation = createAction('[Ui Component] switchTrueShowSideNavigation');
export const switchFalseShowSideNavigation = createAction('[Ui Component] switchFalseShowSideNavigation');
export const switchTrueShowMenuInSideNavi = createAction('[Ui Component] switchTrueShowMenuInSideNavi');
export const switchFalseShowMenuInSideNavi = createAction('[Ui Component] switchFalseShowMenuInSideNavi');
export const switchTrueLoading = createAction('[Ui Component] switchTrueLoading');
export const switchFalseLoading = createAction('[Ui Component] switchFalseLoading');

export const storeUrlState = createAction(
  '[Ui Component] storeUrlState',
  (payload?: { urlState: UrlState }) => ({ payload: payload.urlState }),
);
export const storeHeaderLogoSrc = createAction(
  '[Ui Component] storeHeaderLogoSrc',
  (payload?: string) => ({ payload: payload }),
);
export const storeIsNotFound = createAction(
  '[Ui Component] storeIsNotFound',
  (payload?: boolean) => ({ payload: payload }),
);


const actions = union({
  switchTrueShowSideNavigation: switchTrueShowSideNavigation,
  switchFalseShowSideNavigation: switchFalseShowSideNavigation,
  switchTrueShowMenuInSideNavi: switchTrueShowMenuInSideNavi,
  switchFalseShowMenuInSideNavi: switchFalseShowMenuInSideNavi,
  switchTrueLoading: switchTrueLoading,
  switchFalseLoading: switchFalseLoading,
  storeUrlState: storeUrlState,
  storeHeaderLogoSrc: storeHeaderLogoSrc,
  storeIsNotFound: storeIsNotFound,
});
export type UiActions = typeof actions;
