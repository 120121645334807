import {MonetizeMethods} from "./monetize-methods";
import {PublishRangesKeys} from "./publish-range";
import {VideoFrame} from "./course-frames";
import {PublicImage} from "./public-image";
import {CourseIndex} from "./course-index";
import {Tag} from "./tag";
export class Course {
  id?: string | null;
  accountId: string | null;
  isOem: boolean;
  channelId: string | null;
  categoryId: string | null;
  name: string;
  description: string | null;
  eyeCatchImage: PublicImage | null;
  prImages: PublicImage[];
  samples: VideoFrame[];
  courseIndex: CourseIndex;
  tags: string[];
  tagPayloads?: Tag[];
  published: boolean;
  willPublish: boolean;
  monetized: boolean;
  monetizeMethod: MonetizeMethods;
  unitPrice: number;
  courseSubscriptionPrice: number;
  publishRange: PublishRangesKeys;
  useReminder?: boolean;
  createdAt: Date | null;
  updatedAt: Date | null;
  createdBy: string | null;
  updatedBy: string | null;
  constructor() {
    this.id = null;
    this.accountId =　null;
    this.isOem = false;
    this.categoryId = null;
    this.name = '';
    this.description = '';
    this.eyeCatchImage = null;
    this.prImages = [];
    this.samples = [];
    this.tags = [];
    this.courseIndex = new CourseIndex();
    this.published = false;
    this.willPublish = false;
    this.monetized = false;
    this.monetizeMethod = null;
    this.unitPrice = 0;
    this.courseSubscriptionPrice = 0;
    this.publishRange = PublishRangesKeys.forChannelMembershipOnly;
    this.useReminder = false;
    this.createdAt = null;
    this.updatedAt = null;
    this.createdBy = '';
    this.updatedBy = '';
  }
  deserialize(): any {
    const json = JSON.stringify(this)
    return JSON.parse(json)
  }
}

