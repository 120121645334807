export class ChannelSubscription {
  id?: string
  uid: string;
  channelAccountId: string;
  channelId: string;
  activated: boolean;
  activatedAt: Date;
  rejected: boolean;
  rejectedAt: Date;
  rejectedBy: string;
  rejectNote: string;
  quited: boolean;
  quitedAt: Date;
  completed: boolean;
  completedAt: Date;
  createdAt: Date;
  updatedAt: Date;
  createdBy: string;
  updatedBy: string;
  constructor() {
    this.uid = null;
    this.activated = false;
    this.activatedAt = null;
    this.rejected = false;
    this.rejectedAt = null;
    this.rejectNote = null;
    this.rejectedBy = null;
    this.quited = false;
    this.quitedAt = null;
    this.createdAt = null;
    this.createdBy = null;
    this.updatedAt = null;
    this.updatedBy = null;
  }
  deserialize(): any {
    const json = JSON.stringify(this)
    return JSON.parse(json)
  }
}
