import { Component } from '@angular/core';

@Component({
  selector: 'app-manager-channel-course-inspection-result',
  templateUrl: './manager-channel-course-inspection-result.component.html',
  styleUrls: ['./manager-channel-course-inspection-result.component.scss']
})
export class ManagerChannelCourseInspectionResultComponent {

}
