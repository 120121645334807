<app-not-found *ngIf="isNotFound"></app-not-found>
<app-forbidden *ngIf="forbidden"></app-forbidden>
<div class="l-main"
  *ngIf="!forbidden && !isNotFound">
  <app-navigations></app-navigations>
  <div class="l-content">
    <app-breadcrumb></app-breadcrumb>
    <div class="l-content__inner">
      <div class="c-content-header">
        <div class="c-content-header__title">
          <span translate>チャンネル</span>「{{currentChannel?.name}}」<span translate>受講者</span>
        </div>
      </div>
      <div class="l-templates">
        <div class="p-templates">
          <div class="p-templates__content">

            <div class="p-courseEdit">
              <div class="p-courseEdit__buttons__button">
                <button
                  (click)="openAddUserModal()"
                  type="button"
                  class="c-button c-button-secondary"
                ><span translate>受講者を登録</span></button>
              </div>
            </div>
            <div class="p-templates__content">
              <div class="c-content-header__channelName" translate>受講者の検索</div>

              <div class="p-profile">
                <ng-container *ngIf="currentAccount?.accountType == AccountType.general">
                  <div class="p-profile__row">
                    <div class="p-profile__row__input">
                      <label
                        class="c-form-label"
                        for="nicknameSearch"
                        translate
                      >ニックネーム</label>
                      <input class="c-form-input" type="text" name="nicknameSearch" id="nicknameSearch" placeholder="">
                    </div>
                  </div>
                </ng-container>
                <div class="p-profile__row">
                  <div class="p-profile__row__input">
                    <label
                      class="c-form-label"
                      for="nameSearch"
                      translate
                    >名前</label>
                    <input [formControl]="name" class="c-form-input" type="text" name="nameSearch" id="nameSearch" placeholder="">
                  </div>
                </div>
                <div class="p-profile__row">
                  <div class="p-profile__row__input">
                    <label class="c-form-label" for="emailSearch" translate>メールアドレス</label>
                    <input [formControl]="email" class="c-form-input" type="text" name="emailSearch" id="emailSearch" placeholder="">
                  </div>
                </div>
                <div class="p-profile__row">
                  <div class="p-profile__row__input">
                    <label class="c-form-label" for="companyNameSearch" translate>会社名</label>
                    <input [formControl]="companyName" class="c-form-input" type="text" name="companyNameSearch" id="companyNameSearch" placeholder="">
                  </div>
                </div>
                <div class="p-profile__row">
                  <div class="p-profile__row__buttons">
                    <div class="p-profile__row__buttons__button">
                      <button
                        (click)="resetForm()"
                        class="c-button c-button-cancel"
                      ><span translate>検索条件のリセット</span></button>
                    </div>
                    <div class="p-profile__row__buttons__button">
                      <button
                        (click)="search()"
                        class="c-button c-button-primary"
                      ><span translate>検索する</span></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-templates__content__list">
                <div class="c-list-user">
                  <div class="c-list-user__head">
                    <div class="c-list-user__head__name" translate>名前</div>
                    <div class="c-list-user__head__date" translate>登録日</div>
                    <div class="c-list-user__head__name" translate>会社名</div>
                    <div class="c-list-user__head__name" translate>メールアドレス</div>
                    <div class="c-list-user__head__talk" translate>チャット</div>
                    <div class="c-list-user__head__edit" translate>情報</div>
                  </div>
                  <div class="c-list-user__body">
                    <ng-container *ngFor="let user of showUsers">
                      <div class="c-list-user__body__row">
                        <div class="c-list-user__body__row__name">{{user?.user?.fullName}}</div>
                        <div class="c-list-user__body__row__date">{{user?.subscription?.activatedAt | dateFormatFirestore}}</div>
                        <div class="c-list-user__body__row__name">{{user?.user?.companyName}}</div>
                        <div class="c-list-user__body__row__name">{{user?.user?.email}}</div>
                        <div class="c-list-user__body__row__talk">
                          <button
                            (click)="openChatModal(user)"
                            class="c-list-user__body__row__talk__button"></button>
                        </div>
                        <div class="c-list-user__body__row__edit">
                          <button (click)="openEditModal(user.user)" class="c-list-user__body__row__edit__button"></button>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-edit-oem-user-modal
  (isOpenModalEmitter)="changeOpenEditUserModal($event)"
  (removedEmitter)="afterRemovedUser($event)"
  [isOpen]="isOpenEditModal"
  [editUser]="editUser"
  [account]="currentAccount"
  [channel]="currentChannel"
></app-edit-oem-user-modal>

<app-add-oem-user-modal
  (isOpenModalEmitter)="changeOpenAddUserModal($event)"
  [isOpen]="isOpenAddUserModal"
  [account]="currentAccount"
  [channel]="currentChannel"
></app-add-oem-user-modal>

<app-chat
  (isOpenModalEmitter)="changeOpenChatModal($event)"
  [isOpenModal]="isOpenChatModal"
  [onUser]="false"
  [targetUser]="chatTargetUser"
  [channel]="currentChannel"
  [account]="currentAccount"
></app-chat>

