<ng-container>
  <div class="l-signup">
    <div class="p-signup">
      <div class="p-signup__head">
        <div routerLink="/" class="p-signup__head__kv"></div>
      </div>
      <div class="p-signup__main">
        <div class="p-signup__main__title" translate>ログイン</div>
        <div class="p-signup__main__input">
          <label>
            <span translate>メールアドレス</span>
            <span class="c-form-required" translate>必須</span>
          </label>
          <input
            [formControl]="email"
            type="text"
            name="email"
            id="email"
            [placeholder]="'メールアドレス' | translate"
          >
        </div>
        <div class="p-signup__main__input">
          <label>
            <span translate>パスワード</span>
            <span class="c-form-required" translate>必須</span>
          </label>
          <input
            [type]="showPassword ? 'text' : 'password'"
            [formControl]="password"
            name="password"
            id="password"
            [placeholder]="'パスワード6文字以上' | translate"
          >
          <div
            class="p-signup__main__input__password"
            [ngClass]="showPassword ? 'p-signup__main__input__password-hide':'p-signup__main__input__password-show'"
            (click)="showPasswordToggle()">
          </div>
        </div>
        <div class="c-form-error" *ngIf="showError" translate>
          メールアドレスもしくはパスワード一致していない可能性があります。またはユーザー登録後に送信されるメールアドレスの確認が完了していません。
        </div>
        <div class="p-signup__main__button">
          <button
            type="button"
            class="c-button c-button-login"
            (click)="onSubmit()"
          ><span translate>ログイン</span></button>
        </div>
        <div class="p-signup__main__link" routerLink="/password/reset" translate>
          パスワードを忘れてしまった方はこちら
        </div>
        <div class="p-signup__main__registerButton">
          <button
            type="button"
            class="c-button c-button-authCode"
            routerLink="/signup"
          ><span translate>新規登録</span></button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
