import { Injectable } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {UsersService} from "../users/users.service";

@Injectable({
  providedIn: 'root'
})
export class LanguagesService {
  constructor(
    private usersService: UsersService,
    private translate: TranslateService
  ) { }
  // 言語切り替えと保存
  async change(language: 'ja' | 'en', uid: string | null): Promise<void> {
    this.translate.use(language);
    // ログインしているときは保存する
    if (uid) {
      const user = await this.usersService.fetchUser(uid);
      user.language = language;
      await this.usersService.update(user);
    }
  }
  getDefaultLanguage(): 'ja' | 'en' {
    return this.translate.getDefaultLang() as 'ja' | 'en';
  }
  getCurrentLanguage(): 'ja' | 'en' {
    return this.translate.currentLang as 'ja' | 'en';
  }
}
