<div class="l-main">
  <app-navigations></app-navigations>
  <div class="l-content">
    <app-breadcrumb></app-breadcrumb>
    <div class="l-content__inner">
      <div class="c-content-header">
        <div class="c-content-header__title">
          テストの作成
        </div>
      </div>
      <div class="l-templates">
        <div class="p-templates">
          <div class="p-templates__content">
            <div class="p-profile">
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label c-form-label-required" for="testName">テスト名</label>
                  <input class="c-form-input" type="text" name="testName" id="testName" placeholder="例）テスト">
                  <div class="c-form-error">テスト名を入力してください。</div>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__textArea">
                  <label class="c-form-label c-form-label-required" for="testOverview">テスト概要</label>
                  <textarea class="c-form-textArea" name="testOverview" id="testOverview" placeholder="例）ふぁいあ例）ふぁいあ例）ふぁいあ例）ふぁいあ例）ふぁいあ例）ふぁいあ"></textarea>
                  <div class="c-form-error">テスト概要を入力してください。</div>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label c-form-label-required" for="scoringSystem">採点方式</label>
                  <select class="c-form-input" name="scoringSystem" id="scoringSystem">
                    <option value="">選択してください</option>
                    <option value="">採点方式1</option>
                    <option value="">採点方式2</option>
                    <option value="">採点方式3</option>
                  </select>
                  <div class="c-form-error">採点方式を選択してください。</div>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label c-form-label-required" for="passingScore">合格基準</label>
                  <div class="p-profile__row__input__passingLine-ratio">
                    <input class="c-form-input" type="text" name="passingScore" id="passingScore">
                  </div>
                  <div class="c-form-error">合格基準を入力してください。</div>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label c-form-label-required" for="passingScore">合格基準</label>
                  <div class="p-profile__row__input__passingLine-score">
                    <input class="c-form-input" type="text" name="passingScore" id="passingScore">
                  </div>
                  <div class="c-form-error">合格基準を入力してください。</div>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__display">
                  <div class="p-profile__row__display__column">
                    <label class="c-form-label">登録日</label>
                    <div class="p-profile__row__display__column__date">2023年12月31日</div>
                  </div>
                  <div class="p-profile__row__display__column">
                    <label class="c-form-label">更新日</label>
                    <div class="p-profile__row__display__column__date">2023年12月31日</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-content-header">
        <div class="c-content-header__title">
          問題リスト
        </div>
      </div>
      <div class="p-templates">
        <div class="p-templates__content">
          <div class="c-card-tryouts">
            <div class="c-card-tryouts__item">
              <div class="c-card-tryouts__item__thumb">
                <img src="assets/images/sampleImage_video_01.png" alt="">
              </div>
              <div class="c-card-tryouts__item__title">損しないための社会人マナー損しないための社会人マナー</div>
              <div class="c-card-tryouts__item__format">回答方式</div>
              <div class="c-card-tryouts__item__summary">問題全文のサマリー問題全文のサマリー問題全文のサマリー問題全文のサマリー問題全文のサマリー問題</div>
              <div class="c-card-tryouts__item__buttons">
                <button (click)="openEditModal()" class="c-card-tryouts__item__buttons__button c-card-tryouts__item__buttons__button-edit">編集</button>
                <button class="c-card-tryouts__item__buttons__button c-card-tryouts__item__buttons__button-delete">削除</button>
                <div class="c-card-tryouts__item__buttons__sort">
                  <button class="c-card-tryouts__item__buttons__sort__up"></button>
                  <button class="c-card-tryouts__item__buttons__sort__down"></button>
                </div>
              </div>
            </div>
            <div class="c-card-tryouts__item">
              <div class="c-card-tryouts__item__thumb">
                <img src="assets/images/sampleImage_video_01.png" alt="">
              </div>
              <div class="c-card-tryouts__item__title">損しないための社会人マナー損しないための社会人マナー</div>
              <div class="c-card-tryouts__item__format">回答方式</div>
              <div class="c-card-tryouts__item__summary">問題全文のサマリー問題全文のサマリー問題全文のサマリー問題全文のサマリー問題全文のサマリー問題</div>
              <div class="c-card-tryouts__item__buttons">
                <button (click)="openEditModal()" class="c-card-tryouts__item__buttons__button c-card-tryouts__item__buttons__button-edit">編集</button>
                <button class="c-card-tryouts__item__buttons__button c-card-tryouts__item__buttons__button-delete">削除</button>
                <div class="c-card-tryouts__item__buttons__sort">
                  <button class="c-card-tryouts__item__buttons__sort__up"></button>
                  <button class="c-card-tryouts__item__buttons__sort__down"></button>
                </div>
              </div>
            </div>
            <div class="c-card-tryouts__item">
              <div class="c-card-tryouts__item__thumb">
                <img src="assets/images/sampleImage_video_01.png" alt="">
              </div>
              <div class="c-card-tryouts__item__title">損しないための社会人マナー損しないための社会人マナー</div>
              <div class="c-card-tryouts__item__format">回答方式</div>
              <div class="c-card-tryouts__item__summary">問題全文のサマリー問題全文のサマリー問題全文のサマリー問題全文のサマリー問題全文のサマリー問題</div>
              <div class="c-card-tryouts__item__buttons">
                <button (click)="openEditModal()" class="c-card-tryouts__item__buttons__button c-card-tryouts__item__buttons__button-edit">編集</button>
                <button class="c-card-tryouts__item__buttons__button c-card-tryouts__item__buttons__button-delete">削除</button>
                <div class="c-card-tryouts__item__buttons__sort">
                  <button class="c-card-tryouts__item__buttons__sort__up"></button>
                  <button class="c-card-tryouts__item__buttons__sort__down"></button>
                </div>
              </div>
            </div>
          </div>
          <div class="p-profile">
            <div class="p-profile__row">
              <div class="p-profile__row__createButton">
                <button class="c-button c-button-create">問題を追加する</button>
              </div>
            </div>
            <div class="p-profile__row">
              <div class="p-profile__row__display">
                <div class="p-profile__row__display__column">
                  <label class="c-form-label">現在の合計点数</label>
                  <div class="p-profile__row__display__column__score"></div>
                </div>
              </div>
              <div class="c-form-error">100点になるように問題を設定してください。</div>
            </div>
            <div class="p-profile__row">
              <div class="p-profile__row__buttons">
                <div class="p-profile__row__buttons__button">
                  <button class="c-button c-button-cancel">保存せずに戻る</button>
                </div>
                <div class="p-profile__row__buttons__button">
                  <button class="c-button c-button-primary">保存する</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="l-modal" *ngIf="isOpenEditModal">
  <div class="c-modal">
    <div class="c-modal__head">
      <div class="c-modal__head__title">
        Q1の内容
      </div>
      <div class="c-modal__head__closeButton" (click)="closeEditModal()"></div>
    </div>
    <div class="c-modal__main">
      <div class="c-modal__main__info">
        モーダル説明モーダル説明モーダル説明モーダル説明モーダル説明モーダル説明モーダル説明モーダル説明モーダル説明
      </div>
      <div class="c-modal__main__form">
        <div class="c-modal__main__form__input">
          <label class="c-form-label c-form-label-required" for="questionTitle">設問タイトル</label>
          <input class="c-form-input" type="text" name="questionTitle" id="questionTitle" placeholder="例）ふぁいあ">
          <div class="c-form-error">設問タイトルを入力してください。</div>
        </div>
        <div class="c-modal__main__form__input">
          <label class="c-form-label c-form-label-required" for="question">設問全文</label>
          <input class="c-form-input" type="text" name="question" id="question" placeholder="例）ふぁいあ">
          <div class="c-form-error">設問全文を入力してください。</div>
        </div>
        <div class="c-modal__main__form__eyeCatchUpload">
          <label class="c-form-label">コースアイキャッチ画像</label>
          <div class="c-modal__main__form__eyeCatchUpload__img">
            <img src="../../../assets/images/no-image.jpg" alt="">
          </div>
          <input type="file" id="eyeCatch">
          <div class="c-modal__main__form__eyeCatchUpload__button">
            <label for="eyeCatch" class="c-button c-button-create">
              画像を選択
            </label>
          </div>
        </div>
        <div class="c-modal__main__form__input">
          <label class="c-form-label c-form-label-required" for="answerFormat">回答形式</label>
          <select class="c-form-input" name="answerFormat" id="answerFormat">
            <option value="">選択してください</option>
            <option value="1">回答形式1</option>
            <option value="2">回答形式2</option>
            <option value="3">回答形式3</option>
          </select>
          <div class="c-form-error">回答形式を入力してください。</div>
        </div>
        <div class="c-modal__main__form__input">
          <label class="c-form-label">選択肢(ラジオボタン)</label>
          <div class="c-form-answer">
            <input type="radio" name="selectAnswer" value="" id="selectAnswer1">
            <label for="selectAnswer1"></label>
            <input class="c-form-input" type="text" name="answerText" placeholder="例）ふぁいあ">
            <button type="button"></button>
          </div>
          <div class="c-form-answer">
            <input type="radio" name="selectAnswer" value="" id="selectAnswer2">
            <label for="selectAnswer2"></label>
            <input class="c-form-input" type="text" name="answerText" placeholder="例）ふぁいあ">
            <button type="button"></button>
          </div>
          <div class="c-form-answer">
            <input type="radio" name="selectAnswer" value="" id="selectAnswer3">
            <label for="selectAnswer3"></label>
            <input class="c-form-input" type="text" name="answerText" placeholder="例）ふぁいあ">
            <button type="button"></button>
          </div>
          <div class="c-form-error">正解にチェックを入れてください。</div>
          <div class="c-modal__main__form__input__addButton">
            <button class="c-button c-button-create">選択肢を追加</button>
          </div>
        </div>
        <div class="c-modal__main__form__input">
          <label class="c-form-label">選択肢(チェックボックス)</label>
          <div class="c-form-answer">
            <input type="checkbox" name="selectAnswer" value="" id="selectAnswer4">
            <label for="selectAnswer4"></label>
            <input class="c-form-input" type="text" name="answerText" placeholder="例）ふぁいあ">
            <button type="button"></button>
          </div>
          <div class="c-form-answer">
            <input type="checkbox" name="selectAnswer" value="" id="selectAnswer5">
            <label for="selectAnswer5"></label>
            <input class="c-form-input" type="text" name="answerText" placeholder="例）ふぁいあ">
            <button type="button"></button>
          </div>
          <div class="c-form-answer">
            <input type="checkbox" name="selectAnswer" value="" id="selectAnswer6">
            <label for="selectAnswer6"></label>
            <input class="c-form-input" type="text" name="answerText" placeholder="例）ふぁいあ">
            <button type="button"></button>
          </div>
          <div class="c-form-error">正解にチェックを入れてください。</div>
          <div class="c-modal__main__form__input__addButton">
            <button class="c-button c-button-create">選択肢を追加</button>
          </div>
        </div>
        <div class="c-modal__main__form__input">
          <label class="c-form-label">選択肢(○×)</label>
          <div class="c-form-answer">
            <input type="checkbox" name="selectAnswer" value="" id="selectAnswer7">
            <label for="selectAnswer7"></label>
            <input class="c-form-input" type="text" name="answerText" placeholder="例）ふぁいあ">
            <button type="button"></button>
          </div>
          <div class="c-form-answer">
            <input type="checkbox" name="selectAnswer" value="" id="selectAnswer8">
            <label for="selectAnswer8"></label>
            <input class="c-form-input" type="text" name="answerText" placeholder="例）ふぁいあ">
            <button type="button"></button>
          </div>
          <div class="c-form-answer">
            <input type="checkbox" name="selectAnswer" value="" id="selectAnswer9">
            <label for="selectAnswer9"></label>
            <input class="c-form-input" type="text" name="answerText" placeholder="例）ふぁいあ">
            <button type="button"></button>
          </div>
          <div class="c-form-error">正解(○)にチェックを入れてください。</div>
          <div class="c-modal__main__form__input__addButton">
            <button class="c-button c-button-create">選択肢を追加</button>
          </div>
        </div>
        <div class="c-modal__main__form__input">
          <label class="c-form-label c-form-label-required" for="wordLimit">記述式の文字数制限</label>
          <div class="c-modal__main__form__input__word">
            <input class="c-form-input" type="text" name="wordLimit" id="wordLimit">
          </div>
          <div class="c-form-error">文字数を入力してください。</div>
        </div>
        <div class="c-modal__main__form__input">
          <label class="c-form-label c-form-label-required" for="allocate">配点</label>
          <div class="c-modal__main__form__input__score">
            <input class="c-form-input" type="text" name="allocate" id="allocate">
          </div>
          <div class="c-form-error">配点を入力してください。</div>
        </div>
      </div>
      <div class="c-modal__main__control">
        <div class="c-modal__main__control__buttons">
          <div class="c-modal__main__control__buttons__button">
            <button class="c-button c-button-cancel" (click)="closeEditModal()">保存せずに戻る</button>
          </div>
          <div class="c-modal__main__control__buttons__button">
            <button class="c-button c-button-primary" (click)="closeEditModal()">保存する</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
