import {Injectable} from '@angular/core';
import {User} from '../../models/user';
import {Account} from '../../models/account';
import {Config} from '../../models/config';
import {environment} from '../../../environments/environment';
import {
  AngularFirestore,
} from "@angular/fire/compat/firestore";
import {BackendService} from "../backend/backend.service";
@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  accounts: Account[] = [];
  account: Account = new Account();
  constructor(
    private fireStore: AngularFirestore,
    private backendService: BackendService,
  ) { }
  async getMyClientIp(): Promise<string> {
    const ipAddress = await this.backendService.get('addresses/client-ip/get') as {ipAddress: string};
    return ipAddress?.ipAddress as string;
  }

  async isAcceptByClientIp(account: Account): Promise<boolean> {
    const ipAddress = await this.backendService.get('addresses/client-ip/get') as {ipAddress: string};
    if (!account?.protectByIpAddress) {
      return true;
    }
    if (!account?.acceptedIpAddresses) {
      return false;
    }
    if (account?.acceptedIpAddresses.length === 0) {
      return false;
    }
    for (let a of account.acceptedIpAddresses) {
      if (a === ipAddress.ipAddress) {
        return true;
      }
    }
    return false;
  }
  fetchAccount(accountId: string): Promise<Account> {
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('accounts')
        .doc(accountId)
        .get()
        .toPromise()
        .then((dSnapShot: any ) => {
          if (dSnapShot.data() === undefined) {
            resolve(undefined);
            return;
          } else {
            const newDoc: Account = dSnapShot.data();
            newDoc.id = dSnapShot.id;
            resolve(newDoc);
            return;
          }
        })
        .catch((e) => {
          reject(e);
        });
    })
  }
  update(accountId: string, account: Account): Promise<void> {
    const copied = JSON.parse(JSON.stringify(account));
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('accounts')
        .doc(accountId)
        .set(copied)
        .then(() => {
          resolve();
        })
        .catch((e) => {
          console.log('reject');
          reject(e);
        });
    })
  }
  async fetchAccounts(uid: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('users')
        .doc(uid)
        .collection('accounts')
        .get()
        .toPromise()
        .then(( querySnapshot ) => {
          this.accounts = [];
          querySnapshot.forEach((doc) => {
            this.fetchAccount(doc.id)
              .then((account) => {
                if (this.accounts.findIndex( a => a.id === doc.id) === -1) {
                  this.accounts.push(account);
                }
              })
              .catch((e) => {
                if (e === 'account not found.') {
                  this.removeAccountInUser(
                    uid,
                    doc.id
                  )
                    .catch(() => {})
                }
              });
          });
          resolve(true);
        }).catch(() => {
        reject([]);
      });
    })
  }
  removeAccountInUser(uid: string, accountId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('users')
        .doc(uid)
        .collection('accounts')
        .doc(accountId)
        .delete()
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
      });
    })
  }
  addUser(accountId: string, user: User): Promise<void> {
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('accounts')
        .doc(accountId)
        .collection('users')
        .doc(user.id)
        .set(user)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    })
  }
  addAccountOnUser(accountId: string, user: User): Promise<void> {
    const postParams = {
      account: {
        write: true,
        read: true,
        admin: true,
      },
      customerNotes: {
        write: true,
        read: true,
        admin: true,
      }
    }
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('users')
        .doc(user.id)
        .collection('accounts')
        .doc(accountId)
        .set(postParams)
        .then(() => {
          resolve();
        })
        .catch(() => {
          console.log('reject');
          reject();
        });
    })
  }
  addAppConfig(accountId: string, currentConfig: Config): Promise<any> {
    return new Promise((resolve, reject) => {
      const json = JSON.stringify(currentConfig)
      this.fireStore
        .collection('accounts')
        .doc(accountId)
        .collection('apps')
        .doc(environment.appId)
        .collection('configs')
        .doc('line')
        .set(JSON.parse(json))
        .then(() => {
          resolve(true);
        })
        .catch((e) => {
          console.log('reject');
          reject(e);
        });
    })
  }
  fetchConfig(accountId: string): Promise<Config> {
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('accounts')
        .doc(accountId)
        .collection('apps')
        .doc(environment.appId)
        .collection('configs')
        .doc('line')
        .get()
        .toPromise()
        .then((data: any) => {
          if (!data.data()) {
            reject()
          }
          resolve(data.data() as Config);
        })
        .catch(() => {
          console.log('reject');
          reject();
        });
    })
  }
  updateCurrentAccount(
    uid: string,
    accountId: string,
    accountName: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const postParams = {
        currentAccountId: accountId,
        currentAccountName: accountName
      }
      this.fireStore
        .collection('users')
        .doc(uid)
        .update(postParams)
        .then(() => {
          resolve();
        })
        .catch(() => {
          console.log('reject');
          reject();
        });
    })
  }
}
