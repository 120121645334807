import {User} from "./user";

export enum ReportingStatus {
  submitted = 'submitted',
  reject = 'reject',
  undecided = 'undecided',
  passed = 'passed'
}

export interface ReportDocInterface {
  id?: string;
  uid: string;
  user?: User;
  result: ReportingStatus;
  objectName: string;
  fileName: string;
  comment: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  [propertyNames: string]: any
}
export class ReportDoc implements ReportDocInterface {
  id?: string;
  uid: string;
  user?: User;
  result: ReportingStatus;
  objectName: string;
  fileName: string;
  comment: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  constructor() {
    this.result = ReportingStatus.undecided;
    this.comment = '';
    this.createdAt = new Date();
    this.updatedAt = new Date();
    this.createdBy = null;
    this.updatedBy = null;
  }
  deserialize(): any {
    const json = JSON.stringify(this)
    return JSON.parse(json)
  }
}
