import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from "@angular/fire/compat/firestore";
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {ContentFrame} from '../../models/course-frames';

@Injectable({
  providedIn: 'root'
})
export class CourseCoverFramesService {
  dbDocument: AngularFirestoreDocument<any>
  collectionRef: AngularFirestoreCollection
  framesDoc: Observable<ContentFrame | undefined>;
  constructor(
    private fireStore: AngularFirestore,
  ) { }
  create(
    accountId: string,
    courseId: string,
    uid: string,
    partId: string,
    frames: ContentFrame
  ): Promise<void> {
    frames.id = partId;
    frames.createdAt = new Date();
    frames.createdBy = uid;
    frames.updatedAt = new Date();
    frames.updatedBy = uid;
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('accounts')
        .doc(accountId)
        .collection('apps')
        .doc(environment.appId)
        .collection('courses')
        .doc(courseId)
        .collection('coverFrames')
        .doc(partId)
        .set(frames.deserialize())
        .then(() => {
          resolve();
        })
        .catch((e) => {
          reject(e);
        });
    })
  }
  async fetch(
    accountId: string,
    courseId: string,
    partId: string,
  ): Promise<ContentFrame> {
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('accounts')
        .doc(accountId)
        .collection('apps')
        .doc(environment.appId)
        .collection('courses')
        .doc(courseId)
        .collection('coverFrames')
        .doc(partId)
        .get()
        .toPromise()
        .then((data) => {
          const newDoc = data.data() as ContentFrame;
          newDoc.id = data.id;
          resolve(newDoc);
        })
        .catch(() => {
          reject(null);
        });
    })
  }
  async update(
    accountId: string,
    courseId: string,
    uid: string,
    partId: string,
    frame: ContentFrame
  ): Promise<void> {
    console.log(frame);
    frame.updatedAt = new Date();
    frame.updatedBy = uid;
    const forSave = JSON.parse(JSON.stringify(frame));
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('accounts')
        .doc(accountId)
        .collection('apps')
        .doc(environment.appId)
        .collection('courses')
        .doc(courseId)
        .collection('coverFrames')
        .doc(partId)
        .set(forSave)
        .then(() => {
          resolve();
        })
        .catch((e) => {
          console.log('reject');
          reject(e);
        });
    })
  }
  async remove(
    accountId: string,
    courseId: string,
    partId: string,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('accounts')
        .doc(accountId)
        .collection('apps')
        .doc(environment.appId)
        .collection('courses')
        .doc(courseId)
        .collection('coverFrames')
        .doc(partId)
        .delete()
        .then(() => {
          resolve();
        })
        .catch((e) => {
          console.log('reject');
          reject(e);
        });
    })
  }
  connectSnapShot(
    accountId: string,
    curseId: string,
    partId: string,
  ): void {
    this.dbDocument = this.fireStore
      .collection<{}>('accounts')
      .doc(accountId)
      .collection<{}>('apps')
      .doc(environment.appId)
      .collection<{}>('courses')
      .doc(curseId)
      .collection('coverFrames')
      .doc(partId)
    this.dbDocument.get().toPromise().then(() => {});
    this.framesDoc = this.dbDocument.valueChanges({ idField: "id" });
  }
}
