<app-header-contents-channel></app-header-contents-channel>
<div class="l-content-front">
  <app-breadcrumb-front></app-breadcrumb-front>
  <div class="l-content-front__inner">
    <div class="c-content-header">
      <div class="c-content-header__title" translate>パスワード変更</div>
    </div>
    <div class="l-myPage">
      <div class="p-myPage">
        <div class="p-myPage__form">
          <div class="p-myPage__form__input">
            <label class="c-form-label" translate>新しいパスワード</label>
            <input
              [formControl]="password"
              class="c-form-input"
              type="password"
              name="password"
              id="password"
              placeholder="パスワード6文字以上"
              [placeholder]="'パスワード6文字以上' | translate"
            >
          </div>
          <div class="p-myPage__form__input">
            <label class="c-form-label" translate>パスワード再入力</label>
            <input
              [formControl]="passwordRe"
              class="c-form-input"
              type="password"
              name="passwordConfirmation"
              id="passwordConfirmation"
              placeholder="パスワード再入力"
              [placeholder]="'パスワード再入力' | translate"
            >
          </div>
        </div>
        <div class="p-myPage__buttons">
          <div class="p-myPage__buttons__button">
            <button
              (click)="back()"
              class="c-button c-button-cancel"
            ><span translate>戻る</span></button>
          </div>
          <div class="p-myPage__buttons__button">
            <button
              (click)="change()"
              class="c-button c-button-primary"
            ><span translate>パスワードを変更する</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
