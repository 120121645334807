<section
  (click)="closeModal()"
  class="l-modalTopLayer" *ngIf="isOpenCreditModal">
  <div class="c-modal">
    <div class="c-modal__head">
      <div class="c-modal__head__title" translate>
        クレジットカード登録・変更
      </div>
      <div class="c-modal__head__closeButton" (click)="closeModal()"></div>
    </div>
    <div class="c-modal__main">

      <div class="l-myPage">
        <div class="p-myPage">
          <div class="p-myPage__notes" translate>
            お支払いに使用するクレジットカードを登録・変更します。
          </div>

          <div class="p-myPage__info">
            <div class="p-myPage__info__title" translate>支払いに設定された優先のクレジットカード</div>
            <div class="p-myPage__info__detail">VISA xxxx-xxxx-xxxx-1234</div>
          </div>

          <div class="p-myPage__info">
            <div class="p-myPage__info__title" translate>登録済みのクレジットカード</div>
            <select class="c-form-select">
              <option>VISA xxxx-xxxx-xxxx-1234</option>
            </select>
          </div>


          <div class="p-myPage__info">
            <div class="p-myPage__buttons">
              <div class="p-myPage__buttons__button">
                <button class="c-button c-button-primary"><span translate>支払に使用する</span></button>
              </div>
            </div>
          </div>


          <div class="p-myPage__info">
            <div class="p-myPage__subTitle" translate>カード情報変更</div>
            <div class="p-myPage__form">
              <div class="p-myPage__form__input">
                <label class="c-form-label" for="cardNumber">
                  <span translate>カード番号</span>
                  <span class="c-form-required" translate>必須</span>
                </label>
                <input
                  [formControl]="cardNumber"
                  class="c-form-input" type="text" name="cardNumber" id="cardNumber" placeholder="1234123412341234">
                <div
                  *ngIf="cardNumber.invalid"
                  class="c-form-error" translate>カード番号を入力してください。</div>
              </div>
              <div class="p-myPage__form__input">
                <label class="c-form-label" for="expireY">
                  <span translate>有効期限(年)</span>
                  <span class="c-form-required" translate>必須</span>
                </label>
                <input
                  [formControl]="expireY"
                  class="c-form-input"
                  type="text"
                  name="expireY"
                  id="expireY"
                  [placeholder]="'YY(年）' | translate"
                >
                <div
                  *ngIf="expireY.invalid"
                  class="c-form-error">有効期限(年)を入力してください。</div>
              </div>
              <div class="p-myPage__form__input">
                <label class="c-form-label" for="expireM">
                  <span translate>有効期限(月)</span>
                  <span class="c-form-required" translate>必須</span>
                </label>
                <input
                  [formControl]="expireM"
                  class="c-form-input"
                  type="text"
                  name="expireM"
                  id="expireM"
                  [placeholder]="'MM(月）' | translate"
                >
                <div
                  *ngIf="expireM.invalid"
                  class="c-form-error" translate>有効期限(月)を入力してください。</div>
              </div>
              <div class="p-myPage__form__input">
                <label class="c-form-label" for="cvc">
                  <span translate>CVC番号(セキュリティコード）</span>
                  <span class="c-form-required" translate>必須</span>
                </label>
                <input
                  [formControl]="cvc"
                  class="c-form-input" type="text" name="cvc" id="cvc" placeholder="123">
                <div
                  *ngIf="cvc.invalid"
                  class="c-form-error" translate>CVC番号(セキュリティコード）を入力してください。</div>
              </div>
              <div class="p-myPage__form__input">
                <label class="c-form-label" for="holderName">
                  <span translate>カードのお名前</span>
                  <span class="c-form-required" translate>必須</span>
                </label>
                <input
                  [formControl]="cvc"
                  class="c-form-input" type="text" name="holderName" id="holderName" placeholder="ICHIRO YAMADA">
                <div
                  *ngIf="holderName.invalid"
                  class="c-form-error" translate>お名前を入力してください。</div>
              </div>
            </div>
          </div>

          <div class="p-myPage__buttons">
            <div class="p-myPage__buttons__button">
              <button
                (click)="closeModal()"
                class="c-button c-button-cancel"
              ><span translate>キャンセルして戻る</span></button>
            </div>
            <div class="p-myPage__buttons__button">
              <button
                (click)="submit()"
                class="c-button c-button-primary"
              ><span translate>保存する</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div id="fincodeScript"></div>

