import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Confirm} from "../../models/confirm";
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  @Input() isOpenModal = true;
  @Input() modalData: Confirm;
  @Output() isOpenModalEmitter = new EventEmitter<boolean>();
  @Output() confirmedEmitter = new EventEmitter<boolean>();

  constructor() {}
  async ngOnInit(): Promise<void> {}
  ngOnDestroy(): void{}
  closeModal(): void {
    this.isOpenModalEmitter.emit(false);
  }
  cancel(): void {
    this.isOpenModalEmitter.emit(false);
    this.confirmedEmitter.emit(false);
  }
  apply(): void {
    this.isOpenModalEmitter.emit(false);
    this.confirmedEmitter.emit(true);
  }
}
