<app-forbidden *ngIf="forbidden"></app-forbidden>
<div *ngIf="!forbidden && currentChannel.slug"
  class="l-main">
  <app-navigations></app-navigations>
  <div class="l-content">
    <app-breadcrumb></app-breadcrumb>
    <div class="l-content__inner">
      <div class="c-content-header">
        <div class="c-content-header__channelName">
          <span translate>現在のチャンネル</span> {{currentChannel.name}} ({{currentChannel.slug}})
        </div>
        <div
          *ngIf="channelOwnerAccount?.accountType !== accountTypes.oem"
          class="c-content-header__title"
          translate
        >サマリー</div>
      </div>
      <div class="l-templates">
        <div class="p-templates">
          <ng-container *ngIf="channelOwnerAccount?.accountType !== accountTypes.oem">
            <div class="p-templates__content">
              <div class="p-templates__content__summary">
                <div class="p-templates__content__summary__item">
                  <div class="p-templates__content__summary__item__title" translate>総登録者数</div>
                  <div class="p-templates__content__summary__item__detail">0<span translate>人</span></div>
                </div>
                <div class="p-templates__content__summary__item">
                  <div class="p-templates__content__summary__item__title" translate>コース登録数</div>
                  <div class="p-templates__content__summary__item__detail">0<span translate>講座</span></div>
                </div>
                <div class="p-templates__content__summary__item">
                  <div class="p-templates__content__summary__item__title" translate>今月の新規受講</div>
                  <div class="p-templates__content__summary__item__detail">0<span></span></div>
                </div>
                <div class="p-templates__content__summary__item">
                  <div class="p-templates__content__summary__item__title" translate>気になるリスト登録数</div>
                  <div class="p-templates__content__summary__item__detail">0<span></span></div>
                </div>
                <div class="p-templates__content__summary__item">
                  <div class="p-templates__content__summary__item__title" translate>今月の登録者数</div>
                  <div class="p-templates__content__summary__item__detail">0<span translate>人</span></div>
                </div>
                <div class="p-templates__content__summary__item">
                  <div class="p-templates__content__summary__item__title" translate>コース受講者のべ</div>
                  <div class="p-templates__content__summary__item__detail">0<span translate>人</span></div>
                </div>
                <div class="p-templates__content__summary__item">
                  <div class="p-templates__content__summary__item__title" translate>今月の修了数</div>
                  <div class="p-templates__content__summary__item__detail">0<span></span></div>
                </div>
                <div class="p-templates__content__summary__item">
                  <div class="p-templates__content__summary__item__title" translate>今月の売上</div>
                  <div class="p-templates__content__summary__item__detail">0<span translate>円</span></div>
                </div>
                <div class="p-templates__content__summary__item">
                  <div class="p-templates__content__summary__item__title" translate>今月の退会者数</div>
                  <div class="p-templates__content__summary__item__detail">0<span translate>人</span></div>
                </div>
                <div class="p-templates__content__summary__item">
                  <div class="p-templates__content__summary__item__title" translate>メンバーシップ登録数</div>
                  <div class="p-templates__content__summary__item__detail">0<span translate>人</span></div>
                </div>
              </div>
            </div>

          </ng-container>
        </div>
      </div>
      <div class="c-content-header">
        <div class="c-content-header__title" translate>チャンネルへの問い合わせ未読リスト</div>
      </div>
      <div translate>未読をクリックすると既読に変更されます。既読になった未読データはこのページを離れると削除されます。未読に戻すにはリスト行をダブルクリックします。</div>
      <div class="l-templates">
        <div class="p-templates">
          <div class="p-templates__content">
            <div class="p-chats-manager">
              <ng-container *ngFor="let u of unreadPosts">
                <div  (dblclick)="returnUnread(u)" class="p-chats-manager__item">
                  <div
                    *ngIf="!u.read"
                    class="p-chats-manager__item__status p-chats-manager__item__status-unread"
                    translate
                  >未読</div>
                  <div
                    *ngIf="u.read"
                    class="p-chats-manager__item__status"
                    translate
                  >既読</div>
                  <div class="p-chats-manager__item__date">{{u.createdAt | datetimeFormatFirestore}}</div>
                  <div class="p-chats-manager__item__userName">{{getUserName(u.uid)}}</div>
                  <div class="p-chats-manager__item__detail">{{u.body}}</div>
                  <div class="p-chats-manager__item__button"><button (click)="clickUnread(u)"
                  ><span translate>対応する</span></button></div>
                </div>

              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="channelOwnerAccount?.accountType !== accountTypes.oem">
        <div class="c-content-header">
          <div class="c-content-header__title" translate>チャンネル登録新着</div>
        </div>
        <div class="l-templates">
          <div class="p-templates">
            <div class="p-templates__content">
              <div class="p-chats-manager">
                <!--<div class="p-chats-manager__item">
                  <div class="p-chats-manager__item__status p-chats-manager__item__status-unread">未読</div>
                  <div class="p-chats-manager__item__date">2022年07月21日 20時22分</div>
                  <div class="p-chats-manager__item__userName">名無し〇〇〇</div>
                  <div class="p-chats-manager__item__detail">無いよう内容無いよう内容無いよう内容無いよう内容無無いよう内容無</div>
                </div>
                <div class="p-chats-manager__item">
                  <div class="p-chats-manager__item__status p-chats-manager__item__status-unread">未読</div>
                  <div class="p-chats-manager__item__date">2022年07月21日 20時22分</div>
                  <div class="p-chats-manager__item__userName">名無し〇〇〇</div>
                  <div class="p-chats-manager__item__detail">無いよう内容無いよう内容無いよう内容無いよう内容無無いよう内容無</div>
                </div>
                <div class="p-chats-manager__item">
                  <div class="p-chats-manager__item__status p-chats-manager__item__status-unread">未読</div>
                  <div class="p-chats-manager__item__date">2022年07月21日 20時22分</div>
                  <div class="p-chats-manager__item__userName">名無し〇〇〇</div>
                  <div class="p-chats-manager__item__detail">無いよう内容無いよう内容無いよう内容無いよう内容無無いよう内容無</div>
                </div>
                <div class="p-chats-manager__item">
                  <div class="p-chats-manager__item__status p-chats-manager__item__status-unread">未読</div>
                  <div class="p-chats-manager__item__date">2022年07月21日 20時22分</div>
                  <div class="p-chats-manager__item__userName">名無し〇〇〇</div>
                  <div class="p-chats-manager__item__detail">無いよう内容無いよう内容無いよう内容無いよう内容無無いよう内容無</div>
                </div>-->
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<app-chat
  (isOpenModalEmitter)="changeOpenChatModal($event)"
  [isOpenModal]="isOpenChatModal"
  [onUser]="false"
  [targetUser]="chatTargetUser"
  [channel]="currentChannel"
  [account]="channelOwnerAccount"
></app-chat>
