<app-not-found *ngIf="isNotFound"></app-not-found>
<app-forbidden *ngIf="forbidden"></app-forbidden>
<div
  *ngIf="!isNotFound && !forbidden && currentCourse.id"
  class="l-main">
  <app-navigations></app-navigations>
  <div class="l-content">
    <app-breadcrumb></app-breadcrumb>
    <div class="l-content__inner">
      <div class="c-content-header">
        <div class="c-content-header__channelName">
          <span translate>現在のチャンネル</span>
          {{currentChannel.name}}
          <span>({{currentChannel.slug}})</span>
        </div>
        <div class="c-content-header__channelName">
          <span translate>現在のコース</span>
          {{currentCourse.name}}
          <span>(id: {{currentChannel.id}})</span>
        </div>
        <div class="c-content-header__title" translate>コース概要情報の編集</div>
      </div>
      <div class="l-templates">
        <div class="p-templates">
          <div class="p-templates__content">
            <div class="p-profile">
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label
                    class="c-form-label"
                    for="name"
                  >
                    <span translate>コース名</span>
                    <span class="c-form-required" translate>必須</span>
                  </label>
                  <input
                    [formControl]="name"
                    class="c-form-input"
                    type="text"
                    name="name"
                    id="name"
                    [placeholder]="'例）ふぁいあ' | translate"
                  >
                  <div
                    *ngIf="name.dirty && name.invalid"
                    class="c-form-error" translate
                  >コース名は8文字〜100文字以内で入力してください。</div>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__textArea">
                  <label class="c-form-label" for="invalid" translate>コース概要説明</label>
                  <textarea
                    [formControl]="description"
                    class="c-form-textArea"
                    name="invalid"
                    id="invalid"
                    [placeholder]="'コース概要説明を入力してください。' | translate"
                  ></textarea>
                </div>
                <div
                  *ngIf="description.dirty && description.invalid"
                  class="c-form-error" translate>
                  8文字〜2,000文字以内で入力してください。
                </div>
              </div>

              <div class="p-profile__row">
                <label class="c-form-label" translate>コースアイキャッチ画像</label>
                <div class="c-form-images">
                  <div class="c-form-images__image">
                    <img
                      *ngIf="!currentCourse?.eyeCatchImage?.src"
                      ngSrc="no-image"
                      width="240"
                      height="135"
                      ngSrcset="160w,240w,320w,480w"
                      alt="">
                    <div
                      *ngIf="currentCourse?.eyeCatchImage?.src"
                      (click)="removeImageEyeCatch()"
                         class="c-form-images__image__remove"></div>
                    <img
                      *ngIf="currentCourse?.eyeCatchImage?.src"
                      ngSrc="{{currentCourse?.eyeCatchImage?.src}}"
                      width="240"
                      height="135"
                      ngSrcset="160w,240w,320w,480w"
                      alt="">
                  </div>
                </div>
                <div
                  *ngIf="!currentCourse?.eyeCatchImage?.src"
                  class="c-form-error"
                  translate
                >コースアイキャッチ画像をアップロードしてください。</div>
                <div class="p-profile__row__buttons">
                  <div class="p-profile__row__buttons__button">
                    <button
                      *ngIf="!currentCourse?.eyeCatchImage?.src"
                      (click)="openEyeCatchImageUploadModal()"
                      class="c-button c-button-primary"
                      id="eyeCatch">
                      <span translate>画像をアップロード</span>
                    </button>
                    <button
                      *ngIf="currentCourse?.eyeCatchImage?.src"
                      (click)="removeImageEyeCatch()"
                      class="c-button c-button-cancel"
                      type="button"
                    ><span translate>アイキャッチを削除する</span></button>
                  </div>
                </div>
              </div>


              <div class="p-profile__row">
                <label class="c-form-label" translate>アピール画像</label>
                <div class="c-form-images">
                  <img
                    *ngIf="currentChannel?.eyeCatchImage?.src.length === 0"
                    ngSrc="no-image"
                    width="240"
                    height="135"
                    ngSrcset="160w,240w,320w,480w"
                    alt="">
                  <ng-container
                    *ngFor="let pr of currentCourse?.prImages; index as i">
                    <div class="c-form-images__image">
                      <div (click)="removeImage(i)"
                        class="c-form-images__image__remove"></div>
                      <img
                        *ngIf="!pr?.src"
                        ngSrc="no-image"
                        width="240"
                        height="135"
                        ngSrcset="160w,240w,320w,480w"
                        alt="">
                      <img
                        *ngIf="pr?.src"
                        [ngSrc]="pr?.src"
                        width="240"
                        height="135"
                        ngSrcset="160w,240w,320w,480w"
                        alt="">
                    </div>
                  </ng-container>
                  <div
                    *ngIf="!currentCourse?.prImages || currentCourse?.prImages?.length === 0"
                    class="c-form-images__image">
                    <img ngSrc="no-image"
                         width="240"
                         height="135"
                         ngSrcset="160w,240w,320w,480w"
                         alt="">
                  </div>
                </div>
                <div
                  *ngIf="currentCourse?.prImages?.length === 0"
                  class="c-form-error" translate>アピール画像を選択してください。</div>
                <div
                  class="p-profile__row__buttons">
                  <div class="p-profile__row__buttons__button">
                    <button
                      *ngIf="currentCourse?.prImages?.length < 8"
                      (change)="uploadImagePrImages($event)"
                      (click)="openPrImageUploadModal()"
                      class="c-button c-button-primary"
                      id="prImageUpload"
                    ><span translate>画像をアップロード</span>
                    </button>
                  </div>
                </div>
              </div>

              <div class="p-profile__row">
                <label class="c-form-label" translate>アピール動画</label>
                <div
                  cdkDropList
                  (cdkDropListDropped)="dropSamples($event)"
                  class="c-form-images">
                  <div
                    *ngIf="!currentCourse?.samples || currentCourse?.samples?.length === 0"
                    class="c-form-images__image">
                    <img ngSrc="no-image"
                         width="240"
                         height="135"
                         ngSrcset="160w,240w,320w,480w"
                         alt="">
                  </div>

                  <div
                    *ngFor="let sample of currentCourse?.samples;index as i"
                    class="c-form-images__image">
                    <div
                      (click)="removeSampleVideo(i)"
                      class="c-form-images__image__remove"></div>
                    <img
                      (click)="openSampleVideoModal(i)"
                      *ngIf="!sample?.eyeCatchImage?.src"
                      ngSrc="no-image"
                      width="240"
                      height="135"
                      ngSrcset="160w,240w,320w,480w"
                      alt="">
                    <img
                      (click)="openSampleVideoModal(i)"
                      *ngIf="sample?.eyeCatchImage?.src"
                      ngSrc="{{sample?.eyeCatchImage?.src}}"
                      width="240"
                      height="135"
                      ngSrcset="160w,240w,320w,480w"
                      alt="">
                  </div>
                </div>
                <div
                  *ngIf="!currentCourse?.samples && currentCourse?.samples?.length > 0"
                  class="c-form-error" translate>サンプル動画をアップロードしてください。</div>
                <div class="c-form-advice" translate>画像をクリックすると編集することができます。</div>
                <div
                  class="p-profile__row__buttons">
                  <div class="p-profile__row__buttons__button">
                    <button
                      (click)="openVideoUploadModal()"
                      class="c-button c-button-primary"
                      id="sampleVideoUpload"
                    ><span translate>動画をアップロード</span>
                    </button>
                  </div>
                </div>
              </div>


              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="categoryId" translate>カテゴリーを選択</label>
                  <select
                    [formControl]="categoryId"
                    class="c-form-input" name="categoryId" id="categoryId">
                    <option value="" translate>選択してください</option>
                    <option
                      *ngFor="let c of formCategories"
                      [ngValue]="c.id"
                      translate
                    >{{c.name}}</option>
                  </select>
                </div>
              </div>

              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="tagKeyword" translate>ハッシュタグ</label>
                  <div
                    *ngIf="tagPayload.length > 0"
                    class="c-tag">
                    <div
                      *ngFor="let tag of tagPayload"
                      (click)="removeContentTag(tag)"
                      class="c-tag__item"><span>#{{tag.name}}</span></div>
                  </div>
                  <div class="c-form__box">
                    <div class="c-form__box__row">
                      <input
                        [formControl]="tagKeyword"
                        (keyup)="searchTags()"
                        class="c-form-input c-form__box__row__input"
                        type="text"
                        name="tagKeyword"
                        id="tagKeyword"
                        [placeholder]="'タグを探す(前方一致)' | translate"
                      >
                      <button
                        *ngIf="canAddTag()"
                        (click)="addContentTag()"
                        class="c-form__box__row__button c-form__box__row__button-add c-form__box__row__button-short">+</button>
                    </div>
                    <div
                      *ngIf="tagList.length > 0"
                      class="l-suggestionsModal">
                      <div class="c-suggestionsModal">
                        <div class="c-suggestionsModal__items">
                          <div
                            *ngFor="let item of tagList"
                            (click)="pushContentTag(item)"
                            class="c-suggestionsModal__items__item">
                            <span>{{item.name}}</span>
                          </div>
                        </div>
                        <div
                          (click)="closeTagModal()"
                          class="c-suggestionsModal__hint"
                          translate
                        >閉じる</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="p-profile__row">
                <label class="c-form-label" translate>公開/非公開</label>
                <div class="c-form-radio">
                  <div class="c-form-radio__wrap">
                    <input
                      [formControl]="published"
                      [value]="true"
                      type="radio" name="published" id="published-true">
                    <label class="c-form-label" for="published-true" translate>公開</label>
                  </div>
                  <div class="c-form-radio__wrap">
                    <input
                      [formControl]="published"
                      [value]="false"
                      type="radio" name="published" id="published-false">
                    <label class="c-form-label" for="published-false" translate>非公開</label>
                  </div>
                </div>
              </div>

              <div class="p-profile__row">
                <label class="c-form-label" translate>販売形態</label>
                <div class="c-form-radio">
                  <div class="c-form-radio__wrap">
                    <input
                      [formControl]="monetized"
                      type="radio" name="monetizeMethod" id="monetized-true" [value]="true">
                    <label class="c-form-label" for="monetized-true" translate>有料</label>
                  </div>
                  <div class="c-form-radio__wrap">
                    <input
                      [formControl]="monetized"
                      type="radio" name="monetizeMethod" id="monetized-false" [value]="false">
                    <label class="c-form-label" for="monetized-false" translate>無料(無料体験)</label>
                  </div>
                </div>
              </div>
              <div class="p-profile__row">
                <label class="c-form-label" translate>課金方法</label>
                <div class="c-form-radio">
                  <div
                    *ngFor="let m of formMonetizeMethods"
                    class="c-form-radio__wrap">
                    <input
                      [formControl]="monetizeMethod"
                      type="radio" name="billing" [value]="m.key" id="{{m.key}}">
                    <label class="c-form-label" for="{{m.key}}" translate>{{m.nameJa}}</label>
                  </div>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="unitPrice" translate>単体販売時の価格</label>
                  <div class="p-profile__row__input__price">
                    <input
                      [formControl]="unitPrice"
                      class="c-form-input" type="text" name="unitPrice" id="unitPrice">
                  </div>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="price2" translate>コース単体サブスクリプション時の月額料金</label>
                  <div class="p-profile__row__input__price">
                    <input
                      [formControl]="courseSubscriptionPrice"
                      class="c-form-input" type="text" name="price2" id="price2">
                  </div>
                </div>
              </div>
              <div class="p-profile__row">
                <label class="c-form-label" translate>公開範囲</label>
                <div class="c-form-radio">
                  <div
                    *ngFor="let p of formPublishRanges"
                    class="c-form-radio__wrap">
                    <input
                      [formControl]="publishRange"
                      type="radio" name="formPublishRanges" [value]="p.key" id="{{p.key}}">
                    <label class="c-form-label" for="{{p.key}}" translate>{{p.nameJa}}</label>
                  </div>
                </div>
              </div>

              <div class="p-profile__row">
                <div class="p-profile__row__head">
                  <label class="c-form-label" translate>リマインダー</label>
                  <div
                    class="c-form-advice"
                    translate
                  >最終の学習から7日以上経っていて学習が100％完了していない場合リマインダーを送信</div>
                </div>
                <div class="c-form-radio">
                  <div class="c-form-radio__wrap">
                    <input
                      [formControl]="useReminder"
                      [value]="true"
                      type="radio" name="reminder" id="reminder-true">
                    <label class="c-form-label" for="reminder-true">ON</label>
                  </div>
                  <div class="c-form-radio__wrap">
                    <input
                      [formControl]="useReminder"
                      [value]="false"
                      type="radio" name="reminder" id="reminder-false">
                    <label class="c-form-label" for="reminder-false">OFF</label>
                  </div>
                </div>
              </div>

              <div class="p-profile__row">
                <div class="p-profile__row__buttons">
                  <div class="p-profile__row__buttons__button">
                    <button
                      (click)="back()"
                      class="c-button c-button-cancel"><span translate>戻る</span></button>
                  </div>
                  <div class="p-profile__row__buttons__button">
                    <button
                      (click)="submit()"
                      class="c-button c-button-primary"><span translate>保存する</span></button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>


      <div class="c-content-header">
        <div class="c-content-header__title" translate>コースの削除</div>
      </div>

      <div class="l-templates">
        <div class="p-templates">
          <div class="p-templates__content">
            <div class="p-profile">
              <div class="p-profile__row">
                <div class="p-profile__row__buttons__button">
                  <button
                    routerLink="/manager/channel/course/remove/{{currentChannel.id}}/{{currentCourse.id}}"
                    class="c-button c-button-warning"
                  ><span translate>削除する</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>

<section class="l-modalTopLayer" *ngIf="isOpenEyeCatchImageUploadModal">
  <div class="c-modal">
    <div class="c-modal__head">
      <div class="c-modal__head__title" translate>アイキャッチ画像アップロード</div>
      <div class="c-modal__head__closeButton" (click)="closeEyeCatchImageUploadModal()"></div>
    </div>
    <div class="c-modal__documentUpload">
      <input
        (change)="uploadImageEyeCatch($event)"
        type="file" id="uploadEyeCatchImage">
      <label
        for="uploadEyeCatchImage"
        class="c-modal__documentUpload__description"
        translate
      >ファイルを選択</label>
      <div class="c-modal__documentUpload__uploadButton">
        <button
          (click)="clickUploadFile('uploadEyeCatchImage')"
          type="button" class="c-button c-button-primary"
        ><span translate>アップロードする</span>
        </button>
      </div>
    </div>
  </div>
</section>

<section class="l-modalTopLayer" *ngIf="isOpenPrImageImageUploadModal">
  <div class="c-modal">
    <div class="c-modal__head">
      <div class="c-modal__head__title" translate>アピール画像アップロード</div>
      <div class="c-modal__head__closeButton" (click)="closePrImageUploadModal()"></div>
    </div>
    <div class="c-modal__documentUpload">
      <input
        (change)="uploadImagePrImages($event)"
        type="file" id="uploadPrImage">
      <label
        for="uploadPrImage"
        class="c-modal__documentUpload__description"
        translate
      >ファイルを選択</label>
      <div class="c-modal__documentUpload__uploadButton">
        <button
          (click)="clickUploadFile('uploadPrImage')"
          type="button" class="c-button c-button-primary"
        ><span translate>アップロードする</span>
        </button>
      </div>
    </div>
  </div>
</section>


<section class="l-modal" *ngIf="isOpenSampleVideoModal">
  <div class="c-modal">
    <div class="c-modal__head">
      <div class="c-modal__head__title" translate>サンプル動画</div>
      <div class="c-modal__head__closeButton" (click)="closeSampleVideoModal()"></div>
    </div>
    <div class="c-modal__main">
      <div class="c-modal__main__form">
        <div class="c-modal__main__form__eyeCatchUpload">
          <label class="c-form-label" translate>動画</label>
          <strong
            *ngIf="!currentCourse?.samples[sampleVideoIndex]?.name"
            translate
          >動画をアップロードしてください</strong>
          <strong *ngIf="currentCourse?.samples[sampleVideoIndex]?.name"
          ><span translate>アップロード済みの動画</span>
            : {{currentCourse?.samples[sampleVideoIndex]?.name}}
          </strong>
          <div class="c-modal__main__form__eyeCatchUpload__button">
            <button
              *ngIf="!currentCourse?.samples[sampleVideoIndex]?.name"
              (click)="openSampleVideoUploadModal()"
              class="c-button c-button-primary"
            ><span translate>動画をアップロード</span>
            </button>
            <button
              *ngIf="currentCourse?.samples[sampleVideoIndex]?.name"
              (click)="openSampleVideoReplacedModal()"
              class="c-button c-button-primary"
            ><span translate>動画を再アップロード</span>
            </button>
          </div>
        </div>

        <div class="c-modal__main__form__eyeCatchUpload">
          <label class="c-form-label" translate>アイキャッチ画像</label>
          <div class="c-modal__main__form__eyeCatchUpload__img">
            <img
              *ngIf="!currentCourse?.samples[sampleVideoIndex]?.eyeCatchImage?.src"
              ngSrc="no-image"
              width="160"
              height="90"
              alt=""
              ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w">
            <img
              *ngIf="currentCourse?.samples[sampleVideoIndex]?.eyeCatchImage?.src"
              ngSrc="{{currentCourse?.samples[sampleVideoIndex]?.eyeCatchImage?.src}}"
              width="160"
              height="90"
              alt=""
              ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w">
          </div>
          <div class="c-modal__main__form__eyeCatchUpload__button">
            <button
              *ngIf="!currentCourse?.samples[sampleVideoIndex]?.eyeCatchImage?.src"
              (click)="openSampleEyeCatchImageUploadModal()"
              class="c-button c-button-primary"
              id="sampleEyeCatch"
            ><span translate>画像をアップロード</span>
            </button>
            <button
              *ngIf="currentCourse?.samples[sampleVideoIndex]?.eyeCatchImage?.src"
              (click)="removeSampleEyeCatchImage()"
              class="c-button c-button-cancel"
              type="button"
            ><span translate>アイキャッチを削除する</span>
            </button>
          </div>
        </div>
      </div>

      <div class="c-modal__main__control">
        <div class="c-modal__main__control__status"></div>
        <div class="c-modal__main__control__buttons">
          <div class="c-modal__main__control__buttons__button">
            <button
              class="c-button c-button-primary"
              (click)="closeSampleVideoModal()"
            ><span translate>閉じる</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<section class="l-modalTopLayer" *ngIf="isOpenSampleVideoUploadModal">
  <div class="c-modal">
    <div class="c-modal__head">
      <div class="c-modal__head__title" translate>サンプル動画アップロード</div>
      <div class="c-modal__head__closeButton" (click)="closeVideoUploadModal()"></div>
    </div>
    <div class="c-modal__documentUpload">
      <input
        (change)="uploadSampleVideo($event)"
        type="file" id="uploadSampleVideo">
      <label
        for="uploadSampleVideo"
        class="c-modal__documentUpload__description"
        translate
      >ファイルを選択</label>
      <div class="c-modal__documentUpload__uploadButton">
        <button
          (click)="clickUploadFile('uploadSampleVideo')"
          type="button"
          class="c-button c-button-primary"
        ><span translate>アップロードする</span>
        </button>
      </div>
    </div>
  </div>
</section>

<section class="l-modalTopLayer" *ngIf="isOpenSampleVideoReplacedModal">
  <div class="c-modal">
    <div class="c-modal__head">
      <div class="c-modal__head__title" translate>サンプル動画再アップロード</div>
      <div class="c-modal__head__closeButton" (click)="closeVideoUploadModal()"></div>
    </div>
    <div class="c-modal__documentUpload">
      <input
        (change)="uploadSampleVideoReplace($event)"
        type="file" id="uploadSampleVideoRe">
      <label
        for="uploadSampleVideoRe"
        class="c-modal__documentUpload__description"
        translate
      >ファイルを選択</label>
      <div class="c-modal__documentUpload__uploadButton">
        <button
          (click)="clickUploadFile('uploadSampleVideoReplace')"
          type="button" class="c-button c-button-primary"
        ><span translate>アップロードする</span>
        </button>
      </div>
    </div>
  </div>
</section>

<section class="l-modalTopLayer" *ngIf="isOpenSampleVideoEyeCatchImageUploadModal">
  <div class="c-modal">
    <div class="c-modal__head">
      <div class="c-modal__head__title" translate>サンプル動画のアイキャッチ画像アップロード</div>
      <div class="c-modal__head__closeButton" (click)="closeSampleVideoEyeCatchModal()"></div>
    </div>
    <div class="c-modal__documentUpload">
      <input
        (change)="uploadSampleVideoImageEyeCatch($event)"
        type="file" id="uploadSampleVideoEyeCatchImage">
      <label
        for="uploadSampleVideoEyeCatchImage"
        class="c-modal__documentUpload__description"
        translate
      >ファイルを選択</label>
      <div class="c-modal__documentUpload__uploadButton">
        <button
          (click)="clickUploadFile('uploadSampleVideoEyeCatchImage')"
          type="button" class="c-button c-button-primary"
        ><span translate>アップロードする</span>
        </button>
      </div>
    </div>
  </div>
</section>
