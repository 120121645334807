<app-header-contents-channel></app-header-contents-channel>
<div class="l-content-front">
  <app-breadcrumb-front></app-breadcrumb-front>
  <div class="l-content-front__inner">
    <div class="p-category">
      <div class="p-category__title" translate>
        カテゴリータイトル
      </div>
      <div class="p-category__inner">
        <div class="p-category__inner__item">
          <div class="p-category__inner__item__thumb">
            <img src="assets/images/sample_contentsChannelTop.png" alt="">
          </div>
          <div class="p-category__inner__item__channelName">「共感講座Ⓡ」シリーズ</div>
          <div class="p-category__inner__item__curseName">損しないための社会人マナー</div>
          <div class="p-category__inner__item__description">〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●</div>
        </div>
        <div class="p-category__inner__item">
          <div class="p-category__inner__item__thumb">
            <img src="assets/images/sample_contentsChannelTop.png" alt="">
          </div>
          <div class="p-category__inner__item__channelName">「共感講座Ⓡ」シリーズ</div>
          <div class="p-category__inner__item__curseName">損しないための社会人マナー</div>
          <div class="p-category__inner__item__description">〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●</div>
        </div>
        <div class="p-category__inner__item">
          <div class="p-category__inner__item__thumb">
            <img src="assets/images/sample_contentsChannelTop.png" alt="">
          </div>
          <div class="p-category__inner__item__channelName">「共感講座Ⓡ」シリーズ</div>
          <div class="p-category__inner__item__curseName">損しないための社会人マナー</div>
          <div class="p-category__inner__item__description">〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●</div>
        </div>
        <div class="p-category__inner__item">
          <div class="p-category__inner__item__thumb">
            <img src="assets/images/sample_contentsChannelTop.png" alt="">
          </div>
          <div class="p-category__inner__item__channelName">「共感講座Ⓡ」シリーズ</div>
          <div class="p-category__inner__item__curseName">損しないための社会人マナー</div>
          <div class="p-category__inner__item__description">〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●</div>
        </div>
        <div class="p-category__inner__item">
          <div class="p-category__inner__item__thumb">
            <img src="assets/images/sample_contentsChannelTop.png" alt="">
          </div>
          <div class="p-category__inner__item__channelName">「共感講座Ⓡ」シリーズ</div>
          <div class="p-category__inner__item__curseName">損しないための社会人マナー</div>
          <div class="p-category__inner__item__description">〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●</div>
        </div>
        <div class="p-category__inner__item">
          <div class="p-category__inner__item__thumb">
            <img src="assets/images/sample_contentsChannelTop.png" alt="">
          </div>
          <div class="p-category__inner__item__channelName">「共感講座Ⓡ」シリーズ</div>
          <div class="p-category__inner__item__curseName">損しないための社会人マナー</div>
          <div class="p-category__inner__item__description">〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●</div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
