import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subscription} from "rxjs";
import {SessionState, setSession} from "../../store/sessions/sessions.reducer";
import {Channel} from "../../models/channel";
import {ChannelsService} from "../../services/channels/channels.service";
import {AuthService} from "../../services/auth/auth.service";
import {Store} from "@ngrx/store";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  private sessionsSubscription: Subscription;
  currentSession: SessionState | undefined;
  channels: Channel[] = [];
  menuClosed = true;
  isOpenSearchModal = false;
  @Input() logo: string = '../../../assets/images/logo_header.png';
  @Output() currentLanguageEmitter = new EventEmitter<'ja' | 'en'>();
  constructor(
    private channelsService: ChannelsService,
    private authService: AuthService,
    private sessionsStore: Store<{ sessionsStore: SessionState }>,
  ) { }
  async ngOnInit(): Promise<void> {
    await this.authService.refresh();
    this.sessionsSubscription = this.sessionsStore
      .select(setSession)
      .pipe()
      .subscribe(async (sessionState) => {
        this.currentSession = sessionState;
      })
    // チャンネルリスト取得
    this.channelsService.fetched = [];
    await new Promise(resolve => setTimeout(resolve, 200));
    if (!this.currentSession.currentUser.currentAccountId) {
      return;
    }
    await this.channelsService.fetchByAccountId(this.currentSession.currentUser.currentAccountId);
    this.channels = this.channelsService.fetched;
  }
  toggleSearchModal(): void {
    if (this.isOpenSearchModal === true) {
      this.isOpenSearchModal = false;
    } else {
      this.isOpenSearchModal = true;
    }
  }
  ngOnDestroy(): void{
    if (this.sessionsSubscription !== undefined) {
      this.sessionsSubscription.unsubscribe();
    }
  }

}
