import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manager-channel-course-remotes',
  templateUrl: './manager-channel-course-remotes.component.html',
  styleUrls: ['./manager-channel-course-remotes.component.scss']
})
export class ManagerChannelCourseRemotesComponent implements OnInit {
  isOpenUploadModal = false;
  isOpenEditModal = false;

  constructor() { }

  ngOnInit(): void {
  }
  openUploadModal(): void {
    this.isOpenUploadModal = true;
  }
  closeUploadModal(): void {
    this.isOpenUploadModal = false;
  }
  openEditModal(): void {
    this.isOpenEditModal = true;
  }
  closeEditModal(): void {
    this.isOpenEditModal = false;
  }
}
