import { Component } from '@angular/core';
import {categories} from "../../models/categories";

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent {

  protected readonly categories = categories;
}
