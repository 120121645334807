import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreDocument, CollectionReference, Query} from "@angular/fire/compat/firestore";
import {Channel} from "../../models/channel";
import {environment} from "../../../environments/environment";
import {Observable, of} from "rxjs";
import {PublishRangesKeys} from "../../models/publish-range";
import {ChannelSubscription} from "../../models/channel-subscription";

@Injectable({
  providedIn: 'root'
})
export class ChannelsService {

  constructor(
    private fireStore: AngularFirestore,
  ) {}
  dbDocument: AngularFirestoreDocument<any>
  fetched: Channel[] = [];
  currentChannel: Observable<Channel> = of(new Channel());
  channels: Channel[] = [];
  async create(
    channel: Channel
  ): Promise<string> {
    const collectionRef =
      this.fireStore.collection('apps')
        .doc(environment.appId)
        .collection('channels')
    const docRef = await collectionRef.add(channel.deserialize());
    return docRef.id;
  }
  async remove(
    channel: Channel
  ): Promise<void> {
    const collectionRef =
      this.fireStore.collection('apps')
        .doc(environment.appId)
        .collection('channels')
    await collectionRef.doc(channel.id).delete();
  }
  async fetch(
    channelId: string
  ): Promise<Channel> {
    return new Promise((resolve, reject) => {
      const collRef =
        this.fireStore.collection('apps')
          .doc(environment.appId)
          .collection('channels')
      collRef.doc(channelId)
        .get()
        .toPromise()
        .then((doc) => {
          if (doc.data()) {
            const newDoc = doc.data() as Channel;
            newDoc.id = doc.id;
            resolve(newDoc);
          }
        })
        .catch();
    });
  }
  async fetchAll(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.fireStore.collection('apps')
        .doc(environment.appId)
        .collection('channels')
        .get()
        .toPromise()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const newDoc = doc.data() as Channel;
            newDoc.id = doc.id;
            this.channels.push(newDoc);
            resolve();
          })
        })
        .catch();
    });
  }
  async fetchBySlug(
    slug: string
  ): Promise<Channel> {
    return new Promise((resolve, reject) => {
      const docRef =
        this.fireStore.collection('apps')
          .doc(environment.appId);
      docRef.collection(
        'channels',
        ref => {
          let query: CollectionReference | Query = ref;
          query = query.where('slug', '==', slug);
          return query;
        })
        .get()
        .toPromise()
        .then((querySnapshot) => {
          if (querySnapshot.docs.length === 0) {
            resolve(undefined);
            return;
          }
          querySnapshot.forEach((doc) => {
            const newDoc = doc.data() as Channel;
            newDoc.id = doc.id;
            resolve(newDoc);
            return;
          })
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  }
  async fetchByRecommend(): Promise<Channel[]> {
    this.channels = [];
    return new Promise((resolve, reject) => {
      const docRef =
        this.fireStore.collection('apps')
          .doc(environment.appId);
      docRef.collection(
        'channels',
        ref => {
          let query: CollectionReference | Query = ref;
          query = query.where('published', '==', true);
          query = query.where('recommended', '==', true);
          query = query.orderBy('recommendedRate', 'desc');
          return query;
        })
        .get()
        .toPromise()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const newDoc = doc.data() as Channel;
            newDoc.id = doc.id;
            this.channels.push(newDoc);
          })
          resolve(this.channels);
        })
        .catch();
    });
  }
  async fetchByAccountId(
    accountId: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const docRef =
        this.fireStore.collection('apps')
          .doc(environment.appId);
      docRef.collection(
        'channels',
        ref => {
          let query: CollectionReference | Query = ref;
          query = query.where('accountId', '==', accountId);
          return query;
        })
        .get()
        .toPromise()
        .then((querySnapshot) => {
          const docs: Channel[] = [];
          querySnapshot.forEach((doc) => {
            const newDoc = doc.data() as Channel;
            newDoc.id = doc.id;
            this.fetched.push(newDoc);
          })
          resolve();
        })
        .catch();
    });
  }
  async fetchByCurrentAccountId(
    accountId: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const docRef =
        this.fireStore.collection('apps')
          .doc(environment.appId);
      docRef.collection(
        'channels',
        ref => {
          let query: CollectionReference | Query = ref;
          query = query.where('accountId', '==', accountId);
          return query;
        })
        .get()
        .toPromise()
        .then((querySnapshot) => {
          const docs: Channel[] = [];
          querySnapshot.forEach((doc) => {
            const newDoc = doc.data() as Channel;
            newDoc.id = doc.id;
            this.fetched.push(newDoc);
          })
          resolve();
        })
        .catch();
    });
  }
  async connectSnapShot(
    channelId: string
  ): Promise<void> {
    this.dbDocument = this.fireStore
      .collection('apps')
      .doc(environment.appId)
      .collection('channels')
      .doc(channelId);
    await this.dbDocument.get().toPromise().then();
    this.currentChannel = this.dbDocument.valueChanges({ idField: 'id' });
  }
  async update(channel: Channel): Promise<void> {
    const json = JSON.stringify(channel);
    await this.dbDocument.update(JSON.parse(json));
  }
  getLogoImageUrl(src: string): string {
    let width = 480;
    return `https://storage.googleapis.com/${environment.publicAssetsBucketName}/${src}/${width}`;
  }
  hasPermission(
    isMyChannel: boolean,
    currentChannel: Channel,
    channelSubscriptionStatus: ChannelSubscription
  ): boolean {
    return isMyChannel
      || (currentChannel.published && currentChannel.publishRange === PublishRangesKeys.public)
      || (currentChannel.published && currentChannel.publishRange === PublishRangesKeys.forInvitationOnly && channelSubscriptionStatus?.activated)
      || (currentChannel.published && currentChannel.publishRange === PublishRangesKeys.forChannelMembershipOnly && channelSubscriptionStatus?.activated);
  }
}
