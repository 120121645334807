<div class="l-breadcrumb" *ngIf="isShow">
  <div class="p-breadcrumbMenu">
    <button (click)="toggleNavi()"></button>
  </div>
  <div class="p-breadcrumb">
    <ul>
      <li
        *ngFor="let page of tree"
        (click)="move(page)"
        translate
      >{{page.title}}</li>
    </ul>
    <div *ngIf="isShowMenu" class="p-breadcrumb__nav">
      <div class="p-language">
        <div class="p-language__inner">
          <select name="language" [formControl]="languages" (change)="changeLanguage()">
            <option value="ja">日本語</option>
            <option value="en">English</option>
          </select>
        </div>
      </div>
      <div
        [matMenuTriggerFor]="menu"
        at-raised-button
        matTooltip="{{displayName + ' ' + translateService.instant('ログイン中')}}"
        class="p-breadcrumb__nav__userIcon"></div>
      <mat-menu #menu="matMenu">
        <button routerLink="/my-page" mat-menu-item><span translate>マイページ</span></button>
        <!--<button routerLink="/my-page/payment/history" mat-menu-item>購入履歴</button>-->
        <!--<button routerLink="/my-page/notifications" mat-menu-item>通知設定</button>-->
        <button [matMenuTriggerFor]="channels" mat-menu-item><span translate>自分のチャンネル</span></button>
        <!--<button routerLink="/my-page/payment/credit" mat-menu-item>クレジットカード登録・変更</button>-->
        <button routerLink="/my-page/password" mat-menu-item><span translate>パスワード変更</span></button>
        <!--<button routerLink="/my-page/auth-setup" mat-menu-item>2段階認証</button>-->
        <button routerLink="/logout" mat-menu-item><span translate>ログアウト</span></button>
        <!--<button mat-menu-item></button>
        <button routerLink="/my-page/quit" mat-menu-item>ユーザー削除（退会）</button>-->
      </mat-menu>
      <mat-menu #channels="matMenu">
        <button
          *ngFor="let c of listOfChannels"
          routerLink="/manager/channel/{{c.id}}"
          mat-menu-item
        >{{c.name}}</button>
        <button routerLink="/manager/channels" mat-menu-item><span translate>チャンネル選択</span></button>
        <button routerLink="/manager/channel/create" mat-menu-item><span translate>チャンネル作成</span></button>
        <button routerLink="/manager/accounts" mat-menu-item><span translate>アカウントの切替</span></button>
      </mat-menu>
      <div
        [matMenuTriggerFor]="contentMenu"
        class="p-breadcrumb__nav__spMenu"></div>
      <mat-menu #contentMenu="matMenu">
        <button routerLink="/" mat-menu-item><span translate>TOPページ</span></button>
        <!--<button routerLink="/categories" mat-menu-item>カテゴリー一覧</button>-->
        <!--<button [matMenuTriggerFor]="categoriesList" mat-menu-item>講座を探す</button>-->
        <button routerLink="/contracts" mat-menu-item><span translate>利用規約</span></button>
        <button routerLink="/privacy-policy" mat-menu-item><span translate>プライバシーポリシー</span></button>
        <button routerLink="/business-contract" mat-menu-item><span translate>特定商取引法に基づく表記</span></button>
      </mat-menu>
      <mat-menu #categoriesList="matMenu">
        <button
          *ngFor="let c of categories"
          routerLink="/category/{{c.slug}}" mat-menu-item>{{c.name}}</button>
      </mat-menu>
    </div>
  </div>
</div>
