import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {FormControl, Validators} from "@angular/forms";
import {AuthService} from "../../services/auth/auth.service";
import {Store} from "@ngrx/store";
import {ChannelsService} from "../../services/channels/channels.service";
import {Channel} from "../../models/channel";
import {UiState} from "../../store/ui/ui.reducer";
import {
  switchFalseLoading,
  switchTrueShowMenuInSideNavi,
  switchTrueShowSideNavigation
} from "../../store/ui/ui.actions";
import {AccountsService} from "../../services/accounts/accounts.service";

@Component({
  selector: 'app-manager-channels',
  templateUrl: './manager-channels.component.html',
  styleUrls: ['./manager-channels.component.scss']
})
export class ManagerChannelsComponent implements OnInit, OnDestroy {
  isVisible = false;
  forbidden: boolean = false;
  constructor(
    private router: Router,
    private authService: AuthService,
    private uiStore: Store<{ ui: UiState }>,
    private channelsService: ChannelsService,
    private accountService: AccountsService,
  ) {
    window.scrollTo(0, 0);
    this.uiStore.dispatch(switchTrueShowSideNavigation());
    this.uiStore.dispatch(switchTrueShowMenuInSideNavi());
  }
  channels: Channel[] = [];
  channel = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  async ngOnInit(): Promise<void> {
    await this.authService.refresh();
    await new Promise(resolve => setTimeout(resolve, 200));
    const account = await this.accountService.fetchAccount(this.authService.currentSession.currentUser.currentAccountId);
    if (!await this.accountService.isAcceptByClientIp(account)) {
      this.uiStore.dispatch(switchFalseLoading());
      this.forbidden = true;
      return;
    }    // チャンネルリスト取得
    this.channelsService.fetched = [];
    if (!this.authService.currentSession.currentUser.currentAccountId) {
      return;
    }
    await this.channelsService.fetchByAccountId(this.authService.currentSession.currentUser.currentAccountId);
    this.channels = this.channelsService.fetched;
    this.isVisible = true;
  }
  ngOnDestroy(): void{}
  async move() {
    if (!this.channel.valid) {
      return;
    }
    await this.router.navigate([`/manager/channel/${this.channel.value}`]);
    return;
  }
}
