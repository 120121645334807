<app-forbidden *ngIf="forbidden"></app-forbidden>
<div *ngIf="isVisible && !forbidden"
  class="l-main">
  <app-navigations></app-navigations>
  <div class="l-content">
    <app-breadcrumb></app-breadcrumb>
    <div class="l-content__inner">
      <div class="c-content-header">
        <div class="c-content-header__title" translate>チャンネル選択</div>
      </div>
      <div class="l-templates">
        <div class="p-templates">
          <div class="p-templates__content">
            <div class="p-profile">
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label
                    class="c-form-label"
                    for="channel"
                    translate
                  >チャンネル</label>
                  <select
                    [formControl]="channel"
                    class="c-form-select"
                    name="channel" id="channel">
                    <option [ngValue]="null" translate>選択してください</option>
                    <option
                      *ngFor="let c of channels"
                      [ngValue]="c.id">{{c.name}}</option>
                  </select>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__createButton">
                  <button
                    (click)="move()"
                    class="c-button c-button-arrow-both"
                  ><span translate>チャンネルに移動する</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

