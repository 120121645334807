import { Component, OnInit } from '@angular/core';
import {Channel} from "../../models/channel";
import {ActivatedRoute} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {AuthService} from "../../services/auth/auth.service";
import {Store} from "@ngrx/store";
import {BreadcrumbState} from "../../store/breadcrumb/breadcrumb.reducer";
import {UiState, UrlState} from "../../store/ui/ui.reducer";
import {ChannelsService} from "../../services/channels/channels.service";
import {storeUrlState, switchFalseLoading, switchTrueLoading} from "../../store/ui/ui.actions";
import {environment} from "../../../environments/environment";
import {ChannelSubscriptionsService} from "../../services/channel-subscriptions/channel-subscriptions.service";

@Component({
  selector: 'app-channels',
  templateUrl: './channels.component.html',
  styleUrls: ['./channels.component.scss']
})
export class ChannelsComponent implements OnInit {
  channels: Channel[] = [];
  noImageThumbUrl: string = environment.noImageThumb;
  constructor(
    private route: ActivatedRoute,
    private toasterService: ToastrService,
    private authService: AuthService,
    private breadcrumbStore: Store<{ breadcrumb: BreadcrumbState }>,
    private uiStore: Store<{ ui: UiState }>,
    private channelSubscriptionsService: ChannelSubscriptionsService,
    private channelsService: ChannelsService,
  ) {
    window.scrollTo(0, 0)
    this.uiStore.dispatch(switchTrueLoading());
  }
  async ngOnInit(): Promise<void> {
    // TODO !env.prod の時だけ開けるようにする
    await this.authService.refresh();
    const urlState = new UrlState();
    this.uiStore.dispatch(storeUrlState({ urlState: urlState}));
    // 公開チャンネル全部
    await this.channelsService.fetchAll();
    this.channels = this.channelsService.channels;
    this.uiStore.dispatch(switchFalseLoading());
  }
}
