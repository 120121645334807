import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

  transform(seconds: number, ...args: unknown[]): unknown {
    const oneHour = 3600;
    const oneMin = 60;
    const remainderHour = seconds % oneHour;
    const remainderMin = remainderHour % oneMin;
    const h = Math.floor(seconds / oneHour);
    const m = Math.floor(remainderHour / oneMin);
    const mString = m.toString();
    let minute = mString;
    if (mString.length === 1) {
      minute = `0${mString}`
    }
    const sString = (Math.floor(remainderMin)).toString();
    let second = sString;
    if (sString.length === 1) {
      second = `0${sString}`
    }
    return `${h}:${minute}:${second}`;
  }
}
