import {Role} from "./role";
import {Address} from "./address";
import {SexValues} from "./common";
import {PublicImage} from "./public-image";

export class AccountRole {
  account: Role;
}
export class User {
  id?: string;
  language?: 'ja' | 'en';
  email?: string;
  displayName?: string;
  companyName?: string;
  branchName?: string;
  divisionName?: string;
  jobTitle?: string;
  profileImage?: PublicImage;
  lineId?: string;
  currentAccountId: string;
  isOem: boolean;
  oemAccountId: string;
  roles?: AccountRole;
  fullName?: string;
  familyName: string;
  givenName: string;
  familyNamePhonetic?: string;
  givenNamPhonetic?: string;
  sex?: SexValues;
  phoneNumber?: string;
  zip?: string;
  pref?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  address?: Address[];
  birthday: string;
  birthdayWithoutYear?: string;
  quitedAt?: Date;
  createdAt: Date;
  updatedAt: Date;
  createdBy: string;
  updatedBy: string;
  constructor() {
    this.id = null;
    this.language = 'ja';
    this.email = null;
    this.displayName = null;
    this.companyName = null;
    this.branchName = null;
    this.divisionName = null;
    this.jobTitle = null;
    this.profileImage = null;
    this.lineId = null;
    this.currentAccountId = null;
    this.isOem = false;
    this.oemAccountId = null;
    this.roles = null;
    this.fullName = null;
    this.familyName = null;
    this.givenName = null;
    this.familyNamePhonetic = null;
    this.givenNamPhonetic = null;
    this.sex = null;
    this.phoneNumber = null;
    this.zip = null;
    this.pref = null;
    this.address1 = null;
    this.address2 = null;
    this.address3 = null;
    this.address = null;
    this.birthday = null;
    this.birthdayWithoutYear = null;
    this.quitedAt = null;
    this.createdAt = new Date();
    this.updatedAt = null;
    this.createdBy = null;
    this.updatedBy = null;
  }
}
