import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {SessionState} from "../../store/sessions/sessions.reducer";
import {UiState} from "../../store/ui/ui.reducer";
import {BackendService} from "../../services/backend/backend.service";
import {ToastrService} from "ngx-toastr";
import {Subscription} from "rxjs";
import {AuthService} from "../../services/auth/auth.service";
import {categories, Category} from "../../models/categories";
import {environment} from "../../../environments/environment";
import {Account, AccountType} from "../../models/account";
import {ChannelsService} from "../../services/channels/channels.service";
import {UsersService} from "../../services/users/users.service";
import {User} from "../../models/user";
import {Channel} from "../../models/channel";
import {Config} from "../../models/config";
import {AppConfig} from "../../models/app-config";
import {PlanNames} from "../../models/plan";
import {switchFalseLoading, switchTrueLoading} from "../../store/ui/ui.actions";
import {CountersService} from "../../services/counters/counters.service";
import {AccountsService} from "../../services/accounts/accounts.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-manager-channel-create',
  templateUrl: './manager-channel-create.component.html',
  styleUrls: ['./manager-channel-create.component.scss']
})
export class ManagerChannelCreateComponent implements OnInit {
  forbidden: boolean = false;
  isVisible: boolean = false;
  private sessionsSubscription: Subscription;
  private uiSubscription: Subscription;
  account: Account;
  formCategories: Category[]  = categories;
  baseUrl: string = environment.hostName;
  showError = false;
  isDuplicate = false;
  name = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
      Validators.maxLength(100),
      Validators.minLength(4),
    ]
  });
  category = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  slug = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
      Validators.maxLength(32),
      Validators.minLength(6),
    ]
  });
  createNewAccount = new FormControl<boolean>( false, {});
  privacy = new FormControl<boolean>(false, {
    validators: [
      Validators.required,
      Validators.requiredTrue
    ]
  });
  contract = new FormControl<boolean>(false, {
    validators: [
      Validators.required,
      Validators.requiredTrue
    ]
  });
  form = this.formBuilder.group({
    name: this.name,
    category: this.category,
    slug: this.slug,
    privacy: this.privacy,
    contract: this.contract,
  });
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private uiStore: Store<{ ui: UiState }>,
    private channelsService: ChannelsService,
    private usersService: UsersService,
    private backendService: BackendService,
    private toasterService: ToastrService,
    private authService: AuthService,
    private counter: CountersService,
    private accountService: AccountsService,
    private translateService: TranslateService,
  ) {
    window.scrollTo(0, 0);
  }
  async ngOnInit(): Promise<void> {
    await this.authService.refresh();
    await new Promise(resolve => setTimeout(resolve, 200));
    if (this.authService?.currentSession?.currentUser?.currentAccountId) {
      this.account = await this.accountService.fetchAccount(this.authService.currentSession.currentUser.currentAccountId);
      if (this.account) {
        this.createNewAccount.setValue(false);
      }
      if (!await this.accountService.isAcceptByClientIp(this.account)) {
        this.uiStore.dispatch(switchFalseLoading());
        this.forbidden = true;
        return;
      }
    }
    this.isVisible = true;
  }
  ngOnDestroy(): void{
    this.uiSubscription?.unsubscribe();
    this.sessionsSubscription?.unsubscribe();
  }
  openContractsLink(): void {
    window.open('/contracts', '_blank');
  }
  openPrivacyLink(): void {
    window.open('https://www.grab-design.com/privacy/', '_blank');
  }
  async onSubmit(): Promise<void> {
    this.showError = false;
    this.uiStore.dispatch(switchTrueLoading());
    // currentAccountがnullならaccount作成
    // もし持っていれば現在のビジネスアカウント名を表示する
    if (!this.form.valid) {
      this.showError = true;
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    // 新しい利用者アカウントを作って使う
    if (this.createNewAccount.value || !this.account) {
      this.backendService.setIdToken(this.authService.currentSession.idToken);
      const newAccount = new Account();
      this.account = await this.backendService.post('accounts/account/create', { account: newAccount });
      const user: User = await this.authService.fetchUser(this.authService.currentSession.currentUser.id);
      user.currentAccountId = this.account.id;
      await this.usersService.update(user);
      // アカウントロールを作成する
      await this.backendService.post(
        'accounts/account/new',
        {
          accountId: this.account.id,
          configId: environment.appId,
          config: new Config()
        });
      const appStatus = new AppConfig(PlanNames.free);
      appStatus.activated = true;
      appStatus.activatedAt = new Date();
      await this.backendService.post(
        'subscriptions/for-user/create-account-app-status',
        {
          accountId: this.account.id,
          accountAppStatus: appStatus
        });
      await this.backendService.post('accounts/roles/new',
        {
          accountId: this.account.id,
          configId: environment.appId,
          config: new Config()
        });
    }
    /// チャンネルを作る
    const channel = new Channel();
    channel.name = this.name.value;
    channel.accountId = this.account.id;
    channel.slug = this.slug.value;
    channel.categoryId = this.category.value;
    const now = new Date();
    channel.createdAt = now;
    channel.updatedAt = now;
    channel.createdBy = this.authService.currentSession.currentUser.id;
    channel.updatedBy = this.authService.currentSession.currentUser.id;
    if (this.account.accountType === AccountType.oem) {
      channel.isOem = true;
    }
    const channelId = await this.channelsService.create(channel);
    this.toasterService.success(this.translateService.instant('チャンネルが開設されました。'));
    this.counter.inc(
      {
        accountId: channel.accountId,
        countName: 'channels',
        term: '',
        addKey: '',
        value: 1,
      }
    );
    this.uiStore.dispatch(switchFalseLoading());
    await this.router.navigate([`/manager/channel/${channelId}`])
    return;
  }
  async checkUrlDuplication(): Promise<void> {
    // チャンネルの被りが無いか検索
    const duplicate = await this.channelsService.fetchBySlug(
      this.slug.value
    );
    this.isDuplicate = !!duplicate;
  }
  async cancel(): Promise<void> {
    await this.router.navigate([`/`]);
    return;
  }
}
