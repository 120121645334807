import {PublicImage} from "./public-image";
import {FileObject} from "./file-object";

export enum scoreAllocations {
  percentage= 'percentage',
  score4EachQuestions = 'score4EachQuestions'
}
export const scoreAllocationOptions = [
  {
    name: '正答数÷問題数×100で％表示します。',
    value: scoreAllocations.percentage
  },
  {
    name: '問1：30点　問2：20点　問3：50点とし、100点満点で表示します。',
    value: scoreAllocations.score4EachQuestions
  },
];
export enum scoringMethods {
  percentage = 'percentage',
  score = 'score'
}
export const scoringMethodsOptions = [
  {
    name: '％表示',
    value: scoringMethods.percentage
  },
  {
    name: '点表示',
    value: scoringMethods.score
  },
];
export enum AnswerTypes {
  radio = 'radio',
  check = 'check',
  yesOrNo = 'yesOrNo',
  describe = 'describe',
  rating = 'rating',
}
export const answerTypeOptions = [
  {
    name: '択一式(ラジオボタン)',
    value: AnswerTypes.radio,
  },
  {
    name: '複数選択式(チェックボックス)',
    value: AnswerTypes.check,
  },
  /*{
    name: '◯✕式',
    value: AnswerTypes.yesOrNo,
  },*/
  {
    name: '記述式(テキストエリア)',
    value: AnswerTypes.describe,
  },

];
export enum ExamAnswerTypes {
  radio = 'radio',
  check = 'check',
}
export const examAnswerTypeOptions = [
  {
    name: '択一式(ラジオボタン)',
    value: AnswerTypes.radio,
  },
  {
    name: '複数選択式(チェックボックス)',
    value: AnswerTypes.check,
  },
];
export enum FrameTypes {
  CoverFrame = 'coverFrame',
  SlideFrame = 'slideFrame',
  VideoFrame = 'videoFrame',
  OnlineFrame = 'onlineFrame',
  ExaminationFrame = 'examinationFrame',
  LiveFrame = 'liveFrame',
  ReportFrame = 'reportFrame',
  FileFrame = 'fileFrame',
  SurveyFrame = 'surveyFrame',
}
export class ContentFrame {
  id?: string;
  contentKey: string;
  courseId: string;
  name: string;
  note: string;
  eyeCatchImage?: PublicImage;
  coverFrame: CoverFrame;
  slideFlame: SlideFrame;
  videoFlame: VideoFrame;
  audios: VideoFrame[];
  onlineFrame: OnlineFrame;
  liveFrame: LiveFrame;
  reportFrame: ReportFrame;
  examinationFrame: ExaminationFrame;
  surveyFrame: SurveyFrame;
  fileFrame: FileFrame;
  scoreAllocationMethod: scoreAllocations;
  additionalMaterials: FileObject[];
  createdAt: Date;
  createdBy: string
  updatedAt: Date;
  updatedBy: string;
  constructor() {
    this.id = null;
    this.contentKey = null;
    this.courseId = null;
    this.name = null;
    this.note = null;
    this.coverFrame = null;
    this.eyeCatchImage = null;
    this.slideFlame = null;
    this.videoFlame = null;
    this.audios = null;
    this.onlineFrame = null;
    this.reportFrame = null;
    this.examinationFrame = null;
    this.surveyFrame = null;
    this.fileFrame = null;
    this.scoreAllocationMethod = null;
    this.additionalMaterials = [];
    this.createdAt = new Date();
    this.createdBy = '';
    this.updatedAt = new Date();
    this.updatedBy = '';
  }
  deserialize(): any {
    const json = JSON.stringify(this)
    return JSON.parse(json)
  }
}
export class CoverFrame {
  contentKey: string;
  name: string;
  eyeCatchImage?: PublicImage;
  note: string;
  createdAt: Date;
  createdBy: string
  updatedAt: Date;
  updatedBy: string;
  constructor() {
    this.contentKey = null;
    this.name = null;
    this.eyeCatchImage = null;
    this.note = null;
  }
}
export class VideoFrame {
  contentKey: string;
  name: string;
  description: string;
  m3u8ObjectName: string;
  m3u8ObjectNameUrl?: string;
  duration: number;
  numOfFragments: number;
  eyeCatchImage?: PublicImage;
  width: number;
  height: number;
  note: string;
  playing?: boolean;
  paused?: boolean;
  createdAt: Date;
  createdBy: string
  updatedAt: Date;
  updatedBy: string;
  constructor() {
    this.contentKey = null;
    this.name = null;
    this.description = null;
    this.m3u8ObjectName = null;
    this.m3u8ObjectNameUrl = null;
    this.duration = 0;
    this.numOfFragments = 0;
    this.duration = null;
    this.eyeCatchImage = null;
    this.note = null;
    this.width = 0;
    this.height = 0;
    this.paused = false;
    this.playing = false;
  }
}
export class SlideFrame {
  contentKey: string;
  name: string;
  description: string;
  slideObjectNames: string[];
  numOfFragments: number;
  eyeCatchImage?: PublicImage;
  width: number;
  height: number;
  audios: VideoFrame[];
  createdAt: Date;
  createdBy: string
  updatedAt: Date;
  updatedBy: string;
  constructor() {
    this.contentKey = null;
    this.name = null;
    this.description = null;
    this.slideObjectNames = [];
    this.eyeCatchImage = null;
    this.width = 0;
    this.height = 0;
    this.audios = [];
  }
}
export class OnlineFrame {
  contentKey: string;
  title: string;
  lecturerName: string;
  timeFrames: TimeFrame[];
  note: string;
  constructor() {
    this.contentKey = null;
    this.title = '';
    this.lecturerName = '';
    this.note = '';
  }
}
export class TimeFrame {
  timeFrameKey: string;
  startAtDate: Date;
  startAtTime: Date;
  url: string;
  mapUrl: string;
  address: string;
  capacity: number;
  stocks: number;
  note: string;
  constructor() {
    this.timeFrameKey = null;
    this.startAtDate = null;
    this.startAtTime = null;
    this.address = null;
    this.url = null;
    this.mapUrl = null;
    this.capacity = 0;
    this.stocks = 0;
    this.note = '';
  }
}
export class LiveFrame {
  contentKey: string;
  title: string;
  lecturerName: string;
  timeFrames: TimeFrame[];
  note: string;
  constructor() {
    this.contentKey = null;
    this.title = '';
    this.lecturerName = '';
    this.timeFrames = [];
    this.note = '';
  }
}
export class ReportFrame {
  contentKey: string;
  theme: string;
  body: string;
  files: FileObject[];
  constructor() {
    this.contentKey = null;
    this.theme = '';
    this.body = '';
    this.files = [];
  }
}
export class FileFrame {
  contentKey: string;
  theme: string;
  body: string;
  files: FileObject[];
  constructor() {
    this.contentKey = null;
    this.theme = '';
    this.body = '';
    this.files = [];
  }
}
export class ExaminationSelectOption {
  name: string;
  isCollect: boolean;
}
export class ExaminationFrame {
  contentKey: string;
  title: string;
  note: string;
  type: string;
  questions: ExamQuestion[];
  useTimeLimit: boolean;
  timeLimit: number;
  passThreshold: number;
  constructor() {
    this.contentKey = null;
    this.title = null;
    this.note = '';
    this.type = null;
    this.questions = [];
    this.passThreshold = 0;
    this.timeLimit = 0;
    this.useTimeLimit = true;
  }
}
export enum SurveyType {
  survey = 'survey',
  checkList = 'checkList',
}
export class SurveyFrame {
  contentKey: string;
  title: string;
  note: string;
  type: SurveyType;
  questions: Question[];
  displayResult: boolean;
  useHistory: boolean;
  displayChannelInfo: boolean;
  constructor() {
    this.contentKey = null;
    this.title = null;
    this.note = '';
    this.type = null;
    this.questions = [];
    this.displayResult = false;
    this.useHistory = false;
    this.displayChannelInfo = false;
  }
}
export class Question {
  contentKey: string;
  type: AnswerTypes;
  title: string;
  body: string;
  note: string;
  image: PublicImage; // パブリックに上げないこと
  images?: PublicImage[]; // パブリックに上げないこと
  selectOptions: ExaminationSelectOption[];
  maxLength: number;
  constructor() {
    this.contentKey = null;
    this.type = null;
    this.title = '';
    this.body = '';
    this.note = '';
    this.image = null;
    this.images = [];
    this.selectOptions = [];
    this.maxLength = 100;
  }
}
export class ExamQuestion {
  contentKey: string;
  type: ExamAnswerTypes;
  title: string;
  body: string;
  note: string;
  image: PublicImage;
  selectOptions: ExaminationSelectOption[];
  incorrectNote: string;
  constructor() {
    this.contentKey = null;
    this.type = null;
    this.title = '';
    this.body = '';
    this.note = '';
    this.image = null;
    this.selectOptions = [];
    this.incorrectNote = '';
  }
}
