<app-not-found *ngIf="isNotFound"></app-not-found>
<app-forbidden *ngIf="forbidden"></app-forbidden>

<div class="l-main"
  *ngIf="!isNotFound && !forbidden && currentChannel.id">
  <app-navigations></app-navigations>
  <div class="l-content">
    <app-breadcrumb></app-breadcrumb>
    <div class="l-content__inner">
      <div class="c-content-header">
        <div
          *ngIf="currentChannel.name"
          class="c-content-header__channelName"
        ><span translate>現在のチャンネル</span> {{currentChannel.name}} ({{currentChannel.slug}})</div>
        <div class="c-content-header__title" translate>チャンネル概要情報の編集</div>
      </div>

      <div class="l-templates">
        <div class="p-templates">
          <div class="p-templates__content">
            <div class="p-profile">
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label">
                    <span translate>チャンネル名</span>
                    <span class="c-form-required" translate>必須</span>
                  </label>
                  <input
                    [formControl]="name"
                    class="c-form-input"
                    type="text"
                    name="name"
                    [placeholder]="'例）ふぁいあ' | translate"
                  >
                  <div
                    *ngIf="name.dirty && name.invalid"
                    class="c-form-error"
                    translate
                  >チャンネル名は4文字〜100文字以内で入力してください。</div>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__textArea">
                  <label class="c-form-label" translate>チャンネル概要説明</label>
                  <textarea
                    [formControl]="description"
                    class="c-form-textArea"
                    name="description"
                    [placeholder]="'チャンネルの概要説明文を入力してください。' | translate"
                  ></textarea>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" translate>チャンネルURL(変更出来ません)</label>
                  <input
                    [formControl]="slug"
                    readonly="readonly"
                    class="c-form-input" type="text" name="slug" placeholder="">
                  <div
                    *ngIf="slug.dirty && slug.invalid"
                    class="c-form-error"
                    translate
                  >チャンネルURLは英数字8文字〜100文字以内で入力してください。先頭は英文字で、全て小文字である必要があり、記号はハイフン(-)のみが使用できます。</div>
                  <div
                    *ngIf="slug.dirty && isDuplicate"
                    class="c-form-error"
                    translate
                  >このチャンネルURLは他のチャンネルで既に使用されています。別のチャンネルURLを入力してください。</div>
                </div>
                <div class="p-profile__row__notes">
                  <span translate>チャンネルURLとは？</span><br>
                  {{baseUrl}}/channels/<span translate>入力したURL</span><span translate>のように使用されます。</span>
                </div>
              </div>

              <div class="p-profile__row">
                <label class="c-form-label" translate>チャンネルアイキャッチ画像</label>
                <div class="c-form-images">
                  <div class="c-form-images__image">
                    <img
                      *ngIf="!currentChannel?.eyeCatchImage?.src"
                      ngSrc="no-image"
                      width="240"
                      height="135"
                      ngSrcset="160w,240w,320w,480w"
                      alt="">
                    <img
                      *ngIf="currentChannel?.eyeCatchImage?.src"
                      ngSrc="{{currentChannel?.eyeCatchImage?.src}}"
                      width="240"
                      height="135"
                      ngSrcset="160w,240w,320w,480w"
                      alt="">
                  </div>
                </div>
                <div
                  *ngIf="!currentChannel?.eyeCatchImage?.src"
                  class="c-form-error"
                  translate
                >チャンネルアイキャッチ画像を選択してください。</div>
                <div class="p-profile__row__buttons">
                  <div class="p-profile__row__buttons__button">
                    <button
                      *ngIf="!currentChannel?.eyeCatchImage?.src"
                      (click)="openEyeCatchImageUploadModal()"
                      class="c-button c-button-primary"
                      id="eyeCatch"
                      translate
                    ><span translate>画像をアップロード</span></button>
                    <button
                      *ngIf="currentChannel?.eyeCatchImage?.src"
                      (click)="removeImageEyeCatch()"
                      class="c-button c-button-cancel"
                      type="button"
                      translate
                    ><span translate>アイキャッチを削除する</span></button>
                  </div>
                </div>
                <div class="p-profile__row__notes">
                  <span translate>画像サイズの目安は？</span><br>
                  <span class="p-profile__row__notes__redText" translate>1280ピクセルx720ピクセルのpng/jpg画像</span>
                  <span translate>をアップロードしてください。他の解像度でもアップロード出来ますが、意図しない形に切り抜かれて表示される場合があります。</span>
                </div>
              </div>

              <div class="p-profile__row">
                <label class="c-form-label" translate>アピール画像(最大8枚)</label>
                <div class="c-form-images">
                  <ng-container
                    *ngFor="let pr of currentChannel?.prImages; index as i">
                    <div
                      class="c-form-images__image">
                      <div (click)="removeImage(i)"
                        class="c-form-images__image__remove"></div>
                      <img
                        *ngIf="!pr?.src"
                        ngSrc="no-image"
                        width="240"
                        height="135"
                        ngSrcset="160w,240w,320w,480w"
                        alt="">
                      <img
                        *ngIf="pr?.src"
                        [ngSrc]="pr?.src"
                        width="240"
                        height="135"
                        ngSrcset="160w,240w,320w,480w"
                        alt="">
                    </div>
                  </ng-container>
                  <div
                    *ngIf="!currentChannel?.prImages || currentChannel?.prImages?.length === 0"
                    class="c-form-images__image">
                    <img
                      *ngIf="!currentChannel?.profileImage?.src"
                      ngSrc="no-image"
                      width="240"
                      height="135"
                      ngSrcset="160w,240w,320w,480w"
                      alt="">
                  </div>
                </div>
                <div
                  *ngIf="!currentChannel?.prImages || currentChannel?.prImages?.length === 0"
                  class="c-form-error"
                  translate
                >アピール画像を選択してください。</div>
                <div
                  class="p-profile__row__buttons">
                  <div class="p-profile__row__buttons__button">
                    <button
                      *ngIf="currentChannel?.prImages?.length < 8"
                      (change)="uploadImagePrImages($event)"
                      (click)="openPrImageUploadModal()"
                      class="c-button c-button-primary"
                      id="prImageUpload"
                    ><span translate>画像をアップロード</span></button>
                  </div>
                </div>
                <div class="p-profile__row__notes">
                  <span translate>画像サイズの目安は？</span><br>
                  <span class="p-profile__row__notes__redText" translate>1280ピクセルx720ピクセルのpng/jpg画像</span>
                  <span translate>をアップロードしてください。他の解像度でもアップロード出来ますが、意図しない形に切り抜かれて表示される場合があります。</span>
                </div>
              </div>

              <div class="p-profile__row">
                <label class="c-form-label" translate>プロフィール画像</label>
                <div class="c-form-images">
                  <div
                    class="c-form-images__image c-form-images__image-profile">
                    <img
                      *ngIf="!currentChannel?.profileImage?.src"
                      ngSrc="no-image"
                      width="240"
                      height="135"
                      ngSrcset="160w,240w,320w,480w"
                      alt="">
                    <img
                      *ngIf="currentChannel?.profileImage?.src"
                      ngSrc="{{currentChannel.profileImage.src}}"
                      width="240"
                      height="135"
                      ngSrcset="160w,240w,320w,480w"
                      alt="">
                  </div>
                </div>
                <div
                  *ngIf="!currentChannel?.profileImage?.src"
                  class="c-form-error"
                  translate
                >チャンネルプロフィール画像をアップロード選択してください。</div>
                <div
                  class="p-profile__row__buttons">
                  <div class="p-profile__row__buttons__button">
                    <button
                      *ngIf="!currentChannel?.profileImage?.src"
                      (click)="openProfileImageUploadModal()"
                      class="c-button c-button-primary"
                      id="profileImageUpload"
                    ><span translate>画像をアップロード</span></button>
                    <button
                      *ngIf="currentChannel?.profileImage?.src"
                      (click)="removeImageProfile()"
                      class="c-button c-button-cancel"
                      type="button"><span translate>画像を削除する</span></button>
                  </div>
                </div>
                <div class="p-profile__row__notes">
                  <span translate>画像サイズの目安は？</span><br>
                  <span class="p-profile__row__notes__redText" translate>320ピクセルx320ピクセル程度の正方形png/jpg画像</span>
                  <span translate>をアップロードしてください。他の解像度でもアップロード出来ますが、意図しない形に切り抜かれて表示される場合があります。</span>
                </div>
              </div>

              <div class="p-profile__row">
                <label class="c-form-label" translate>サンプル動画(最大3本)</label>
                <div
                  cdkDropList
                  (cdkDropListDropped)="dropSamples($event)"
                  class="c-form-images">

                  <div *ngIf="!currentChannel?.samples || currentChannel?.samples?.length === 0"
                    class="c-form-images__image">
                    <img ngSrc="no-image"
                         width="240"
                         height="135"
                         ngSrcset="160w,240w,320w,480w"
                         alt="">
                  </div>

                  <div
                    *ngFor="let sample of currentChannel?.samples;index as i"
                    class="c-form-images__image">
                    <div
                      (click)="removeSampleVideo(i)"
                      class="c-form-images__image__remove"></div>
                    <img
                      *ngIf="!sample?.eyeCatchImage?.src"
                      ngSrc="no-image"
                      width="240"
                      height="135"
                      ngSrcset="160w,240w,320w,480w"
                      alt="">
                    <img
                      *ngIf="sample?.eyeCatchImage?.src"
                      ngSrc="{{sample?.eyeCatchImage?.src}}"
                      width="240"
                      height="135"
                      ngSrcset="160w,240w,320w,480w"
                      alt="">
                  </div>
                </div>
                <div
                  *ngIf="!currentChannel?.samples && currentChannel?.samples?.length > 0"
                  class="c-form-error"
                  translate
                >サンプル動画をアップロードしてください。</div>
                <div
                  class="p-profile__row__buttons">
                  <div class="p-profile__row__buttons__button">
                    <button
                      (click)="openVideoUploadModal()"
                      class="c-button c-button-primary"
                      id="sampleVideoUpload"
                    ><span translate>動画をアップロード</span></button>
                  </div>
                </div>
              </div>

              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" translate>カテゴリーを選択</label>
                  <select
                    [formControl]="categoryId"
                    class="c-form-select"
                    name="category">
                    <option [ngValue]="null" selected><span translate>選択</span> ▼</option>
                    <option
                      *ngFor="let category of formCategories"
                      [ngValue]="category.id"
                      translate
                    >{{category.name}}</option>
                  </select>
                  <div
                    *ngIf="categoryId.dirty && categoryId.invalid"
                    class="c-form-error"
                    translate
                  >カテゴリーを選択してください。</div>
                </div>
              </div>
              <div class="p-profile__row">
                <label class="c-form-label" translate>公開/非公開</label>
                <div class="c-form-radio">
                  <div class="c-form-radio__wrap">
                    <input
                      [formControl]="published"
                      [value]="true"
                      type="radio" name="published" id="published-true">
                    <label class="c-form-label" for="published-true" translate>公開</label>
                  </div>
                  <div class="c-form-radio__wrap">
                    <input
                      [formControl]="published"
                      [value]="false"
                      type="radio" name="published" id="published-false">
                    <label class="c-form-label" for="published-false" translate>非公開</label>
                  </div>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="tagKeyword" translate>ハッシュタグ</label>
                  <div
                    *ngIf="tagPayload.length > 0"
                    class="c-tag">
                    <div
                      *ngFor="let tag of tagPayload"
                      (click)="removeContentTag(tag)"
                      class="c-tag__item"><span>#{{tag.name}}</span></div>
                  </div>
                  <div class="c-form__box">
                    <div class="c-form__box__row">
                      <input
                        [formControl]="tagKeyword"
                        (keyup)="searchTags()"
                        class="c-form-input c-form__box__row__input"
                        type="text"
                        name="tagKeyword"
                        id="tagKeyword"
                        [placeholder]="'タグを探す(前方一致)' | translate"
                      >
                      <button
                        *ngIf="canAddTag()"
                        (click)="addContentTag()"
                        class="c-form__box__row__button c-form__box__row__button-add c-form__box__row__button-short">+</button>
                    </div>
                    <div
                      *ngIf="tagList.length > 0"
                      class="l-suggestionsModal">
                      <div class="c-suggestionsModal">
                        <div class="c-suggestionsModal__items">
                          <div
                            *ngFor="let item of tagList"
                            (click)="pushContentTag(item)"
                            class="c-suggestionsModal__items__item">
                            <span>{{item.name}}</span>
                          </div>
                        </div>
                        <div
                          (click)="closeTagModal()"
                          class="c-suggestionsModal__hint"
                          translate
                        >閉じる</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



              <div class="p-profile__row">
                <label class="c-form-label" translate>販売形態</label>
                <div class="c-form-radio">
                  <div class="c-form-radio__wrap">
                    <input
                      [formControl]="monetized"
                      [value]="true"
                      type="radio" name="monetized" id="monetized-true">
                    <label class="c-form-label" for="monetized-true" translate>有料（チャンネルメンバーシップ）</label>
                  </div>
                  <div class="c-form-radio__wrap">
                    <input
                      [formControl]="monetized"
                      [value]="false"
                      type="radio" name="monetized" id="monetized-false">
                    <label class="c-form-label" for="monetized-false" translate>無料(コース毎に課金する場合はこちら)</label>
                  </div>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" translate>チャンネルメンバーシップ時の月額料金（一般）</label>
                  <div class="p-profile__row__input__price">
                    <input
                      [formControl]="channelSubscriptionPrice"
                      class="c-form-input" type="text" name="channelSubscriptionPrice">
                  </div>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" translate>チャンネルメンバーシップ（プレミアム）時の月額料金</label>
                  <div class="p-profile__row__input__price">
                    <input
                      [formControl]="channelSubscriptionPriceForPremium"
                      class="c-form-input" type="text" name="channelSubscriptionPrice">
                  </div>
                </div>
              </div>
              <div class="p-profile__row">
                <label class="c-form-label" translate>公開範囲</label>
                <div class="c-form-radio">
                  <div
                    *ngFor="let p of formPublishRanges"
                    class="c-form-radio__wrap">
                    <input
                      [formControl]="publishRange"
                      type="radio" name="formPublishRanges" [value]="p.key" id="{{p.key}}">
                    <label
                      class="c-form-label"
                      for="{{p.key}}"
                      translate
                    >{{p.nameJa}}</label>
                  </div>
                </div>
              </div>

              <div class="p-profile__row">
                <div class="p-profile__row__buttons">
                  <div class="p-profile__row__buttons__button">
                    <button
                      (click)="back()"
                      class="c-button c-button-cancel"
                    ><span translate>戻る</span></button>
                  </div>
                  <div class="p-profile__row__buttons__button">
                    <button
                      (click)="submit()"
                      class="c-button c-button-primary"
                    ><span translate>保存する</span></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="c-content-header">
        <div class="c-content-header__title" translate>チャンネルの削除</div>
      </div>

      <div class="l-templates">
        <div class="p-templates">
          <div class="p-templates__content">
            <div class="p-profile">
              <div class="p-profile__row">
                <div class="p-profile__row__buttons__button">
                  <button
                    routerLink="/manager/channel/remove/{{currentChannel.id}}"
                    class="c-button c-button-warning"
                  ><span translate>削除する</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    </div>
  </div>
</div>

<section class="l-modal" *ngIf="isOpenEyeCatchImageUploadModal">
  <div class="c-modal">
    <div class="c-modal__head">
      <div class="c-modal__head__title" translate>アイキャッチ画像アップロード</div>
      <div class="c-modal__head__closeButton" (click)="closeEyeCatchImageUploadModal()"></div>
    </div>
    <div class="c-modal__documentUpload">
      <input
        (change)="uploadImageEyeCatch($event)"
        type="file" id="uploadEyeCatchImage">
      <label
        for="uploadEyeCatchImage"
        class="c-modal__documentUpload__description"
        translate
      >ファイルを選択</label>
      <div class="c-modal__documentUpload__uploadButton">
        <button
          (click)="clickUploadFile('uploadEyeCatchImage')"
          type="button" class="c-button c-button-primary"
        ><span translate>アップロードする</span></button>
      </div>
    </div>
  </div>
</section>

<section class="l-modal" *ngIf="isOpenPrImageImageUploadModal">
  <div class="c-modal">
    <div class="c-modal__head">
      <div class="c-modal__head__title" translate>アピール画像アップロード</div>
      <div class="c-modal__head__closeButton" (click)="closePrImageUploadModal()"></div>
    </div>
    <div class="c-modal__documentUpload">
      <input
        (change)="uploadImagePrImages($event)"
        type="file" id="uploadPrImage">
      <label
        for="uploadPrImage"
        class="c-modal__documentUpload__description"
        translate
      >ファイルを選択</label>
      <div class="c-modal__documentUpload__uploadButton">
        <button
          (click)="clickUploadFile('uploadPrImage')"
          type="button" class="c-button c-button-primary"
        ><span translate>アップロードする</span></button>
      </div>
    </div>
  </div>
</section>

<section class="l-modal" *ngIf="isOpenProfileImageUploadModal">
  <div class="c-modal">
    <div class="c-modal__head">
      <div class="c-modal__head__title" translate>プロフィール画像アップロード</div>
      <div class="c-modal__head__closeButton" (click)="closeProfileImageUploadModal()"></div>
    </div>
    <div class="c-modal__documentUpload">
      <input
        (change)="uploadImageProfile($event)"
        type="file" id="uploadProfileImage">
      <label
        for="uploadProfileImage"
        class="c-modal__documentUpload__description"
        translate
      >ファイルを選択</label>
      <div class="c-modal__documentUpload__uploadButton">
        <button
          (click)="clickUploadFile('uploadProfileImage')"
          type="button" class="c-button c-button-primary"
        ><span translate>アップロードする</span></button>
      </div>
    </div>
  </div>
</section>

<section class="l-modal" *ngIf="isOpenSampleVideoUploadModal">
  <div class="c-modal">
    <div class="c-modal__head">
      <div class="c-modal__head__title" translate>サンプル動画アップロード</div>
      <div class="c-modal__head__closeButton" (click)="closeVideoUploadModal()"></div>
    </div>
    <div class="c-modal__documentUpload">
      <input
        (change)="uploadSampleVideo($event)"
        type="file" id="uploadSampleVideo">
      <label
        for="uploadSampleVideo"
        class="c-modal__documentUpload__description"
        translate
      >ファイルを選択</label>
      <div class="c-modal__documentUpload__uploadButton">
        <button
          (click)="clickUploadFile('uploadSampleVideo')"
          type="button" class="c-button c-button-primary"
        ><span translate>アップロードする</span></button>
      </div>
    </div>
  </div>
</section>

