import { Injectable } from '@angular/core';
import {BackendService} from "../backend/backend.service";
import {environment} from "../../../environments/environment";
import {counters} from "../../models/counter";
import {DateFormat} from "../../models/date-format";
type PostDataDTO = {
  accountId: string | null,
  countName: string | null,
  term: string | null,
  addKey: string | null,
  value: number | null,
}
@Injectable({
  providedIn: 'root'
})
export class CountersService {
  incEndpoint = 'counters/counters/inc';
  decEndpoint = 'counters/counters/dec';
  addEndpoint = 'counters/counters/add';
  subEndpoint = 'counters/counters/sub';
  fetchEndpoint = 'counters/counters/fetch';
  formatter = new DateFormat();
  constructor(
    private backendService: BackendService,
  ) {
  }
  counters = counters;
  postData: PostDataDTO = {
    accountId: null,
    countName: null,
    term: null,
    addKey: null,
    value: null,
  }
  inc(data: PostDataDTO): void {
    this.backendService.setAppToken(environment.appApiToken);
    this.backendService.post(
      this.incEndpoint,
      data
    ).then(() => {})
      .catch((e) => {
        console.log(e);
      })
  }
  dec(data: PostDataDTO): void {
    this.backendService.setAppToken(environment.appApiToken);
    this.backendService.post(
      this.decEndpoint,
      data
    ).then(() => {})
      .catch((e) => {
        console.log(e);
      })
  }
  add(data: PostDataDTO): void {
    this.backendService.setAppToken(environment.appApiToken);
    this.backendService.post(
      this.addEndpoint,
      data
    ).then(() => {})
      .catch((e) => {
        console.log(e);
      })
  }
  sub(data: PostDataDTO): void {
    this.backendService.setAppToken(environment.appApiToken);
    this.backendService.post(
      this.subEndpoint,
      data
    ).then(() => {})
      .catch((e) => {
        console.log(e);
      })
  }
  fetch(idToken: string, data: PostDataDTO): Promise<any> {
    return new Promise((resolve, reject)  => {
      this.backendService.setIdToken(idToken);
      this.backendService.setAppToken(environment.appApiToken);
      this.backendService.post(
        this.fetchEndpoint,
        data
      ).then((counter) => {
        if (counter) {
          resolve(Number(counter));
        } else {
          resolve(Number(0));
        }
      })
        .catch((e) => {
          console.log(e);
          reject(e);
        })
    });
  }
}

