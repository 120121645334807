import { Injectable } from '@angular/core';
import {BackendService} from "../backend/backend.service";
import {environment} from "../../../environments/environment";
import {FileUpload} from "../../models/file-upload";
import {PublicImage} from "../../models/public-image";
import {nanoid} from "nanoid";
import {FileUploaderToStorageService} from "../file-uploader-to-storage/file-uploader-to-storage.service";
import {BehaviorSubject} from "rxjs";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class ImageConverterService {
  fileSize: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  bytesTransferred: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  constructor(
    private backendService: BackendService,
    private fileUploaderToStorageService: FileUploaderToStorageService,
    private translateService: TranslateService,
  ) { }
  async uploadPublicImage(
    anyId: string,
    accountId: string,
    file: File,
    uid: string,
    idToken: string,
    isSquare = false,
    notRemoveOrigin: boolean = false,
    keepRatio: boolean = false,
  ): Promise<PublicImage>
  {
    const fileInfo = new FileUpload(file);
    this.fileSize.next(fileInfo.file.size);
    this.bytesTransferred.next(0);
    const processSubscription = this.fileUploaderToStorageService
      .process$.
      subscribe((process) => {
        this.bytesTransferred?.next(process?.bytesTransferred);
      });
    const tmpDestinationPath= `tmp/${accountId}`;
    const tmpDestinationFullPath = `${tmpDestinationPath}/${fileInfo.file.name}`;
    await this.fileUploaderToStorageService.upload(
      tmpDestinationFullPath,
      fileInfo,
    );
    processSubscription.unsubscribe();
    const publicImage = new PublicImage();
    if (isSquare) {
      publicImage.ratio = '1';
    }
    const contentKey = nanoid();
    const basePath = `public/${environment.appId}/${accountId}/${anyId}/${contentKey}`;
    publicImage.contentKey = contentKey;
    publicImage.src = basePath;
    publicImage.uploadedBy = uid;
    await this.convertPublicImage(
      accountId,
      idToken,
      fileInfo,
      tmpDestinationPath,
      basePath,
      publicImage.widths,
      publicImage.ratio,
      notRemoveOrigin,
      keepRatio
    );
    console.log(publicImage);
    return publicImage;
  }
  async removePublicImage(
    publicImage: PublicImage,
    idToken: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!publicImage?.src) {
        resolve();
      }
      if (!publicImage?.widths) {
        resolve();
      }
      const postData = {
        bucketName: environment.publicAssetsBucketName,
        src: publicImage.src,
        widths: publicImage.widths,
      };
      this.backendService.setIdToken(idToken);
      this.backendService.post('media/images/remove_public_image', postData)
        .then(() => {
          resolve();
        })
        .catch((e) => {
          console.error(e);
          reject();
        })
    });
  }
  async convertPublicImage(
    accountId: string,
    idToken: string,
    fileUpload: FileUpload,
    originalPath: string,
    destinationPath: string,
    widths: string[],
    ratio: string,
    notRemoveOrigin: boolean,
    keepRatio: boolean,
    ): Promise<any> {
    return new Promise((resolve, reject) => {
      const postData = {
        originalBucketName: environment.firebase.storageBucket,
        destinationBucketName: environment.publicAssetsBucketName,
        originalPath: originalPath,
        destinationPath: destinationPath,
        fileName: fileUpload.file.name,
        widths: widths,
        ratio: ratio,
        notRemoveOrigin: notRemoveOrigin,
        keepRatio: keepRatio
      };
      this.backendService.setIdToken(idToken);
      this.backendService.post('media/images/convert_public_image', postData)
        .then((ret) => {
          console.log(ret);
          resolve(ret);
        })
        .catch((e) => {
          console.error(e);
          alert(this.translateService.instant('通信エラーが発生しました。画面を再読み込みします。'));
          location.reload();
          reject();
        })
    });
  }
  async convertRequest(
    accountId: string,
    idToken: string,
    fileUpload: FileUpload,
    basePath: string,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const postData = {
        accountId: accountId,
        bucketName: environment.firebase.storageBucket,
        basePath: basePath,
        fileName: fileUpload.file.name,
        fileType: fileUpload.file.type,
        fileSize: fileUpload.file.size,
      };
      this.backendService.setIdToken(idToken);
      this.backendService.post('media/images/convert_image', postData)
        .then((ret) => {
          console.log(ret);
          resolve(ret);
        })
        .catch((e) => {
          console.error(e);
          reject();
        })
    });
  }
  async base64ToFile(dataURL: string, fileName: string) {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    return (fetch(dataURL)
      .then(function (result) {
        return result.arrayBuffer();
      }));
  }
}
