import { Component } from '@angular/core';

@Component({
  selector: 'app-header-top',
  templateUrl: './header-top.component.html',
  styleUrls: ['./header-top.component.scss']
})
export class HeaderTopComponent {
  isShowFixedHeader = false;
  isOpenSpMenu = false;
  constructor() { }
  ngOnInit(): void {
  }
  closeSpMenu(): void {
    this.isOpenSpMenu = false;
  }
  openSpMenu(): void {
    this.isOpenSpMenu = true;
  }
}
