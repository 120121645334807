import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Channel} from "../../models/channel";
import {Course} from "../../models/course";
import {AnswerTypes, ContentFrame, FrameTypes, SurveyType} from "../../models/course-frames";
import {ContentFragment, CourseProgress, Progress} from "../../models/course-progress";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {AuthService} from "../../services/auth/auth.service";
import {Store} from "@ngrx/store";
import {UiState} from "../../store/ui/ui.reducer";
import {CourseProgressesService} from "../../services/course-progresses/course-progresses.service";
import {ChannelsService} from "../../services/channels/channels.service";
import {CoursesService} from "../../services/courses/courses.service";
import {switchFalseLoading, switchTrueLoading} from "../../store/ui/ui.actions";
import {CourseSurveyFramesService} from "../../services/course-survey-frames/course-survey-frames.service";
import {Answer, AnswerStatus, SurveyAnswers} from "../../models/answer";
import {FormControl} from "@angular/forms";
import {CourseSurveyAnswersService} from "../../services/course-suevey-answers/course-survey-answers.service";
import {nanoid} from "nanoid";
import {DateFormatFirestorePipe} from "../../pipes/date-format-firestore/date-format-firestore.pipe";
import {CourseSubscription} from "../../models/course-subscription";
import {CourseSubscriptionsService} from "../../services/course-subscriptions/course-subscriptions.service";
import {AccountsService} from "../../services/accounts/accounts.service";
import {Account, AccountType} from "../../models/account";
import {ChannelSubscriptionsService} from "../../services/channel-subscriptions/channel-subscriptions.service";
import {ChannelSubscription} from "../../models/channel-subscription";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-channel-course-survey',
  templateUrl: './channel-course-survey.component.html',
  styleUrls: ['./channel-course-survey.component.scss']
})
export class ChannelCourseSurveyComponent implements OnInit{
  channelId: string;
  slug: string;
  courseId: string;
  partId: string;
  frameTypes = FrameTypes;
  framesLoaded: boolean;
  sessionsSubscription: Subscription;
  channelSubscriptionStatus: ChannelSubscription;
  currentChannel: Channel = new Channel();
  channelOwnerAccount: Account;
  currentCourse: Course = new Course();
  surveyFrames: ContentFrame;
  frameIndex = 0;
  currentProgress: CourseProgress;
  isNotFound = false;
  fragmentIndex: number;
  surveyFragmentLength: number;
  surveyFragments: ContentFragment[] = [];
  surveyAnswers: SurveyAnswers[] = [];
  latestAnswers: SurveyAnswers;
  forms: FormControl[] = [];
  isOpenHistoryModal = false;
  isHistoryMode = false;
  isDirty = false;
  courseSubscriptionStatus: CourseSubscription;
  accountType = AccountType;
  questionCurrentImageIndex: number[] = [];
  protected readonly surveyType = SurveyType;
  protected readonly answerTypes = AnswerTypes;
  protected readonly answerStatus = AnswerStatus;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toasterService: ToastrService,
    private authService: AuthService,
    private uiStore: Store<{ ui: UiState }>,
    private accountService: AccountsService,
    private courseProgressesService: CourseProgressesService,
    private courseSurveyFramesService: CourseSurveyFramesService,
    private courseSubscriptionsService: CourseSubscriptionsService,
    private channelsService: ChannelsService,
    private coursesService: CoursesService,
    private courseSurveyAnswersService: CourseSurveyAnswersService,
    private channelSubscriptionsService: ChannelSubscriptionsService,
    private translateService: TranslateService,
  ) {
    window.scrollTo(0, 0)
    this.slug = this.route.snapshot.paramMap.get('slug');
    this.courseId = this.route.snapshot.paramMap.get('courseId');
    this.partId = this.route.snapshot.paramMap.get('partId');
    this.uiStore.dispatch(switchTrueLoading());
  }
  async ngOnDestroy(): Promise<void> {
    if (this.currentProgress) {
      if (!this.isHistoryMode) {
        this.saveProgress();
        await this.saveAnswers();
        this.toasterService.success(this.translateService.instant('回答を保存しました。'));
      }
    }
  }
  async ngOnInit(): Promise<void> {
    await this.authService.refresh();
    // 未ログイン時は見えない
    if (this.authService.currentSession.isAnonymous) {
      console.log('404 isAnonymous');
      this.isNotFound = true;
      this.uiStore.dispatch(switchFalseLoading());
      return ;
    }
    this.currentChannel = await this.channelsService.fetchBySlug(this.slug);
    this.channelId = this.currentChannel?.id;
    this.channelOwnerAccount = await this.accountService.fetchAccount(this.currentChannel?.accountId);
    this.channelSubscriptionStatus = await this.channelSubscriptionsService.fetch(this.currentChannel?.id, this.authService.currentSession?.currentUser?.id);
    this.currentCourse = await this.coursesService.fetch(this.courseId);
    this.courseSubscriptionStatus = await this.courseSubscriptionsService.fetch(this.currentCourse?.id, this.authService.currentSession?.currentUser?.id);
    this.courseProgressesService.loadAllFrames(this.currentCourse, this.currentCourse.accountId).then();
    this.framesLoaded = this.courseProgressesService.framesLoaded;
    // コース権限チェック
    if (!this.coursesService.canShow(
      this.currentChannel,
      this.channelSubscriptionStatus,
      this.currentCourse,
      this.courseSubscriptionStatus,
      this.authService.currentSession.currentUser
    )) {
      console.log('404 コースを閲覧できません。');
      this.isNotFound = true;
      this.uiStore.dispatch(switchFalseLoading());
      return ;
    }
    // コンテンツデータ読み込み
    this.surveyFrames = await this.courseSurveyFramesService.fetch(
      this.currentChannel.accountId,
      this.courseId,
      this.partId
    );
    if (!this.surveyFrames) {
      console.log('404 コンテンツデータが読み込めない（存在しない）');
      this.isNotFound = true;
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    if (this.surveyFrames.surveyFrame.questions.length === 0) {
      console.log('404 設問が未作成');
      this.isNotFound = true;
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    this.buildForm();
    // 画像表示テーブル初期化
    this.initQuestionImage();
    // 回答データを取得
    this.surveyAnswers = await this.courseSurveyAnswersService.fetchByUser(
      this.courseId,
      this.surveyFrames.contentKey,
      this.authService.currentSession.currentUser.id
    );
    if (this.surveyAnswers.length > 0) {
      // 今回の回答データを作る
      const datePipe = new DateFormatFirestorePipe();
      const today = new Date();
      if (datePipe.transform(this.surveyAnswers[0].updatedAt) === datePipe.transform(today)) {
        this.latestAnswers = JSON.parse(JSON.stringify(this.surveyAnswers[0]));
        this.fillFormValue();
      } else {
        this.latestAnswers = JSON.parse(JSON.stringify(this.surveyAnswers[0]));
        this.latestAnswers.id = nanoid();
        this.fillFormValue();
      }
    } else {
      console.log('履歴なし')
      this.latestAnswers = new SurveyAnswers();
      this.latestAnswers.courseId = this.courseId;
      this.latestAnswers.contentKey = this.surveyFrames.contentKey;
      this.latestAnswers.uid = this.authService.currentSession.currentUser.id;
      this.latestAnswers.id = nanoid();
      this.buildAnswers();
      /*await this.courseSurveyAnswersService.create(
        this.courseId,
        this.authService.currentSession.currentUser.id,
        this.latestAnswers
      )*/
    }
    // コース進捗データ取得
    this.courseProgressesService.fetchByMy(
      this.courseId,
      this.authService.currentSession.currentUser.id
    )
      .then(async (courseProgress) => {
        if (courseProgress && courseProgress.progresses) {
          // 視聴進捗の準備
          this.currentProgress = courseProgress;
          const index = courseProgress.progresses.findIndex(item => item.contentKey === this.partId);
          let currentIndex = index;
          if (index === -1) {
            currentIndex = this.currentProgress.progresses.length;
            this.currentProgress.progresses[currentIndex] = new Progress();
            this.currentProgress.progresses[currentIndex].contentKey = this.partId;
            this.currentProgress.progresses[currentIndex] = this.fixProgress(courseProgress.progresses[currentIndex]);
          } else {
            if (this.surveyFrames.surveyFrame.questions.length !== courseProgress.progresses[currentIndex].fragment.length) {
              this.currentProgress.progresses[currentIndex] = this.fixProgress(courseProgress.progresses[currentIndex]);
            }
          }
          this.surveyFragments = this.currentProgress.progresses[currentIndex].fragment;
          this.surveyFragmentLength = this.currentProgress.progresses[currentIndex].fragment.length;
          this.fragmentIndex = currentIndex;
        } else {
          // 無ければ作る
          await this.courseProgressesService.create(
            this.currentCourse.id,
            this.authService.currentSession.currentUser.id
          );
          this.currentProgress = await this.courseProgressesService.fetchByMy(
            this.currentCourse.id,
            this.authService.currentSession.currentUser.id
          );
          this.currentProgress.progresses = [];
          this.currentProgress.progresses[0] = new Progress();
          this.currentProgress.progresses[0].contentKey = this.partId;
          this.currentProgress.progresses[0] = this.fixProgress(this.currentProgress.progresses[0]);
          await this.courseProgressesService.update(
            this.currentCourse.id,
            this.authService.currentSession.currentUser.id,
            this.currentProgress
          );
          this.currentProgress = await this.courseProgressesService.fetchByMy(
            this.currentCourse.id,
            this.authService.currentSession.currentUser.id
          );
          this.surveyFragments = this.currentProgress.progresses[0].fragment;
          this.fragmentIndex = 0;
        }
      })
    this.uiStore.dispatch(switchFalseLoading());
  }
  saveProgress(): void {
    if (!this.currentProgress) {
      return;
    }
    const index = this.currentProgress.progresses.findIndex((p) => p.contentKey === this.partId);
    this.currentProgress.progresses[index].progress = this.countNumOfAnswered() / this.surveyFrames.surveyFrame.questions.length;
    this.courseProgressesService.update(this.courseId,
      this.authService.currentSession.currentUser.id,
      this.currentProgress
    )
      .then(() => {
        console.log('updated progress');
      })
      .catch((e) => {
        console.log(e);
      });
  }
  fixProgress(progress: Progress): Progress {
    progress.fragment = [];
    let c = 0;
    for (let i = 0; i < this.surveyFrames.surveyFrame.questions.length; i++) {
      const fragment = new ContentFragment();
      fragment.pointSec = 0;
      fragment.viewed = false;
      progress.fragment.push(fragment);
    }
    return progress;
  }
  async saveAnswers(): Promise<void> {
    if (!this.latestAnswers) {
      return;
    }
    if (!this.isHistoryMode) {
      this.latestAnswers.applied = true;
      this.latestAnswers.appliedAt = new Date();
      await this.courseSurveyAnswersService.update(
        this.courseId,
        this.authService.currentSession.currentUser.id,
        this.latestAnswers
      )
      console.log('saved answers.');
    }
  }
  async backToIndex(): Promise<void> {
    await this.router.navigate([`/channel/course/index/${this.currentChannel.slug}/${this.currentCourse.id}`]);
    return;
  }
  isExistRev(): boolean {
    if (!this.framesLoaded) {
      return false;
    }
    const i = this.courseProgressesService.indexSeq.findIndex(p => p.partId === this.partId);
    return !!this.courseProgressesService.indexSeq[i - 1];
  }
  isExistFwd(): boolean {
    if (!this.framesLoaded) {
      return false;
    }
    const i = this.courseProgressesService.indexSeq.findIndex(p => p.partId === this.partId);
    return !!this.courseProgressesService.indexSeq[i + 1];
  }
  clickRev(): void {
    const i = this.courseProgressesService.indexSeq.findIndex(p => p.partId === this.partId);
    if (this.isExistRev()) {
      const part = this.courseProgressesService.indexSeq[i -1];
      this.router.navigate([`/channel/course/${this.getUrlByFrameType(part.frameType)}/${this.slug}/${this.courseId}/${part.partId}`]).then();
      return;
    }
    return;
  }
  clickFwd(): void {
    const i = this.courseProgressesService.indexSeq.findIndex(p => p.partId === this.partId);
    if (this.isExistFwd()) {
      const part = this.courseProgressesService.indexSeq[i +1];
      this.router.navigate([`/channel/course/${this.getUrlByFrameType(part.frameType)}/${this.slug}/${this.courseId}/${part.partId}`]).then();
      return;
    }
    return;
  }
  private getUrlByFrameType(type: string): string {
    switch (type) {
      case this.frameTypes.VideoFrame:
        return 'video';
      case this.frameTypes.SurveyFrame:
        return 'survey';
      case this.frameTypes.SlideFrame:
        return 'slide';
      case this.frameTypes.ExaminationFrame:
        return 'examination';
      case this.frameTypes.ReportFrame:
        return 'report';
      case this.frameTypes.FileFrame:
        return 'file';
      case this.frameTypes.LiveFrame:
        return 'live';
      default:
        return '';
    }
  }
  buildForm(): void {
    this.forms = [];
    for (const q of this.surveyFrames.surveyFrame.questions) {
      const form = new FormControl<string|null>(null,[]);
      this.forms.push(form);
    }
  }
  fillFormValue(): void {
    let c = 0;
    for (const q of this.surveyFrames.surveyFrame.questions) {
      switch (q.type) {
        case this.answerTypes.radio:
        case this.answerTypes.describe:
          this.forms[c].setValue(this.latestAnswers.answers[c].answer);
          break;
        case this.answerTypes.check:
          break;
      }
      c++;
    }
  }
  buildAnswers(): void {
    for (const q of this.surveyFrames.surveyFrame.questions) {
      const answer = new Answer();
      answer.contentKey = q.contentKey;
      answer.type = q.type;
      answer.status = this.answerStatus.noAnswer;
      this.latestAnswers.answers.push(answer);
    }
  }
 checkboxIsChecked(index: number, value: string): boolean {
    if (!this.latestAnswers) {
      return false;
    }
    return this.latestAnswers.answers[index].answer.findIndex(a => a === value) !== -1;
  }
  answerUpdate(contentKey: string, value: string, e: any): void {
    if (this.isHistoryMode) {
      const aIndex = this.latestAnswers.answers.findIndex(a => a.contentKey === contentKey);
      this.latestAnswers.answers[aIndex].answer[0] = this.latestAnswers.answers[aIndex].answer[0];
      if (this.surveyFrames.surveyFrame.questions[aIndex].type === this.answerTypes.check) {
        e.currentTarget.checked = this.latestAnswers.answers[aIndex].answer.findIndex(a => a === value) !== -1;
        return;
      } else {
        this.forms[aIndex].setValue(this.latestAnswers.answers[aIndex].answer[0]);
        return;
      }
    }
    this.isDirty = true;
    const q = this.surveyFrames.surveyFrame.questions.find(q => q.contentKey === contentKey);
    const aIndex = this.latestAnswers.answers.findIndex(a => a.contentKey === contentKey);
    this.latestAnswers.answers[aIndex].status = this.answerStatus.notScore;
    switch (q.type) {
      case this.answerTypes.radio:
      case this.answerTypes.describe:
        this.latestAnswers.answers[aIndex].answer[0] = this.forms[aIndex].value;
        break;
      case this.answerTypes.check:
        if (e.currentTarget.checked && this.latestAnswers.answers[aIndex].answer.findIndex(o => o === value) === -1) {
          this.latestAnswers.answers[aIndex].answer.push(value);
        } else if (!e.currentTarget.checked) {
          const index = this.latestAnswers.answers[aIndex].answer.findIndex(o => o === value);
          if (index !== -1) {
            this.latestAnswers.answers[aIndex].answer.splice(index, 1);
          }
        }
        break;
    }
  }
  countNumOfAnswered(): number {
    if (!this.latestAnswers) {
      return 0;
    }
    let c = 0;
    for (const a of this.latestAnswers.answers) {
      if (a.status !== this.answerStatus.noAnswer) {
        c++
      }
    }
    return c;
  }
  countNumOfAnsweredForHistory(index: number): number {
    if (!this.surveyAnswers[index]) {
      return 0;
    }
    let c = 0;
    for (const a of this.surveyAnswers[index].answers) {
      if (a.status !== this.answerStatus.noAnswer) {
        c++
      }
    }
    return c;
  }
  openHistoryModal(): void {
    this.isOpenHistoryModal = true;
  }
  closeHistoryModal(): void {
    this.isOpenHistoryModal = false;
  }
  async showHistory(index: number): Promise<void> {
    if (window.confirm(this.translateService.instant('過去の回答を表示する前に、現在の回答を保存します。よろしいですか？'))) {
      await this.saveAnswers();
      this.latestAnswers = JSON.parse(JSON.stringify(this.surveyAnswers[index]));
      this.isHistoryMode = true;
    }
    this.closeHistoryModal();
  }
  checkDirty(): void {
    for (let f of this.forms) {
      if (f.dirty) {
        this.isDirty = true;
        break;
      }
    }
  }
  initQuestionImage(): void {
    let qIndex = 0;
    for (let q of this.surveyFrames?.surveyFrame?.questions) {
      this.questionCurrentImageIndex.push(0);
      qIndex++;
    }
  }
  getSelectedImageSrc(qIndex: number): string {
    if (!this.surveyFrames?.surveyFrame?.questions[qIndex].images) {
      return 'no-image'
    }
    return this.surveyFrames?.surveyFrame?.questions[qIndex].images[this.questionCurrentImageIndex[qIndex]]?.src;
  }
  changeSelectedImageSrc(qIndex: number, imageIndex: number): void {
    this.questionCurrentImageIndex[qIndex] = imageIndex;
  }
}
