import {createReducer, on, createSelector, createFeatureSelector} from '@ngrx/store';
import * as BreadcrumbActions from './breadcrumb.actions';
export const sessionFeatureKey = 'breadcrumb';
export interface BreadcrumbState {
  showBreadcrumb: boolean;
  urlParams: string[];
}
export const initialState: BreadcrumbState = {
  showBreadcrumb: true,
  urlParams: [],
};
const _sessionsReducer = createReducer(
  initialState,
  on(BreadcrumbActions.switchTrueShowBreadcrumb, state => ({ ...state, showBreadcrumb: true })),
  on(BreadcrumbActions.switchFalseShowBreadcrumb, state => ({ ...state, showBreadcrumb: false })),
  on(BreadcrumbActions.storeUrlParams, (state, { payload }) => ({ ...state, urlParams: payload })),
);
export function breadcrumbReducer(state: any, action: any): any {
  return _sessionsReducer(state, action);
}
const getState = createFeatureSelector<BreadcrumbState>(sessionFeatureKey);
export const isShowBreadcrumb = createSelector(
  getState,
  state => state.showBreadcrumb
);
