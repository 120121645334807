import { Injectable } from '@angular/core';
import {FileUpload} from "../../models/file-upload";
import {BehaviorSubject, finalize} from "rxjs";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import firebase from "firebase/compat/app";
import FirebaseError = firebase.FirebaseError;

@Injectable({
  providedIn: 'root'
})
export class FileUploaderToStorageService {
  process$: BehaviorSubject<firebase.storage.UploadTaskSnapshot | null> = new BehaviorSubject(null);
  downloadURL$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  downloadURL: string;
  constructor(
    private storage: AngularFireStorage,
  ) { }
  upload(
    destinationPath: string,
    fileUpload: FileUpload
  ) {
    return new Promise<void>((resolve, reject) => {
      const filePath = destinationPath;
      const storageRef = this.storage.ref(filePath);
      const uploadTask = this.storage.upload(filePath, fileUpload.file);
      try {
        uploadTask.snapshotChanges()
          .pipe(finalize(() => {
              storageRef.getDownloadURL()
                .subscribe(downloadURL => {
                  this.downloadURL$ = downloadURL;
                });
            })
          ).subscribe(result => {
          this.process$.next(result);
          const transfer = Number(result.bytesTransferred.toFixed());
          if (fileUpload.file.size === transfer) {
            console.log('finished')
            resolve();
          }
        });
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  }
  remove(objectName: string) {
    const storageRef = this.storage.ref(objectName);
    storageRef.delete()
      .toPromise()
      .then(() => {
      })
      .catch((e: FirebaseError) => {
        if (e.code === 'storage/object-not-found') {}
      })
  }
  async getDownloadUrl(objectName: string): Promise<string> {
    return new Promise((resolve) => {
      const storageRef = this.storage.ref(objectName);
      storageRef.getDownloadURL().subscribe((url) => {
        if (url) {
          resolve(url);
        }
      });
    })
  }
}
