<app-forbidden *ngIf="forbidden"></app-forbidden>
<ng-container *ngIf="!forbidden && isVisible">
  <div class="l-signup">
    <div class="p-signup">
      <div class="p-signup__head">
        <div class="p-signup__head__kv"></div>
      </div>
      <div class="p-signup__main">
        <div class="p-signup__main__title" translate>新しいチャンネルを作成する</div>
        <div class="p-signup__main__input">
          <label>
            <span translate>チャンネル表示名</span>
            <span class="c-form-required" translate>必須</span>
          </label>
          <input
            [formControl]="name"
            type="text"
            name="channelName"
            [placeholder]="'例）●●●●講座' | translate"
          >
          <div
            *ngIf="name.dirty && name.invalid"
            class="c-form-error"
            translate
          >チャンネル名は4文字〜100文字以内で入力してください。</div>
          <div class="p-signup__main__notes">
            <p>
              <span translate>チャンネル表示名とは？</span><br>
            <span translate>これから作成するあなたのチャンネルの名称です。</span>
            </p>
          </div>
        </div>

        <div class="p-signup__main__input">
          <label>
            <span translate>チャンネルURL</span>
            <span class="c-form-required" translate>必須</span>
          </label>
          <input
            (keyup)="checkUrlDuplication()"
            [formControl]="slug"
            type="text" name="channelURL" placeholder="sample-channel">
          <div
            *ngIf="slug.dirty && slug.invalid"
            class="c-form-error"
            translate
          >チャンネルURLは英数字8文字〜100文字以内で入力してください。先頭は英文字で、全て小文字である必要があり、記号はハイフン(-)のみが使用できます。</div>
          <div
            *ngIf="slug.dirty && isDuplicate"
            class="c-form-error"
            translate
          >このチャンネルURLは他のチャンネルで既に使用されています。別のチャンネルURLを入力してください。</div>
          <div class="p-signup__main__notes">
            <p>
              <span translate>チャンネルURLとは？</span><br>
              {{baseUrl}}/channels/
              <span class="p-signup__main__notes__redTexts" translate>入力したURL</span>
              <span translate>のように使用されます。</span>
            </p>
          </div>
        </div>

        <div class="p-signup__main__input">
          <label>
            <span translate>メインカテゴリーを選択</span>
            <span class="c-form-required" translate>必須</span>
          </label>
          <select
            [formControl]="category"
            name="channelCategory">
            <option [ngValue]="null" selected><span translate>選択</span> ▼</option>
            <option
              *ngFor="let category of formCategories"
              [ngValue]="category.id"
              translate
            >{{category.name}}</option>
          </select>
          <div
            *ngIf="category.dirty && category.invalid"
            class="c-form-error"
            translate
          >カテゴリーを選択してください。</div>
          <div class="p-signup__main__notes">
            <p>
              <span translate>メインカテゴリーとは？</span><br>
              <span translate>受講者がチャンネルやコースを探したり、サイト上での分類に使用されます。コースにもカテゴリーが設定できるので、あくまでもメインとなるカテゴリーを選択してください。</span>
            </p>
          </div>
        </div>

        <ng-container *ngIf="account">
          <div class="p-signup__main__radio">
            <div class="p-signup__main__radio__wrap">
              <input [formControl]="createNewAccount" [value]="false" type="radio" name="channelCreate" id="addChannel">
              <label class="c-form-label" for="addChannel"
              ><span translate>現在の</span>「{{account.companyName}}」<span translate>アカウントにチャンネルを追加する</span></label>
            </div>
            <div class="p-signup__main__radio__wrap">
              <input [formControl]="createNewAccount" [value]="true"  type="radio" name="channelCreate" id="newChannel">
              <label class="c-form-label" for="newChannel" translate>新しいアカウントでチャンネルを作成する</label>
            </div>
          </div>
        </ng-container>
        <div class="p-signup__main__consent">
          <div class="p-signup__main__consent__wrap">
            <input
              [formControl]="privacy"
              type="checkbox" name="privacy" value="" id="privacy">
            <label
              class="p-signup__main__consent__wrap__label"
              for="privacy"
              translate
            >プライバシーポリシーに同意する</label>
            <div class="p-signup__main__consent__wrap__link" (click)="openPrivacyLink()"></div>
          </div>
          <div
            *ngIf="privacy.dirty && privacy.invalid"
            class="c-form-error"
            translate
          >プライバシーポリシーへの同意が必要です。</div>
          <div class="p-signup__main__consent__wrap">
            <input
              [formControl]="contract"
              type="checkbox" name="contract" value="" id="contract">
            <label
              class="p-signup__main__consent__wrap__label"
              for="contract"
              translate
            >FireRocketサービス規約に同意する</label>
            <div class="p-signup__main__consent__wrap__link" (click)="openContractsLink()"></div>
          </div>
          <div
            *ngIf="contract.dirty && contract.invalid"
            class="c-form-error"
            translate
          >サービス規約への同意が必要です。</div>
        </div>
        <div class="c-form-error" *ngIf="showError" translate>入力されていない項目があります。</div>
        <div class="p-signup__main__button">
          <button
            type="button"
            class="c-button c-button-login"
            (click)="onSubmit()"
          ><span translate>チャンネルを作成する</span></button>
        </div>
        <div class="p-signup__main__button">
          <button
            type="button"
            class="c-button c-button-cancel"
            (click)="cancel()"
          ><span translate>キャンセルして戻る</span></button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
