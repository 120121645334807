import { Component } from '@angular/core';

@Component({
  selector: 'app-my-page-auth-setup',
  templateUrl: './my-page-auth-setup.component.html',
  styleUrls: ['./my-page-auth-setup.component.scss']
})
export class MyPageAuthSetupComponent {

}
