import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-business-contract',
  templateUrl: './business-contract.component.html',
  styleUrls: ['./business-contract.component.scss']
})
export class BusinessContractComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

}
