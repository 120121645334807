import { createAction, union } from '@ngrx/store';
import {CurrentUser} from './sessions.reducer';
import {User} from '../../models/user';
import {AppConfig} from '../../models/app-config';

export const storeUser = createAction(
  '[Sessions Component] storeUser',
  (payload?: { currentUser: CurrentUser }) => ({ payload: payload.currentUser }),
);
export const storeIdToken = createAction(
  '[Sessions Component] storeIdToken',
  (payload?: { idToken: string }) => ({ payload: payload.idToken }),
);
export const storeUsers = createAction(
  '[Sessions Component] storeUsers',
  (payload?: { users: User[] | undefined }) => ({ payload: payload.users }),
);
export const storeIsAnonymous = createAction(
  '[Sessions Component] storeIsAnonymous',
  (payload?: { isAnonymous: boolean }) => ({ payload: payload.isAnonymous }),
);
export const storeIsOem = createAction(
  '[Sessions Component] storeIsOem',
  (payload?: { isOem: boolean }) => ({ payload: payload.isOem }),
);
export const storeAppConfig = createAction(
  '[Sessions Component] storePlanName',
  (payload?: { appConfig: AppConfig }) => ({ payload: payload.appConfig }),
);
const actions = union({
  storeUser: storeUser,
  storeUsers: storeUsers,
  storeIdToken: storeIdToken,
  storeIsAnonymous: storeIsAnonymous,
  storeAppConfig: storeAppConfig,
  storeIsOem: storeIsOem,
});

export type SessionsActions = typeof actions;
