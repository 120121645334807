<div class="l-main">
  <app-navigations></app-navigations>
  <div class="l-content">
    <app-breadcrumb></app-breadcrumb>
    <div class="l-content__inner">
      <div class="c-content-header">
        <div
          *ngIf="currentChannel.name"
          class="c-content-header__channelName"><span translate>削除するコース</span> {{currentCourse.name}} ({{currentCourse.id}})</div>
        <div class="c-content-header__title" translate>
          コース削除
        </div>
      </div>
      <div class="l-templates">
        <div class="p-templates">
          <div class="p-templates__content">
            <div class="p-templates__content__notes">
              <p translate>・公開済みのコースは削除することが出来ません。</p>
              <p translate>・販売済みのコースは削除するとが出来ません。</p>
              <p translate>・作成したコースや画像、動画、スライド、音声などもすべて削除されます。</p>
            </div>
            <div class="p-profile">
            <div class="p-profile__row">
              <div class="c-form-error" translate>この操作は、取り消すことができません。</div>
              <div class="p-profile__row__buttons">
                  <div class="p-profile__row__buttons__button">
                    <button
                      (click)="back()"
                      class="c-button c-button-cancel"><span translate>キャンセル</span></button>
                  </div>
                  <div class="p-profile__row__buttons__button">
                    <button
                      (click)="remove()"
                      class="c-button c-button-warning"><span translate>コースを削除する</span></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
