export class Signup {
  displayName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  constructor() {
    this.displayName = '';
    this.email = '';
    this.password = '';
    this.passwordConfirmation = '';
  }
  reset(): void {
    this.displayName = '';
    this.email = '';
    this.password = '';
    this.passwordConfirmation = '';
  }
}
