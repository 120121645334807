import { Component, OnInit } from '@angular/core';
import {Channel} from "../../models/channel";
import {Account, AccountType} from "../../models/account";
import {ChannelSubscription} from "../../models/channel-subscription";
import {User} from "../../models/user";
import {ActivatedRoute} from "@angular/router";
import {Store} from "@ngrx/store";
import {BreadcrumbState} from "../../store/breadcrumb/breadcrumb.reducer";
import {UiState, UrlState} from "../../store/ui/ui.reducer";
import {ChannelsService} from "../../services/channels/channels.service";
import {AuthService} from "../../services/auth/auth.service";
import {SessionState} from "../../store/sessions/sessions.reducer";
import {Confirm} from "../../models/confirm";
import {
  storeUrlState,
  switchFalseLoading,
  switchTrueLoading,
  switchTrueShowMenuInSideNavi,
  switchTrueShowSideNavigation
} from "../../store/ui/ui.actions";
import {storeUrlParams, switchTrueShowBreadcrumb} from "../../store/breadcrumb/breadcrumb.actions";
import {AccountsService} from "../../services/accounts/accounts.service";
import {BackendService} from "../../services/backend/backend.service";
import {BehaviorSubject} from "rxjs";
import {FormControl} from "@angular/forms";
import {ChannelSubscriptionsService} from "../../services/channel-subscriptions/channel-subscriptions.service";
import {CourseUser} from "../../models/course-subscription";

@Component({
  selector: 'app-manager-channel-users',
  templateUrl: './manager-channel-users.component.html',
  styleUrls: ['./manager-channel-users.component.scss']
})
export class ManagerChannelUsersComponent implements OnInit {
  forbidden: boolean = false;
  isNotFound = false;
  currentSession:SessionState | undefined;
  currentAccount: Account;
  channelId: string;
  currentChannel: Channel;
  channelSubscriptions: ChannelSubscription[] = [];
  isOpenEditModal = false;
  isOpenAddUserModal = false;
  isOpenConfirmModal = false;
  confirmModalData: Confirm;
  editUser = new BehaviorSubject<User>(null);
  name = new FormControl<string>(null);
  email = new FormControl<string>(null);
  companyName = new FormControl<string>(null);
  showUsers: CourseUser[] = [];
  fetchedUsers: CourseUser[] = [];
  users: User[] = [];
  isOpenChatModal = false;
  chatTargetUser: User;
  protected readonly AccountType = AccountType;
  constructor(
    private route: ActivatedRoute,
    private breadcrumbStore: Store<{ breadcrumb: BreadcrumbState }>,
    private uiStore: Store<{ ui: UiState }>,
    private channelsService: ChannelsService,
    private accountsService: AccountsService,
    private authService: AuthService,
    private backendService: BackendService,
    private channelSubscriptionsService: ChannelSubscriptionsService,
    private accountService: AccountsService,
  ) {
    window.scrollTo(0, 0);
    this.channelId = this.route.snapshot.paramMap.get('channelId');
    this.uiStore.dispatch(switchTrueShowSideNavigation());
    this.uiStore.dispatch(switchTrueShowMenuInSideNavi());
    this.breadcrumbStore.dispatch(storeUrlParams({ urlParams: [ this.channelId ]}));
    this.breadcrumbStore.dispatch(switchTrueShowBreadcrumb());
    this.uiStore.dispatch(switchTrueLoading());
  }
  async ngOnInit(): Promise<void> {
    // 認証
    this.fetchedUsers = [];
    this.channelSubscriptions = [];
    await this.authService.refresh();
    await new Promise(resolve => setTimeout(resolve, 200));
    const account = await this.accountService.fetchAccount(this.authService.currentSession.currentUser.currentAccountId);
    if (!await this.accountService.isAcceptByClientIp(account)) {
      this.uiStore.dispatch(switchFalseLoading());
      this.forbidden = true;
      return;
    }
    this.currentSession = this.authService.currentSession;
    this.currentChannel = await this.channelsService.fetch(this.channelId);
    this.currentAccount = await this.accountsService.fetchAccount(this.currentSession.currentUser.currentAccountId);
    if (this.currentChannel.accountId !== this.currentAccount.id) {
      this.isNotFound = true;
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    const urlState = new UrlState();
    urlState.channelId = this.channelId;
    urlState.slug = this.currentChannel.slug;
    urlState.courseId = undefined;
    this.uiStore.dispatch(storeUrlState({ urlState: urlState}));
    await this.loadBySubscription();
    this.search();
    this.uiStore.dispatch(switchFalseLoading());
  }
  async loadBySubscription(): Promise<void> {
    const subs = await this.channelSubscriptionsService.fetchAll(
      this.channelId
    );
    subs.map(async s => {
      this.fetchedUsers.push(
        {
          uid: s.uid,
          subscription: s,
          courseProgress: null,
          user: null
        }
      );
    });
    this.search();
    this.fetchedUsers.map(async u => {
      u.user = await this.fetchUser(u.uid);
    })
  }
  async fetchUser(uid: string): Promise<User> {
    return new Promise((resolve) => {
      const user = this.users.find(u => u.id === uid);
      if (user) {
        resolve(user);
      }
      this.fetchOneUser(uid).then((user => {
        resolve(user);
      }))
        .catch(() => {
          resolve(null);
        });
    });
  }
  async fetchOneUser(uid: string): Promise<User> {
    this.backendService.setIdToken(
      this.authService.currentSession.idToken
    )
    const data = {
      accountId: this.currentAccount.id,
      uid: uid,
    }
    const user = await this.backendService.post('lms/channel-manager/fetch_user', data)
      .then((user) => {
        return user as User;
      })
    return user;
  }
  resetForm(): void {
    this.name.setValue(null);
    this.email.setValue(null);
    this.companyName.setValue(null);
    this.search();
  }
  search(): void {
    this.showUsers = [];
    let searched = false;
    if (this.name.value) {
      this.showUsers = this.fetchedUsers.filter(u => u?.user?.fullName && u?.user?.fullName?.indexOf(this.name.value) !== -1);
      searched = true;
    }
    if (this.email.value) {
      if (this.showUsers.length === 0) {
        this.showUsers = this.fetchedUsers.filter(u => u?.user?.email && u?.user?.email.indexOf(this.email.value) !== -1);
      } else {
        this.showUsers = this.showUsers.filter(u => u?.user?.email && u?.user?.email.indexOf(this.email.value) !== -1);
      }
      searched = true;
    }
    if (this.companyName.value) {
      if (this.showUsers.length === 0) {
        this.showUsers = this.fetchedUsers.filter(u => u?.user?.companyName && u?.user?.email.indexOf(this.companyName.value) !== -1);
      } else {
        this.showUsers = this.showUsers.filter(u => u?.user?.companyName && u?.user?.email.indexOf(this.companyName.value) !== -1);
      }
      searched = true;
    }
    if (!searched) {
      this.showUsers = this.fetchedUsers;
    }
  }
  openEditModal(user: User): void {
    this.editUser.next( user);
    this.isOpenEditModal = true;
  }
  changeOpenEditUserModal($event: boolean): void {
    this.isOpenEditModal = $event;
  }
  afterRemovedUser(userId: string): void {
    this.fetchedUsers = this.fetchedUsers.filter(u => u.uid !== userId);
    this.search();
  }
  openAddUserModal(): void {
    this.isOpenAddUserModal = true;
  }
  async changeOpenAddUserModal($event: boolean): Promise<void> {
    this.uiStore.dispatch(switchTrueLoading());
    this.isOpenAddUserModal = $event;
    this.fetchedUsers = [];
    this.channelSubscriptions = [];
    await this.loadBySubscription();
    this.search();
    this.uiStore.dispatch(switchFalseLoading());
  }
  openChatModal(user: CourseUser): void {
    if (!user?.user?.id) {
      return;
    }
    this.chatTargetUser = user.user;
    this.isOpenChatModal = true;
  }
  changeOpenChatModal($event: boolean): void {
    this.isOpenChatModal = $event;
  }
}
