<div class="l-main">
  <app-navigations></app-navigations>
  <div class="l-content">
    <app-breadcrumb></app-breadcrumb>
    <div class="l-content__inner">
      <div class="c-content-header">
        <div class="c-content-header__title" translate>お問い合わせ</div>
      </div>
      <div class="l-templates">
        <div class="p-templates">
          <div class="p-templates__content">
            <div
              class="p-templates__content__info"
              [innerHTML]="'お問合せ内容の確認後、担当者よりご連絡させていただきます。\n内容次第ではお答えできない場合があります。' | translate | nl2br"
            ></div>

            <div class="p-profile">
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="titleOfinquiry" translate>お問い合わせ内容</label>
                  <select class="c-form-input" name="titleOfinquiry" id="titleOfinquiry">
                    <option value="" translate>選択してください</option>
                    <option value="" translate>問い合わせ1</option>
                    <option value="" translate>問い合わせ2</option>
                    <option value="" translate>問い合わせ3</option>
                    <option value="" translate>問い合わせ4</option>
                    <option value="" translate>その他</option>
                  </select>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label
                    class="c-form-label"
                    for="name"
                  >
                    <span translate>お名前（法人の場合、法人名とご担当者名）</span>
                    <span class="c-form-required" translate>必須</span>
                  </label>
                  <input
                    class="c-form-input"
                    type="text"
                    name="name"
                    id="name"
                    [placeholder]="'山田　太郎' | translate"
                  >
                  <div class="c-form-error" translate>お名前を入力してください。</div>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label
                    class="c-form-label"
                    for="email"
                  >
                    <span translate>メールアドレス</span>
                    <span class="c-form-required" translate>必須</span>
                  </label>
                  <input class="c-form-input" type="text" name="email" id="email" placeholder="abc@defg.jp">
                  <div class="c-form-error" translate>メールアドレスを入力してください。</div>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__textArea">
                  <label
                    class="c-form-label"
                    for="invalid"
                    translate
                  >お問い合わせ本文</label>
                  <textarea
                    class="c-form-textArea"
                    name="invalid"
                    id="invalid"
                    [placeholder]="'お問い合わせ本文を入力してください。' | translate"
                  ></textarea>
                </div>
              </div>

              <div class="p-profile__row">
                <div class="p-profile__row__buttons">
                  <div class="p-profile__row__buttons__button">
                    <button class="c-button c-button-cancel"
                    ><span translate>戻る</span></button>
                  </div>
                  <div class="p-profile__row__buttons__button">
                    <button class="c-button c-button-primary"
                    ><span translate>送信する</span></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
