import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth/auth.service";
import {storeHeaderLogoSrc} from "../../store/ui/ui.actions";
import {environment} from "../../../environments/environment";
import {Store} from "@ngrx/store";
import {UiState} from "../../store/ui/ui.reducer";
import {ChannelsService} from "../../services/channels/channels.service";
import {Router} from "@angular/router";
import {ChannelSubscriptionsService} from "../../services/channel-subscriptions/channel-subscriptions.service";

@Component({
  selector: 'app-contents-top',
  templateUrl: './contents-top.component.html',
  styleUrls: ['./contents-top.component.scss']
})
export class ContentsTopComponent implements OnInit {
  isReady = false;
  isNotFound = false;
  constructor(
    private authService: AuthService,
    private router: Router,
    private channelSubscriptionsService: ChannelSubscriptionsService,
    private channelsService: ChannelsService,
    private uiStore: Store<{ ui: UiState}>,
  ) {
  }
  async ngOnInit(): Promise<void> {
    await this.authService.refresh();
    if (this.authService?.user?.isOem) {
      const subs = await this.channelSubscriptionsService.fetchAllByMy(
        this.authService.user.id
      );
      if (subs.length > 0) {
        for (let s of subs) {
          if (s.activated) {
            const c = await this.channelsService.fetch(s.channelId);
            if (c) {
              this.router.navigate([`/channel/${c.slug}`])
                .catch();
              return;
            }
          }
        }
        this.isNotFound = true;
        return;
      }
    }
    this.isReady = true;
    this.uiStore.dispatch(storeHeaderLogoSrc(environment.productMainLogoSrc));
  }
}
