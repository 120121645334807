<div class="l-main">
  <app-navigations></app-navigations>
  <div class="l-content">
    <app-breadcrumb></app-breadcrumb>
    <div class="l-content__inner">
      <div class="c-content-header">
        <div class="c-content-header__title" translate>アカウントの切替</div>
      </div>
      <div class="l-templates">
        <div class="p-templates">

          <div class="p-templates__content">
            <div class="p-templates__content__list">
              <h3 translate>所属アカウント一覧</h3>
              <div class="c-list-user">
                <div class="c-list-user__head">
                  <div class="c-list-user__head__name" translate>会社名</div>
                  <div class="c-list-user__head__date" translate>id</div>
                  <div class="c-list-user__head__date" translate>登録日</div>
                  <div class="c-list-user__head__edit" translate>切替</div>
                </div>
                <div class="c-list-user__body">
                  <ng-container *ngFor="let a of accounts">
                    <div class="c-list-user__body__row">
                      <div class="c-list-user__body__row__name">{{a.companyName}}</div>
                      <div class="c-list-user__body__row__name">{{a.id}}</div>
                      <div class="c-list-user__body__row__date">{{a.createdAt | dateFormatFirestore}}</div>
                      <div class="c-list-user__body__row__change">
                        <button
                          *ngIf="currentUser.currentAccountId !== a.id"
                          (click)="switch(a)"
                          class="c-list-user__body__row__change"
                        ><span translate>切り替える</span></button>
                        <span *ngIf="currentUser.currentAccountId === a.id" translate>使用中</span>
                      </div>
                    </div>
                  </ng-container>

                </div>
              </div>
            </div>
          </div>

          <div class="p-templates__content">
            <div class="p-templates__content__list">
              <h3 translate>招待されたアカウント一覧</h3>
              <div class="c-list-user">
                <div class="c-list-user__head">
                  <div class="c-list-user__head__name" translate>会社名</div>
                  <div class="c-list-user__head__date" translate>登録日</div>
                  <div class="c-list-user__head__edit" translate>承認</div>
                </div>
                <div class="c-list-user__body">
                  <ng-container *ngFor="let a of invitedAccounts">
                    <div class="c-list-user__body__row">
                      <div class="c-list-user__body__row__name">{{a.companyName}}</div>
                      <div class="c-list-user__body__row__date">{{a.createdAt | dateFormatFirestore}}</div>
                      <div class="c-list-user__body__row__change">
                        <button
                          (click)="join(a )"
                          class="c-list-user__body__row__change"
                        ><span translate>招待を承認する</span></button>
                      </div>
                    </div>
                  </ng-container>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
