import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from "../../models/user";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {BackendService} from "../../services/backend/backend.service";
import {Account} from "../../models/account";
import {Channel} from "../../models/channel";
import {AuthService} from "../../services/auth/auth.service";
import {BehaviorSubject} from "rxjs";
import {environment} from "../../../environments/environment";
import {CoursesService} from "../../services/courses/courses.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-edit-oem-user-modal',
  templateUrl: './edit-oem-user-modal.component.html',
  styleUrls: ['./edit-oem-user-modal.component.scss']
})
export class EditOemUserModalComponent implements OnInit {
  @Input() isOpen: boolean;
  @Input() account: Account;
  @Input() channel: Channel;
  @Input() editUser: BehaviorSubject<User>;
  @Output() isOpenModalEmitter = new EventEmitter<boolean>();
  @Output() removedEmitter = new EventEmitter<string>();
  user: User;
  // form
  familyName = new FormControl<string>('', {
    validators: [
      Validators.required,
    ]});
  familyNamePhonetic = new FormControl<string>('');
  givenName = new FormControl<string>('');
  givenNamPhonetic = new FormControl<string>('');
  companyName = new FormControl<string>('');
  branchName = new FormControl<string>('');
  divisionName = new FormControl<string>('');
  jobTitle = new FormControl<string>('');
  email = new FormControl<string>('', {
    validators: [
      Validators.required,
    ]});
  phoneNumber = new FormControl<string>('');
  formGroup = this.formBuilder.group({
    familyName: this.familyName,
    familyNamePhonetic: this.familyNamePhonetic,
    givenName: this.givenName,
    givenNamPhonetic: this.givenNamPhonetic,
    companyName: this.companyName,
    branchName: this.branchName,
    divisionName: this.divisionName,
    jobTitle: this.jobTitle,
    email: this.email,
    phoneNumber: this.phoneNumber,
  });
  constructor(
    private formBuilder: FormBuilder,
    private toasterService: ToastrService,
    private backendService: BackendService,
    private coursesService: CoursesService,
    private authService: AuthService,
    private translateService: TranslateService,
  ) {}
  async ngOnInit(): Promise<void> {
    this.editUser.subscribe((u) => {
      this.user = u;
      this.resetForm();
    });
  }
  resetForm(): void {
    if (!this.user) {
      return;
    }
    this.familyName.setValue(this.user.familyName);
    this.familyNamePhonetic.setValue(this.user.familyNamePhonetic);
    this.givenName.setValue(this.user.givenName);
    this.givenNamPhonetic.setValue(this.user.givenNamPhonetic);
    this.companyName.setValue(this.user.companyName);
    this.branchName.setValue(this.user.branchName);
    this.divisionName.setValue(this.user.divisionName);
    this.jobTitle.setValue(this.user.jobTitle);
    this.email.setValue(this.user.email);
    this.phoneNumber.setValue(this.user.phoneNumber);
  }
  close(): void {
    this.isOpen = false;
    this.isOpenModalEmitter.emit(this.isOpen);
    this.resetForm();
  }
  async save(): Promise<void> {
    if (!confirm(this.translateService.instant('保存します。よろしいですか？'))) {
      return;
    }
    if (this.formGroup.invalid) {
      this.toasterService.warning(this.translateService.instant('お名前（漢字）姓とメールアドレスは必須です。'));
      return;
    }
    this.user.familyName = this.familyName.value;
    this.user.familyNamePhonetic = this.familyNamePhonetic.value;
    this.user.givenName = this.givenName.value;
    this.user.givenNamPhonetic = this.givenNamPhonetic.value;
    this.user.companyName = this.companyName.value;
    this.user.branchName = this.branchName.value;
    this.user.divisionName = this.divisionName.value;
    this.user.jobTitle = this.jobTitle.value;
    this.user.phoneNumber = this.phoneNumber.value;
    this.user.email = this.email.value;
    this.user.fullName = this.familyName.value + ' ' + this.givenName.value;
    const data = {
      accountId: this.account.id,
      user: this.user,
    }
    this.backendService.setIdToken(this.authService.currentSession.idToken);
    this.backendService.post('lms/channel-manager/update_user', data)
      .then(() => {
        this.toasterService.success(this.translateService.instant('保存しました。'));
      })
      .catch((e: { error?: string }) => {
        console.log(e);
        this.toasterService.error(e?.error);
        this.toasterService.warning(this.translateService.instant('保存処理に失敗しました。'));
      })
  }
  async remove(): Promise<void> {
    if (!confirm(this.translateService.instant('削除します。よろしいですか？'))) {
      return;
    }
    await this.coursesService.fetchByChannelId(this.channel.id);
    const courses = this.coursesService.fetched;
    const courseIds: string[] = [];
    courses.map(c => {
      courseIds.push(c.id);
    })
    const data = {
      accountId: this.account.id,
      userId: this.user.id,
      channelId: this.channel.id,
      courseIds: courseIds,
      bucketName: environment.publicAssetsBucketName,
    };
    this.backendService.setIdToken(this.authService.currentSession.idToken);
    this.backendService.post('lms/channel-manager/delete_user', data)
      .then(() => {
        this.toasterService.success(this.translateService.instant('削除しました。'));
        this.removedEmitter.emit(this.user.id);
        this.close();
      })
      .catch((e: { error?: string }) => {
        console.log(e);
        this.toasterService.error(e?.error);
        this.toasterService.warning(this.translateService.instant('削除処理に失敗しました。'));
      });
  }
}
