import {AnswerTypes} from "./course-frames";
import {User} from "./user";
export enum AnswerStatus {
  correct = 'correct',
  failure = 'failure',
  notScore = 'notScore',
  noAnswer = 'noAnswer'
}
export class Answer {
  contentKey: string;
  answer: string[];
  status: AnswerStatus;
  score: number;
  type: AnswerTypes;
  constructor() {
    this.contentKey = null;
    this.answer = [];
    this.status = null;
    this.score = 0;
    this.type = null;
  }
}
export class Answers {
  uid: string;
  courseId: string;
  contentKey: string;
  answers: Answer[];
  applied: boolean;
  appliedAt: Date;
  scored: boolean;
  scoredAt: Date;
  createdAt: Date;
  updatedAt: Date;
  createdBy: string;
  updatedBy: string;
  user?: User;
  constructor() {
    this.uid = null;
    this.courseId = null;
    this.answers = [];
    this.applied = false;
    this.appliedAt = null;
    this.scored = false;
    this.scoredAt = null;
    this.createdAt = new Date();
    this.updatedAt = new Date();
    this.createdBy = null;
    this.updatedBy = null;
  }
}
export class SurveyAnswers {
  id: string;
  uid: string;
  courseId: string;
  contentKey: string;
  answers: Answer[];
  numOfAnswered: number;
  applied: boolean;
  appliedAt: Date;
  createdAt: Date;
  updatedAt: Date;
  createdBy: string;
  updatedBy: string;
  user?: User;
  constructor() {
    this.id = null;
    this.uid = null;
    this.courseId = null;
    this.contentKey = null;
    this.answers = [];
    this.applied = false;
    this.appliedAt = null;
    this.createdAt = new Date();
    this.updatedAt = new Date();
    this.createdBy = null;
    this.updatedBy = null;
  }
}
