import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-header-business',
  templateUrl: './header-business.component.html',
  styleUrls: ['./header-business.component.scss']
})
export class HeaderBusinessComponent {
  @Input() logo: string = 'assets/images/logo.png';
}
