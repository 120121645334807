<app-header-contents-public></app-header-contents-public>
<div class="l-content-front">
  <app-breadcrumb-front></app-breadcrumb-front>
  <div class="l-content-front__inner">
    <div class="p-contracts">
      <div class="p-contracts__title">
        <h2 class="p-contracts__title__main" translate>推奨動作環境について</h2>
      </div>
      <h3 translate>Windowsをご利用の方</h3>
      <h4>OS</h4>
      <ul class="p-contracts__subList-disc">
        <li>Microsoft Windows 11</li>
        <li>Microsoft Windows 10</li><br>
      </ul>
      <ul class="p-contracts__unOrderedList">
        <li translate>Microsoft Edgeご使用の場合はCreators Updateを適用する必要があります。</li>
      </ul>

      <h4 translate>ブラウザ</h4>
      <ul class="p-contracts__subList-disc">
        <li translate>Microsoft Chromium版Edge 79.0.309.65以上</li>
      </ul>
      <ul class="p-contracts__unOrderedList">
        <li><a href="https://www.microsoft.com/ja-jp/edge">
          <span translate>Microsoft Chromium版Edgeに関してはこちら</span>  </a><span translate>をご確認ください。</span>
        </li>
      </ul>
      <ul class="p-contracts__subList-disc">
        <li translate>Chrome 69以上</li>
      </ul>
      <ul class="p-contracts__unOrderedList">
        <li><a href="https://www.google.co.jp/chrome/"><span translate>Chromeに関してはこちら</span>  </a><span translate>をご確認ください。</span></li>
      </ul>
      <ul class="p-contracts__subList-disc">
        <li translate>Firefox 68以上</li>
      </ul>
      <ul class="p-contracts__unOrderedList">
        <li><a href="https://www.mozilla.org/ja/firefox/new/"><span translate>Firefoxに関してはこちら</span>  </a><span translate>をご確認ください。</span></li>
      </ul>


      <h3 translate>Macintoshをご利用の方</h3>

      <h4>OS</h4>
      <ul class="p-contracts__subList-disc">
        <li translate>macOS Ventura(バージョン13.0以上)</li>
        <li translate>macOS Monterey(バージョン12.0以上)</li>
        <li translate>macOS Big Sur(バージョン11.0以上)</li><br>
      </ul>

      <h4 translate>ブラウザ</h4>
      <ul class="p-contracts__subList-disc">
        <li>Safari</li>
      </ul>
      <ul class="p-contracts__unOrderedList">
        <li><a href="https://www.apple.com/jp/safari/"><span translate>Safariに関してはこちら</span>  </a><span translate>をご確認ください。</span></li>
      </ul>
      <ul class="p-contracts__subList-disc">
        <li translate>Chrome 69以上</li>
      </ul>
      <ul class="p-contracts__unOrderedList">
        <li><a href="https://www.google.co.jp/chrome/"><span translate>Chromeに関してはこちら</span>  </a><span translate>をご確認ください。</span></li>
      </ul>
      <ul class="p-contracts__subList-disc">
        <li translate>Firefox 68以上</li>
      </ul>
      <ul class="p-contracts__unOrderedList">
        <li><a href="https://www.mozilla.org/ja/firefox/new/"><span translate>Firefoxに関してはこちら</span>  </a><span translate>をご確認ください。</span></li>
      </ul><br>

      <h3 translate>Androidをご利用の方</h3>
      <h4 translate>Android端末の準備</h4>

      <h4>OS</h4>
      <ul class="p-contracts__subList-disc">
        <li translate>Android 6.0以上</li>
      </ul><br>

      <h4 translate>ブラウザ</h4>
      <ul class="p-contracts__subList-disc">
        <li translate>Chrome 69以上</li>
      </ul>
      <ul class="p-contracts__unOrderedList">
        <li><a href="https://www.google.co.jp/chrome/"><span translate>Chromeに関してはこちら</span>  </a><span translate>をご確認ください。</span></li>
      </ul><br>

      <h3 translate>iOSをご利用の方</h3>
      <h4 translate>iPhone端末の準備</h4>

      <h4>OS</h4>
      <ul class="p-contracts__subList-disc">
        <li translate>iOS 14.0以上</li>
      </ul><br>

      <h4 translate>ブラウザ</h4>
      <ul class="p-contracts__subList-disc">
        <li translate>Safari 13以上</li>
      </ul>
      <ul class="p-contracts__unOrderedList">
        <li><a href="https://www.apple.com/jp/safari/"><span translate>Safariに関してはこちら</span>  </a><span translate>をご確認ください。</span></li>
      </ul>
      <ul class="p-contracts__subList-disc">
        <li translate>Chrome 69以上</li>
      </ul>
      <ul class="p-contracts__unOrderedList">
        <li><a href="https://www.google.co.jp/chrome/"><span translate>Chromeに関してはこちら</span>  </a><span translate>をご確認ください。</span></li>
      </ul>
    </div>
  </div>
</div>
<app-footer></app-footer>
