<app-header-contents-channel></app-header-contents-channel>
<div
  *ngIf="user.id"
  class="l-content-front">
  <app-breadcrumb-front></app-breadcrumb-front>
  <div class="l-content-front__inner">
    <div class="l-myPage">
      <div class="p-myPage">
        <div class="p-myPage__user">
          <div class="p-user">
            <div class="p-user__avatar">
              <img
                *ngIf="user?.profileImage?.src"
                ngSrc="{{user.profileImage?.src}}" width="160" height="160" alt="">
              <img
                *ngIf="!user?.profileImage?.src"
                src="{{noImage}}" alt="">
            </div>
            <div class="p-user__profile">
              <div class="p-user__profile__name">
                {{user.displayName}}
              </div>
              <div class="p-user__profile__id">
                {{user.email}} / {{user.id}}
              </div>
            </div>
          </div>
        </div>
        <div class="c-content-header">
          <div class="c-content-header__title" translate>プロフィール</div>
        </div>
        <div class="p-myPage__form-accordion">
          <div class="c-accordion">
            <input class="c-accordion__opener" type="checkbox" id="opener-1" checked>
            <label
              class="c-accordion__head"
              for="opener-1"
              translate
            >名前</label>
            <div class="c-accordion__body">
              <div class="c-accordion__body__row">
                <label class="c-form-label" translate>プロフィール画像</label>
                <div class="c-accordion__body__row__image">
                  <div class="c-form-images">
                    <div class="c-form-images__image c-form-images__image-profile">
                      <img
                        *ngIf="user?.profileImage?.src"
                        ngSrc="{{user?.profileImage?.src}}" width="320" height="320" alt="">
                      <img
                        *ngIf="!user?.profileImage?.src"
                        src="{{noImage}}" alt="">
                    </div>
                  </div>
                </div>
                <div class="c-accordion__body__row__button">
                  <button
                    *ngIf="!user?.profileImage?.src"
                    (click)="openImageUploadModal()"
                    class="c-button c-button-primary"
                    id="eyeCatch"
                  ><span translate>画像を選択</span></button>
                </div>
                <div class="c-accordion__body__row__button">
                  <button
                    *ngIf="user?.profileImage?.src"
                    (click)="removeImageProfile()"
                    class="c-button c-button-cancel"
                    type="button"
                  ><span translate>画像を削除する</span></button>
                </div>
                <div class="c-form-advice" translate>JPG/PNG形式のファイルをアップロードしてください。横長や縦長の画像は中央部が正方形に切り抜かれます。</div>

              </div>
              <div class="c-accordion__body__row">
                <label
                  class="c-form-label"
                  for="nickName"
                  translate
                >ニックネーム</label>
                <input
                  [formControl]="displayName"
                  class="c-form-input" type="text" name="nickName" id="nickName">
                <div
                  *ngIf="displayName.invalid"
                  class="c-form-error"
                  translate
                >3文字以上、16文字以内で入力してください。先頭は数字以外である必要があり、記号はハイフン(-)のみが使用できます。</div>
              </div>
              <div class="c-accordion__body__groupTitle" translate>お名前(漢字)</div>
              <div class="c-accordion__body__row">
                <label
                  class="c-form-label"
                  for="familyName"
                  translate
                >姓</label>
                <input
                  [formControl]="familyName"
                  class="c-form-input" type="text" name="familyName" id="familyName">
                <div
                  *ngIf="familyName.invalid"
                  class="c-form-error"
                  translate
                >16文字以内で入力してください。先頭は数字以外である必要があり、記号はハイフン(-)のみが使用できます。</div>
              </div>
              <div class="c-accordion__body__row">
                <label
                  class="c-form-label"
                  for="givenName"
                  translate
                >名</label>
                <input
                  [formControl]="givenName"
                  class="c-form-input" type="text" name="givenName" id="givenName">
                <div
                  *ngIf="givenName.invalid"
                  class="c-form-error"
                  translate
                >16文字以内で入力してください。先頭は数字以外である必要があり、記号はハイフン(-)のみが使用できます。</div>
              </div>
              <div class="c-accordion__body__groupTitle" translate>お名前(ふりがな)</div>
              <div class="c-accordion__body__row">
                <label
                  class="c-form-label"
                  for="familyNamePhonetic"
                  translate
                >せい</label>
                <input
                  [formControl]="familyNamePhonetic"
                  class="c-form-input" type="text" name="familyNamePhonetic" id="familyNamePhonetic">
                <div
                  *ngIf="familyNamePhonetic.invalid"
                  class="c-form-error"
                  translate
                >16文字以内で入力してください。ひらがなのみで入力してください。</div>
              </div>
              <div class="c-accordion__body__row">
                <label
                  class="c-form-label"
                  for="givenNamPhonetic"
                  translate
                >めい</label>
                <input
                  [formControl]="givenNamPhonetic"
                  class="c-form-input" type="text" name="givenNamPhonetic" id="givenNamPhonetic">
                <div
                  *ngIf="givenNamPhonetic.invalid"
                  class="c-form-error"
                  translate
                >16文字以内で入力してください。ひらがなのみで入力してください。</div>
              </div>
            </div>
          </div>
          <div class="c-accordion">
            <input class="c-accordion__opener" type="checkbox" id="opener-2" checked>
            <label
              class="c-accordion__head"
              for="opener-2"
              translate
            >住所</label>
            <div class="c-accordion__body">
              <div class="c-accordion__body__row">
                <label
                  class="c-form-label"
                  for="postCode"
                  translate
                >郵便番号</label>
                <input
                  [formControl]="zip"
                  (keyup)="updatePostalCode()"
                  class="c-form-input" type="text" name="postCode" id="postCode">
                <div
                  *ngIf="zip.invalid"
                  class="c-form-error"
                  translate
                >半角数字7文字で入力してください。</div>
              </div>
              <div class="c-accordion__body__row">
                <label
                  class="c-form-label"
                  for="Prefectures"
                  translate
                >都道府県</label>
                <input
                  [formControl]="pref"
                  class="c-form-input" type="text" name="Prefectures" id="Prefectures">
                <div
                  *ngIf="pref.invalid"
                  class="c-form-error"
                  translate
                >都道府県名を入力してください。郵便番号からの自動入力をお試しください。</div>
              </div>
              <div class="c-accordion__body__row">
                <label
                  class="c-form-label"
                  for="address-1"
                  translate
                >住所1</label>
                <input
                  [formControl]="address1"
                  class="c-form-input" type="text" name="address-1" id="address-1">
                <div
                  *ngIf="address1.invalid"
                  class="c-form-error"
                  translate
                >市区町村町名を入力してください。郵便番号からの自動入力をお試しください。</div>
              </div>
              <div class="c-accordion__body__row">
                <label
                  class="c-form-label"
                  for="address-2"
                  translate
                >住所2</label>
                <input
                  [formControl]="address2"
                  class="c-form-input" type="text" name="address-2" id="address-2">
                <div
                  *ngIf="address2.invalid"
                  class="c-form-error"
                  translate
                >番地以降の住所を入力してください。</div>
              </div>
              <div class="c-accordion__body__row">
                <label
                  class="c-form-label"
                  for="address-3"
                  translate
                >住所3(建物名、部屋番号等)</label>
                <input
                  [formControl]="address3"
                  class="c-form-input" type="text" name="address-3" id="address-3">
              </div>
            </div>
          </div>
          <div class="c-accordion">
            <input class="c-accordion__opener" type="checkbox" id="opener-4" checked>
            <label
              class="c-accordion__head"
              for="opener-4"
              translate
            >メールアドレス</label>
            <div class="c-accordion__body">
              <div class="c-accordion__body__row">
                <label
                  class="c-form-label"
                  for="email"
                  translate
                >メールアドレス</label>
                <input
                  readonly
                  [formControl]="email"
                  class="c-form-input" type="text" name="email" id="email">
              </div>
              <div class="c-accordion__body__row">
                <label
                  class="c-form-label"
                  for="phone"
                  translate
                >電話番号</label>
                <input
                  [formControl]="phoneNumber"
                  class="c-form-input" type="text" name="phone" id="phone">
                <div
                  *ngIf="phoneNumber.invalid"
                  class="c-form-error" translate>電話番号をハイフン抜きで入力してください。</div>
              </div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" translate>性別</label>
                <div class="c-form-radio">
                  <div
                    class="c-form-radio__wrap">
                    <input
                      [formControl]="sex"
                      type="radio" name="sex" value="1" id="male">
                    <label class="c-form-label" for="male" translate>男性</label>
                  </div>
                  <div class="c-form-radio__wrap">
                    <input
                      [formControl]="sex"
                      type="radio" name="sex" value="2" id="female">
                    <label class="c-form-label" for="female" translate>女性</label>
                  </div>
                  <div class="c-form-radio__wrap">
                    <input
                      [formControl]="sex"
                      type="radio" name="sex" value="0" id="noSetting">
                    <label class="c-form-label" for="noSetting" translate>設定しない</label>
                  </div>
                </div>
              </div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="birthday" translate>生年月日</label>
                <input
                  [formControl]="birthday"
                  class="c-form-input" type="date" name="birthday" id="birthday">
                <div
                  *ngIf="birthday.invalid"
                  class="c-form-error" translate>誕生日（生年月日）をカレンダーから入力してください。</div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-myPage__buttons">
          <div class="p-myPage__buttons__button">
            <button
              type="button"
              class="c-button c-button-cancel"
              routerLink="/my-page"
            ><span translate>キャンセルして戻る</span></button>
          </div>
          <div class="p-myPage__buttons__button">
            <button
              (click)="save()"
              class="c-button c-button-primary"
            ><span translate>保存する</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="user.id">
  <app-footer></app-footer>
</ng-container>
<section class="l-modal" *ngIf="isOpenProfileImageUploadModal">
  <div class="c-modal">
    <div class="c-modal__head">
      <div class="c-modal__head__title" translate>画像アップロード</div>
      <div class="c-modal__head__closeButton" (click)="closeImageUploadModal()"></div>
    </div>
    <div class="c-modal__documentUpload">
      <input
        (change)="uploadImageProfile($event)"
        type="file" id="uploadProfileImage">
      <label
        for="uploadProfileImage"
        class="c-modal__documentUpload__description"
        translate
      >ファイルを選択</label>
      <div class="c-modal__documentUpload__uploadButton">
        <button
          (click)="clickUploadFile('uploadProfileImage')"
          type="button"
          class="c-button c-button-primary"
          translate
        ><span translate>アップロードする</span></button>
      </div>
    </div>
  </div>
</section>

<section class="l-modal" *ngIf="isOpenZipCodeSelectorModal">
  <div class="c-modal">
    <div class="c-modal__head">
      <div class="c-modal__head__title" translate>複数の住所が該当しました</div>
    </div>
    <div class="c-modal__main">
      <div class="c-modal__main__form">
        <label>
          <select
            class="c-modal__main__form__select"
            [formControl]="selectedPostalCode"
          >
            <option
              *ngFor="let postalCode of postalCodes;index as i"
              value="{{i}}"
            >
              {{postalCode.bizName}}{{postalCode.pref}}{{postalCode.address1}}{{postalCode.address2}}{{postalCode.address3}}
            </option>
          </select>
        </label>
      </div>
      <div class="c-modal__main__control">
        <div class="c-modal__main__control__status"></div>
        <div class="c-modal__main__control__buttons">
          <div class="c-modal__main__control__buttons__button">
            <button class="c-button c-button-cancel"
            ><span translate>キャンセル</span></button>
          </div>
          <div class="c-modal__main__control__buttons__button">
            <button
              (click)="chosePostalCode()"
              class="c-button c-button-primary"
            ><span translate>決定する</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>　
</section>
