<ng-container>
  <div class="l-signup">
    <div class="p-signup">
      <div class="p-signup__head">
        <div class="p-signup__head__kv"></div>
      </div>
      <div class="p-signup__main">
        <div class="p-signup__main__title" translate>ログアウト</div>
        <div class="p-signup__main__subTitle" translate>ログアウトしますか？</div>
        <div class="p-signup__main__button">
          <button
            type="button"
            class="c-button c-button-logout"
            (click)="logout()"
          ><span translate>ログアウト</span></button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
