export class VideoUpload {
  m3u8ObjectName: string;
  contentKey: string;
  numOfFragments: number;
  thumbObjectName: string;
  thumbUrl?: string;
  thumbSrc?: string;
  duration: number;
  width: number;
  height: number;
  constructor() {
    this.m3u8ObjectName = '';
    this.contentKey = '';
    this.numOfFragments = 0;
    this.thumbObjectName = '';
    this.thumbUrl = '';
    this.thumbSrc = '';
    this.duration = 0;
    this.width = 0;
    this.height = 0;
  }
}
