<div class="l-main">
  <app-navigations></app-navigations>
  <div class="l-content">
    <app-breadcrumb></app-breadcrumb>
    <div class="l-content__inner">
      <div class="c-content-header">
        <div class="c-content-header__title">
          売上通帳
        </div>
      </div>
      <div class="l-templates">
        <div class="p-templates">
          <div class="p-templates__content">
            <div class="p-templates__content__passbook">
              <div class="p-templates__content__passbook__summary">
                <div class="p-templates__content__passbook__summary__head">現在の残高</div>
                <div class="p-templates__content__passbook__summary__balance">
                  <div class="p-templates__content__passbook__summary__balance__body">
                    1,403,344<span>円</span>
                  </div>
                  <div class="p-templates__content__passbook__summary__balance__updateAt">
                    (更新:2023/03/20 18:18)
                  </div>
                </div>
              </div>

            </div>
            <div class="c-search">
              <div class="c-search__row">
                <div class="c-search__row__half">
                  <label class="c-form-label">対象月</label>
                  <select class="c-form-select">
                    <option value="">選択してください</option>
                    <option value="">1月</option>
                    <option value="">2月</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="c-list-passbook">
              <div class="c-list-passbook__head">
                <div class="c-list-passbook__head__date">日付</div>
                <div class="c-list-passbook__head__description">適用</div>
                <div class="c-list-passbook__head__sales">売上</div>
                <div class="c-list-passbook__head__payment">支払</div>
                <div class="c-list-passbook__head__balance">残高</div>
              </div>
              <div class="c-list-passbook__body">
                <div class="c-list-passbook__body__row">
                  <div class="c-list-passbook__body__row__date">03/20</div>
                  <div class="c-list-passbook__body__row__description">売上</div>
                  <div class="c-list-passbook__body__row__sales">9999円</div>
                  <div class="c-list-passbook__body__row__payment">-</div>
                  <div class="c-list-passbook__body__row__balance">99999999円</div>
                </div>
                <div class="c-list-passbook__body__row">
                  <div class="c-list-passbook__body__row__date">03/20</div>
                  <div class="c-list-passbook__body__row__description">売上</div>
                  <div class="c-list-passbook__body__row__sales">9999円</div>
                  <div class="c-list-passbook__body__row__payment">-</div>
                  <div class="c-list-passbook__body__row__balance">99999999円</div>
                </div>
                <div class="c-list-passbook__body__row">
                  <div class="c-list-passbook__body__row__date">03/20</div>
                  <div class="c-list-passbook__body__row__description">お支払</div>
                  <div class="c-list-passbook__body__row__sales">-</div>
                  <div class="c-list-passbook__body__row__payment">9999円</div>
                  <div class="c-list-passbook__body__row__balance">99999999円</div>
                </div>
                <div class="c-list-passbook__body__row">
                  <div class="c-list-passbook__body__row__date">03/20</div>
                  <div class="c-list-passbook__body__row__description">売上</div>
                  <div class="c-list-passbook__body__row__sales">9999円</div>
                  <div class="c-list-passbook__body__row__payment">-</div>
                  <div class="c-list-passbook__body__row__balance">99999999円</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
