import { Injectable } from '@angular/core';
import {Chat} from "../../models/chat";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  CollectionReference,
  DocumentData,
  Query
} from "@angular/fire/compat/firestore";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  constructor(
    private fireStore: AngularFirestore,
  ) {}
  collectionRef: AngularFirestoreCollection;
  docs: Observable<DocumentData[]>;
  query: Query;
  offset: number = undefined;
  add(accountId: string, uid: string, chat: Chat): Promise<void> {
    const deserialize = JSON.parse(JSON.stringify(chat)) as Chat;
    deserialize.createdAt = new Date();
    deserialize.timestamp = deserialize.createdAt.getTime();
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('accounts')
        .doc(accountId)
        .collection('apps')
        .doc(environment.appId)
        .collection('chatPostOffice')
        .doc(uid)
        .collection('chats')
        .add(deserialize)
        .then(() => {
          resolve();
        })
        .catch((e) => {
          console.log('reject');
          reject(e);
        });
    })
  }
  connectSnapShots(accountId: string, uid: string): void {
    this.collectionRef = this.fireStore
      .collection('accounts')
      .doc(accountId)
      .collection('apps')
      .doc(environment.appId)
      .collection('chatPostOffice')
      .doc(uid)
      .collection<{}>('chats' , ref => {
        let query: CollectionReference | Query = ref;
        query = query.orderBy('createdAt', 'desc');
        this.query = query;
        return query;
      })
    this.docs = this.collectionRef.valueChanges({ idField: 'id' });
  }
   more(): void {}
}
