import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'digest'
})
export class DigestPipe implements PipeTransform {
  async transform(
    body: string,
    algorithm: 'sha-256' | 'sha-1' | 'sha-384' | 'sha-512'
    ) {
    if (!body) {
      return '';
    }
    const stringBuff: any = [];
    const buff = new Uint8Array(
      stringBuff.map.call(
        body,
        (c: any) => c.charCodeAt(0))).buffer;
    // Calculate digest
    const digest = await crypto.subtle.digest(algorithm, buff);
    // Convert ArrayBuffer to hex string
    // (from: https://stackoverflow.com/a/40031979)
    const arrayBuff: any = [];
    return arrayBuff.map.call(new Uint8Array(digest), (x: { toString: (arg0: number) => string; }) => ('00' + x.toString(16)).slice(-2)).join('');
  }
}
