<section class="l-modalTopLayer" *ngIf="isOpenVideoModal">
  <div
    [ngClass]="videoReady ? 'c-videoModal-isReady': ''"
    class="c-videoModal">
    <div class="c-videoModal__head">
      <div
        [ngClass]="videoReady ? 'c-videoModal__head__closeButton-isReady': ''"
        class="c-videoModal__head__closeButton" (click)="closeModal()"></div>
    </div>
    <div class="c-videoModal__main">
      <div class="c-videoModal__main__content">
        <iframe id="video-p" [src]="videoUrl"></iframe>
      </div>
    </div>
 </div>

  <div class="c-videoModal__loading">
    <div class="l-loader" *ngIf="!videoReady">
      <div class="loader"></div>
    </div>
  </div>

</section>


