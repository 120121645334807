import {Component, OnInit} from '@angular/core';
import {ChannelsService} from "../../services/channels/channels.service";
import {Channel} from "../../models/channel";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss'],
})
export class FavoritesComponent implements OnInit{
  channels: Channel[] = [];
  noImageThumbUrl: string = environment.noImageThumb;
  constructor(
    private channelsService: ChannelsService,
  ) {
    this.channels = [];
  }
  async ngOnInit(): Promise<void> {
    this.channels = await this.channelsService.fetchByRecommend();
  }
}
