export class BreadCrumbList {
  path: string;
  paramString: string;
  parentPath: string;
  manualUrl?: string;
  title?: string;
}
export const breadCrumbs: BreadCrumbList[] = [
  { path: 'logout', paramString: '', parentPath: '', title: '' },
  { path: 'login', paramString: '',  parentPath: '' , title: ''},
  { path: 'sign-up', paramString: '',  parentPath: '', title: '' },
  { path: 'password/reset', paramString: '',  parentPath: '' , title: ''},
  { path: 'business-contract', paramString: '',  parentPath: '' , title: ''},
  { path: 'privacy-policy', paramString: '',  parentPath: '', title: '' },
  { path: 'contracts', paramString: '',  parentPath: '' , title: ''},
  { path: 'slo', paramString: '',  parentPath: '' , title: ''},
  { path: 'system_requirements', paramString: '',  parentPath: '' , title: ''},
  // { path: 'contents-top', paramString: '',  parentPath: '' , title: ''},

  { path: 'manager/accounts', paramString: '',  parentPath: '', title: ''},
  { path: 'manager/business', paramString: '',  parentPath: '', title: ''},
  { path: 'manager/users', paramString: '',  parentPath: '', title: ''},

  { path: 'manager/channels', paramString: '',  parentPath: '', title: ''},
  { path: 'manager/channel', paramString: '/:channelId',  parentPath: '' },
  { path: 'manager/channel/create', paramString: '/:channelId',  parentPath: 'manager/channel', title: '' },
  { path: 'manager/channel/users', paramString: '/:channelId',  parentPath: 'manager/channel' , title: ''},
  { path: 'manager/channel/meta', paramString: '/:channelId',  parentPath: '' , title: ''},
  { path: 'manager/channel/courses', paramString: '/:channelId/:courseId',  parentPath: '' , title: ''},
  { path: 'manager/channel/courses/users', paramString: '',  parentPath: 'manager/channel' , title: ''},
  { path: 'manager/channel/course/index', paramString: '/:channelId/:courseId',  parentPath: 'manager/channel/courses' , title: ''},
  { path: 'manager/channel/course/users', paramString: '/:channelId/:courseId',  parentPath: 'manager/channel/courses' , title: ''},
  { path: 'manager/channel/course/create', paramString: '/:channelId',  parentPath: 'manager/channel' , title: ''},
  { path: 'manager/channel/course/meta', paramString: '/:channelId/:courseId',  parentPath: 'manager/channel/courses', title: '' },
  { path: 'manager/channel/courses/videos', paramString: '',  parentPath: '', title: '' },
  { path: 'manager/channel/courses/slides', paramString: '',  parentPath: '' , title: ''},
  { path: 'manager/channel/courses/remotes', paramString: '',  parentPath: '' , title: ''},
  { path: 'manager/channel/courses/remotes/reservations', paramString: '',  parentPath: '' , title: ''},
  { path: 'manager/channel/courses/tryouts/create', paramString: '',  parentPath: '' , title: ''},
  { path: 'my-page', paramString: '',  parentPath: '' , title: ''},
  { path: 'my-page/notifications', paramString: '',  parentPath: '' , title: ''},
  { path: 'my-page/password', paramString: '',  parentPath: '' , title: ''},
  { path: 'my-page/personal', paramString: '',  parentPath: '' , title: ''},
  { path: 'my-page/quit', paramString: '',  parentPath: '' , title: ''},
  { path: 'my-page/payment/credit', paramString: '',  parentPath: 'my-page' , title: ''},
  { path: 'my-page/payment/history', paramString: '',  parentPath: 'my-page' , title: ''},
  { path: 'channel/course/video', paramString: '',  parentPath: '' , title: ''},
  { path: 'channel/course/slide', paramString: '',  parentPath: '' , title: ''},
  { path: 'channel/course/remote/reservation', paramString: '',  parentPath: '' , title: ''},
  { path: 'channel/course', paramString: '',  parentPath: '' , title: ''},
  { path: 'top', paramString: '',  parentPath: '' , title: ''},
  { path: '**', paramString: '',  parentPath: '' , title: ''},
];
