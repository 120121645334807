import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manager-channel-course-remotes-reservations',
  templateUrl: './manager-channel-course-remotes-reservations.component.html',
  styleUrls: ['./manager-channel-course-remotes-reservations.component.scss']
})
export class ManagerChannelCourseRemotesReservationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
