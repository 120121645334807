import {Injectable} from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  CollectionReference,
  DocumentData,
  Query
} from "@angular/fire/compat/firestore";
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {ContentFragment, CourseProgress, Progress} from "../../models/course-progress";
import {AttendanceStatus} from "../../models/reservation";
import {ReportingStatus} from "../../models/report-doc";
import {CourseSubscriptionsService} from "../course-subscriptions/course-subscriptions.service";
import {FrameTypes} from "../../models/course-frames";
import {LoadingFrames, LoadingPart, LoadingSection} from "../../models/course-part";
import {CourseIndexPartService} from "../course-index/course-index-part.service";
import {Course} from "../../models/course";

@Injectable({
  providedIn: 'root'
})
export class CourseProgressesService {
  dbDocument: AngularFirestoreDocument<any>
  collectionRef: AngularFirestoreCollection<{}>
  framesDoc: Observable<CourseProgress | undefined>;
  docs: Observable<DocumentData[]>;
  myDocs: Observable<DocumentData[]>;
  currentProgress: CourseProgress;
  currentFragments: ContentFragment[] = [];
  frames: LoadingFrames[] = [];
  framesLoaded: boolean = false;
  loader: {
    chapterIndex: number,
    sectionIndex: number,
    partIndex: number,
    partId: string,
    frameType: FrameTypes
  }[] = [];
  indexSeq: {
    chapterIndex: number,
    sectionIndex: number,
    partIndex: number,
    partId: string,
    frameType: FrameTypes
  }[] = [];
  constructor(
    private fireStore: AngularFirestore,
    private courseSubscriptionsService: CourseSubscriptionsService,
    private partService: CourseIndexPartService,
  ) { }
  async create(
    courseId: string,
    uid: string
  ): Promise<void> {
    const progresses = new CourseProgress();
    progresses.id = uid;
    progresses.uid = uid;
    progresses.createdAt = new Date();
    progresses.createdBy = uid;
    progresses.updatedAt = new Date();
    progresses.updatedBy = uid;
    const copied = JSON.parse(JSON.stringify(progresses));
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('apps')
        .doc(environment.appId)
        .collection('courses')
        .doc(courseId)
        .collection('courseProgresses')
        .doc(uid)
        .set(copied)
        .then(() => {
          resolve();
        })
        .catch((e) => {
          console.log('reject create progress');
          reject(e);
        });
    })
  }
  async fetchByMy(
    courseId: string,
    uid: string
  ): Promise<CourseProgress|undefined> {
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('apps')
        .doc(environment.appId)
        .collection('courses')
        .doc(courseId)
        .collection('courseProgresses')
        .doc(uid)
        .get()
        .toPromise()
        .then((data) => {
          if (data.data.length === 0) {
            resolve(undefined);
          }
          const newDoc = data.data() as CourseProgress;
          resolve(newDoc);
        })
        .catch((e) => {
          console.log('reject fetchByMy progress');
          reject(e);
        });
    })
  }
  async update(
    courseId: string,
    uid: string,
    progresses: CourseProgress
  ): Promise<void> {
    if (!this.framesLoaded) {
      console.log('frames no load. can not update progress.')
      return;
    }
    progresses.updatedAt = new Date();
    progresses.updatedBy = uid;
    progresses.all = this.calculateCourseProgress(progresses, this.frames);
    // subscription にも書き込む
    this.courseSubscriptionsService.fetch(
      courseId,
      uid
    )
      .then(subs => {
        if (subs) {
          subs.totalProgress = progresses.all;
          subs.updatedAt = new Date();
          this.courseSubscriptionsService.update(
            uid,
            subs
          ).catch((e) => {
            console.log(e);
          })
        }
      });
    const forUpdate = JSON.parse(JSON.stringify(progresses));
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('apps')
        .doc(environment.appId)
        .collection('courses')
        .doc(courseId)
        .collection('courseProgresses')
        .doc(uid)
        .set(forUpdate)
        .then(() => {
          console.log('progress updated.');
          resolve();
        })
        .catch((e) => {
          console.log('reject');
          reject(e);
        });
    })
  }
  async remove(
    accountId: string,
    courseId: string,
    uid: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('accounts')
        .doc(accountId)
        .collection('apps')
        .doc(environment.appId)
        .collection('courses')
        .doc(courseId)
        .collection('courseProgresses')
        .doc(uid)
        .delete()
        .then((data) => {
          resolve(data);
        })
        .catch((e) => {
          console.log('reject');
          reject(e);
        });
    })
  }
  connectSnapShotByMy(
    accountId: string,
    curseId: string,
    uid: string
  ): void {
    this.dbDocument = this.fireStore
      .collection<{}>('accounts')
      .doc(accountId)
      .collection<{}>('apps')
      .doc(environment.appId)
      .collection<{}>('courses')
      .doc(curseId)
      .collection('courseProgresses')
      .doc(uid)
    this.dbDocument.get().toPromise().then(() => {});
    this.framesDoc = this.dbDocument.valueChanges({ idField: "id" });
  }
  connectSnapShots(
    accountId: string,
    curseId: string
  ): void {
    this.collectionRef = this.fireStore
      .doc(accountId)
      .collection<{}>('apps')
      .doc(environment.appId)
      .collection<{}>('courses')
      .doc(curseId)
      .collection<{}>('courseProgresses' , ref => {
        let query: CollectionReference | Query = ref;
        query = query.orderBy('createdAt', 'desc');
        return query;
      });
    this.docs = this.collectionRef.valueChanges({ idField: "id" });
  }
  calculateCourseProgress(courseProgress:CourseProgress, frames :LoadingFrames[]): number {
    let sum = 0;
    let c = 0;
    for (const chapter of frames) {
      sum = sum + this.calculateChapterProgress(
        courseProgress,
        frames,
        c,
      );
      c++;
    }
    return sum / c;
  }
  calculateChapterProgress(courseProgress:CourseProgress, frames :LoadingFrames[], chapterIndex: number): number {
    let sum = 0;
    let c = 0;
    if (!frames[chapterIndex]?.sections) {
      return 0;
    }
    for (const section of frames[chapterIndex]?.sections) {
      for (const part of section?.parts) {
        if (part.frameType !== FrameTypes.CoverFrame) {
          const progress = courseProgress.progresses.find(p => p.contentKey === part.id);
          if (!progress) {
            c++;
            continue;
          }
          if (!progress.progress) {
            c++;
            continue;
          }
          sum = sum + progress.progress;
          c++;
        }
      }
    }
    if (sum === 0) {
      return 0;
    }
    return sum / c;
  }
  calculateSectionProgress(
    courseProgress:CourseProgress,
    frames :LoadingFrames[],
    chapterIndex: number,
    sectionIndex: number
  ): number {
    let sum = 0;
    let c = 0;
    for (const part of frames[chapterIndex].sections[sectionIndex].parts) {
      if (part.frameType !== FrameTypes.CoverFrame) {
        const progress = courseProgress.progresses.find(p => p.contentKey === part.id);
        if (!progress) {
          c++;
          continue;
        }
        if (!progress.progress) {
          c++;
          continue;
        }
        sum = sum + progress.progress;
        c++;
      }
    }
    if (c === 0) {
      return 0;
    }
    if (sum === 0) {
      return 0;
    }
    return sum / c;
  }
  calculateVideoFrameProgress(fragments: ContentFragment[]): number {
    let numOfViewed = 0;
    for (let f of fragments) {
      if (f.viewed) {
        numOfViewed++;
      }
    }
    return numOfViewed/fragments.length;
  }
  review(courseProgress: CourseProgress): CourseProgress {
    for (let p of courseProgress.progresses) {
      switch (p.frameType) {
        case FrameTypes.CoverFrame:
          break;
        case FrameTypes.VideoFrame:
        case FrameTypes.SlideFrame:
          p.progress = this.calculateVideoFrameProgress(p.fragment);
          break;
        case FrameTypes.OnlineFrame:
        case FrameTypes.LiveFrame:
          p.progress = this.reviewAttendanceProgresses(p.attendance);
          break;
        case FrameTypes.ReportFrame:
          p.progress = this.reviewReportsProgresses(p.reportResult);
          break;
        case FrameTypes.FileFrame:
          if (p.isFileDownloaded) {
            p.progress = 1;
          }
          break;
        case FrameTypes.ExaminationFrame:
          break;
        case FrameTypes.SurveyFrame:
          break;
      }
    }
    let sum = 0;
    for (let p of courseProgress.progresses) {
      sum += p.progress;
    }
    courseProgress.all = sum / courseProgress.progresses.length;
    return courseProgress;
  }
  reviewAttendanceProgresses(attendance: AttendanceStatus): number {
    if (attendance === AttendanceStatus.did) {
      return 1;
    } else {
      return 0;
    }
  }
  reviewReportsProgresses(reportResult: ReportingStatus): number {
    if (reportResult === ReportingStatus.passed) {
      return 1;
    } else {
      return 0;
    }
  }
  removeProgressUnknownPart(frames: LoadingFrames[], courseProgress: CourseProgress): CourseProgress {
    const progresses: {contentKey: string, isExist: boolean, progress: Progress }[] = [];
    for (let c of courseProgress.progresses) {
      progresses.push(
        {
          contentKey: c.contentKey,
          isExist: false,
          progress: c
        }
      )
    }
    for (let f of frames) {
      for (let s of f.sections) {
        for (let p of s.parts ) {
          for (let g of progresses) {
            if (g.contentKey === p.id) {
              g.isExist = true;
            }
          }
        }
      }
    }
    let copied: Progress[] = JSON.parse(JSON.stringify(courseProgress.progresses));
    let result: Progress[]  = [];
    let i = 0;
    for (let c of courseProgress.progresses) {
      if (!progresses[i].isExist) {
        result = copied.filter(f => f.contentKey !== c.contentKey);
      }
    }
    courseProgress.progresses =  result;
    return courseProgress;
  }
  async loadAllFrames(
    course: Course,
    accountId: string,
  ): Promise<void> {
    this.frames = [];
    this.loader = [];
    this.indexSeq = [];
    let chapterIndex = 0;
    for (const chapter of course.courseIndex.chapters) {
      this.frames[chapterIndex] = new LoadingFrames();
      this.frames[chapterIndex].id = chapter.id;
      let sectionIndex = 0;
      for (const section of chapter.sections) {
        this.frames[chapterIndex].sections[sectionIndex] = new LoadingSection();
        this.frames[chapterIndex].sections[sectionIndex].id = section.id;
        let partIndex = 0;
        for (let part of section.parts ) {
          this.frames[chapterIndex].sections[sectionIndex].parts[partIndex] = new LoadingPart(part.frameType);
          this.frames[chapterIndex].sections[sectionIndex].parts[partIndex].id = part.id;
          this.loader.push({
            chapterIndex: chapterIndex,
            sectionIndex: sectionIndex,
            partIndex: partIndex,
            partId: part.id,
            frameType: part.frameType,
          });
          if (part.frameType !== FrameTypes.CoverFrame && part.frameType !== FrameTypes.FileFrame) {
            this.indexSeq.push({
              chapterIndex: chapterIndex,
              sectionIndex: sectionIndex,
              partIndex: partIndex,
              partId: part.id,
              frameType: part.frameType,
            })
          }
          partIndex++;
        }
        sectionIndex++;
      }
      chapterIndex++;
    }
    await Promise.all(this.loader.map((load => {
      this.partService.loadFrameBody(
        accountId,
        course.id,
        load.partId,
        load.frameType
      ).then((res) => {
        this.frames[load.chapterIndex].sections[load.sectionIndex].parts[load.partIndex].frame = res;
      });
    })));
    this.framesLoaded = true;
  }
}
