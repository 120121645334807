<section *ngIf="isOpenModal" class="l-modal">
  <div class="c-modal">
    <div class="c-modal__head">
      <div class="c-modal__head__title">
        {{title}}
      </div>
      <div (click)="closeModal()" class="c-modal__head__closeButton"></div>
    </div>
    <div class="c-modal__chat">
      <div class="p-talk">
        <div class="p-talk__in">
          <div class="p-talk__in__thread">
            <div class="p-talk__in__thread__messages"  #chatWindow id="chatWindow">
                <ng-container *ngFor="let c of chats">
                  <ng-container *ngIf="c.way === chatWays.received">
                    <div class="p-talk__in__thread__messages__message-l">
                      <div class="p-talk__in__thread__messages__message-l__user">
                        <img
                          *ngIf="!targetUser?.profileImage?.src"
                          ngSrc="no-image"
                          width="160"
                          height="90"
                          ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                          alt="">
                        <img
                          *ngIf="targetUser?.profileImage?.src"
                          ngSrc="{{targetUser?.profileImage?.src}}"
                          width="160"
                          height="90"
                          ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                          alt="">
                      </div>
                      <div class="p-talk__in__thread__messages__message-l__body">
                        <div class="p-talk__in__thread__messages__message-l__body__text">
                          {{c.content?.body | nl2br}}
                        </div>
                        <div class="p-talk__in__thread__messages__message-l__body__info">
                          {{c.createdAt | datetimeFormatFirestore}}
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="c.way === chatWays.send">
                    <div class="p-talk__in__thread__messages__message-r">
                      <div class="p-talk__in__thread__messages__message-r__user">
                        <img
                          *ngIf="!channel?.profileImage?.src"
                          ngSrc="no-image"
                          width="160"
                          height="90"
                          ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                          alt="">
                        <img
                          *ngIf="channel?.profileImage?.src"
                          ngSrc="{{channel?.profileImage?.src}}"
                          width="160"
                          height="90"
                          ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                          alt="">
                      </div>
                      <div class="p-talk__in__thread__messages__message-r__body">
                        <div class="p-talk__in__thread__messages__message-r__body__text">
                          {{c.content?.body}}
                        </div>
                        <div class="p-talk__in__thread__messages__message-r__body__info">
                          {{c.createdAt | datetimeFormatFirestore}}
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="c.way === chatWays.system">
                    <div class="p-talk__in__thread__messages__message-system">
                      <div class="p-talk__in__thread__messages__message-system__body">
                        <div class="p-talk__in__thread__messages__message-system__body__text">
                          {{c.content?.body}}
                        </div>
                        <div class="p-talk__in__thread__messages__message-system__body__info">
                          {{c.createdAt | datetimeFormatFirestore}}
                        </div>
                      </div>
                    </div>
                  </ng-container>

                </ng-container>
            </div>
            <div class="p-talk__in__thread__control">
              <!--<button
                (click)="openUploadModal()"
                data-tooltip="写真/ファイルを送信"
                type="button"
                class="p-talk__in__thread__control__button p-talk__in__thread__control__button-image">
              </button>-->
              <textarea
                (keydown.shift.enter)="sendChat()"
                [formControl]="body"
                class="p-talk__in__thread__control__text"
                [placeholder]="'メッセージを入力してください' | translate"
              ></textarea>
              <button
                (click)="sendChat()"
                data-tooltip="テキスト送信"
                type="button"
                class="p-talk__in__thread__control__button p-talk__in__thread__control__button-send">
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-file-upload-modal
  (isOpenModalEmitter)="changeOpenUploadModal($event)"
  [isOpenModal]="isOpenSomeModal.fileUpload"
  [accountId]="account?.id"
  (imageEmitter)="afterUploadImage($event)"
></app-file-upload-modal>
