import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  DocumentData, QuerySnapshot,
} from '@angular/fire/compat/firestore';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {CourseSubscription} from '../../models/course-subscription';
import {ChannelSubscription} from "../../models/channel-subscription";
@Injectable({
  providedIn: 'root'
})
export class ChannelInvitationsService {
  dbDocument: AngularFirestoreDocument<any>;
  collectionRef: AngularFirestoreCollection;
  doc: Observable<CourseSubscription | undefined>;
  docs: Observable<DocumentData[]>;
  myDocs: Observable<DocumentData[]>;
  subscriptions: CourseSubscription[] = [];
  constructor(
    private fireStore: AngularFirestore,
  ) { }
  create(
    channelAccountId: string,
    channelId: string,
    uid: string,
  ): Promise<any> {
    const subscription = new ChannelSubscription();
    subscription.channelAccountId = channelAccountId;
    subscription.channelId = channelId;
    subscription.uid = uid;
    subscription.createdAt = new Date();
    subscription.createdBy = uid;
    subscription.updatedAt = new Date();
    subscription.updatedBy = uid;
    subscription.activated = true;
    subscription.activatedAt = new Date();
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('accounts')
        .doc(channelAccountId)
        .collection('apps')
        .doc(environment.appId)
        .collection('channels')
        .doc(channelId)
        .collection('channelInvitations')
        .doc(uid)
        .set(subscription.deserialize())
        .then((data) => {
          resolve(data);
        })
        .catch((e) => {
          console.log('create subscription reject');
          reject(e);
        });
    })
  }
  async fetch(
    channelAccountId: string,
    channelId: string,
    subscriptionId: string
  ): Promise<ChannelSubscription | undefined> {
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('accounts')
        .doc(channelAccountId)
        .collection('apps')
        .doc(environment.appId)
        .collection('channels')
        .doc(channelId)
        .collection('channelInvitations')
        .doc(subscriptionId)
        .get()
        .toPromise()
        .then((data) => {
          if (!data.data()) {
            resolve(undefined);
          }
          const newDoc = data.data() as ChannelSubscription;
          newDoc.id = data.id;
          resolve(newDoc);
        })
        .catch((e) => {
          console.log('channel invitation fetch reject');
          reject(e);
        });
    })
  }
  async fetchAll(
    accountId: string,
  ): Promise<QuerySnapshot<CourseSubscription[]>> {
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('accounts')
        .doc(accountId)
        .collection('apps')
        .doc(environment.appId)
        .collection('channelInvitations')
        .get()
        .toPromise()
        .then(( querySnapshot: any ) => {
          resolve(querySnapshot);
        })
        .catch((e) => {
          console.log('reject');
          reject(e);
        });
    })
  }
  async update(
    channelAccountId: string,
    uid: string,
    subscription: ChannelSubscription
  ): Promise<any> {
    subscription.updatedAt = new Date();
    subscription.updatedBy = uid;
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('accounts')
        .doc(channelAccountId)
        .collection('apps')
        .doc(environment.appId)
        .collection('channelInvitations')
        .doc(subscription.id)
        .set(subscription)
        .then((data) => {
          resolve(data);
        })
        .catch((e) => {
          console.log('reject');
          reject(e);
        });
    })
  }
  async remove(
    channelAccountId: string,
    uid: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('accounts')
        .doc(channelAccountId)
        .collection('apps')
        .doc(environment.appId)
        .collection('channelInvitations')
        .doc(uid)
        .delete()
        .then((data) => {
          resolve(data);
        })
        .catch((e) => {
          console.log('reject');
          reject(e);
        });
    })
  }
}
