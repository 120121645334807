import { Component } from '@angular/core';

@Component({
  selector: 'app-manager-channel-course-inspection-apply',
  templateUrl: './manager-channel-course-inspection-apply.component.html',
  styleUrls: ['./manager-channel-course-inspection-apply.component.scss']
})
export class ManagerChannelCourseInspectionApplyComponent {

}
