<div class="l-main">
  <app-navigations></app-navigations>
  <div class="l-content">
    <app-breadcrumb></app-breadcrumb>
    <div class="l-content__inner">
      <div class="c-content-header">
        <div class="c-content-header__title">
          売上レポート
        </div>
      </div>
      <div class="l-templates">
        <div class="p-templates">
          <div class="p-templates__content">
            <div class="c-search">
              <div class="c-search__row">
                <div class="c-search__row__quarter">
                  <label class="c-form-label" for="from">期間(From)</label>
                  <input class="c-form-input" type="date" name="from" id="from">
                </div>
                <div class="c-search__row__quarter">
                  <label class="c-form-label" for="to">期間(To)</label>
                  <input class="c-form-input" type="date" name="to" id="to">
                </div>
              </div>
            </div>
            <div class="p-templates__content__chart">
              <div class="p-templates__content__chart__head">
                <div class="p-templates__content__chart__head__item">
                  <div class="p-templates__content__chart__head__item__title">今月の売上</div>
                  <div class="p-templates__content__chart__head__item__data">1,403,344<span>円</span></div>
                </div>
                <div class="p-templates__content__chart__head__item">
                  <div class="p-templates__content__chart__head__item__title">購読者数</div>
                  <div class="p-templates__content__chart__head__item__data">3,344<span>人</span></div>
                </div>
                <div class="p-templates__content__chart__head__item">
                  <div class="p-templates__content__chart__head__item__title">コース購入者数</div>
                  <div class="p-templates__content__chart__head__item__data">3,344<span>人</span></div>
                </div>
                <div class="p-templates__content__chart__head__item">
                  <div class="p-templates__content__chart__head__item__title">売上総合計</div>
                  <div class="p-templates__content__chart__head__item__data">1,403,344<span>円</span></div>
                </div>

              </div>
              <div class="p-templates__content__chart__body">
                <img src="assets/images/sampleImage_graph.jpg" width="100%"  alt="">
              </div>
            </div>
            <div class="c-list-report">
              <div class="c-list-report__head">
                <div class="c-list-report__head__date">日付</div>
                <div class="c-list-report__head__userName">お客様名</div>
                <div class="c-list-report__head__plan">プラン</div>
                <div class="c-list-report__head__course">コース</div>
                <div class="c-list-report__head__sales">売上</div>
                <div class="c-list-report__head__fee">手数料</div>
                <div class="c-list-report__head__profit">利益</div>
              </div>
              <div class="c-list-report__body">
                <div class="c-list-report__body__row">
                  <div class="c-list-report__body__row__date">03/20</div>
                  <div class="c-list-report__body__row__userName">ふぁいあふぁいあふぁいあふぁいあ</div>
                  <div class="c-list-report__body__row__plan">購入</div>
                  <div class="c-list-report__body__row__course">後悔しないための社会人マナー後悔しないための社会人マナー</div>
                  <div class="c-list-report__body__row__sales">12345円</div>
                  <div class="c-list-report__body__row__fee">123円</div>
                  <div class="c-list-report__body__row__profit">12345円</div>
                </div>
                <div class="c-list-report__body__row">
                  <div class="c-list-report__body__row__date">03/20</div>
                  <div class="c-list-report__body__row__userName">ふぁいあふぁいあふぁいあふぁいあ</div>
                  <div class="c-list-report__body__row__plan">購入</div>
                  <div class="c-list-report__body__row__course">後悔しないための社会人マナー後悔しないための社会人マナー</div>
                  <div class="c-list-report__body__row__sales">12345円</div>
                  <div class="c-list-report__body__row__fee">123円</div>
                  <div class="c-list-report__body__row__profit">12345円</div>
                </div>
                <div class="c-list-report__body__row">
                  <div class="c-list-report__body__row__date">03/20</div>
                  <div class="c-list-report__body__row__userName">ふぁいあふぁいあふぁいあふぁいあ</div>
                  <div class="c-list-report__body__row__plan">購入</div>
                  <div class="c-list-report__body__row__course">後悔しないための社会人マナー後悔しないための社会人マナー</div>
                  <div class="c-list-report__body__row__sales">12345円</div>
                  <div class="c-list-report__body__row__fee">123円</div>
                  <div class="c-list-report__body__row__profit">12345円</div>
                </div>
                <div class="c-list-report__body__row">
                  <div class="c-list-report__body__row__date">03/20</div>
                  <div class="c-list-report__body__row__userName">ふぁいあふぁいあふぁいあふぁいあ</div>
                  <div class="c-list-report__body__row__plan">購入</div>
                  <div class="c-list-report__body__row__course">後悔しないための社会人マナー後悔しないための社会人マナー</div>
                  <div class="c-list-report__body__row__sales">12345円</div>
                  <div class="c-list-report__body__row__fee">123円</div>
                  <div class="c-list-report__body__row__profit">12345円</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
