import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {Store} from '@ngrx/store';
import {BehaviorSubject, firstValueFrom, Observable, Subscription} from 'rxjs';
import firebase from 'firebase/compat/app';
import {getAuth, linkWithCredential, EmailAuthProvider, OAuthProvider} from "firebase/auth";
import firebaseUser = firebase.User;
import UserCredential = firebase.auth.UserCredential;
import IdTokenResult = firebase.auth.IdTokenResult;
import {storeIdToken, storeIsAnonymous, storeIsOem, storeUser} from "../../store/sessions/sessions.actions";
import {map} from "rxjs/operators";
import {User} from "../../models/user";
import {CurrentUser, SessionState, setSession} from "../../store/sessions/sessions.reducer";
import {LanguagesService} from "../languages/languages.service";
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  sessionsSubscription: Subscription;
  fireAuthSubscription: Subscription;
  currentSession: SessionState | undefined;
  currentAuth$: BehaviorSubject<firebaseUser | null>;
  currentAuth: firebaseUser | null;
  userCredential$: BehaviorSubject<UserCredential | null>;
  currentIdTokenResult$: BehaviorSubject<IdTokenResult | null>;
  uid: string;
  idToken: string;
  idTokenExpireAt: Number;
  accessToken: string;
  auth: UserCredential;
  user: User;
  claims: any;
  loggedIn$: BehaviorSubject<boolean | null>;
  isAnonymous$: BehaviorSubject<boolean | null>;
  constructor(
    private fireAuth: AngularFireAuth,
    private fireStore: AngularFirestore,
    private sessionsStore: Store<{ sessions: SessionState}>,
    private languagesService: LanguagesService,
    private http: HttpClient,
  ) {
    this.currentAuth$ = new BehaviorSubject(null);
    this.currentIdTokenResult$ = new BehaviorSubject(null);
    this.userCredential$ = new BehaviorSubject(null);
    this.loggedIn$ = new BehaviorSubject(null);
    this.isAnonymous$ = new BehaviorSubject(null);
  }
  async checkIdTokenExpire (): Promise<void> {
    if (this.idTokenExpireAt < new Date().getTime()) {
      await this.refresh();
    }
  }
  async refresh(): Promise<void> {
    this.fireAuthSubscription?.unsubscribe();
    this.sessionsSubscription?.unsubscribe();
    this.fireAuthSubscription = this.fireAuth.authState.pipe()
      .subscribe(async (userFirebase) => {
        if (userFirebase) {
          this.currentAuth$.next(userFirebase);
          this.currentAuth = userFirebase;
          this.uid = this.currentAuth.uid;
          this.currentAuth.getIdTokenResult().then(async (token) => {
            console.log(token);
            if (token?.claims) {
              this.claims = token.claims;
            }
            this.accessToken = sessionStorage.getItem('accessToken');
            this.currentIdTokenResult$.next(token);
            this.idTokenExpireAt = (new Date().getTime()) + (3600*1000);
          })
        }
      });
    this.sessionsSubscription = this.sessionsStore
      .select(setSession)
      .pipe()
      .subscribe(async (sessionState) => {
        console.log(sessionState);
        this.currentSession = sessionState;
      })
    await new Promise(resolve => setTimeout(resolve, 1000));
    await this.resetSession();
  }
  async resetSession(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (!this.currentAuth) {
        this.loginAnonymous().then(() => {
          console.log('loginAnonymous exec');
          resolve();
          return;
        });
      }
      const idTokenResult = await this.currentAuth?.getIdTokenResult();
      if (!idTokenResult) {
        this.loginAnonymous().then(() => {
          console.log('loginAnonymous exec');
          resolve();
          return;
        });
      }
      this.sessionsStore.dispatch(storeIdToken({idToken: idTokenResult?.token}));
      console.log(this.currentAuth?.uid);
      if (!this.currentAuth?.uid) {
        return;
      }
      // マイクロソフトSSO
      if (this.claims?.firebase?.sign_in_provider === 'microsoft.com') {
        console.log('マイクロソフトSSO');
        this.http.get(
          'https://graph.microsoft.com/v1.0/me',
          {
            headers: new HttpHeaders({
              'Authorization': 'Bearer ' + sessionStorage.getItem('accessToken'),
              'Content-Type': 'application/json',
            })
          }
        )
          .toPromise()
          .then(async (res) => {
            const response: any = res;
            console.log(res);
            const user = await this.fetchUser(this.currentAuth?.uid);
            user.id = this.currentAuth.uid;
            user.language = response?.preferredLanguage;
            user.jobTitle = response?.jobTitle;
            user.givenName = response?.givenName;
            user.familyName = response?.surname;
            user.displayName = response?.displayName;
            user.isOem = true;
            user.oemAccountId = environment.ssoMicrosoftOemId;
            user.email = response?.userPrincipalName;
            console.log(user)
            await this.updateUser(user);
            this.sessionsStore.dispatch(storeIsOem({isOem: user?.isOem}));
            this.sessionsStore.dispatch(storeIsAnonymous({isAnonymous: this.currentAuth?.isAnonymous}));
            const currentUser: CurrentUser = {
              displayName: user?.displayName,
              language: user?.language,
              photoUrl: user?.profileImage?.src,
              id: user?.id,
              currentAccountId: user?.currentAccountId,
              roles: null,
              emailValid: this.currentAuth?.emailVerified,
            }
            this.sessionsStore.dispatch(storeUser({currentUser: currentUser}));
            if (user?.language) {
              await this.languagesService.change(user?.language, user?.id);
            } else {
              await this.languagesService.change('ja', user?.id);
            }
            resolve();
            return;
          })
          .catch(async (e) => {
            console.log('microsoft データ取れない');
            const user = await this.fetchUser(this.currentAuth?.uid);
            this.sessionsStore.dispatch(storeIsOem({isOem: user?.isOem}));
            this.sessionsStore.dispatch(storeIsAnonymous({isAnonymous: this.currentAuth?.isAnonymous}));
            const currentUser: CurrentUser = {
              displayName: user?.displayName,
              language: user?.language,
              photoUrl: user?.profileImage?.src,
              id: user?.id,
              currentAccountId: user?.currentAccountId,
              roles: null,
              emailValid: this.currentAuth?.emailVerified,
            }
            this.sessionsStore.dispatch(storeUser({currentUser: currentUser}));
            if (user?.language) {
              await this.languagesService.change(user?.language, user?.id);
            } else {
              await this.languagesService.change('ja', user?.id);
            }
            resolve(e);
            return;
          });
      } else {
        // 通常の処理
        console.log('通常の処理');
        const user = await this.fetchUser(this.currentAuth?.uid);
        if (user) {
          console.log(user);
          this.user = user;
          this.sessionsStore.dispatch(storeIsOem({isOem: user?.isOem}));
          this.sessionsStore.dispatch(storeIsAnonymous({isAnonymous: this.currentAuth?.isAnonymous}));
          const currentUser: CurrentUser = {
            displayName: user?.displayName,
            language: user?.language,
            photoUrl: user?.profileImage?.src,
            id: user?.id,
            currentAccountId: user?.currentAccountId,
            roles: null,
            emailValid: this.currentAuth?.emailVerified,
          }
          this.sessionsStore.dispatch(storeUser({currentUser: currentUser}));
          if (user?.language) {
            await this.languagesService.change(user?.language, user?.id);
          } else {
            await this.languagesService.change('ja', user?.id);
          }
          resolve();
        } else {
          console.log('user data nothing');
          console.log('user loginAnonymous');
          const currentUser: CurrentUser = {
            id: this.currentAuth.uid,
            language: 'ja',
            displayName: 'ログインしていません',
          };
          this.sessionsStore.dispatch(storeIsOem({isOem: false}));
          this.sessionsStore.dispatch(storeIsAnonymous({isAnonymous: this.currentAuth.isAnonymous}));
          this.sessionsStore.dispatch(storeUser({currentUser: currentUser}));
          resolve();
          return;
        }
      }
    });
  }
  loginAnonymous(): Promise<void> {
    // 匿名ログイン
    return new Promise( (resolve, reject) => {
      this.fireAuth.signInAnonymously()
        .then((currentUserCredential) => {
          this.userCredential$.next(currentUserCredential);
          const user: CurrentUser = {
            id: currentUserCredential.user.uid,
            displayName: 'ログインしていません',
          };
          this.currentAuth = currentUserCredential.user;
          this.sessionsStore.dispatch(storeIsAnonymous({isAnonymous: true}));
          this.sessionsStore.dispatch(storeUser({ currentUser: user}));
          resolve();
        })
        .catch((e) => {
          // 匿名ログイン出来ない場合
          console.log('can not login with Anonymous');
          reject(e);
        })
    });
  }
  login(email: string, password: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.fireAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(async () => {
          try {
            const auth = await this.fireAuth.signInWithEmailAndPassword(email, password);
            this.userCredential$.next(auth);
            const idTokenResult = await auth.user.getIdTokenResult();
            if (!idTokenResult) {
              reject();
            }
            this.loggedIn$.next(true);
            this.currentAuth = auth.user;
            this.sessionsStore.dispatch(storeIdToken({idToken: idTokenResult.token}));
            const user = await this.fetchUser(auth.user.uid);
            this.sessionsStore.dispatch(storeIsOem({isOem: user?.isOem}));
            this.sessionsStore.dispatch(storeIsAnonymous({isAnonymous: this.currentAuth?.isAnonymous}));
            const currentUser: CurrentUser = {
              displayName: user?.displayName,
              language: user?.language,
              photoUrl: user?.profileImage?.src,
              id: user?.id,
              currentAccountId: user?.currentAccountId,
              roles: null,
              emailValid: this.currentAuth?.emailVerified,
            };
            this.sessionsStore.dispatch(storeUser({currentUser: currentUser}));
            resolve();
          } catch (err) {
            console.log(err);
            reject();
          }
        })
    })
  }
  logout(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.fireAuth
        .signOut()
        .then(() => {
          this.loggedIn$.next(false);
          resolve();
        })
        .catch( (e) => {
          this.loggedIn$.next(false);
          reject();
        });
    })
  }
  signup(
    email: string,
    password: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const credential = EmailAuthProvider.credential(email, password);
      const auth = getAuth();
      linkWithCredential(auth.currentUser, credential)
        .then(async (userCred) => {
          const user = userCred.user;
          const idTokenResult = await user.getIdTokenResult();
          this.idToken = idTokenResult.token;
          this.uid = userCred.user.uid;
          console.log("Anonymous account successfully upgraded", user);
          resolve();
        }).catch((error) => {
        console.log("Error upgrading anonymous account", error);
        reject();
      });
    });
  }
  checkLoginState(): Observable<firebaseUser> {
    return this.fireAuth
      .authState
      .pipe(
        map((auth: firebaseUser | null) => {
          if (auth) {
            this.uid = auth.uid;
            this.currentAuth = auth;
            return auth;
          } else {
            this.uid = null;
            this.currentAuth = null;
            return auth;
          }
        })
      );
  }
  async fetchUser(id: string): Promise<User | null> {
    const doc = this.fireStore
      .collection('users')
      .doc(id)
      .get();
    const data = await firstValueFrom(doc);
    if (!data.data()) {
      return null;
    }
    const user = data.data() as User;
    user.id = data.id as string;
    return user;
  }
  async updateUser(user: User): Promise<any> {
    const userJson = JSON.stringify(user);
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('users')
        .doc(user.id)
        .set(JSON.parse(userJson))
        .then(() => {
          resolve(true);
        })
        .catch((e) => {
          console.log('reject');
          reject(e);
        });
    })
  }
}
