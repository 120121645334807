import { Component } from '@angular/core';

@Component({
  selector: 'app-slo',
  templateUrl: './slo.component.html',
  styleUrls: ['./slo.component.scss']
})
export class SloComponent {
  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

}
