import { Injectable } from '@angular/core';
import {
  AngularFirestore, AngularFirestoreCollection, CollectionReference, DocumentData, Query,
} from '@angular/fire/compat/firestore';
import {environment} from '../../../environments/environment';
import {Tag} from '../../models/tag';
import {BehaviorSubject, Observable} from 'rxjs';
import {DigestPipe} from "../../pipes/digest/digest.pipe";
@Injectable({
  providedIn: 'root'
})
export class ContentTagsService {
  collectionRef: AngularFirestoreCollection;
  tag: BehaviorSubject<Tag | undefined>;
  tags: BehaviorSubject<Tag[] | undefined>;
  tagList: Tag[] = [];

  constructor(
    private fireStore: AngularFirestore,
  ) {
    this.tag = new BehaviorSubject<Tag | undefined>(new Tag());
    this.tags = new BehaviorSubject<Tag[] | undefined>([]);
  }
  async create(
    tag: Tag
  ): Promise<string> {
    return new Promise(async (resolve, reject) => {
      const sh256 = new DigestPipe();
      const id = await sh256.transform(tag.name, 'sha-1');
      tag.id = id;
      this.fireStore
        .collection('apps')
        .doc(environment.appId)
        .collection('contentTags')
        .doc(id)
        .set(tag.deserialize())
        .then((data) => {
          resolve(id);
        })
        .catch((e) => {
          console.log('reject');
          reject(e);
        });
    })
  }

  update(
    changeTag: Tag
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('apps')
        .doc(environment.appId)
        .collection('contentTags')
        .doc(changeTag.id)
        .set(changeTag)
        .then((data) => {
          resolve(data);
        })
        .catch((e) => {
          console.log('reject');
          reject(e);
        });
    })
  }
  searchTagsByKeyword(
    keyword: string
  ): void {
    this.fireStore
      .collection('apps')
      .doc(environment.appId)
      .collection(
        'contentTags', ref => {
          let query: CollectionReference | Query = ref;
          query = query
            .orderBy('name')
            .startAt(keyword)
            .endAt(keyword + '\uf8ff');
          return query;
        }
      )
      .get()
      .toPromise()
      .then((querySnapshot) => {
        this.tagList = [];
        querySnapshot.forEach(( doc ) => {
          const tag = new Tag(doc.id , doc.data() as Tag);
          console.log(tag);
          this.tagList.push(tag);
        })
      })
      .catch();
  }
  async gatheringTags(tagIds: string[]): Promise<Tag[]> {
    const result: Tag[] = [];
    tagIds.map(async (tagId) => {
      const tag = await this.fetchById(tagId);
      result.push(tag);
    });
    return result;
  }
  async fetchById(
    tagId: string
  ): Promise<Tag> {
    return new Promise((( resolve, reject) => {
      this.fireStore
        .collection('apps')
        .doc(environment.appId)
        .collection('contentTags')
        .doc(tagId)
        .get()
        .toPromise()
        .then((querySnapshot) => {
          resolve( new Tag(querySnapshot.id , querySnapshot.data() as Tag));
        })
        .catch(() => {
          reject();
        });
    }))
  }
  connectSnapShotFindTag(
    keyword: string
  ): void {
    this.collectionRef = this.fireStore
      .collection('apps')
      .doc(environment.appId)
      .collection<{}>('contentTags', ref => {
        let query: CollectionReference | Query = ref;
        query = query
          .orderBy('keyword')
          .startAt(keyword)
          .endAt(keyword + '\uf8ff');
        return query;
      });
    const tags$ = this.collectionRef
      .valueChanges({idField: "id"})
      .pipe()
      .subscribe((tags => {
        tags.forEach((t) => {
          this.tag.next(t as Tag);
        })
      }))
  }
  async remove(tagId: string): Promise<void> {
    await this.fireStore
      .collection('apps')
      .doc(environment.appId)
      .collection('contentTags')
      .doc(tagId)
      .delete();
  }
}
