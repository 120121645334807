import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {getMenuSideNavi, getSideNavi, getUrlState, UiState} from "../../store/ui/ui.reducer";
import {switchFalseShowSideNavigation, switchTrueShowSideNavigation} from "../../store/ui/ui.actions";
import {Router} from "@angular/router";

@Component({
  selector: 'app-navigations',
  templateUrl: './navigations.component.html',
  styleUrls: ['./navigations.component.scss']
})
export class NavigationsComponent implements OnInit, OnDestroy {
  constructor(
    private uiStore: Store<{ uiState: UiState}>,
    private router: Router
  ) { }
  menuInSideNaviSubscription: Subscription;
  sideNaviSubscription: Subscription;
  urlStateSubscription: Subscription;
  showNavi = false;
  showMenuInSideNavi = false;
  channelId: string;
  courseId: string;
  slug: string;
  contentKey: string;
  url: string;
  ngOnInit(): void {
    this.url = location.pathname;
    this.sideNaviSubscription = this.uiStore.select(getSideNavi)
      .pipe()
      .subscribe((state) => {
        this.showNavi = state;
      });
    this.menuInSideNaviSubscription = this.uiStore.select(getMenuSideNavi)
      .pipe()
      .subscribe((state) => {
        this.showMenuInSideNavi = state;
      });
    this.urlStateSubscription = this.uiStore.select(getUrlState)
      .pipe()
      .subscribe((state) => {
        this.channelId = state.channelId;
        this.slug = state.slug;
        this.courseId = state.courseId;
        this.contentKey = state.contentKey;
      });
  }
  ngOnDestroy(): void{
    this.menuInSideNaviSubscription?.unsubscribe();
    this.menuInSideNaviSubscription?.unsubscribe();
    this.urlStateSubscription?.unsubscribe();
  }
  toggleNavi(): void {
    this.showNavi = !this.showNavi;
    if (this.showNavi) {
      this.uiStore.dispatch(switchTrueShowSideNavigation());
      return;
    }
    this.uiStore.dispatch(switchFalseShowSideNavigation());
  }
  moveToCourseUsers(): void {
    if (this.channelId && this.courseId) {
      this.router.navigate([`/manager/channel/course/users/${this.channelId}/${this.courseId}`]).then();
      return;
    }
    if (this.channelId) {
      this.router.navigate([`/manager/channel/courses/${this.channelId}/all`]).then();
      return;
    }
    return;
  }
  isCurrent(url: string): boolean {
    return this.url === url;
  }
}
