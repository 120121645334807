export enum PublishRangesKeys {
  public = 'public',
  forInvitationOnly = 'forInvitationOnly',
  forChannelMembershipOnly = 'forChannelMembershipOnly',
  forAccountUsersOnly = 'forAccountUsersOnly',
}
export class PublishRange {
  key: PublishRangesKeys;
  nameJa: string;
}
export const publishRanges: PublishRange[] = [
  {
    key: PublishRangesKeys.public,
    nameJa: '一般公開',
  },
  {
    key: PublishRangesKeys.forInvitationOnly,
    nameJa: '招待者のみ',
  },
  {
    key: PublishRangesKeys.forChannelMembershipOnly,
    nameJa:'チャンネルメンバーのみ',
  },
  {
    key: PublishRangesKeys.forAccountUsersOnly,
    nameJa: '同一アカウントのユーザーのみ(非公開)',
  },
];
