<div class="l-main">
  <app-navigations></app-navigations>
  <div class="l-content">
    <app-breadcrumb></app-breadcrumb>
    <div class="l-content__inner">
      <div class="c-content-header">
        <div class="c-content-header__title">
          集合研修（リアル研修/講義）
        </div>
      </div>
      <div class="l-templates">
        <div class="p-templates">
          <div class="p-templates__content">
            <div class="p-templates__content__createButton">
              <button (click)="openUploadModal()" class="c-button c-button-create">講義を追加する</button>
            </div>
            <div class="c-card-remotes">
              <div class="c-card-remotes__item">
                <div class="c-card-remotes__item__body">
                  <div class="c-card-remotes__item__body__thumb">
                    <img src="assets/images/sampleImage_video_01.png" alt="">
                  </div>
                  <div class="c-card-remotes__item__body__title">
                    <div class="c-card-remotes__item__body__title__main">
                      損しないための社会人マナー損しないための社会人マナー
                    </div>
                    <div class="c-card-remotes__item__body__title__updateAt">
                      最終更新:2022/11/01 22:51
                    </div>
                  </div>
                  <div class="c-card-remotes__item__body__meta">
                    <div class="c-card-remotes__item__body__meta__row">累計参加人数：29,014人</div>
                  </div>
                  <div class="c-card-remotes__item__body__buttons">
                    <button (click)="openEditModal()" class="c-card-remotes__item__body__buttons__button c-card-remotes__item__body__buttons__button-edit">編集</button>
                    <button class="c-card-remotes__item__body__buttons__button c-card-remotes__item__body__buttons__button-delete">削除</button>
                    <button class="c-card-remotes__item__body__buttons__button c-card-remotes__item__body__buttons__button-preview">プレビュー</button>
                    <div class="c-card-remotes__item__body__buttons__sort">
                      <button class="c-card-remotes__item__body__buttons__sort__up"></button>
                      <button class="c-card-remotes__item__body__buttons__sort__down"></button>
                    </div>
                  </div>
                </div>
                <div class="c-card-remotes__item__plans">
                  <div class="c-card-remotes__item__plans__row">
                    <button class="c-card-remotes__item__plans__row__column">編集</button>
                    <button class="c-card-remotes__item__plans__row__column">削除</button>
                    <div class="c-card-remotes__item__plans__row__column">2023/12/12 15:00-17:00</div>
                    <div class="c-card-remotes__item__plans__row__column">参加予定人数:123456 人</div>
                    <button class="c-card-remotes__item__plans__row__column">予約確認</button>
                  </div>
                  <div class="c-card-remotes__item__plans__row">
                    <button class="c-card-remotes__item__plans__row__column">編集</button>
                    <button class="c-card-remotes__item__plans__row__column">削除</button>
                    <div class="c-card-remotes__item__plans__row__column">2023/12/12 15:00-17:00</div>
                    <div class="c-card-remotes__item__plans__row__column">参加予定人数:123456 人</div>
                    <button class="c-card-remotes__item__plans__row__column">予約確認</button>
                  </div>
                </div>
              </div>
              <div class="c-card-remotes__item">
                <div class="c-card-remotes__item__body">
                  <div class="c-card-remotes__item__body__thumb">
                    <img src="assets/images/sampleImage_video_01.png" alt="">
                  </div>
                  <div class="c-card-remotes__item__body__title">
                    <div class="c-card-remotes__item__body__title__main">
                      損しないための社会人マナー損しないための社会人マナー
                    </div>
                    <div class="c-card-remotes__item__body__title__updateAt">
                      最終更新:2022/11/01 22:51
                    </div>
                  </div>
                  <div class="c-card-remotes__item__body__meta">
                    <div class="c-card-remotes__item__body__meta__row">累計参加人数：29,014人</div>
                  </div>
                  <div class="c-card-remotes__item__body__buttons">
                    <button (click)="openEditModal()" class="c-card-remotes__item__body__buttons__button c-card-remotes__item__body__buttons__button-edit">編集</button>
                    <button class="c-card-remotes__item__body__buttons__button c-card-remotes__item__body__buttons__button-delete">削除</button>
                    <button class="c-card-remotes__item__body__buttons__button c-card-remotes__item__body__buttons__button-preview">プレビュー</button>
                    <div class="c-card-remotes__item__body__buttons__sort">
                      <button class="c-card-remotes__item__body__buttons__sort__up"></button>
                      <button class="c-card-remotes__item__body__buttons__sort__down"></button>
                    </div>
                  </div>
                </div>
                <div class="c-card-remotes__item__plans">
                  <div class="c-card-remotes__item__plans__row">
                    <button class="c-card-remotes__item__plans__row__column">編集</button>
                    <button class="c-card-remotes__item__plans__row__column">削除</button>
                    <div class="c-card-remotes__item__plans__row__column">2023/12/12 15:00-17:00</div>
                    <div class="c-card-remotes__item__plans__row__column">参加予定:123456/300000 人</div>
                    <button class="c-card-remotes__item__plans__row__column">予約確認</button>
                  </div>
                  <div class="c-card-remotes__item__plans__row">
                    <button class="c-card-remotes__item__plans__row__column">編集</button>
                    <button class="c-card-remotes__item__plans__row__column">削除</button>
                    <div class="c-card-remotes__item__plans__row__column">2023/12/12 15:00-17:00</div>
                    <div class="c-card-remotes__item__plans__row__column">参加予定:30/30 人</div>
                    <button class="c-card-remotes__item__plans__row__column">予約確認</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<section class="l-modal" *ngIf="isOpenUploadModal">
  <div class="c-modal">
    <div class="c-modal__head">
      <div class="c-modal__head__title">
        講義作成・編集
      </div>
      <div class="c-modal__head__closeButton" (click)="closeUploadModal()"></div>
    </div>
    <div class="c-modal__main">
      <div class="c-modal__main__info">
        モーダル説明モーダル説明モーダル説明モーダル説明モーダル説明モーダル説明モーダル説明モーダル説明モーダル説明
      </div>
      <div class="c-modal__main__form">
        <div class="c-modal__main__form__input">
          <label class="c-form-label c-form-label-required" for="lectureTitle">講義タイトル</label>
          <input class="c-form-input" type="text" name="lectureTitle" id="lectureTitle" placeholder="例）ふぁいあ">
          <div class="c-form-error">講義タイトルを入力してください。</div>
        </div>
        <div class="c-modal__main__form__input">
          <label class="c-form-label c-form-label-required" for="lectureOutline">講義概要</label>
          <input class="c-form-input" type="text" name="lectureOutline" id="lectureOutline" placeholder="例）ふぁいあ">
          <div class="c-form-error">講義概要を入力してください。</div>
        </div>
        <div class="c-modal__main__form__eyeCatchUpload">
          <label class="c-form-label">コースアイキャッチ画像</label>
          <div class="c-modal__main__form__eyeCatchUpload__img">
            <img src="../../../assets/images/no-image.jpg" alt="">
          </div>
          <input type="file" id="eyeCatch">
          <div class="c-modal__main__form__eyeCatchUpload__button">
            <label for="eyeCatch" class="c-button c-button-create">
              画像を選択
            </label>
          </div>
        </div>
      </div>
      <div class="c-modal__main__control">
        <div class="c-modal__main__control__buttons">
          <div class="c-modal__main__control__buttons__button">
            <button class="c-button c-button-cancel" (click)="closeUploadModal()">キャンセル</button>
          </div>
          <div class="c-modal__main__control__buttons__button">
            <button class="c-button c-button-primary" (click)="closeUploadModal()">保存する</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="l-modal" *ngIf="isOpenEditModal">
  <div class="c-modal">
    <div class="c-modal__head">
      <div class="c-modal__head__title">
        日程の作成・変更
      </div>
      <div class="c-modal__head__closeButton" (click)="closeEditModal()"></div>
    </div>
    <div class="c-modal__main">
      <div class="c-modal__main__info">
        モーダル説明モーダル説明モーダル説明モーダル説明モーダル説明モーダル説明モーダル説明モーダル説明モーダル説明
      </div>
      <div class="c-modal__main__form">
        <div class="c-modal__main__form__input">
          <label class="c-form-label c-form-label-required" for="startDate">開始日時</label>
          <input class="c-form-input" type="datetime-local" name="startDate" id="startDate">
          <div class="c-form-error">開始日時を入力してください。</div>
        </div>
        <div class="c-modal__main__form__input">
          <label class="c-form-label c-form-label-required" for="endDate">終了日時</label>
          <input class="c-form-input" type="datetime-local" name="endDate" id="endDate">
          <div class="c-form-error">終了日時を入力してください。</div>
        </div>
        <div class="c-modal__main__form__input">
          <label class="c-form-label c-form-label-required" for="remoteLectureUrl">オンライン講義URL</label>
          <input class="c-form-input" type="text" name="remoteLectureUrl" id="remoteLectureUrl" placeholder="例）https://sample.co.jp">
          <div class="c-form-error">オンライン講義URLを入力してください。</div>
        </div>
        <div class="c-modal__main__form__input">
          <label class="c-form-label c-form-label-required" for="zoomApiUrl">ZOOM API連携URL</label>
          <input class="c-form-input" type="text" name="zoomApiUrl" id="zoomApiUrl" placeholder="例）https://sample.co.jp">
          <div class="c-modal__main__form__input__generateButton">
            <button class="c-button c-button-primary">生成する</button>
          </div>
        </div>
        <div class="c-modal__main__form__input">
          <label class="c-form-label c-form-label-required" for="roomCapacity">定員</label>
          <select class="c-form-input" name="roomCapacity" id="roomCapacity">
            <option value="">選択してください</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
            <option value="21">21</option>
            <option value="22">22</option>
            <option value="23">23</option>
            <option value="24">24</option>
            <option value="25">25</option>
            <option value="26">26</option>
            <option value="27">27</option>
            <option value="28">28</option>
            <option value="29">29</option>
            <option value="30">30</option>
          </select>
          <div class="c-form-error">定員を入力してください。</div>
        </div>
      </div>
      <div class="c-modal__main__control">
        <div class="c-modal__main__control__buttons">
          <div class="c-modal__main__control__buttons__button">
            <button class="c-button c-button-cancel" (click)="closeEditModal()">キャンセル</button>
          </div>
          <div class="c-modal__main__control__buttons__button">
            <button class="c-button c-button-primary" (click)="closeEditModal()">保存する</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
