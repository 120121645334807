<app-header-contents-channel></app-header-contents-channel>
<div class="l-content-front">
  <app-breadcrumb-front></app-breadcrumb-front>
  <div class="l-content-front__inner">
    <div class="p-category">
      <div class="p-category__title" translate>全チャンネル（テスト用）</div>
      <div
        *ngIf="channels"
        class="p-category__inner">

        <ng-container *ngFor="let c of channels">
          <div
            routerLink="/channel/{{c.slug}}"
            class="p-category__inner__item">
            <div class="p-category__inner__item__thumb">
              <img
                *ngIf="c?.eyeCatchImage?.src"
                [ngSrc]="c.eyeCatchImage.src" alt="" fill>
              <img
                *ngIf="!c?.eyeCatchImage?.src"
                src="{{noImageThumbUrl}}" alt="">
            </div>
            <div class="p-category__inner__item__channelName">{{c.slug}}</div>
            <div class="p-category__inner__item__curseName">{{c.name}}</div>
            <div class="p-category__inner__item__description">{{c.description}}</div>
          </div>
        </ng-container>


      </div>
    </div>
  </div>
</div>
<app-footer *ngIf="channels?.length > 0"></app-footer>
