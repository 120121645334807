import {Component, EventEmitter, Input, Output} from '@angular/core';
import {User} from "../../models/user";

@Component({
  selector: 'app-edit-user-modal',
  templateUrl: './edit-user-modal.component.html',
  styleUrls: ['./edit-user-modal.component.scss']
})
export class EditUserModalComponent {
  @Input() isOpen = true;
  @Input() user: User;
  @Output() isOpenModalEmitter = new EventEmitter<boolean>();

  close(): void {
    this.isOpen = false;
    this.isOpenModalEmitter.emit(this.isOpen);
  }
  save(): void {}

}
