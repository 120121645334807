<div class="l-header" [ngClass]="isShowHeader ? 'l-header-show': 'l-header-hidden'">
  <div class="p-header">
    <div class="p-header__inner">
      <div (click)="clickToTop()" class="p-header__inner__logo" [style]="getLogoStyle()"></div>
      <div class="p-header__inner__right">
        <ng-container>
          <div class="p-language">
            <div class="p-language__inner">
              <select name="language" [formControl]="languages" (change)="changeLanguage()">
                <option value="ja">日本語</option>
                <option value="en">English</option>
              </select>
            </div>
          </div>
        </ng-container>
        <div
          (click)="changeSearchModal(true)"
          class="p-header__inner__right__item p-header__inner__right__item-search"></div>

        <ng-container *ngIf="isAnonymous">
          <div
            (click)="changeLoginModal(true)"
            class="p-header__inner__right__item p-header__inner__right__item-login"></div>
        </ng-container>

        <ng-container *ngIf="!isAnonymous">
          <div
            at-raised-button
            matTooltip="{{displayName + ' ' + translateService.instant('ログイン中')}}"
            [matMenuTriggerFor]="menu"
            class="p-header__inner__right__item p-header__inner__right__item-user">
            <!--<div class="p-header__inner__right__item__notification"></div>-->
          </div>
          <mat-menu #menu="matMenu">
            <button routerLink="/my-page" mat-menu-item><span translate>マイページ</span></button>
            <button
              *ngIf="user?.currentAccountId"
              [matMenuTriggerFor]="channels" mat-menu-item><span translate>自分のチャンネル</span></button>
            <!--<button routerLink="/my-page/personal" mat-menu-item>プロフィール</button>-->
            <!--<button routerLink="/my-page/payment/history" mat-menu-item>購入履歴</button>-->
            <!--<button routerLink="/my-page/notifications" mat-menu-item>通知設定</button>-->
            <!--<button routerLink="/my-page/payment/credit" mat-menu-item>クレジットカード登録・変更</button>-->
            <!--<button routerLink="/my-page/email-verify" mat-menu-item>メールアドレスの有効性確認</button>-->
            <button routerLink="/my-page/password" mat-menu-item><span translate>パスワード変更</span></button>
            <!--<button routerLink="/my-page/auth-setup" mat-menu-item>2段階認証</button>-->
            <button routerLink="/logout" mat-menu-item><span translate>ログアウト</span></button>
          </mat-menu>
          <mat-menu #channels="matMenu">
            <button
              *ngFor="let c of listOfChannels"
              routerLink="/manager/channel/{{c.id}}"
              mat-menu-item>{{c.name}}</button>
            <button *ngIf="listOfChannels?.length > 0" routerLink="/manager/channels" mat-menu-item>
              <span translate>チャンネル選択</span></button>
            <button routerLink="/manager/channel/create" mat-menu-item><span translate>チャンネル作成</span></button>
            <button routerLink="/manager/accounts" mat-menu-item><span translate>アカウントの切替</span></button>
          </mat-menu>
        </ng-container>
        <div
          *ngIf="ownerAccount?.accountType === AccountType.general"
          [matMenuTriggerFor]="contentMenu"
          class="p-header__inner__right__item p-header__inner__right__item-sp-menu"></div>
        <mat-menu #contentMenu="matMenu">
          <button routerLink="/" mat-menu-item><span translate>TOPページ</span></button>
          <!--<button routerLink="/categories" mat-menu-item>カテゴリー一覧</button>
          <button [matMenuTriggerFor]="categoriesList" mat-menu-item>講座を探す</button>-->
          <button routerLink="/contracts" mat-menu-item><span translate>利用規約</span></button>
          <a href="https://www.grab-design.com/privacy/" mat-menu-item translate><span translate>プライバシーポリシー</span></a>
          <button routerLink="/business-contract" mat-menu-item><span translate>特定商取引法に基づく表記</span></button>
        </mat-menu>
        <mat-menu #categoriesList="matMenu">
          <button
            *ngFor="let c of categories"
            routerLink="/category/{{c.slug}}" mat-menu-item
          ><span translate>{{ c.name }}</span></button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
<app-modal-login *ngIf="isOpenLoginModal" (isOpenLoginModalEmitter)="changeLoginModal($event)"  [isOpenLoginModal]="isOpenLoginModal"></app-modal-login>
<app-modal-search (isOpenSearchModalEmitter)="changeSearchModal($event)" [isOpenSearchModal]="isOpenSearchModal"></app-modal-search>
