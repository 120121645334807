<section class="l-modal" *ngIf="isOpen">
  <div class="c-modal">
    <div class="c-modal__head">
      <div class="c-modal__head__title" translate>基本情報編集</div>
      <div class="c-modal__head__closeButton" (click)="close()"></div>
    </div>
    <div class="c-modal__main">
      <div class="c-modal__form">
        <div class="c-modal__main__form__accordion">
          <div class="c-accordion">
            <input class="c-accordion__opener" type="checkbox" id="opener-1">
            <label class="c-accordion__head" for="opener-1" translate>名前</label>
            <div class="c-accordion__body">
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="nickName" translate>ニックネーム</label>
                <input class="c-form-input" type="text" name="nickName" id="nickName" value="山田">
              </div>
              <div class="c-accordion__body__groupTitle" translate>お名前(漢字)</div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="familyName" translate>姓</label>
                <input class="c-form-input" type="text" name="familyName" id="familyName" value="山田">
              </div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="firstName" translate>名</label>
                <input class="c-form-input" type="text" name="firstName" id="firstName" value="太郎">
              </div>
              <div class="c-accordion__body__groupTitle" translate>お名前(ふりがな)</div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="familyNamePhonetic" translate>せい</label>
                <input class="c-form-input" type="text" name="familyNamePhonetic" id="familyNamePhonetic" value="やまだ">
              </div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="firstNamePhonetic" translate>めい</label>
                <input class="c-form-input" type="text" name="firstNamePhonetic" id="firstNamePhonetic" value="たろう">
              </div>
            </div>
          </div>
          <div class="c-accordion">
            <input class="c-accordion__opener" type="checkbox" id="opener-2">
            <label class="c-accordion__head" for="opener-2" translate>住所</label>
            <div class="c-accordion__body">
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="postCode" translate>郵便番号</label>
                <input class="c-form-input" type="text" name="postCode" id="postCode" value="123-4567">
              </div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="Prefecture" translate>都道府県</label>
                <input class="c-form-input" type="text" name="Prefecture" id="Prefecture" value="東京都">
              </div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="address-1" translate>住所1</label>
                <input class="c-form-input" type="text" name="address-1" id="address-1" value="新宿区">
              </div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="address-2" translate>住所2</label>
                <input class="c-form-input" type="text" name="address-2" id="address-2" value="四谷坂町">
              </div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="address-3" translate>住所3</label>
                <input class="c-form-input" type="text" name="address-3" id="address-3" value="111-123">
              </div>
            </div>
          </div>
          <div class="c-accordion">
            <input class="c-accordion__opener" type="checkbox" id="opener-3">
            <label class="c-accordion__head" for="opener-3" translate>法人</label>
            <div class="c-accordion__body">
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="companyName" translate>会社名</label>
                <input class="c-form-input" type="text" name="companyName" id="companyName" value="株式会社ファイア">
              </div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="branchName" translate>支店名</label>
                <input class="c-form-input" type="text" name="branchName" id="branchName" value="四谷支店">
              </div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="departmentName" translate>部署名</label>
                <input class="c-form-input" type="text" name="departmentName" id="departmentName" value="営業部">
              </div>
            </div>
          </div>
          <div class="c-accordion">
            <input class="c-accordion__opener" type="checkbox" id="opener-4">
            <label class="c-accordion__head" for="opener-4" translate>メールアドレス</label>
            <div class="c-accordion__body">
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="email" translate>メールアドレス</label>
                <input class="c-form-input" type="text" name="email" id="email" value="abc@defg.jp">
              </div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="phone" translate>電話番号</label>
                <input class="c-form-input" type="text" name="phone" id="phone" value="090-1234-5678">
              </div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" translate>性別</label>
                <div class="c-form-radio">
                  <div class="c-form-radio__wrap">
                    <input type="radio" name="gender" value="male" id="male">
                    <label class="c-form-label" for="male" translate>男性</label>
                  </div>
                  <div class="c-form-radio__wrap">
                    <input type="radio" name="gender" value="woman" id="woman">
                    <label class="c-form-label" for="woman" translate>女性</label>
                  </div>
                  <div class="c-form-radio__wrap">
                    <input type="radio" name="gender" value="noSetting" id="noSetting">
                    <label class="c-form-label" for="noSetting" translate>設定しない</label>
                  </div>
                </div>
              </div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="birthday" translate>生年月日</label>
                <input class="c-form-input" type="text" name="birthday" id="birthday" value="2023年12月31日">
              </div>
            </div>
          </div>
          <div class="c-accordion">
            <input class="c-accordion__opener" type="checkbox" id="opener-5">
            <label class="c-accordion__head" for="opener-5" translate>コースmeta情報</label>
            <div class="c-accordion__body">
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="startDate" translate>受講開始日</label>
                <div class="c-form-display" id="startDate">2023年12月31日</div>
              </div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="payment" translate>支払日</label>
                <div class="c-form-display" id="payment">2023年12月31日</div>
              </div>
            </div>
          </div>
          <div class="c-accordion">
            <input class="c-accordion__opener" type="checkbox" id="opener-6">
            <label class="c-accordion__head" for="opener-6" translate>meta情報</label>
            <div class="c-accordion__body">
              <div class="c-accordion__body__row">
                <label class="c-form-label" translate>メール送信</label>
                <div class="c-form-radio">
                  <div class="c-form-radio__wrap">
                    <input type="radio" name="email-send" value="emailSend-OK" id="emailSend-OK">
                    <label class="c-form-label" for="emailSend-OK">OK</label>
                  </div>
                  <div class="c-form-radio__wrap">
                    <input type="radio" name="email-send" value="emailSend-NG" id="emailSend-NG">
                    <label class="c-form-label" for="emailSend-NG">NG</label>
                  </div>
                </div>
              </div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" translate>LINE連携状態</label>
                <div class="c-form-radio">
                  <div class="c-form-radio__wrap">
                    <input type="radio" name="line-alignment" value="cooperating" id="cooperating">
                    <label class="c-form-label" for="cooperating" translate>連携中</label>
                  </div>
                  <div class="c-form-radio__wrap">
                    <input type="radio" name="line-alignment" value="noCooperating" id="noCooperating">
                    <label class="c-form-label" for="noCooperating" translate>連携なし</label>
                  </div>
                </div>
              </div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" translate>LINE送信</label>
                <div class="c-form-radio">
                  <div class="c-form-radio__wrap">
                    <input type="radio" name="line-send" value="lineSend-OK" id="lineSend-OK">
                    <label class="c-form-label" for="lineSend-OK">OK</label>
                  </div>
                  <div class="c-form-radio__wrap">
                    <input type="radio" name="line-send" value="lineSend-NG" id="lineSend-NG">
                    <label class="c-form-label" for="lineSend-NG">NG</label>
                  </div>
                </div>
              </div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="registrationDate"translate>登録日</label>
                <div class="c-form-display" id="registrationDate">2023年12月31日</div>
              </div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="updateDate"translate>更新日</label>
                <div class="c-form-display" id="updateDate">2023年12月31日</div>
              </div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="lastAccessed"translate>最終アクセス日</label>
                <div class="c-form-display" id="lastAccessed">2023年12月31日</div>
              </div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="withdrawal"translate>退会日時</label>
                <div class="c-form-display" id="withdrawal">2023年12月31日</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-modal__main__control">
        <div class="c-modal__main__control__status"></div>
        <div class="c-modal__main__control__buttons">
          <div class="c-modal__main__control__buttons__button">
            <button
              class="c-button c-button-cancel"
              (click)="close()"
            ><span translate>キャンセル</span></button>
          </div>
          <div class="c-modal__main__control__buttons__button">
            <button
              class="c-button c-button-primary"
              (click)="save()"
            ><span translate>保存する</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>　
</section>
