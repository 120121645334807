import {createReducer, on, createSelector, createFeatureSelector} from '@ngrx/store';
import * as UiActions from './ui.actions';
export const sessionFeatureKey = 'ui';
export interface UiState {
  loading: boolean;
  showSideNavigation: boolean,
  showMenuInSideNavi: boolean,
  headerLogoSec: string,
  isNotFound: boolean;
  urlState: UrlState,
}
export class UrlState {
  channelId: string | null;
  courseId: string | null;
  slug: string | null;
  contentKey: string | null;
}
export const initialState: UiState = {
  loading: false,
  showSideNavigation: false,
  showMenuInSideNavi: false,
  headerLogoSec: null,
  isNotFound: false,
  urlState: {
    channelId: null,
    courseId: null,
    slug: null,
    contentKey: null,
  }
};
const _uiReducer = createReducer(
  initialState,
  on(UiActions.switchTrueShowSideNavigation, state => ({ ...state, showSideNavigation: true })),
  on(UiActions.switchFalseShowSideNavigation, state => ({ ...state, showSideNavigation: false })),
  on(UiActions.switchTrueShowMenuInSideNavi, state => ({ ...state, showMenuInSideNavi: true })),
  on(UiActions.switchFalseShowMenuInSideNavi, state => ({ ...state, showMenuInSideNavi: false })),
  on(UiActions.switchTrueLoading, state => ({ ...state, loading: true })),
  on(UiActions.switchFalseLoading, state => ({ ...state, loading: false })),
  on(UiActions.storeHeaderLogoSrc, (state, { payload }) => ({ ...state, headerLogoSec: payload })),
  on(UiActions.storeIsNotFound, (state, { payload }) => ({ ...state, isNotFound: payload })),
  on(UiActions.storeUrlState, (state, { payload }) => ({ ...state, urlState: payload })),
);
export function uiReducer(state: any, action: any): UiState {
  return _uiReducer(state, action);
}
const getState = createFeatureSelector<UiState>(sessionFeatureKey);
export const getLoaderState = createSelector(
  getState,
  state => state.loading
);
export const getSideNavi = createSelector(
  getState,
  state => state.showSideNavigation
);
export const getIsNotFoundState = createSelector(
  getState,
  state => state.isNotFound
);
export const getMenuSideNavi = createSelector(
  getState,
  state => state.showMenuInSideNavi
);
export const getUrlState = createSelector(
  getState,
  state => state.urlState
);
export const getHeaderLogoSrc = createSelector(
  getState,
  state => state.headerLogoSec
);
