import {FrameTypes} from "./course-frames";
import {AttendanceStatus} from './reservation';
import {ReportingStatus} from "./report-doc";

export class CourseProgress {
  id?: string;
  channelId: string;
  courseId?: string;
  uid: string;
  all: number;
  progresses: Progress[];
  completed?: boolean;
  completedAt?: Date;
  completedNumber?: string;
  createdAt: Date;
  updatedAt: Date;
  createdBy: string;
  updatedBy: string;
  constructor() {
    this.id = null;
    this.channelId = null;
    this.uid = null;
    this.all = 0;
    this.progresses = [];
    this.completed = false;
    this.completedAt = null;
  }
}
export class Progress {
  contentKey: string;
  timeFrameKey: string;
  frameType: FrameTypes;
  progress: number;
  fragment: ContentFragment[];
  reportResult: ReportingStatus;
  attendance: AttendanceStatus;
  isFileDownloaded: boolean;
  constructor() {
    this.contentKey = undefined;
    this.timeFrameKey = undefined;
    this.frameType = undefined;
    this.progress = 0;
    this.fragment = [];
    this.reportResult = undefined;
    this.attendance = undefined;
  }
}
export class ContentFragment {
  viewed: boolean;
  pointSec: number
  constructor() {
    this.viewed = false;
    this.pointSec = 0;
  }
}
