<app-header-contents-channel></app-header-contents-channel>
<!--<app-not-found *ngIf="isNotFound"></app-not-found>-->
<div class="l-content-front">
  <app-breadcrumb-front></app-breadcrumb-front>
  <div class="l-content-front__inner">
    <div class="l-top">

      <ng-container *ngIf="questionIndex === -1">
        <!--テスト開始画面-->
        <div class="p-course__examination">
          <div class="p-course__examination__head">
            <div class="p-course__examination__head__title">{{examFrames?.name}}</div>
            <div class="p-course__examination__head__sub">
              {{examFrames?.examinationFrame?.questions?.length}}<span translate>問</span>　
              {{examFrames?.examinationFrame?.timeLimit}}<span translate>分</span>　<span translate>正解率</span>
              {{examFrames?.examinationFrame?.passThreshold}}<span translate>%以上で合格</span>
            </div>
          </div>
          <div class="p-course__examination__introduction">
            <div class="p-course__examination__introduction__textBody" [innerHTML]="examFrames?.note | nl2br"></div>
          </div>
          <div class="p-course__examination__buttons">
            <div class="p-course__examination__buttons__button">
              <button (click)="backToIndex()" class="c-button c-button-cancel"><span translate>キャンセル</span></button>
            </div>
            <div class="p-course__examination__buttons__button">
              <button (click)="clickNextQuestion()" class="c-button c-button-primary"><span translate>テストを開始する</span></button>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="questionIndex > -1 && questionIndex < examFrames?.examinationFrame?.questions?.length">
        <!--テスト画面-->
        <div class="p-course__examination">
          <div class="p-course__examination__head">
            <div class="p-course__examination__head__title">{{examFrames?.name}}</div>
            <div class="p-course__examination__head__sub">
              {{examFrames?.examinationFrame?.questions?.length}}<span translate>問</span>　
              {{examFrames?.examinationFrame?.timeLimit}}<span translate>分</span>　<span translate>正解率</span>
              {{examFrames?.examinationFrame?.passThreshold}}<span translate>%以上で合格</span>
            </div>
          </div>
          <div class="p-course__examination__question">
            <div class="p-course__examination__question__textBody"
                 [innerHTML]="examFrames?.examinationFrame?.questions[questionIndex]?.title | nl2br">
            </div>
            <div class="p-course__examination__question__answer">
              <div *ngIf="currentQuestion?.image?.src" class="p-questions__item__imageWrap">

                <div class="p-questions__item__imageWrap__head">
                  <img
                    src="{{getImageUrl(currentQuestion?.image?.src)}}"
                    width="1280"
                    height="720"
                    alt="">
                </div>

              </div>
              <div *ngIf="examFrames?.examinationFrame?.questions[questionIndex]?.body" class="p-questions__item__detail" [innerHTML]="examFrames?.examinationFrame?.questions[questionIndex]?.body | nl2br"></div>

              <div *ngIf="examFrames?.examinationFrame?.questions[questionIndex].type === 'radio'" class="p-examination-radio">
                <ng-container *ngFor="let option of currentQuestion?.selectOptions; index as optionIndex">
                  <div class="p-examination-radio__wrap">
                    <input
                      *ngIf="forms[questionIndex]"
                      [formControl]="forms[questionIndex]"
                      [checked]="latestAnswers?.answers[questionIndex]?.answer[0] === option?.name"
                      (change)="answerUpdate(currentQuestion.contentKey, option?.name, $event)"
                      type="radio" name="answer-{{questionIndex}}" [value]="option?.name" id="answer-{{questionIndex}}-{{optionIndex}}">
                    <label class="c-form-label" for="answer-{{questionIndex}}-{{optionIndex}}">{{option.name}}</label>
                  </div>
                </ng-container>
              </div>

              <div *ngIf="examFrames?.examinationFrame?.questions[questionIndex].type === 'check'" class="p-examination-check">
                <ng-container *ngFor="let option of currentQuestion?.selectOptions; index as optionIndex">
                  <div class="p-examination-check__wrap">
                    <input
                      [checked]="checkboxIsChecked(questionIndex, option?.name)"
                      (change)="answerUpdate(currentQuestion.contentKey, option?.name, $event)"
                      type="checkbox" name="answer-{{questionIndex}}" [value]="option?.name" id="answer-{{questionIndex}}-{{optionIndex}}">
                    <label for="answer-{{questionIndex}}-{{optionIndex}}">{{option?.name}}</label>
                  </div>
                </ng-container>
              </div>

              <div *ngIf="examFrames?.examinationFrame?.questions[questionIndex]?.note" class="p-questions__item__note" [innerHTML]="examFrames?.examinationFrame?.questions[questionIndex]?.note | nl2br"></div>

            </div>
          </div>
          <div class="p-course__examination__buttons">
            <div class="p-course__examination__buttons__button">
              <button (click)="clickPreviousQuestion()" class="c-button c-button-cancel"><span translate>前の問題へ</span></button>
            </div>
            <div class="p-course__examination__buttons__button">
              <button (click)="clickNextQuestion()" class="c-button c-button-primary"><span translate>次の問題へ</span></button>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="questionIndex === examFrames?.examinationFrame?.questions?.length">
        <!--テスト結果-->
        <div class="p-course__examination">
          <div class="p-course__examination__head">
            <div class="p-course__examination__head__title">{{examFrames?.name}}</div>
            <div class="p-course__examination__head__sub">
              {{examFrames?.examinationFrame?.questions?.length}}<span translate>問</span>　
              {{examFrames?.examinationFrame?.timeLimit}}<span translate>分</span>　<span translate>正解率</span>
              {{examFrames?.examinationFrame?.passThreshold}}<span translate>%以上で合格</span>
            </div>
          </div>
          <div class="p-course__examination__result">
            <div class="p-course__examination__result__graph">
              <canvas id="resultChart"></canvas>
            </div>
            <div class="p-course__examination__result__body">
              <div class="p-course__examination__result__body__headline" *ngIf="getCorrectRate() >= 100">PERFECT!!</div>
              <div class="p-course__examination__result__body__headline" *ngIf="getCorrectRate() < 100 && getCorrectRate() >= examFrames?.examinationFrame?.passThreshold">GREAT!!</div>
              <div class="p-course__examination__result__body__headline" *ngIf="getCorrectRate() < examFrames?.examinationFrame?.passThreshold">NICE TRY!!</div>
              <div class="p-course__examination__result__body__subhead" *ngIf="getCorrectRate() <= 100 && getCorrectRate() >= examFrames?.examinationFrame?.passThreshold">
                <span><span translate>合格</span>({{examFrames?.examinationFrame?.passThreshold}}<span translate>％以上で合格</span>）</span></div>
              <div class="p-course__examination__result__body__subhead" *ngIf="getCorrectRate() < examFrames?.examinationFrame?.passThreshold">
                <span><span translate>不合格</span>({{examFrames?.examinationFrame?.passThreshold}}<span translate>％以上で合格</span>）</span></div>
              <div class="p-course__examination__result__body__correctAnswers">
                <div class="p-course__examination__result__body__correctAnswers__percentage">{{getCorrectRate()}}%</div>
                <div class="p-course__examination__result__body__correctAnswers__ratio"><span translate>正解</span>({{getNumOfCorrect()}}/{{examFrames?.examinationFrame?.questions?.length}})</div>
              </div>
              <div class="p-course__examination__result__body__time">{{latestAnswers?.updatedAt | datetimeFormatFirestore}}</div>
              <div class="p-course__examination__result__body__button">
                <button (click)="clickShowExplain()" class="c-button c-button-monotone"><span translate>解説画面を見る</span></button>
              </div>
            </div>
          </div>
          <div class="p-course__examination__buttons">
            <div class="p-course__examination__buttons__button">
              <button (click)="clickRewindQuestion()" class="c-button c-button-cancel"><span translate>再テスト</span></button>
            </div>
            <div class="p-course__examination__buttons__button">
              <button (click)="backToIndex()" class="c-button c-button-primary"><span translate>コース目次へ</span></button>
            </div>
          </div>
        </div>
      </ng-container>






      <!--<ng-container>
        // アンケート結果 OEM
        <div class="p-course__examination">
          <div class="p-course__examination__head">
            <div class="p-course__examination__head__title">認定要件の問診票</div>
            <div class="p-course__examination__head__sub">20問　10分　○○○○●○○○○●</div>
          </div>
          <div class="p-course__examination__result">
            <div class="p-course__examination__result__body">
              <div class="p-course__examination__result__body__correctAnswers">
                <div class="p-course__examination__result__body__correctAnswers__ratio-oem">7/20</div>
              </div>
              <div class="p-course__examination__result__body__time-oem">00:00　2023/6/6 00:00</div>
              <div class="p-course__examination__result__body__graphLegend">
                <div class="p-course__examination__result__body__graphLegend__item p-course__examination__result__body__graphLegend__item-correct">正解</div>
                <div class="p-course__examination__result__body__graphLegend__item p-course__examination__result__body__graphLegend__item-incorrect">不正解</div>
                <div class="p-course__examination__result__body__graphLegend__item p-course__examination__result__body__graphLegend__item-noAnswer">未回答</div>
              </div>
              <div class="p-course__examination__result__body__button">
                <button class="c-button c-button-monotone">解説画面を見る</button>
              </div>
            </div>
          </div>
          <div class="p-course__examination__buttons">
            <div class="p-course__examination__buttons__button">
              <button class="c-button c-button-cancel">再テスト</button>
            </div>
            <div class="p-course__examination__buttons__button">
              <button class="c-button c-button-primary">コース詳細へ</button>
            </div>
          </div>
        </div>
      </ng-container>-->


      <ng-container *ngIf="questionIndex === -2">
        <!--テスト解説画面-->
        <div class="p-course__examination">
          <div class="p-course__examination__head">
            <div class="p-course__examination__head__title">{{examFrames?.name}}</div>
            <div class="p-course__examination__head__sub">
              {{examFrames?.examinationFrame?.questions.length}}<span translate>問</span>　
              {{examFrames?.examinationFrame?.timeLimit}}<span translate>分</span>　<span translate>正解率</span>
              {{examFrames?.examinationFrame?.passThreshold}}<span translate>%以上で合格</span>
            </div>
          </div>
          <div class="p-course__examination__explanation">
            <div class="p-course__examination__explanation__head">
              <div class="p-course__examination__explanation__head__graph">
                <canvas id="resultChartMini"></canvas>
              </div>
              <div class="p-course__examination__explanation__head__textBody" *ngIf="getCorrectRate() <= 100 && getCorrectRate() >= examFrames?.examinationFrame?.passThreshold">
                <span><span translate>合格</span>({{examFrames?.examinationFrame?.passThreshold}}<span translate>％以上で合格</span>）</span>
              </div>
              <div class="p-course__examination__explanation__head__textBody" *ngIf="getCorrectRate() < examFrames?.examinationFrame?.passThreshold">
                <span><span translate>不合格</span>({{examFrames?.examinationFrame?.passThreshold}}<span translate>％以上で合格</span>）</span>
              </div>
            </div>

            <div class="p-course__examination__explanation__body">
              <ng-container *ngFor="let q of examFrames?.examinationFrame?.questions; index as qIndex">
                <div class="p-course__examination__explanation__body__item">
                  <div class="p-explanation">
                    <div class="p-explanation__title">{{q.title}}</div>
                    <div class="p-explanation__status p-explanation__status-noAnswer" *ngIf="isNotAnswered(qIndex)" translate>未回答</div>
                    <div class="p-explanation__status p-explanation__status-correct" *ngIf="latestAnswers?.answers[qIndex]?.isCorrect" translate>正解</div>
                    <div class="p-explanation__status p-explanation__status-incorrect" *ngIf="isIncorrect(qIndex)" translate>不正解</div>
                    <div class="p-explanation__choices">
                      <ng-container *ngFor="let s of q?.selectOptions; index as oIndex">
                        <div class="p-explanation__choices__item"
                             [class]="getClassNameForSelectOption(qIndex, s?.name)"
                        >{{s.name}}</div>
                      </ng-container>
                    </div>
                    <div class="p-explanation__detail">
                      <div class="p-explanation__title" translate>解説</div>
                      <div class="p-explanation__detail__textBody" [innerHTML]="q.incorrectNote | nl2br"></div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="p-course__examination__buttons">
            <div class="p-course__examination__buttons__button">
              <button (click)="clickRewindQuestion()" class="c-button c-button-cancel"><span translate>再テスト</span></button>
            </div>
            <div class="p-course__examination__buttons__button">
              <button (click)="backToFinished()" class="c-button c-button-primary"><span translate>結果表示に戻る</span></button>
            </div>
          </div>
        </div>
      </ng-container>

    </div>
  </div>

  <ng-container>
    <div *ngIf="questionIndex > -1 && questionIndex < examFrames?.examinationFrame?.questions.length" class="p-course__examination__timer">
      <div class="p-course__examination__timer__inner">
        <div class="p-course__examination__timer__inner__page">{{questionIndex + 1}}/{{examFrames?.examinationFrame?.questions.length}}</div>
        <div class="p-course__examination__timer__inner__bar">
          <div class="p-progress p-progress-h18px">
            <div class="p-progress__bar">
              <div class="p-progress__bar__bg"></div>
              <div class="p-progress__bar__body" [style]="getProgressStyle()"></div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="isUseTimeProgress">
          <div class="p-course__examination__timer__inner__time">{{minutes}}:{{second}}</div>
          <div class="p-course__examination__timer__inner__pause">
            <button *ngIf="isStartTimer" (click)="clickPause()" class="c-button c-button-pause"></button>
            <button *ngIf="!isStartTimer" (click)="clickRestart()" class="c-button c-button-play"></button>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>


<app-footer></app-footer>
