<div class="l-main">
  <app-navigations></app-navigations>
  <div class="l-content">
    <app-breadcrumb></app-breadcrumb>
    <div class="l-content__inner">
      <div class="c-content-header">
        <div class="c-content-header__title">
          講座　公開審査結果通知
        </div>
      </div>
      <div class="l-templates">
        <div class="p-templates">
          <div class="p-templates__content">
            <div class="p-inspection">
              <div class="p-inspection__message">
                <div class="p-inspection__message__heading">現在審査中です。</div>
                <div class="p-inspection__message__detail">
                  審査完了後、ご登録のメールアドレスに結果をお送りいたします。（仮）<br>審査完了後、ご登録のメールアドレスに結果をお送りいたします。（仮）
                </div>
              </div>
              <div class="p-inspection__result">
                <div class="p-inspection__result__course">
                  <div class="c-card-courseInspection">
                    <div class="c-card-courseInspection__thumb">
                      <img src="assets/images/sampleImage_slides_01.png" alt="">
                    </div>
                    <div class="c-card-courseInspection__title">損しないための社会人マナー損しないための社会人マナー</div>
                    <div class="c-card-courseInspection__meta">提出日:2023年10月12日</div>
                  </div>
                </div>
                <div class="p-inspection__result__feedback">
                  <div class="c-list-feedback">
                    <div class="c-list-feedback__title">フィードバック内容</div>
                    <div class="c-list-feedback__head">
                      <div class="c-list-feedback__head__title">項目</div>
                      <div class="c-list-feedback__head__detail">詳細</div>
                    </div>
                    <div class="c-list-feedback__body">
                      <div class="c-list-feedback__body__row">
                        <div class="c-list-feedback__body__row__title">名無し〇〇〇</div>
                        <div class="c-list-feedback__body__row__detail">〇〇に問題があります。〇〇に問題があります。〇〇に問題があります。〇〇に問題があります。〇〇に問題があります。〇〇に問題があります。〇〇に問題があります。</div>
                        <div class="c-list-feedback__body__row__select c-list-feedback__body__row__select-selected">
                          <select>
                            <option value="">選択してください</option>
                            <option value="">修正済み</option>
                          </select>
                        </div>
                      </div>
                      <div class="c-list-feedback__body__row">
                        <div class="c-list-feedback__body__row__title">名無し〇〇〇</div>
                        <div class="c-list-feedback__body__row__detail">〇〇に問題があります。〇〇に問題があります。〇〇に問題があります。〇〇に問題があります。〇〇に問題があります。〇〇に問題があります。〇〇に問題があります。</div>
                        <div class="c-list-feedback__body__row__select">
                          <select>
                            <option value="">選択してください</option>
                            <option value="">修正済み</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-inspection__buttons">
                <div class="p-inspection__buttons__button">
                  <button class="c-button c-button-primary">審査を申し込む</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

