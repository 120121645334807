<app-header-contents-channel></app-header-contents-channel>
<div class="l-content-front">
  <app-breadcrumb-front></app-breadcrumb-front>
  <div class="l-content-front__inner">
    <div class="c-content-header">
      <div class="c-content-header__title">
        ユーザーアカウント削除
      </div>
    </div>
    <div class="l-myPage">
      <div class="p-myPage">
        <div class="p-myPage__notes">
          <p>・注意事項注意事項注意事項注意事項注意事項注意</p>
          <p>・注意事項注意事項注意事項注意事項注意事項注意</p>
          <p>・注意事項注意事項注意事項注意事項注意事項注意</p>
        </div>
        <div class="p-myPage__warning">
          この操作は、取り消すことができません。
        </div>
        <div class="p-myPage__buttons">
          <div class="p-myPage__buttons__button">
            <button class="c-button c-button-cancel">キャンセルして戻る</button>
          </div>
          <div class="p-myPage__buttons__button">
            <button class="c-button c-button-warning">ユーザーアカウントを削除する</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
