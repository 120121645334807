import { Component, OnInit } from '@angular/core';
import {Store} from "@ngrx/store";
import {AuthService} from "../../services/auth/auth.service";
import {Router} from "@angular/router";
import {storeIdToken, storeUser} from "../../store/sessions/sessions.actions";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private sessionsStore: Store<{ sessions: any}>,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }
  logout(): void {
    this.authService.logout().then(() => {
      this.sessionsStore.dispatch(storeUser({currentUser: undefined}));
      this.sessionsStore.dispatch(storeIdToken({ idToken: undefined}));
      this.router.navigate(['/']);
    });
  }
}
