<section class="l-modalTopLayer" *ngIf="isOpenModal">
  <div class="c-modal">
    <div class="c-modal__head">
      <div class="c-modal__head__title" translate>画像の送信</div>
      <div class="c-modal__head__closeButton" (click)="closeModal()"></div>
    </div>
    <div class="c-modal__documentUpload">
      <input
        (change)="upload($event)"
        type="file" id="uploadFile">
      <label
        for="uploadFile"
        class="c-modal__documentUpload__description"
        translate
      >ファイルを選択</label>
      <div class="c-modal__documentUpload__uploadButton">
        <button
          *ngIf="!uploading"
          (click)="clickUploadFile('uploadFile')"
          type="button"
          class="c-button c-button-addContent"
        ><span translate>ファイルを選択</span></button>
        <mat-progress-bar
          *ngIf="uploading"
          mode="determinate" value="{{progress * 100}}"></mat-progress-bar>
        <div *ngIf="converting" translate>コンバートしています...</div>
      </div>
    </div>
  </div>
</section>
