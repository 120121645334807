<ng-container
  *ngIf="isOpenSearchModal"
>
  <div class="l-searchModal">
    <div
      class="p-searchModal">
      <div class="p-searchModal__searchForm">
        <div class="p-searchModal__searchForm__form">
          <label for="searchKeywords" translate>検索キーワード</label>
          <div class="p-searchModal__searchForm__form__input">
            <input
              id="searchKeywords"
              type="text"
              [placeholder]="'学びたいことを検索してみよう' | translate"
            >
          </div>
        </div>
      </div>
      <div class="p-searchModal__result">
        <div class="p-searchModal__result__title" translate>
          検索結果
        </div>
        <div class="p-searchModal__result__items">
          <div class="c-courseList">
            <div class="c-courseList__item">
              <div class="c-courseList__item__thumb">
                <img src="/assets/images/courseThumb_01.jpg" alt="">
              </div>
              <div class="c-courseList__item__title">
                個人情報を学ぼう！令和版
              </div>
              <div class="c-courseList__item__description">
                <p>講座説明：</p>
                descriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescription
              </div>
            </div>
            <div class="c-courseList__item">
              <div class="c-courseList__item__thumb">
                <img src="/assets/images/courseThumb_01.jpg" alt="">
              </div>
              <div class="c-courseList__item__title">
                個人情報を学ぼう！令和版
              </div>
              <div class="c-courseList__item__description">
                <p>講座説明：</p>
                descriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescription
              </div>
            </div>
            <div class="c-courseList__item">
              <div class="c-courseList__item__thumb">
                <img src="/assets/images/courseThumb_01.jpg" alt="">
              </div>
              <div class="c-courseList__item__title">
                個人情報を学ぼう！令和版
              </div>
              <div class="c-courseList__item__description">
                <p>講座説明：</p>
                descriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescription
              </div>
            </div>
          </div>
          <div class="p-searchModal__result__items__more">
            <button
              type="button"
              class="c-button c-button-apply"
            ><span translate>さらに読み込む</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
