<div class="l-main">
  <app-navigations></app-navigations>
  <div class="l-content">
    <app-breadcrumb></app-breadcrumb>
    <div class="l-content__inner">
      <div class="c-content-header">
        <div class="c-content-header__title">
          オンライン講義予約管理
        </div>
      </div>
      <div class="l-templates">
        <div class="p-templates">
          <div class="p-templates__content">
            <div class="c-card-reserve">
              <input class="c-card-reserve__opener" type="checkbox" id="reserve1">
              <label class="c-card-reserve__head" for="reserve1">
                <div class="c-card-reserve__head__name">コース名コース名コース名コース名</div>
                <div class="c-card-reserve__head__title">講義タイトル</div>
                <div class="c-card-reserve__head__startTime">2023年01月10日(火) 17:00 開始</div>
                <div class="c-card-reserve__head__status">公開中</div>
              </label>
              <div class="c-card-reserve__participants">
                <div class="c-card-reserve__participants__head">
                  <div class="c-card-reserve__participants__head__name">ニックネーム</div>
                  <div class="c-card-reserve__participants__head__info">情報</div>
                  <div class="c-card-reserve__participants__head__attendance">出欠状況</div>
                </div>
                <div class="c-card-reserve__participants__row">
                  <div class="c-card-reserve__participants__row__name">名無し〇〇〇</div>
                  <div class="c-card-reserve__participants__row__info">〇〇〇〇●〇〇〇〇●〇〇〇〇〇〇〇〇●〇〇〇〇●〇〇〇〇</div>
                  <div class="c-card-reserve__participants__row__attendance c-card-reserve__participants__row__attendance-attend"></div>
                </div>
                <div class="c-card-reserve__participants__row">
                  <div class="c-card-reserve__participants__row__name">名無し〇〇〇</div>
                  <div class="c-card-reserve__participants__row__info">〇〇〇〇●〇〇〇〇●〇〇〇〇〇〇〇〇●〇〇〇〇●〇〇〇〇</div>
                  <div class="c-card-reserve__participants__row__attendance"></div>
                </div>
              </div>
            </div>
            <div class="c-card-reserve">
              <input class="c-card-reserve__opener" type="checkbox" id="reserve2">
              <label class="c-card-reserve__head" for="reserve2">
                <div class="c-card-reserve__head__name">コース名コース名コース名コース名コース名コース名コース名コース名</div>
                <div class="c-card-reserve__head__title">講義タイトル</div>
                <div class="c-card-reserve__head__startTime">2023年01月10日(火) 17:00 開始</div>
                <div class="c-card-reserve__head__status">公開中</div>
              </label>
              <div class="c-card-reserve__participants">
                <div class="c-card-reserve__participants__head">
                  <div class="c-card-reserve__participants__head__name">ニックネーム</div>
                  <div class="c-card-reserve__participants__head__info">情報</div>
                  <div class="c-card-reserve__participants__head__attendance">出欠状況</div>
                </div>
                <div class="c-card-reserve__participants__row">
                  <div class="c-card-reserve__participants__row__name">名無し〇〇〇</div>
                  <div class="c-card-reserve__participants__row__info">〇〇〇〇●〇〇〇〇●〇〇〇〇〇〇〇〇●〇〇〇〇●〇〇〇〇</div>
                  <div class="c-card-reserve__participants__row__attendance c-card-reserve__participants__row__attendance-attend"></div>
                </div>
                <div class="c-card-reserve__participants__row">
                  <div class="c-card-reserve__participants__row__name">名無し〇〇〇</div>
                  <div class="c-card-reserve__participants__row__info">〇〇〇〇●〇〇〇〇●〇〇〇〇〇〇〇〇●〇〇〇〇●〇〇〇〇</div>
                  <div class="c-card-reserve__participants__row__attendance"></div>
                </div>
                <div class="c-card-reserve__participants__row">
                  <div class="c-card-reserve__participants__row__name">名無し〇〇〇</div>
                  <div class="c-card-reserve__participants__row__info">〇〇〇〇●〇〇〇〇●〇〇〇〇〇〇〇〇●〇〇〇〇●〇〇〇〇</div>
                  <div class="c-card-reserve__participants__row__attendance"></div>
                </div>
                <div class="c-card-reserve__participants__row">
                  <div class="c-card-reserve__participants__row__name">名無し〇〇〇</div>
                  <div class="c-card-reserve__participants__row__info">〇〇〇〇●〇〇〇〇●〇〇〇〇〇〇〇〇●〇〇〇〇●〇〇〇〇</div>
                  <div class="c-card-reserve__participants__row__attendance"></div>
                </div>
                <div class="c-card-reserve__participants__row">
                  <div class="c-card-reserve__participants__row__name">名無し〇〇〇</div>
                  <div class="c-card-reserve__participants__row__info">〇〇〇〇●〇〇〇〇●〇〇〇〇〇〇〇〇●〇〇〇〇●〇〇〇〇</div>
                  <div class="c-card-reserve__participants__row__attendance"></div>
                </div>
              </div>
            </div>
            <div class="c-card-reserve">
              <input class="c-card-reserve__opener" type="checkbox" id="reserve3">
              <label class="c-card-reserve__head" for="reserve3">
                <div class="c-card-reserve__head__name">コース名コース名コース名コース名</div>
                <div class="c-card-reserve__head__title">講義タイトル</div>
                <div class="c-card-reserve__head__startTime">2023年01月10日(火) 17:00 開始</div>
                <div class="c-card-reserve__head__status">公開中</div>
              </label>
              <div class="c-card-reserve__participants">
                <div class="c-card-reserve__participants__head">
                  <div class="c-card-reserve__participants__head__name">ニックネーム</div>
                  <div class="c-card-reserve__participants__head__info">情報</div>
                  <div class="c-card-reserve__participants__head__attendance">出欠状況</div>
                </div>
                <div class="c-card-reserve__participants__row">
                  <div class="c-card-reserve__participants__row__name">名無し〇〇〇</div>
                  <div class="c-card-reserve__participants__row__info">〇〇〇〇●〇〇〇〇●〇〇〇〇〇〇〇〇●〇〇〇〇●〇〇〇〇</div>
                  <div class="c-card-reserve__participants__row__attendance c-card-reserve__participants__row__attendance-attend"></div>
                </div>
                <div class="c-card-reserve__participants__row">
                  <div class="c-card-reserve__participants__row__name">名無し〇〇〇</div>
                  <div class="c-card-reserve__participants__row__info">〇〇〇〇●〇〇〇〇●〇〇〇〇〇〇〇〇●〇〇〇〇●〇〇〇〇</div>
                  <div class="c-card-reserve__participants__row__attendance"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
