import { Component, OnInit } from '@angular/core';
import {ToastrService} from "ngx-toastr";
import firebase from "firebase/compat";
import ActionCodeSettings = firebase.auth.ActionCodeSettings;
import {environment} from "../../../environments/environment";
import {AuthService} from "../../services/auth/auth.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-email-verify',
  templateUrl: './email-verify.component.html',
  styleUrls: ['./email-verify.component.scss']
})
export class EmailVerifyComponent implements OnInit {
  showError = false;
  email: string;
  send: boolean = false;
  constructor(
    private toaster: ToastrService,
    private authService: AuthService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
  }
  async onSubmit(): Promise<void> {
    const action: ActionCodeSettings = {
      url: environment.hostName,
      handleCodeInApp: false,
    }
    const auth = this.authService.currentAuth;
    auth.sendEmailVerification()
      .then(() => {
        this.send = true;
        this.toaster.success(this.translateService.instant('有効性確認メールで送信しました。'));
      })
      .catch((e) => {
        this.toaster.warning(this.translateService.instant('有効性確認メールを送信出来ませんでした。'));
        console.log(e);
      })
  }
}
