export class Config {
  lineChannelId: string;
  lineAccessToken: string;
  lineChannelSecret: string;
  lineFollowUrl: string;
  constructor() {
    this.lineAccessToken = '';
    this.lineChannelId = '';
    this.lineChannelSecret = '';
    this.lineFollowUrl = '';
  }
  deserialize(): any {
    const json = JSON.stringify(this)
    return JSON.parse(json)
  }
}
