import { Injectable } from '@angular/core';
import {BackendService} from "../backend/backend.service";
import {environment} from "../../../environments/environment";
import {SlideUpload} from "../../models/slide-upload";
import {VideoFrame} from "../../models/course-frames";
import {VideoUpload} from "../../models/video-upload";

@Injectable({
  providedIn: 'root'
})
export class AudioUploaderService {
  videoStatus: VideoUpload;
  constructor(
    private backendService: BackendService,
  ) { }
  async convertRequest(
    accountId: string,
    idToken: string,
    contentKey: string,
    fileName: string,
    objectNamePath: string,
    destinationPath: string,
  ): Promise<void> {
    this.videoStatus = new VideoUpload();
    this.backendService.setIdToken(idToken);
    const data = {
      accountId: accountId,
      storageBucket: environment.firebase.storageBucket,
      contentKey: contentKey,
      fileName: fileName,
      filePath: objectNamePath,
      destinationPath: destinationPath,
    }
    try {
      this.videoStatus = await this.backendService.post('converter/audio/to_hls', data);
    } catch (e) {
      console.log('convertRequest to_hls error');
    }
  }
  async removeAudio(
    accountId: string,
    idToken: string,
    frame: VideoFrame,
    storagePath: string,
  ): Promise<void> {
    const data = {
      accountId: accountId,
      storageBucket: environment.firebase.storageBucket,
      frame: frame,
      storagePath: storagePath,
    };
    this.backendService.setIdToken(idToken);
    await this.backendService.post('converter/audio/remove', data);
  }
}
