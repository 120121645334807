import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {getLoaderState, UiState} from "./store/ui/ui.reducer";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  showLoading: boolean;
  private uiSubscription: Subscription;
  isSaving: boolean = false;
  constructor(
    private uiStore: Store<{ ui: UiState}>,
  ) {}
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.uiSubscription = this.uiStore.select(getLoaderState).pipe().subscribe(async (state) => {
      this.showLoading = state;
    })
  }
  ngOnDestroy(): void{
    if (this.uiSubscription) {
      this.uiSubscription.unsubscribe();
    }
  }
}
