<div class="l-footer">
  <div class="p-footer">
    <div class="p-footer__left">
      <div class="p-footer__bg"></div>
      <div class="p-footer__left__logo"></div>
      <div class="p-footer__left__nav">
        <ul class="p-footer__left__nav__inner">
          <li><a
            href="https://www.grab-design.com/"
            class="p-footer__left__nav__inner__link"
            translate
          >運営会社</a></li>
          <li><a
            href="https://form.run/embed/@grab-design?embed=direct"
            class="p-footer__left__nav__inner__link"
            translate
          >お問い合わせ</a></li>
          <li><a
            href="https://www.grab-design.com/security/"
            class="p-footer__left__nav__inner__link"
            translate
          >情報セキュリティ方針</a></li>
          <li><a
            href="https://www.grab-design.com/privacy/"
            class="p-footer__left__nav__inner__link"
            translate
          >プライバシーポリシー</a></li>
          <li><button
            class="p-footer__left__nav__inner__link p-footer__left__nav__inner__link-noLink"
          ><span translate>事業再構築補助金</span></button></li>
        </ul>
        <ul class="p-footer__left__nav__inner">
          <li><button
            routerLink="/contracts"
            class="p-footer__left__nav__inner__link"
          ><span translate>利用規約</span></button></li>
          <li><button
            routerLink="/business-contract"
            class="p-footer__left__nav__inner__link"
          ><span translate>特定商取引法に基づく表記</span></button></li>
          <li><button
            routerLink="/system-requirements"
            class="p-footer__left__nav__inner__link"
          ><span translate>推奨動作環境</span></button></li>
          <li><button
            routerLink="/slo"
            class="p-footer__left__nav__inner__link"
          ><span translate>サービスレベルSLO</span></button></li>
        </ul>
      </div>
      <div class="p-footer__left__copyRight">©GRAB DESIGN INC.</div>
    </div>
    <div class="p-footer__right">
      <div class="p-footer__right__bg"></div>
      <div class="p-footer__right__logoInner">
        <div class="p-footer__right__logoInner__ANAB"></div>
        <div class="p-footer__right__logoInner__EQA"></div>
      </div>
      <div class="p-footer__right__certification">
        <div class="p-footer__right__certification__item">株式会社グラブデザインは<span><br></span>ISMS認証を取得しています</div>
        <div class="p-footer__right__certification__item">ISO/IEC 27001:2013(ISMS)<span><br></span>認証登録番号：IA 210319</div>
      </div>
    </div>
  </div>
</div>
