export enum unreadCategories {
  inquiry = 'inquiry',
  chat = 'chat',
  discussion = 'discussion',
  courseApply = 'courseApply',
  test = 'test',
  report = 'report',
  survey = 'survey'
}
export class Unread {
  id?: string;
  uid: string;
  category: unreadCategories;
  accountId: string;
  channelId: string;
  courseId: string;
  body: string;
  read: boolean;
  createdAt: Date;
  constructor() {
    this.id = null;
    this.uid = null;
    this.category = null;
    this.accountId = null;
    this.channelId = null;
    this.courseId = null;
    this.body = null;
    this.read = false;
    this.createdAt = new Date();
  }
}
