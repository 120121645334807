<div class="l-top">
  <div class="p-top">
    <div class="p-top__notfound">
      <h2 class="p-top__notfound__statusCode">403</h2>
      <p class="p-top__notfound__title">forbidden</p>
      <p class="p-top__notfound__detail">アカウント所有者の設定によりアクセスが許可されていません。<br>
      ブラウザの戻るボタンを押して前のページに戻り、URLのご提供者にご確認ください。
      <div class="p-top__notfound__button">
        <button
          class="c-button c-button-returnTop"
          routerLink="/"
        >TOPに戻る</button>
      </div>
    </div>
  </div>
</div>
