import { Component } from '@angular/core';

@Component({
  selector: 'app-manager-channel-payment',
  templateUrl: './manager-channel-payment.component.html',
  styleUrls: ['./manager-channel-payment.component.scss']
})
export class ManagerChannelPaymentComponent {

}
