<div
  *ngIf="!emailVerified"
  class="l-notice">
  <div class="c-notice c-notice-warning">
    <div class="c-notice__bell"></div>
    <div class="c-notice__body">
      <div>
        <span translate>Emailアドレスの有効性確認をお願いしています。</span>
        <a routerLink="/my-page/email-verify" translate>こちら</a>
        <span translate>から確認メールを送信して認証を完了させてください。</span>
    </div>
    </div>
    <div class="c-notice__image"></div>
  </div>
</div>


