import {
  TitleStrategy,
  RouterStateSnapshot,
} from '@angular/router';
import {Injectable} from "@angular/core";
import { Title } from '@angular/platform-browser';
import {environment} from "../environments/environment";

@Injectable()
export class AppTitleStrategy extends TitleStrategy {
  constructor(private titleService: Title) {
    super();
  }
  override updateTitle(snapshot: RouterStateSnapshot) {
    const title = this.buildTitle(snapshot);
    this.titleService.setTitle(title ? `${environment.envName} ${title}` : environment.siteName);
  }
}
