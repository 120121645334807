import {Component, OnInit} from '@angular/core';
import {Channel} from "../../models/channel";
import {ActivatedRoute} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {AuthService} from "../../services/auth/auth.service";
import {Store} from "@ngrx/store";
import {UiState, UrlState} from "../../store/ui/ui.reducer";
import {CourseProgressesService} from "../../services/course-progresses/course-progresses.service";
import {ChannelsService} from "../../services/channels/channels.service";
import {CoursesService} from "../../services/courses/courses.service";
import {Course} from "../../models/course";
import {
  storeUrlState,
  switchFalseLoading,
  switchTrueLoading
} from "../../store/ui/ui.actions";
import {environment} from "../../../environments/environment";
import {emailAddMyChannel, emailOnlineReserved, EmailSender, emailSignature} from "../../models/emails";
import {BackendService} from "../../services/backend/backend.service";
import {ChannelSubscriptionsService} from "../../services/channel-subscriptions/channel-subscriptions.service";
import {ChannelSubscription} from "../../models/channel-subscription";
import {MonetizeMethods} from "../../models/monetize-methods";
import {Tag} from "../../models/tag";
import {ContentTagsService} from "../../services/content-tags/content.tags.service";
import {ChannelInvitation} from "../../models/channel-invitation";
import {PublishRangesKeys} from "../../models/publish-range";
import {CourseProgress} from "../../models/course-progress";
import {LoadingFrames, LoadingPart, LoadingSection} from "../../models/course-part";
import {CourseIndexPartService} from "../../services/course-index/course-index-part.service";
import {Account, AccountType} from "../../models/account";
import {AccountsService} from "../../services/accounts/accounts.service";
import {ChatThreadsService} from "../../services/chat-threads/chat-threads.service";
import {Chat, ChatThread, ChatWays, Content, ContentTypes} from "../../models/chat";
import {Unread, unreadCategories} from "../../models/unread";
import {CourseSubscription} from "../../models/course-subscription";
import {CourseSubscriptionsService} from "../../services/course-subscriptions/course-subscriptions.service";
import {map} from "rxjs/operators";
import {Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
type FramesLoader = { courseId: string, frames: LoadingFrames[] };

@Component({
  selector: 'app-channel-top',
  templateUrl: './channel-top.component.html',
  styleUrls: ['./channel-top.component.scss']
})
export class ChannelTopComponent implements OnInit {
  courseSubsSubscription: Subscription;
  idToken: string;
  isOpenModal = false;
  channelId: string;
  isMyChannel = false;
  isOpenLoginModal = false;
  isOpenCreditModal = false;
  slug: string;
  currentChannel: Channel = new Channel();
  channelOwnerAccount: Account;
  courses: Course[] = [];
  spanCancelBr = 'display:inline;'
  channelSubscriptionStatus: ChannelSubscription;
  channelInvitationStatus: ChannelInvitation;
  monetizeMethods = MonetizeMethods;
  kvIndex = 0;
  tagPayload: Tag[] = [];
  hasPermission = false;
  isPublished = false;
  isNotFound = false;
  isOpenVideoModal = false;
  sampleIndex = 0;
  samplePath = '';
  numOfFragmentsString = '';
  frames: FramesLoader[] = [];
  courseProgresses: CourseProgress[] = [];
  accountType = AccountType;
  courseSubs: CourseSubscription[] = [];
  framesLoaded = false;
  constructor(
    private route: ActivatedRoute,
    private toasterService: ToastrService,
    private authService: AuthService,
    private accountService: AccountsService,
    private uiStore: Store<{ ui: UiState }>,
    private courseProgressesService: CourseProgressesService,
    private channelSubscriptionsService: ChannelSubscriptionsService,
    private channelsService: ChannelsService,
    private coursesService: CoursesService,
    private backendService: BackendService,
    private contentTagsService: ContentTagsService,
    private parts: CourseIndexPartService,
    private chatThreadService: ChatThreadsService,
    private courseSubscriptionsService: CourseSubscriptionsService,
    private translateService: TranslateService,
  ) {
    window.scrollTo(0, 0)
    this.slug = this.route.snapshot.paramMap.get('slug');
    this.uiStore.dispatch(switchTrueLoading());
  }
  async ngOnInit(): Promise<void> {
    await this.authService.refresh();
    this.currentChannel = await this.channelsService.fetchBySlug(this.slug);
    if (!this.currentChannel) {
      console.log('404 channel not found');
      this.isNotFound = true;
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    // 未ログイン時は見えない
    if (this.currentChannel.isOem && this.authService.currentSession.isAnonymous) {
      console.log('404 isAnonymous');
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    this.idToken = this.authService.currentSession.idToken;
    this.channelId = this.currentChannel?.id;
    this.channelOwnerAccount = await this.accountService.fetchAccount(this.currentChannel?.accountId);
    this.channelSubscriptionStatus = await this.channelSubscriptionsService.fetch(this.currentChannel?.id, this.authService.currentSession?.currentUser?.id);
    this.tagPayload = await this.contentTagsService.gatheringTags(this.currentChannel?.tags);
    await this.coursesService.fetchByChannelId(this.channelId);
    this.courses = this.coursesService.fetched;
    const urlState = new UrlState();
    urlState.channelId = this.channelId;
    urlState.slug = this.currentChannel.slug;
    this.uiStore.dispatch(storeUrlState({ urlState: urlState}));
    // 未ログイン時の公開範囲判定
    if (this.authService.currentSession.isAnonymous) {
      // チャンネルの公開範囲をチェックする
      if (this.currentChannel.published && this.currentChannel.publishRange === PublishRangesKeys.public) {
        this.isPublished = true;
        this.uiStore.dispatch(switchFalseLoading());
      } else {
        console.log('404 未ログイン時には見えない設定');
        this.uiStore.dispatch(switchFalseLoading());
        return;
      }
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    // チャンネルの管理者かどうかをチェックする
    if (this.authService.currentSession.currentUser.currentAccountId === this.currentChannel.accountId) {
      this.isMyChannel = true;
    }
    // チャンネルの公開範囲をチェックする
    if (this.isMyChannel
      || (this.currentChannel.published && this.currentChannel.publishRange === PublishRangesKeys.public)
      || (this.currentChannel.published && this.currentChannel.publishRange === PublishRangesKeys.forInvitationOnly && this.channelSubscriptionStatus?.activated)
      || (this.currentChannel.published && this.currentChannel.publishRange === PublishRangesKeys.forChannelMembershipOnly && this.channelSubscriptionStatus?.activated)
      || (this.currentChannel.published && this.currentChannel.publishRange == PublishRangesKeys.forAccountUsersOnly && this.isMyChannel)
    )
    {
      this.isPublished = true;
    } else {
      console.log('404 チャンネルの公開範囲をチェックする');
      this.isNotFound = true;
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    // チャンネルの権限があるかどうかを最終判断する
    if (this.isMyChannel
      || (this.currentChannel.published && this.channelSubscriptionStatus.activated )
    )
    {
      this.hasPermission = true;
    }
    // コースの進捗データを読み込む
    this.loadCourseProgresses().then();
    this.loadCourseTags().then();
    this.loadCourseSubs().then();
    this.uiStore.dispatch(switchFalseLoading());
  }
  async loadAllFrames(): Promise<void> {
    this.frames = [];
    let courseIndex = 0;
    for (const course of this.courses) {
      let chapterIndex = 0;
      for (const chapter of course.courseIndex.chapters) {
        this.frames[courseIndex] = { courseId: course.id, frames: [] };
        this.frames[courseIndex].frames[chapterIndex] = new LoadingFrames();
        this.frames[courseIndex].frames[chapterIndex].id = chapter.id;
        let sectionIndex = 0;
        for (const section of chapter.sections) {
          this.frames[courseIndex].frames[chapterIndex].sections[sectionIndex] = new LoadingSection();
          this.frames[courseIndex].frames[chapterIndex].sections[sectionIndex].id = section.id;
          let partIndex = 0;
          for (const part of section.parts ) {
            this.frames[courseIndex].frames[chapterIndex].sections[sectionIndex].parts[partIndex] = new LoadingPart(part.frameType);
            this.frames[courseIndex].frames[chapterIndex].sections[sectionIndex].parts[partIndex].id = part.id;
            // ロードする
            this.frames[courseIndex].frames[chapterIndex].sections[sectionIndex].parts[partIndex].frame = await this.parts.loadFrameBody(
              this.currentChannel.accountId,
              course.id,
              part.id,
              part.frameType
            );
            partIndex++;
          }
          sectionIndex++;
        }
        chapterIndex++;
      }
      courseIndex++;
    }
  }
  async loadCourseProgresses(): Promise<void> {
    for (const course of this.courses) {
      const p = await this.courseProgressesService.fetchByMy(
        course.id,
        this.authService.currentSession.currentUser.id
      )
      if (!p) {
        continue;
      }
      p.courseId = course.id;
      this.courseProgresses.push(p);
    }
  }
  async loadCourseTags(): Promise<void> {
    for (const course of this.courses) {
      const tags = await this.contentTagsService.gatheringTags(course.tags);
      const p = await this.courseProgressesService.fetchByMy(
        course.id,
        this.authService.currentSession.currentUser.id
      )
      course.tagPayloads = tags;
    }
  }
  async loadCourseSubs(): Promise<void> {
    this.courseSubscriptionsService.connectSnapShotsMyAll(this.authService.user.id,);
    this.courseSubsSubscription = this.courseSubscriptionsService.myDocs
      .pipe(
        map((docs) => docs)
      )
      .subscribe(async (docs) => {
        docs.forEach((doc) => {
          const newDoc = doc as CourseSubscription;
          if (this.courseSubs.findIndex(c => c.id === newDoc.id) === -1) {
            this.courseSubs.push(newDoc as CourseSubscription);
          }
        });
      });
  }
  getCourseProgress(courseId: string): string {
    const progress = this.courseProgresses.find(p => p.courseId === courseId);
    if (!progress) {
      return '--';
    }
    return (Math.round(progress.all * 100)).toString();
  }
  getCourseProgressStyle(courseId: string): string {
    const result = this.getCourseProgress(courseId);
    return `width:${result}%`;
  }
  openModal(): void {
    if (this.authService.currentAuth.isAnonymous) {
      this.isOpenLoginModal = true;
      return;
    }
    this.isOpenModal = true;
  }
  closeModal(): void {
    this.isOpenModal = false;
  }
  changeLoginModal(status: boolean): void {
    this.isOpenLoginModal = status;
  }
  changeSampleVideoModal(event: any, index: number): void {
    this.sampleIndex = index;
    this.samplePath = `channel-videos/${this.currentChannel.accountId}/${this.currentChannel.id}/samples/${this.currentChannel.samples[index].contentKey}`;
    this.numOfFragmentsString = (this.currentChannel.samples[index].numOfFragments).toString();
    this.isOpenVideoModal = !this.isOpenVideoModal;
  }
  changeEyeCatch(index: number): void {
    this.kvIndex = index;
  }
  canShowCourse(course: Course): boolean {
    const subscription = this.courseSubs.find(s => s.courseId === course.id);
    return this.coursesService.canShow(
      this.currentChannel,
      this.channelSubscriptionStatus,
      course,
      subscription,
      this.authService.currentSession.currentUser
    )
  }
  apply(): void {
    // チャンネル購読申し込みデータ作成
    this.channelSubscriptionsService.create(
      this.currentChannel.accountId,
      this.channelId,
      this.authService.currentSession.currentUser.id
    )
      .then(async () => {
        // メール送信
        const user = await this.authService.fetchUser(this.authService.currentSession.currentUser.id);
        const emailSenders: EmailSender[] = [];
        const defaultTemplate: string = emailAddMyChannel.body + emailSignature.body;
        let body = defaultTemplate.replace('__%user_name%__', this.authService.currentSession.currentUser.displayName);
        body = body.replace('__%course_title%__', this.currentChannel.name);
        const sender = new EmailSender();
        sender.email = user.email;
        sender.body = body;
        sender.subject = emailOnlineReserved.subject;
        sender.customerId = this.authService.currentSession.currentUser.id;
        sender.fromAddress = environment.emailFromAddress;
        sender.fromName = environment.emailFromName;
        emailSenders.push(sender);
        const data = {
          accountId: this.currentChannel.accountId,
          guardianId: 'direct',
          emails: emailSenders
        }
        this.backendService.setIdToken(this.authService.currentSession.idToken);
        this.backendService.post(
          'email/email-sender/send-emails',
          data
        )
          .then((ret) => {
          })
          .catch((e) => {
          })
        //　チャットスレッドを開始する
        const chat = new Chat(ChatWays.system);
        chat.content = new Content(ContentTypes.text);
        chat.content.body = `チャンネル「${this.currentChannel.name}」に登録しました。` ;
        chat.createdAt = new Date();
        chat.timestamp = chat.createdAt.getTime();
        const thread = new ChatThread();
        thread.accountId = this.currentChannel.accountId;
        thread.channelId = this.currentChannel.id;
        thread.courseId = null;
        thread.body = chat.content.body;
        thread.postedAt = chat.createdAt;
        const unread = new Unread();
        unread.category = unreadCategories.chat;
        unread.accountId = this.currentChannel.accountId;
        unread.channelId = this.channelId;
        unread.body = `${user.displayName}さんが${chat.content.body}`;
        unread.createdAt = chat.createdAt;
        this.chatThreadService.add(
          this.authService.currentSession.currentUser.id,
          thread
        );
        const postData = {
          chat: chat,
          unread: unread,
        };
        this.backendService.post(
          'lms/chats/post_to_channel',
          postData
        ).catch();
        this.toasterService.success(this.translateService.instant('チャンネル登録しました。'));
      })
      .catch((e) => {
        console.log(e);
        this.toasterService.warning(this.translateService.instant('申し込み出来ませんでした。'));
      })
  }
  async applySubscriptionFree(): Promise<void> {
    await this.channelSubscriptionsService.create(
      this.currentChannel.accountId,
      this.currentChannel.id,
      this.authService.currentSession.currentUser.id
    )
    await new Promise(resolve => setTimeout(resolve, 2000));
    this.channelSubscriptionStatus = await this.channelSubscriptionsService.fetch(
      this.currentChannel.id,
      this.authService.currentSession.currentUser.id
    )
    this.toasterService.success(this.translateService.instant('ご登録ありがとうございます！'), this.currentChannel.name);
    this.isOpenModal = false;
  }
  applySubscriptionMembership(): void {
    // TODO 課金に進む
    this.toasterService.warning(this.translateService.instant('現在課金設定は無効になっています。'));

  }
  applySubscriptionPremiumMembership(): void {
    // TODO 課金に進む
    this.toasterService.warning(this.translateService.instant('現在課金設定は無効になっています。'));

  }
}
