export class PublicImage {
  src: string;
  contentType: string;
  contentKey: string;
  widths: string[];
  ratio: string;
  uploadedAt: Date;
  uploadedBy: string;
  constructor() {
    this.src = null;
    this.widths = [
      '2560',
      '2048',
      '1920',
      '1280',
      '1156',
      '1024',
      '960',
      '768',
      '640',
      '480',
      '320',
      '240',
      '160'
    ];
    this.ratio = '0.5625';
    this.contentType = 'image/jpeg';
    this.contentKey = null;
    this.uploadedAt = new Date();
    this.uploadedBy = null;
  }
}

