import {Injectable} from '@angular/core';
import {CoursePart} from "../../models/course-part";
import {Course} from "../../models/course";
import {CourseSlideFramesService} from "../course-slide-frames/course-slide-frames.service";
import {CourseVideoFramesService} from "../course-video-frames/course-video-frames.service";
import {CourseOnlineFramesService} from "../course-online-frames/course-online-frames.service";
import {CourseExaminationFramesService} from "../course-examination-frames/course-examination-frames.service";
import {CourseLiveFramesService} from "../course-live-frames/course-live-frames.service";
import {CourseReportFramesService} from "../course-report-frames/course-report-frames.service";
import {CourseFileFramesService} from "../course-file-frames/course-file-frames.service";
import {CourseSurveyFramesService} from "../course-survey-frames/course-survey-frames.service";
import {
  ContentFrame,
  ExaminationFrame, FileFrame, FrameTypes,
  LiveFrame,
  OnlineFrame, ReportFrame,
  SlideFrame, SurveyFrame,
  VideoFrame
} from "../../models/course-frames";
import {CourseCoverFramesService} from "../course-cover-frames/course-cover-frames.service";
import {AudioUploaderService} from "../audio-uploader/audio-uploader.service";
import {ImageConverterService} from "../image-converter/image-converter.service";
import {SlideUploaderService} from "../slide-uploader/slide-uploader.service";
import {FileUploaderToStorageService} from "../file-uploader-to-storage/file-uploader-to-storage.service";
import {VideoUploaderService} from "../video-uploader/video-uploader.service";

@Injectable({
  providedIn: 'root'
})
export class CourseIndexPartService {

  constructor(
    private courseCoverFramesService: CourseCoverFramesService,
    private courseSlideFramesService: CourseSlideFramesService,
    private courseVideoFramesService: CourseVideoFramesService,
    private courseOnlineFramesService: CourseOnlineFramesService,
    private courseExaminationFramesService: CourseExaminationFramesService,
    private courseLiveFramesService: CourseLiveFramesService,
    private courseReportFramesService: CourseReportFramesService,
    private courseFileFramesService: CourseFileFramesService,
    private courseSurveyFramesService: CourseSurveyFramesService,
    private fileUploaderToStorageService: FileUploaderToStorageService,
    private videoUploaderService: VideoUploaderService,
    private imageConverterService: ImageConverterService,
    private slideUploaderService: SlideUploaderService,
    private audioUploaderService: AudioUploaderService,
  ) { }
  async add(
    accountId: string,
    uid: string,
    course: Course,
    chapterId: string,
    sectionId: string,
    pushOrUnshift: 'push' | 'unshift',
    frameType: FrameTypes): Promise<Course> {
    const targetChapterIndex = course.courseIndex.chapters.findIndex(c => c.id === chapterId);
    const targetSectionIndex = course.courseIndex.chapters[targetChapterIndex].sections.findIndex(c => c.id === sectionId);
    const part = new CoursePart(frameType);
    if( pushOrUnshift === 'push') {
      course.courseIndex.chapters[targetChapterIndex].sections[targetSectionIndex].parts.push(part);
    } else {
      course.courseIndex.chapters[targetChapterIndex].sections[targetSectionIndex].parts.unshift(part);
    }
    // フレームも作る
    const frame = new ContentFrame();
    switch (frameType) {
      case FrameTypes.CoverFrame:
        await this.courseCoverFramesService.create(
          accountId,
          course.id,
          uid,
          part.id,
          frame
        )
        break;
      case FrameTypes.SlideFrame:
        const slideFrame = new SlideFrame();
        slideFrame.contentKey = part.id;
        frame.slideFlame = slideFrame;
        await this.courseSlideFramesService.create(
          accountId,
          course.id,
          uid,
          part.id,
          frame
        )
        break;
      case FrameTypes.VideoFrame:
        const videoFrame = new VideoFrame();
        videoFrame.contentKey = part.id;
        frame.videoFlame = videoFrame;
        await this.courseVideoFramesService.create(
          accountId,
          course.id,
          uid,
          part.id,
          frame
        )
        break;
      case FrameTypes.OnlineFrame:
        const onlineFrame = new OnlineFrame();
        onlineFrame.contentKey = part.id;
        frame.onlineFrame = onlineFrame;
        await this.courseOnlineFramesService.create(
          accountId,
          course.id,
          uid,
          part.id,
          frame
        )
        break;
      case FrameTypes.ExaminationFrame:
        const examinationFrame = new ExaminationFrame();
        examinationFrame.contentKey = part.id;
        frame.examinationFrame = examinationFrame;
        await this.courseExaminationFramesService.create(
          accountId,
          course.id,
          uid,
          part.id,
          frame
        )
        break;
      case FrameTypes.LiveFrame:
        const liveFrame = new LiveFrame();
        liveFrame.contentKey = part.id;
        frame.liveFrame = liveFrame;
        await this.courseLiveFramesService.create(
          accountId,
          course.id,
          uid,
          part.id,
          frame
        )
        break;
      case FrameTypes.ReportFrame:
        const reportFrame = new ReportFrame();
        reportFrame.contentKey = part.id;
        frame.reportFrame = reportFrame;
        await this.courseReportFramesService.create(
          accountId,
          course.id,
          uid,
          part.id,
          frame
        )
        break;
      case FrameTypes.FileFrame:
        const fileFrame = new FileFrame();
        fileFrame.contentKey = part.id;
        frame.fileFrame = fileFrame;
        await this.courseFileFramesService.create(
          accountId,
          course.id,
          uid,
          part.id,
          frame
        )
        break;
      case FrameTypes.SurveyFrame:
        const surveyFrame = new SurveyFrame();
        surveyFrame.contentKey = part.id;
        frame.surveyFrame = surveyFrame;
        await this.courseSurveyFramesService.create(
          accountId,
          course.id,
          uid,
          part.id,
          frame
        )
        break;
      default:
        break;
    }
    return course;
  }
  async remove(
    accountId: string,
    course: Course,
    chapterId: string,
    sectionId: string,
    partId: string,
  ) {
    const targetChapterIndex = course.courseIndex.chapters.findIndex(c => c.id === chapterId);
    const targetSectionIndex = course.courseIndex.chapters[targetChapterIndex].sections.findIndex(c => c.id === sectionId);
    const targetPart = course.courseIndex.chapters[targetChapterIndex].sections[targetSectionIndex].parts.find(c => c.id === partId);
    course.courseIndex.chapters[targetChapterIndex].sections[targetSectionIndex].parts
      = course.courseIndex.chapters[targetChapterIndex].sections[targetSectionIndex].parts.filter(
      v => v.id !== targetPart.id);
    // 各フレーム消す
    switch (targetPart.frameType) {
      case FrameTypes.CoverFrame:
        await this.courseCoverFramesService.remove(
          accountId,
          course.id,
          partId
        );
        break;
      case FrameTypes.SlideFrame:
        // スライド、オーディオを削除
        await this.courseSlideFramesService.remove(
          accountId,
          course.id,
          partId
        );
        break;
      case FrameTypes.VideoFrame:
        await this.courseVideoFramesService.remove(
          accountId,
          course.id,
          partId
        );
        break;
      case FrameTypes.OnlineFrame:
        await this.courseOnlineFramesService.remove(
          accountId,
          course.id,
          partId
        );
        break;
      case FrameTypes.ExaminationFrame:
        await this.courseExaminationFramesService.remove(
          accountId,
          course.id,
          partId
        );
        break;
      case FrameTypes.LiveFrame:
        await this.courseLiveFramesService.remove(
          accountId,
          course.id,
          partId
        );
        break;
      case FrameTypes.ReportFrame:
        await this.courseReportFramesService.remove(
          accountId,
          course.id,
          partId
        );
        break;
      case FrameTypes.FileFrame:
        await this.courseFileFramesService.remove(
          accountId,
          course.id,
          partId
        );
        break;
      case FrameTypes.SurveyFrame:
        await this.courseSurveyFramesService.remove(
          accountId,
          course.id,
          partId
        );
        break;
      default:
        break;
    }
    return course;
  }
  sortUp(
    course: Course,
    chapterId: string,
    sectionId: string,
    partId: string,
  ): Course {
    const targetChapterIndex = course.courseIndex.chapters.findIndex(c => c.id === chapterId );
    const targetSectionIndex = course.courseIndex.chapters[targetChapterIndex].sections.findIndex(c => c.id === sectionId);
    const targetPartIndex = course.courseIndex.chapters[targetChapterIndex].sections[targetSectionIndex].parts.findIndex(c => c.id === partId);
    const contentLength = course.courseIndex.chapters[targetChapterIndex].sections[targetSectionIndex].parts.length;
    const parts = course.courseIndex.chapters[targetChapterIndex].sections[targetSectionIndex].parts;
    if (targetSectionIndex < 0) {
      return course;
    }
    const origin = parts[targetPartIndex];
    const destination = parts[targetPartIndex - 1];
    for (let i = 0; i < contentLength; i++) {
      if (parts[i]) {
        if (i === targetPartIndex) {
          parts[i] = destination;
        } else if (i === targetPartIndex - 1) {
          parts[i] = origin;
        }
      }
    }
    course.courseIndex.chapters[targetChapterIndex].sections[targetSectionIndex].parts = parts;
    return course;
  }
  sortDown(
    course: Course,
    chapterId: string,
    sectionId: string,
    partId: string,
  ): Course {
    const targetChapterIndex = course.courseIndex.chapters.findIndex(c => c.id === chapterId );
    const targetSectionIndex = course.courseIndex.chapters[targetChapterIndex].sections.findIndex(c => c.id === sectionId);
    const targetPartIndex = course.courseIndex.chapters[targetChapterIndex].sections[targetSectionIndex].parts.findIndex(c => c.id === partId);
    const contentLength = course.courseIndex.chapters[targetChapterIndex].sections[targetSectionIndex].parts.length;
    const parts = course.courseIndex.chapters[targetChapterIndex].sections[targetSectionIndex].parts;
    if (!parts[targetPartIndex + 1]) {
      return course;
    }
    const origin = parts[targetPartIndex];
    const destination = parts[targetPartIndex + 1];
    for (let i = 0; i < contentLength; i++) {
      if (parts[i] ) {
        if (i === targetPartIndex) {
          parts[i] = destination;
        } else if (i === targetPartIndex + 1) {
          parts[i] = origin;
        }
      }
    }
    course.courseIndex.chapters[targetChapterIndex].sections[targetSectionIndex].parts = parts;
    return course;
  }
  async loadFrameBody(
    accountId: string,
    courseId: string,
    partId: string,
    frameType: FrameTypes
  ): Promise<null | ContentFrame> {
    switch (frameType) {
      case FrameTypes.CoverFrame:
        return this.courseCoverFramesService.fetch(
          accountId,
          courseId,
          partId
        );
      case FrameTypes.SlideFrame:
        return this.courseSlideFramesService.fetch(
          accountId,
          courseId,
          partId
        );
      case FrameTypes.VideoFrame:
        return this.courseVideoFramesService.fetch(
          accountId,
          courseId,
          partId
        );
      case FrameTypes.OnlineFrame:
        return this.courseOnlineFramesService.fetch(
          accountId,
          courseId,
          partId
        );
      case FrameTypes.ExaminationFrame:
        return this.courseExaminationFramesService.fetch(
          accountId,
          courseId,
          partId
        );
      case FrameTypes.LiveFrame:
        return this.courseLiveFramesService.fetch(
          accountId,
          courseId,
          partId
        );
      case FrameTypes.ReportFrame:
        return this.courseReportFramesService.fetch(
          accountId,
          courseId,
          partId
        );
      case FrameTypes.FileFrame:
        return this.courseFileFramesService.fetch(
          accountId,
          courseId,
          partId
        );
      case FrameTypes.SurveyFrame:
        return this.courseSurveyFramesService.fetch(
          accountId,
          courseId,
          partId
        );
      default:
        break;
    }
    return null;
  }
  async removeEyeCatchImage(
    idToken: string,
    frame: ContentFrame
  ): Promise<void> {
    await this.imageConverterService.removePublicImage(
      frame.eyeCatchImage,
      idToken
    );
  }
  async removeVideoOrAudio(
    accountId: string,
    idToken: string,
    frame: VideoFrame,
    destinationPath: string,
  ): Promise<void> {
    await this.videoUploaderService.removeVideo(
      accountId,
      idToken,
      frame,
      `${destinationPath}${frame.contentKey}`
    )
  }
  async removeSlide(
    accountId: string,
    idToken: string,
    frame: SlideFrame,
  ): Promise<void> {
    await this.slideUploaderService.removeSlide(
      accountId,
      idToken,
      frame,
    )
  }
}
