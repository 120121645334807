<ng-container>
  <!--<app-header-top></app-header-top>-->
  <div class="l-lp">
    <div class="p-top">
      <div class="p-top__head">
        <div class="p-top__head__title">学びを<span><br></span>加速させる！</div>
      </div>
      <div class="p-top__main">
        <section class="p-top__main__service">
          <div class="p-top__main__service__heading">
            <div class="p-top__main__service__heading__en">INCREASE THE SPEED OF LEARNING</div>
            <div class="p-top__main__service__heading__ja">FireRocketで<span><br></span>新しい学びを創造しよう</div>
            <div class="p-top__main__service__heading__copy">
              FireRocketは誰でも講師になれる<span><br></span>学習プラットフォームです。<br>
              自分の専門知識を多くの方へ届けましょう！
            </div>
          </div>
          <div class="p-top__main__service__body">
            <div class="p-top__main__service__body__title">あなたの知識をマネタイズ。<span><br></span>動画学習を活用して人材育成</div>
            <div class="p-top__main__service__body__prPoint">
              <div class="p-top__main__service__body__prPoint__item">
                <div class="p-top__main__service__body__prPoint__item__text">リアル研修<span><br></span>管理</div>
              </div>
              <div class="p-top__main__service__body__prPoint__crossMark"></div>
              <div class="p-top__main__service__body__prPoint__item">
                <div class="p-top__main__service__body__prPoint__item__text">オンライン<span><br></span>セミナー<span><br></span>管理</div>
              </div>
              <div class="p-top__main__service__body__prPoint__crossMark"></div>
              <div class="p-top__main__service__body__prPoint__item">
                <div class="p-top__main__service__body__prPoint__item__text">オンライン学習<span><br></span>管理</div>
              </div>
              <div class="p-top__main__service__body__prPoint__crossMark"></div>
              <div class="p-top__main__service__body__prPoint__item">
                <div class="p-top__main__service__body__prPoint__item__text">マネタイズ<span><br></span>管理</div>
              </div>
            </div>
          </div>
        </section>
        <section class="p-top__main__introductionLinks">
          <div class="p-top__main__introductionLinks__inner">
            <button class="p-top__main__introductionLinks__inner__item p-top__main__introductionLinks__inner__item-bg_01">
              <div class="p-top__main__introductionLinks__inner__item__text">共感講座</div>
            </button>
            <button class="p-top__main__introductionLinks__inner__item p-top__main__introductionLinks__inner__item-bg_02">
              <div class="p-top__main__introductionLinks__inner__item__text">コースの作り方</div>
            </button>
            <button class="p-top__main__introductionLinks__inner__item p-top__main__introductionLinks__inner__item-bg_03">
              <div class="p-top__main__introductionLinks__inner__item__text">自身のチャンネルで<br>マネタイズが難しいと<br>感じる方へ</div>
            </button>
          </div>
        </section>
        <section class="p-top__main__contact">
          <div class="p-top__main__contact__info">タイムパフォーマンスの良い学習<span></span>多様な業態に対応<span></span>コースごとの受講状況を確認できる</div>
          <button
            (click)="moveToForm()"
            class="p-top__main__contact__button">お問い合わせはこちら</button>
        </section>
        <section class="p-top__main__features">
          <div class="p-top__main__features__heading">
            <div class="p-top__main__features__heading__ja">6つの特徴</div>
            <div class="p-top__main__features__heading__en">Features of FireRocket</div>
          </div>
          <ol class="p-top__main__features__body">
            <li class="p-top__main__features__body__item">
              <div class="p-top__main__features__body__item__thumb p-top__main__features__body__item__thumb-bg_01"></div>
              <div class="p-top__main__features__body__item__text">
                <div class="p-top__main__features__body__item__text__heading">やさしい学習プラットフォームで<br>学習者をサポート！</div>
                <div class="p-top__main__features__body__item__text__body">学習者を手厚くサポートする、わかりやすい学習プラットフォームを目指しました。当社のUI/UXクリエイターが作ったUIで、あなたの学習環境はよりよく整います。</div>
              </div>
            </li>
            <li class="p-top__main__features__body__item p-top__main__features__body__item-reverse">
              <div class="p-top__main__features__body__item__thumb p-top__main__features__body__item__thumb-bg_02"></div>
              <div class="p-top__main__features__body__item__text">
                <div class="p-top__main__features__body__item__text__heading">あなたの「知識」を手軽に<span><br></span>「教材」にできます</div>
                <div class="p-top__main__features__body__item__text__body">FireRocketを使えば、あなたの「専門知識」を教材の動画ファイルにし、学習者へ販売することもできます。Youtubeのチャンネル登録やeラーニングサービスで難しかったマネタイズが可能になります。</div>
              </div>
            </li>
            <li class="p-top__main__features__body__item">
              <div class="p-top__main__features__body__item__thumb p-top__main__features__body__item__thumb-bg_03"></div>
              <div class="p-top__main__features__body__item__text">
                <div class="p-top__main__features__body__item__text__heading">一目で学習進捗管理！</div>
                <div class="p-top__main__features__body__item__text__body">ビデオ、スライド＋オーディオファイル、オンライン講義、対面講義のスケジュールや出席管理など、さまざまなメディアで学習進捗を管理することができます。</div>
              </div>
            </li>
            <li class="p-top__main__features__body__item p-top__main__features__body__item-reverse">
              <div class="p-top__main__features__body__item__thumb p-top__main__features__body__item__thumb-bg_04"></div>
              <div class="p-top__main__features__body__item__text">
                <div class="p-top__main__features__body__item__text__heading">法人向けの機能でビジネスも効率化</div>
                <div class="p-top__main__features__body__item__text__body">一般ユーザとは隔離したセキュアなデータ管理。一般ユーザとは隔離したセキュアなデータ管理。CSVでの一括処理により、管理工数を削減します。さらに、学習証明証の発行・電子署名添付で、一人ひとりの学びの成果を評価。</div>
              </div>
            </li>
            <li class="p-top__main__features__body__item">
              <div class="p-top__main__features__body__item__thumb">
                <div class="p-top__main__features__body__item__thumb__text">社内研修などを撮影</div>
                <div class="p-top__main__features__body__item__thumb__text">FireRocketにアップロード</div>
                <div class="p-top__main__features__body__item__thumb__text">いつでもどこでも学習</div>
              </div>
              <div class="p-top__main__features__body__item__text">
                <div class="p-top__main__features__body__item__text__heading">貴社の動画をクラウドで<span><br></span>共有できます。</div>
                <div class="p-top__main__features__body__item__text__body">FireRocketはクラウド型の学習プラットフォームです。貴社の研修動画などを社員一人ひとりに向けクラウドで共有することが可能です。</div>
              </div>
            </li>
            <li class="p-top__main__features__body__item p-top__main__features__body__item-reverse">
              <div class="p-top__main__features__body__item__thumb p-top__main__features__body__item__thumb-bg_06"></div>
              <div class="p-top__main__features__body__item__text">
                <div class="p-top__main__features__body__item__text__heading">人気の企業向け<span><br></span>学習コンテンツ「共感講座」</div>
                <div class="p-top__main__features__body__item__text__body">FireRocketは、人気の企業向け学習コンテンツ「共感講座」を制作しているグラブデザインが開発しました。永年のコンテンツ制作経験による確かな品質と効果を期待できます。 </div>
              </div>
            </li>
          </ol>
        </section>
        <section class="p-top__main__about">
          <div class="p-top__main__about__heading">
            <div class="p-top__main__about__heading__ja">FireRocketとは</div>
            <div class="p-top__main__about__heading__en">About</div>
          </div>
          <div class="p-top__main__about__body">
            <div class="p-top__main__about__body__title">様々なニーズにフィットする<span><br></span>学習プラットフォーム</div>
            <div class="p-top__main__about__body__outline">
              <div class="p-top__main__about__body__outline__bg"></div>
              <div class="p-top__main__about__body__outline__provider">
                <div class="p-top__main__about__body__outline__provider__row">講師となる個人</div>
                <div class="p-top__main__about__body__outline__provider__row">企業</div>
                <div class="p-top__main__about__body__outline__provider__row">学習塾</div>
              </div>
              <div class="p-top__main__about__body__outline__platform"></div>
              <div class="p-top__main__about__body__outline__user">
                <div class="p-top__main__about__body__outline__user__row">個人</div>
                <div class="p-top__main__about__body__outline__user__row">個人</div>
                <div class="p-top__main__about__body__outline__user__row">個人</div>
              </div>
            </div>
            <div class="p-top__main__about__body__comment">個人間の利用から大企業のニーズにも耐えうる、これまでの学習管理システムとは異なるコンテンツプラットフォームで、様々な教育ニーズに応えると同時に、教育事業の可能性を飛躍的に向上させることになります。</div>
            <div class="p-top__main__about__body__title">企業も個人もスムーズに<span><br></span>FireRocketに移行できます</div>
            <div class="p-top__main__about__body__detail">
              <div class="p-top__main__about__body__detail__bg"></div>
              <div class="p-top__main__about__body__detail__current">
                <div class="p-top__main__about__body__detail__current__chart">
                  <div class="p-top__main__about__body__detail__current__chart__provider">企業</div>
                  <div class="p-top__main__about__body__detail__current__chart__platform">国産の<br>既存LMS</div>
                  <div class="p-top__main__about__body__detail__current__chart__user">従業員</div>
                </div>
                <div class="p-top__main__about__body__detail__current__arrowDown">
                  <div class="p-top__main__about__body__detail__current__arrowDown__text">BtoB</div>
                </div>


              </div>
              <div class="p-top__main__about__body__detail__center">
                <div class="p-top__main__about__body__detail__center__provider">
                  <div class="p-top__main__about__body__detail__center__provider__row">講師となる個人</div>
                  <div class="p-top__main__about__body__detail__center__provider__row">企業</div>
                </div>
                <div class="p-top__main__about__body__detail__center__platform"></div>
                <div class="p-top__main__about__body__detail__center__user">
                  <div class="p-top__main__about__body__detail__center__user__row">個人</div>
                  <div class="p-top__main__about__body__detail__center__user__row">従業員</div>
                </div>
              </div>
              <div class="p-top__main__about__body__detail__current">
                <div class="p-top__main__about__body__detail__current__arrowUp">
                  <div class="p-top__main__about__body__detail__current__arrowUp__text">CtoC</div>
                </div>
                <div class="p-top__main__about__body__detail__current__chart">
                  <div class="p-top__main__about__body__detail__current__chart__provider">個人</div>
                  <div class="p-top__main__about__body__detail__current__chart__platform">海外発の<br>既存LMS</div>
                  <div class="p-top__main__about__body__detail__current__chart__user">個人</div>
                </div>
              </div>
            </div>
            <div class="p-top__main__about__body__title">学習プラットフォーム</div>
            <div class="p-top__main__about__body__devices">
              <div class="p-top__main__about__body__devices__bg"></div>
              <div class="p-top__main__about__body__devices__pc"></div>
              <div class="p-top__main__about__body__devices__sp"></div>
            </div>
          </div>
        </section>
        <section class="p-top__main__Platform">
          <div class="p-top__main__Platform__heading">
            <div class="p-top__main__Platform__heading__ja">今までにない<span><br></span>わかりやすい<br>教育プラットフォーム</div>
            <div class="p-top__main__Platform__heading__en">Platform</div>
          </div>
          <div class="p-top__main__Platform__title">例えばYoutubeでは思うようにチャンネル登録者数が増えずマネタイズが難しくても、<br>一人ひとりの受講者から確実に課金できるようになっています。</div>
          <div class="p-top__main__Platform__body">
            <div class="p-top__main__Platform__body__heading">誰でも手軽に知識を<span><br></span>講座にできます</div>
            <div class="p-top__main__Platform__body__recipe">おばあちゃん秘伝の味</div>
            <div class="p-top__main__Platform__body__inner">
              <div class="p-top__main__Platform__body__inner__Left">
                <div class="p-top__main__Platform__body__inner__Left__text">権利料</div>
                <div class="p-top__main__Platform__body__inner__Left__text">登録</div>
              </div>
              <div class="p-top__main__Platform__body__inner__center"></div>
              <div class="p-top__main__Platform__body__inner__right">
                <div class="p-top__main__Platform__body__inner__right__text">受講料</div>
              </div>
            </div>
          </div>
          <div class="p-top__main__Platform__comment">誰もが手軽にeラーニングを行うことができる環境なので、個人の持つ知識を教材として残すと同時に<span><br></span>マネタイズしていくことが可能となります。</div>
        </section>
        <section class="p-top__main__contact">
          <button
            (click)="moveToForm()"
            class="p-top__main__contact__button">お問い合わせはこちら</button>
        </section>
        <div class="p-top__main__bottom">
          INCREASE<br>THE SPEED OF<br>LEARNING
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</ng-container>
