import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from "../../../environments/environment";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {
  constructor(
    private domSanitizer: DomSanitizer
  ) {
  }
  @Input() idToken = '';
  @Input() contentKey = '';
  @Input() path = '';
  @Input() numOfFragments = '';
  @Input() m3u8ObjectName = '';
  @Input() isOpenVideoModal = false;
  @Output() isOpenVideoModalEmitter = new EventEmitter<boolean>();
  url: string;
  videoUrl: SafeResourceUrl;
  videoReady = false;
  closeModal(): void {
    this.isOpenVideoModalEmitter.emit(false);
  }
  ngOnInit(): void {
    this.url = location.protocol + '//' + environment.hostName + '/video-player?'
      + 'i=' + this.idToken
      + '&a=' + environment.appNameSpace
      + '&b=' + environment.firebase.storageBucket
      + '&v=' + this.contentKey
      + '&d=' + this.path
      + '&f=' + this.numOfFragments
      + '&n=' + this.m3u8ObjectName;
    this.videoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.url);
    window.addEventListener('message', (evt) => {
      const f = document.getElementById('video-p');
      const n = evt.data[0];
      let d = evt.data[1];
      switch (n) {
        case 'setHeight':
          f.setAttribute('height', d);
          d = d + 100;
          f.style.height = `${d}px`;
          this.videoReady = true;
      }
    })
  }
}
