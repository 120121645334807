import {FileObject} from "./file-object";

export class SlideUpload {
  slideObjectNames: string[];
  contentKey: string;
  numOfFragments: number;
  width: number;
  height: number;
  eyeCatchUrl: string;
  eyeCatchObjectName: string;
  constructor() {
    this.contentKey = '';
    this.numOfFragments = 0;
    this.width = 0;
    this.height = 0;
    this.eyeCatchUrl = '';
    this.eyeCatchObjectName = '';
  }
}
