<app-header-contents-channel></app-header-contents-channel>
<div class="l-content-front">
  <app-breadcrumb-front></app-breadcrumb-front>
  <div class="l-content-front__inner">
    <div class="c-content-header">
      <div class="c-content-header__title" translate>2段階認証</div>
    </div>
    <div class="l-myPage">
      <div class="p-myPage">
        <div class="p-myPage__notes">
          ***-****-**99<span translate>に届いた認証コードを入力してください。</span>
        </div>
        <div class="p-myPage__form">
          <div class="p-myPage__form__input">
            <label class="c-form-label" translate>認証コード</label>
            <input
              class="c-form-input"
              type="text"
              name="password"
              id="password"
              [placeholder]="'○○桁の認証コードを入力してください' | translate"
            >
          </div>
        </div>
        <div class="p-myPage__buttons">
          <div class="p-myPage__buttons__button">
            <button class="c-button c-button-primary"><span translate>認証する</span></button>
          </div>
        </div>
        <div class="p-myPage__resendAuthCode" translate>認証コードを再送信する</div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
