import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {
  switchFalseLoading,
  switchTrueShowMenuInSideNavi,
  switchTrueShowSideNavigation
} from "../../store/ui/ui.actions";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {AuthService} from "../../services/auth/auth.service";
import {Store} from "@ngrx/store";
import {UiState} from "../../store/ui/ui.reducer";
import {AccountsService} from "../../services/accounts/accounts.service";
import {Account} from "../../models/account";
import {storeUser, storeUsers} from "../../store/sessions/sessions.actions";
import {CurrentUser} from "../../store/sessions/sessions.reducer";
import {BackendService} from "../../services/backend/backend.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-manager-accounts',
  templateUrl: './manager-accounts.component.html',
  styleUrls: ['./manager-accounts.component.scss']
})
export class ManagerAccountsComponent implements OnInit {
  accounts: Account[] = [];
  invitedAccounts: Account[] = [];
  accountId: string;
  currentUser: CurrentUser = new CurrentUser();
  constructor(
    private router: Router,
    private toasterService: ToastrService,
    private accountService: AccountsService,
    private authService: AuthService,
    private sessionsStore: Store<{ sessions: any}>,
    private uiStore: Store<{ ui: UiState }>,
    private backendService: BackendService,
    private translateService: TranslateService,
  )
  {
    window.scrollTo(0, 0);
    this.uiStore.dispatch(switchTrueShowSideNavigation());
    this.uiStore.dispatch(switchTrueShowMenuInSideNavi());
  }
  async ngOnInit(): Promise<void> {
    await this.authService.refresh();
    await new Promise(resolve => setTimeout(resolve, 200));
    this.currentUser = this.authService.currentSession.currentUser;
    this.accounts = [];
    this.invitedAccounts = [];
    this.accountService.fetchAccounts(this.authService.currentSession.currentUser.id)
      .then(async () => {
        const user = await this.authService.fetchUser(this.authService.currentSession.currentUser.id);
        this.accounts = this.accountService.accounts;
        console.log(this.accounts);
        const data = {
          email: user.email
        };
        this.backendService.setIdToken(this.authService.currentSession.idToken);
        this.backendService.post('accounts/account/fetch-invited-accounts', data)
          .then((result) => {
            result.map((a: Account) => {
              console.log(a);
              if (this.accounts.findIndex(account => a.id === account.id) === -1) {
                if (this.invitedAccounts.findIndex(account => a.id === account.id) === -1) {
                  this.invitedAccounts.push(a);
                }
              }
            })
            this.uiStore.dispatch(switchFalseLoading());
          })
          .catch((e) => {
            this.toasterService.warning(this.translateService.instant('招待アカウントの取得時の認証に失敗しました。'));
            this.uiStore.dispatch(switchFalseLoading());
            console.log(e);
          })
      })
      .catch((e) => {
        this.toasterService.warning(this.translateService.instant('アカウントの取得時の認証に失敗しました。'));
        this.uiStore.dispatch(switchFalseLoading());
        console.log(e);
      });
  }
  async switch(newAccount: Account): Promise<void> {
    if (!window.confirm(this.translateService.instant('アカウントを切り替えます。よろしいですか？'))) {
      return;
    }
    const newCurrentUser: CurrentUser =  {...this.authService.currentSession.currentUser};
    const currentUser = await this.authService.fetchUser(this.authService.currentSession.currentUser.id);
    currentUser.currentAccountId = newAccount.id;
    currentUser.updatedAt = new Date();
    currentUser.updatedBy = this.authService.currentSession.currentUser.id;
    newCurrentUser.currentAccountId = newAccount.id;
    this.authService.updateUser(currentUser).then(r => {
      this.authService.fetchUser(this.authService.currentSession.currentUser.id)
        .then((user) => {
          this.sessionsStore.dispatch(storeUser({currentUser: user}));
          this.sessionsStore.dispatch(storeUsers({users: []}));
          this.accountId = newAccount.id;
          const data = {
            accountId: this.authService.currentSession.currentUser.currentAccountId,
          }
          this.backendService.setIdToken(this.authService.idToken);
          this.backendService.post('accounts/account/fetch-users', data)
            .then((result) => {
              if (result.error) {
                this.authService.logout().catch();
                this.router.navigate(['/login']).catch();
                alert(this.translateService.instant('ログインの有効期間が終了しました。もう一度ログインしてください。'))
              } else {
                this.sessionsStore.dispatch(storeUsers({users: result.users}));
                this.toasterService.success(this.translateService.instant('アカウントを切り替えました。'), this.translateService.instant('アカウント切替'));
                this.router.navigate(['/manager/channels']).catch()
              }
            })
            .catch((e) => {
              console.log(e);
            });
        });
    });
  }
  async join(newAccount: Account): Promise<void> {
    if (!window.confirm(this.translateService.instant('このアカウントへの招待を承諾します。よろしいですか？'))) {
      return;
    }
    const currentUser = await this.authService.fetchUser(this.authService.currentSession.currentUser.id);
    const data = {
      uid: this.authService.currentSession.currentUser.id,
      email: currentUser.email,
      authKey: newAccount.authKey,
    };
    this.backendService.post('accounts/account/add-user', data)
      .then(async () => {
        const newCurrentUser: CurrentUser = {...this.authService.currentSession.currentUser};
        const currentUser = await this.authService.fetchUser(this.authService.currentSession.currentUser.id);
        currentUser.currentAccountId = newAccount.id;
        currentUser.updatedAt = new Date();
        currentUser.updatedBy = this.authService.currentSession.currentUser.id;
        newCurrentUser.currentAccountId = newAccount.id;
        this.authService.updateUser(currentUser).then(r => {
          this.sessionsStore.dispatch(storeUser({currentUser: newCurrentUser}));
          this.toasterService.success(this.translateService.instant('アカウントを切り替えました。'), this.translateService.instant('アカウント切替'));
          this.router.navigate(['/manager/channels']).catch()
        });
      })
      .catch((e) => {
        this.toasterService.error(this.translateService.instant('追加に失敗しました。'));
        console.log(e);
      });
  }
}
