<div class="l-top">
  <div class="p-top">
    <div class="p-top__notfound">
      <h2 class="p-top__notfound__statusCode"></h2>
      <p class="p-top__notfound__title"></p>
      <p class="p-top__notfound__detail"><br><br>
      <div class="p-top__notfound__button"></div>
    </div>
  </div>
</div>
