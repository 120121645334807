<div class="l-main">
  <app-navigations></app-navigations>
  <div class="l-content">
    <app-breadcrumb></app-breadcrumb>
    <div class="l-content__inner">
      <div class="c-content-header">
        <div class="c-content-header__title">
          プラン変更
        </div>
      </div>
      <div class="l-templates">
        <div class="p-templates">
          <div class="p-templates__content">
            <div class="p-profile">
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label">現在のご契約プラン</label>
                  <div class="c-form-display">フリー</div>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="status">受講ステータス</label>
                  <select class="c-form-input" name="status" id="status">
                    <option value="">選択してください</option>
                    <option value="">ベーシック</option>
                    <option value="">アドバンス</option>
                    <option value="">プレミアム</option>
                  </select>
                  <div class="c-form-error">プランを選択してください。</div>
                </div>
              </div>
            </div>
            <div class="p-templates__content__notes">
              プラン内容プラン内容プラン内容プラン内容プラン内容プラン内容プラン内容<br>
              プラン内容プラン内容プラン内容プラン内容プラン内容プラン内容プラン内容<br>
              プラン内容プラン内容プラン内容プラン内容プラン内容プラン内容プラン内容<br>
            </div>
          </div>
        </div>
      </div>
      <div class="c-content-header">
        <div class="c-content-header__title">
          お支払い管理
        </div>
      </div>
      <div class="l-templates">
        <div class="p-templates">
          <div class="p-templates__content">
            <div class="p-profile">
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="payment">お支払い管理</label>
                  <input class="c-form-input" type="text" name="name" id="payment" placeholder="プレミアム">
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__buttons">
                  <div class="p-profile__row__buttons__button">
                    <button class="c-button c-button-cancel">キャンセル</button>
                  </div>
                  <div class="p-profile__row__buttons__button">
                    <button class="c-button c-button-primary">プランを変更する</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



