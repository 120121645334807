<app-header-contents-channel></app-header-contents-channel>
<div class="l-content-front">
  <app-breadcrumb-front></app-breadcrumb-front>
  <div class="l-content-front__inner">
    <div class="p-contracts">
      <h2>プライバシーポリシー</h2>
      <p>「登録する」ボタンを押すと、ご入力されたメールアドレス宛にアドレスの確認メールが届きます。</p>
      <p>「fire-rocket.jp」ドメインより届きます。<br>メールが届かない場合は受信の可否設定や、迷惑メールフォルダーに届いていないかご確認ください。</p>
      <h5>1．FireRocketサービス規約に同意する</h5>
      <p>「登録する」ボタンを押すと、ご入力されたメールアドレス宛にアドレスの確認メールが届きます。</p>
      <p>「fire-rocket.jp」ドメインより届きます。<br>メールが届かない場合は受信の可否設定や、迷惑メールフォルダーに届いていないかご確認ください。</p>
      <h5>2．FireRocketサービス規約に同意する</h5>
      <p>「登録する」ボタンを押すと、ご入力されたメールアドレス宛にアドレスの確認メールが届きます。</p>
      <p>「fire-rocket.jp」ドメインより届きます。<br>メールが届かない場合は受信の可否設定や、迷惑メールフォルダーに届いていないかご確認ください。</p>
      <h5>3．FireRocketサービス規約に同意する</h5>
      <p>「登録する」ボタンを押すと、ご入力されたメールアドレス宛にアドレスの確認メールが届きます。</p>
      <p>「fire-rocket.jp」ドメインより届きます。<br>メールが届かない場合は受信の可否設定や、迷惑メールフォルダーに届いていないかご確認ください。</p>
    </div>
  </div>
</div>
<app-footer></app-footer>
