import { Component, OnInit } from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {AuthService} from "../../services/auth/auth.service";
import {UiState} from "../../store/ui/ui.reducer";
import {Store} from "@ngrx/store";
import {FormBuilder, FormControl,　Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {switchFalseLoading, switchTrueLoading} from "../../store/ui/ui.actions";
import {Signup} from "../../models/signup";
import {BackendService} from "../../services/backend/backend.service";
import {environment} from "../../../environments/environment";
import {CurrentUser, SessionState} from "../../store/sessions/sessions.reducer";
import {storeUser} from "../../store/sessions/sessions.actions";
import {TranslateService} from "@ngx-translate/core";
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  authKey: string;
  invitation: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private sessionsStore: Store<{ sessionsStore: SessionState }>,
    private uiStore: Store<{ ui: UiState }>,
    private authService: AuthService,
    private backendService: BackendService,
    private toasterService: ToastrService,
    private translateService: TranslateService,
  ) { }
  currentSession: SessionState | undefined;
  showError = false;
  isComplete = false;
  showPassword = false;
  baseUrl: string = environment.hostName;
  displayName = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  email = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  password = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  passwordConfirmation = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  privacy = new FormControl<boolean>(false, {
    validators: [
      Validators.required,
      Validators.requiredTrue
    ]
  });
  contract = new FormControl<boolean>(false, {
    validators: [
      Validators.required,
      Validators.requiredTrue
    ]
  });
  signUpForm = this.formBuilder.group({
    userName: this.displayName,
    email: this.email,
    password: this.password,
    passwordConfirmation: this.passwordConfirmation,
    privacy: this.privacy,
    contract: this.contract
  });
  async ngOnInit(): Promise<void> {
    window.scrollTo(0, 0);
    await this.authService.refresh();
    // 招待された場合の処理
    // http://localhost:4201/signup?key=14b42d1c1894e79f900d0e9e1c62a204fcaf374b
    this.route.queryParams
      .subscribe(params => {
        console.log(params);
        this.authKey = params['key'];
        // invitationVerify
        if (this.authKey !== undefined) {
          const data = {
            authKey: this.authKey,
          }
          this.backendService.post('accounts/account/invitation-verify', data)
            .then((invitation) => {
              this.invitation = invitation;
              console.log(invitation);
              this.email.setValue(invitation.email)
            })
        }
      });
  }
  openContractsLink(): void {
    window.open('/contracts', '_blank');
  }
  openPrivacyLink(): void {
    window.open('https://www.grab-design.com/privacy/', '_blank');
  }
  showPasswordToggle(): void {
    this.showPassword = !this.showPassword
  }
  async onSubmit(): Promise<void> {
    this.uiStore.dispatch(switchTrueLoading());
    this.showError = false;
    if (!this.signUpForm.valid) {
      this.uiStore.dispatch(switchFalseLoading());
      this.showError = true;
      return;
    }
    if (this.password.value !== this.passwordConfirmation.value) {
      this.showError = true;
      return;
    }
    this.showError = false;
    const signup = new Signup();
    signup.displayName = this.displayName.value;
    signup.email = this.email.value;
    signup.password = this.password.value;
    signup.passwordConfirmation = this.passwordConfirmation.value;
    try {
      await this.authService.signup(signup.email, signup.password);
      this.backendService.setIdToken(this.authService.idToken);
      await this.backendService.post(
        'accounts/user/sign-up',
        {
          accountId: environment.baseAccountId,
          displayName: this.displayName.value,
          email: this.email.value,
        }
      )
      // 招待された時の処理
      if (this.authKey) {
        const data = {
          uid: this.authService.currentSession.currentUser.id,
          email: this.email.value,
          authKey: this.authKey,
        };
        this.backendService.post('accounts/account/add-user', data)
          .then(async () => {
            const newCurrentUser: CurrentUser = {...this.authService.currentSession.currentUser};
            const currentUser = await this.authService.fetchUser(this.authService.currentSession.currentUser.id);
            currentUser.currentAccountId = this.invitation.accountId;
            currentUser.updatedAt = new Date();
            currentUser.updatedBy = this.authService.currentSession.currentUser.id;
            newCurrentUser.currentAccountId = this.invitation.accountId;
            this.authService.updateUser(currentUser).then(r => {
              this.sessionsStore.dispatch(storeUser({currentUser: newCurrentUser}));
              this.toasterService.success(this.translateService.instant('登録しました。'), this.translateService.instant('アカウントへの招待'));
              this.router.navigate(['/manager/channels']).catch()
              this.uiStore.dispatch(switchFalseLoading());
            });
            return;
          })
          .catch((e) => {
            throw e;
          });
      } else {
        this.toasterService.success(this.translateService.instant('登録しました。'), this.translateService.instant('新規ユーザー登録'));
        this.router.navigate(['/']).catch()
        this.uiStore.dispatch(switchFalseLoading());

      }
    } catch (e) {
      console.log(e);
      this.toasterService.warning(this.translateService.instant('メンバー登録に失敗しました。すでに登録済みのメールアドレスの可能性があります。'));
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
  }
}
