import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {SessionState} from "../../store/sessions/sessions.reducer";
import {AuthService} from "../../services/auth/auth.service";

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  currentSession: SessionState | undefined;
  emailVerified = true;
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}
  async ngOnInit() {
    await new Promise(resolve => setTimeout(resolve, 2000));
    if (!this.authService?.currentSession.isAnonymous
      &&  !this.authService?.currentAuth?.emailVerified)
    {
      this.emailVerified = this.authService.currentAuth.emailVerified;
    }
  }
  moveEmailVerify(): void {
    this.router.navigate(['my-page/email-verify'])
      .then(() => {})
      .catch((err) => {
        console.log(err);
      })
  }
}
