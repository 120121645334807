import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {categories} from "../../models/categories";
import {CurrentUser, getIsOem, SessionState, setSession} from "../../store/sessions/sessions.reducer";
import {Channel} from "../../models/channel";
import {ChannelsService} from "../../services/channels/channels.service";
import {AuthService} from "../../services/auth/auth.service";
import {getHeaderLogoSrc, getLoaderState, UiState} from "../../store/ui/ui.reducer";
import {Store} from "@ngrx/store";
import {Subscription} from "rxjs";
import {FormControl} from "@angular/forms";
import {LanguagesService} from "../../services/languages/languages.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-header-contents-public',
  templateUrl: './header-contents-public.component.html',
  styleUrls: ['./header-contents-public.component.scss']
})
export class HeaderContentsPublicComponent implements OnInit {
  loadingSubscription: Subscription;
  headerSubscription: Subscription;
  isOemSubscription: Subscription;
  sessionsSubscription: Subscription;
  isShowHeader = true;
  isOpenSearchModal = false;
  isOpenLoginModal = false;
  currentUser: CurrentUser = new CurrentUser();
  protected readonly categories = categories;
  currentSession: SessionState | undefined = undefined;
  listOfChannels: Channel[] = [];
  src: string;
  isOem: boolean;
  languages = new FormControl<'ja' | 'en'>(this.languagesService.getDefaultLanguage());
  @Output() currentLanguageEmitter = new EventEmitter<'ja' | 'en'>();
  constructor(
    private channelsService: ChannelsService,
    private uiStore: Store<{ ui: UiState}>,
    private sessionStore: Store<{ session: UiState}>,
    private languagesService: LanguagesService,
    protected translateService: TranslateService,
  ) { }
  async ngOnInit(): Promise<void> {
    this.loadingSubscription = this.uiStore.select(getLoaderState).subscribe((loadingState) => {
      this.isShowHeader = !loadingState;
    });
    this.headerSubscription = this.uiStore.select(getHeaderLogoSrc).subscribe((headerLogoSrcState) => {
      if (headerLogoSrcState) {
        this.src = headerLogoSrcState;
      }
    });
    this.isOemSubscription = this.sessionStore.select(getIsOem).subscribe((isOem) => {
      this.isOem = isOem;
    });
    // ログイン状態取得
    this.sessionsSubscription = this.sessionStore
      .select(setSession)
      .pipe()
      .subscribe(async (sessionState) => {
        console.log(sessionState);
        this.currentSession = sessionState;
        this.currentUser = sessionState?.currentUser;
        // 言語
        if (this.currentUser?.language) {
          this.languages.setValue(this.currentUser?.language);
          this.currentLanguageEmitter.emit(this.currentUser?.language);
        } else {
          this.languages.setValue(this.languagesService.getCurrentLanguage());
          this.currentLanguageEmitter.emit(this.languagesService.getCurrentLanguage());
        }
        // チャンネルリスト取得
        this.channelsService.fetched = [];
        if (
          !this.currentSession?.currentUser) {
          return;
        }
        if (!this.currentSession?.currentUser?.currentAccountId) {
          return;
        }
        await this.channelsService.fetchByAccountId(this.currentSession.currentUser.currentAccountId);
        this.listOfChannels = this.channelsService.fetched;
      });
  }
  changeLoginModal(status: boolean): void {
    this.isOpenLoginModal = status;
  }
  changeSearchModal(status: boolean): void {
    this.isOpenSearchModal = !this.isOpenSearchModal;
  }
  getLogoStyle(): string {
    return `backgroundImage: url(${this.src});`;
  }
  async changeLanguage(): Promise<void> {
    this.currentLanguageEmitter.emit(this.languages.value);
    await this.languagesService.change(this.languages.value,
      this.currentUser?.id
    );
  }
}
