import { Component } from '@angular/core';

@Component({
  selector: 'app-category-top',
  templateUrl: './category-top.component.html',
  styleUrls: ['./category-top.component.scss']
})
export class CategoryTopComponent {

}
