import { Component } from '@angular/core';

@Component({
  selector: 'app-system-requirements',
  templateUrl: './system-requirements.component.html',
  styleUrls: ['./system-requirements.component.scss']
})
export class SystemRequirementsComponent {
  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

}
