<app-header-contents-channel></app-header-contents-channel>
<div class="l-content-front">
  <app-breadcrumb-front></app-breadcrumb-front>
  <app-notification></app-notification>
  <div class="l-content-front__inner">
    <div class="l-myPage">
      <div class="p-myPage">
        <div class="p-myPage__user">
          <div class="p-myPage__user__head">
            <div class="p-myPage__user__head__title" translate>マイページ</div>
            <div class="p-myPage__user__head__edit">
              <div class="p-myPage__user__head__edit__button">
                <button
                  type="button"
                  class="c-button c-button-primary"
                  routerLink="/my-page/personal"
                ><span translate>プロフィール編集</span></button>
              </div>
            </div>
          </div>
          <div class="p-user">
            <div class="p-user__avatar">
              <img
                *ngIf="!user?.profileImage?.src"
                ngSrc="no-image"
                width="160"
                height="90"
                ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                alt="">
              <img
                *ngIf="user?.profileImage?.src"
                ngSrc="{{user?.profileImage?.src}}"
                width="160"
                height="90"
                ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                alt="">
            </div>
            <div class="p-user__profile">
              <div class="p-user__profile__name">
                {{displayName}}
              </div>
              <div class="p-user__profile__id">
                ID: {{currentSession?.currentUser?.id}}
              </div>
              <div class="p-user__profile__id">
                Email: {{user?.email}}
              </div>
            </div>
          </div>
        </div>
        <div class="p-myPage__tabContent">
          <div class="c-tab">

            <!--　ラベル1 未読　-->
            <input type="radio" id="tab_selector-1" name="tab_selector" class="c-tab__selector" checked>
            <label for="tab_selector-1" class="c-tab__label">
              <span class="c-tab__label__icon c-tab__label__icon-notification"></span>
              <span class="c-tab__label__text" translate>未読</span>
            </label>

            <!--　ラベル2 受講　-->
            <input type="radio" id="tab_selector-2" name="tab_selector" class="c-tab__selector">
            <label for="tab_selector-2" class="c-tab__label">
              <span class="c-tab__label__icon c-tab__label__icon-course"></span>
              <span class="c-tab__label__text" translate>受講</span>
            </label>


            <!--<input type="radio" id="tab_selector-3" name="tab_selector" class="c-tab__selector">
            <label for="tab_selector-3" class="c-tab__label">
              <span class="c-tab__label__icon c-tab__label__icon-favorite"></span>
              <span class="c-tab__label__text">お気に入り</span>
            </label>

            <input type="radio" id="tab_selector-4" name="tab_selector" class="c-tab__selector">
            <label for="tab_selector-4" class="c-tab__label">
              <span class="c-tab__label__icon c-tab__label__icon-bookmark"></span>
              <span class="c-tab__label__text">ブック<b><br></b>マーク</span>
            </label>

            <input type="radio" id="tab_selector-5" name="tab_selector" class="c-tab__selector">
            <label for="tab_selector-5" class="c-tab__label">
              <span class="c-tab__label__icon c-tab__label__icon-history"></span>
              <span class="c-tab__label__text">履歴</span>
            </label>-->

            <!--　ラベル6 登録チャンネル　-->
            <input type="radio" id="tab_selector-6" name="tab_selector" class="c-tab__selector">
            <label for="tab_selector-6" class="c-tab__label">
              <span class="c-tab__label__icon c-tab__label__icon-channel"></span>
              <span class="c-tab__label__text" translate>登録チャンネル</span>
            </label>

            <!--　ラベル7 チャット　-->
            <input type="radio" id="tab_selector-7" name="tab_selector" class="c-tab__selector">
            <label for="tab_selector-7" class="c-tab__label">
              <span class="c-tab__label__icon c-tab__label__icon-chat"></span>
              <span class="c-tab__label__text" translate>チャット</span>
            </label>

            <hr>
            <!--　1　未読タブ　-->
            <div class="c-tab__content" id="tab_selector-1_content">
              <div class="c-card-myPage-chat">
                <ng-container *ngIf="unreadPosts.length === 0">
                  <div class="c-card-myPage-chat__item">
                    <div class="c-card-myPage-chat__item__text">
                      <div class="c-card-myPage-chat__item__guidance" translate>未読はありません</div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngFor="let u of unreadPosts">
                  <div (click)="clickUnread(u)" class="c-card-myPage-chat__item">
                    <div class="c-card-myPage-chat__item__thumb">
                      <img
                        *ngIf="!getChannelImageSrc(u.channelId)"
                        ngSrc="no-image"
                        width="160"
                        height="90"
                        ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                        alt="">
                      <img
                        *ngIf="getChannelImageSrc(u.channelId)"
                        ngSrc="{{getChannelImageSrc(u.channelId)}}"
                        width="160"
                        height="90"
                        ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                        alt="">
                    </div>
                    <div class="c-card-myPage-chat__item__text">
                      <div class="c-card-myPage-chat__item__text__status" translate>新着メッセージ</div>
                      <div class="c-card-myPage-chat__item__text__head">
                        <div class="c-card-myPage-chat__item__text__head__title
                         c-card-myPage-chat__item__text__head__title-opened"
                        >
                          {{getChannelName(u.channelId)}}
                        </div>
                      </div>
                      <div class="c-card-myPage-chat__item__text__body">{{u.body}}</div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

            <!--　2　受講タブ コースサブスク　-->
            <div class="c-tab__content" id="tab_selector-2_content">
              <div class="c-card-myPage-courses">
                <ng-container *ngIf="courseSubs?.length === 0">
                  <div class="c-card-myPage-courses__item">
                    <div class="c-card-myPage-courses__item__body">
                      <div class="c-card-myPage-courses__item__body__guidance" translate>受講中のコースはありません</div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngFor="let c of courseSubs">
                  <div
                    *ngIf="canShowCourse(c)"
                    routerLink="/channel/course/index/{{getChannelSlug(c.channelId)}}/{{c.courseId}}"
                    class="c-card-myPage-courses__item"
                  >
                    <div class="c-card-myPage-courses__item__thumb">
                      <div
                        class="c-card-myPage-courses__item__thumb__frame
                        c-card-myPage-courses__item__thumb__frame-circle"
                      >
                        <img
                          *ngIf="!getCourseImageSrc(c.courseId)"
                          ngSrc="no-image"
                          width="160"
                          height="90"
                          ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                          alt="">
                        <img
                          *ngIf="getCourseImageSrc(c.courseId)"
                          ngSrc="{{getCourseImageSrc(c.courseId)}}"
                          width="160"
                          height="90"
                          ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                          alt="">
                      </div>
                    </div>
                    <div class="c-card-myPage-courses__item__body">
                      <div class="c-card-myPage-courses__item__body__subTitle">{{getChannelName(c.channelId)}}</div>
                      <div class="c-card-myPage-courses__item__body__title">{{getCourseName(c.courseId)}}</div>
                      <div class="c-card-myPage-courses__item__body__progress">
                        <div class="p-progress">
                          <div class="p-progress__bar">
                            <div class="p-progress__bar__bg"></div>
                            <div class="p-progress__bar__body" [style]="getCourseProgressStyle(c.courseId)"></div>
                          </div>
                        </div>
                      </div>
                      <div class="c-card-myPage-courses__item__body__detail">
                        <span translate>受講完了率</span>　
                        {{getCourseProgress(c.courseId)}}%
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

            <!--　3　お気に入りタブ　-->
            <div class="c-tab__content" id="tab_selector-3_content">
              <div class="c-card-myPage-favorite">
                <ng-container *ngIf="favorites?.length === 0">
                  <div class="c-card-myPage-favorite__item">
                    <div class="c-card-myPage-favorite__item__body">
                      <div class="c-card-myPage-favorite__item__body__guidance" translate>お気に入りは登録されていません</div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngFor="let f of favorites">
                  <div class="c-card-myPage-favorite__item">
                    <div class="c-card-myPage-favorite__item__thumb">
                      <img src="assets/images/sampleImage_video_01.png" alt="">
                    </div>
                    <div class="c-card-myPage-favorite__item__body">
                      <div class="c-card-myPage-favorite__item__body__subTitle">「共感講座Ⓡ」シリーズ</div>
                      <div class="c-card-myPage-favorite__item__body__title">損しないための社会人マナー</div>
                      <div class="c-card-myPage-favorite__item__body__category" translate>動画</div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

            <!--　4　ブックマークタブ　-->
            <div class="c-tab__content" id="tab_selector-4_content">
              <div class="c-card-myPage-favorite">
                <ng-container *ngIf="bookmarks?.length === 0">
                  <div class="c-card-myPage-favorite__item">
                    <div class="c-card-myPage-favorite__item__body">
                      <div class="c-card-myPage-favorite__item__body__guidance" translate>ブックマークは登録されていません</div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngFor="let b of bookmarks">
                  <div class="c-card-myPage-favorite__item">
                    <div class="c-card-myPage-favorite__item__thumb">
                      <img src="assets/images/sampleImage_video_01.png" alt="">
                    </div>
                    <div class="c-card-myPage-favorite__item__body">
                      <div class="c-card-myPage-favorite__item__body__subTitle">「共感講座Ⓡ」シリーズ</div>
                      <div class="c-card-myPage-favorite__item__body__title">損しないための社会人マナー</div>
                      <div class="c-card-myPage-favorite__item__body__category" translate>動画</div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <!--　5　履歴タブ　-->
            <div class="c-tab__content" id="tab_selector-5_content">
              <div class="c-card-myPage-favorite">

                <ng-container *ngIf="histories?.length === 0">
                  <div class="c-card-myPage-favorite__item">
                    <div class="c-card-myPage-favorite__item__body">
                      <div class="c-card-myPage-favorite__item__body__guidance" translate>閲覧履歴は登録されていません</div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngFor="let h of histories">
                  <div class="c-card-myPage-favorite__item">
                    <div class="c-card-myPage-favorite__item__thumb">
                      <img src="assets/images/sampleImage_video_01.png" alt="">
                    </div>
                    <div class="c-card-myPage-favorite__item__body">
                      <div class="c-card-myPage-favorite__item__body__subTitle">「共感講座Ⓡ」シリーズ</div>
                      <div class="c-card-myPage-favorite__item__body__title">損しないための社会人マナー</div>
                      <div class="c-card-myPage-favorite__item__body__category" translate>動画</div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

            <!--　6　登録チャンネルタブ　-->
            <div class="c-tab__content" id="tab_selector-6_content">
              <div class="c-card-myPage-courses">
                <ng-container *ngIf="channelSubs?.length === 0">
                  <div class="c-card-myPage-courses__item">
                    <div class="c-card-myPage-courses__item__body">
                      <div class="c-card-myPage-courses__item__body__guidance" translate>登録チャンネルはありません</div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngFor="let c of channelSubs">
                  <div routerLink="/channel/{{getChannelSlug(c.channelId)}}"
                       class="c-card-myPage-courses__item"
                  >
                    <div class="c-card-myPage-courses__item__thumb">
                      <div
                        class="c-card-myPage-courses__item__thumb__frame
                       c-card-myPage-courses__item__thumb__frame-circle"
                      >
                        <img
                          *ngIf="!getChannelEyeCatchImageSrc(c.channelId)"
                          ngSrc="no-image"
                          width="160"
                          height="90"
                          ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                          alt="">
                        <img
                          *ngIf="getChannelEyeCatchImageSrc(c.channelId)"
                          ngSrc="{{getChannelEyeCatchImageSrc(c.channelId)}}"
                          width="160"
                          height="90"
                          ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                          alt="">
                      </div>
                    </div>
                    <div class="c-card-myPage-courses__item__body">
                      <div class="c-card-myPage-courses__item__body__subTitle">{{getChannelSlug(c.channelId)}}</div>
                      <div class="c-card-myPage-courses__item__body__title">{{getChannelName(c.channelId)}}</div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

            <!--　7　チャットタブ　-->
            <div class="c-tab__content" id="tab_selector-7_content">
              <div class="c-card-myPage-chat">
                <ng-container *ngIf="chatThreads?.length === 0">
                  <div class="c-card-myPage-chat__item">
                    <div class="c-card-myPage-chat__item__text">
                      <div class="c-card-myPage-chat__item__guidance" translate>チャットはありません</div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngFor="let c of chatThreads ">
                  <div
                    (click)="openChatModal(c.accountId, c.channelId)"
                    class="c-card-myPage-chat__item"
                  >
                    <div
                      class="c-card-myPage-chat__item__thumb
                       c-card-myPage-chat__item__thumb-circle"
                    >
                      <img
                        *ngIf="!getChannelImageSrc(c.channelId)"
                        ngSrc="no-image"
                        width="160"
                        height="90"
                        ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                        alt="">
                      <img
                        *ngIf="getChannelImageSrc(c.channelId)"
                        ngSrc="{{getChannelImageSrc(c.channelId)}}"
                        width="160"
                        height="90"
                        ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                        alt="">
                    </div>
                    <div class="c-card-myPage-chat__item__text">
                      <div class="c-card-myPage-chat__item__text__head">
                        <div class="c-card-myPage-chat__item__text__head__title
                         c-card-myPage-chat__item__text__head__title-opened"
                        >
                          {{getChannelName(c.channelId)}}
                        </div>
                        <div class="c-card-myPage-chat__item__text__head__date">{{c.postedAt | datetimeFormatFirestore}}</div>
                      </div>
                      <div class="c-card-myPage-chat__item__text__body">{{c.body}}</div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="c-courseList-horizontal">
      <!--<div class="c-courseList-horizontal__head">
        <div class="c-courseList-horizontal__head__title">人気のコース</div>
        <div class="c-courseList-horizontal__head__more">すべてを見る</div>
      </div>
      <div class="c-courseList-horizontal__items">
        <div class="c-courseList-horizontal__items__item">
          <div class="c-courseList-horizontal__items__item__thumb">
            <img src="/assets/images/sampleImage_slides_01.png" alt="">
          </div>
          <div class="c-courseList-horizontal__items__item__title">
            損しないための社会人マナー
          </div>
        </div>
        <div class="c-courseList-horizontal__items__item">
          <div class="c-courseList-horizontal__items__item__thumb">
            <img src="/assets/images/sampleImage_video_02.png" alt="">
          </div>
          <div class="c-courseList-horizontal__items__item__title">
            損しないための社会人マナー
          </div>
        </div>
        <div class="c-courseList-horizontal__items__item">
          <div class="c-courseList-horizontal__items__item__thumb">
            <img src="/assets/images/sampleImage_slides_01.png" alt="">
          </div>
          <div class="c-courseList-horizontal__items__item__title">
            損しないための社会人マナー
          </div>
        </div>
        <div class="c-courseList-horizontal__items__item">
          <div class="c-courseList-horizontal__items__item__thumb">
            <img src="/assets/images/sampleImage_video_02.png" alt="">
          </div>
          <div class="c-courseList-horizontal__items__item__title">
            損しないための社会人マナー
          </div>
        </div>
        <div class="c-courseList-horizontal__items__item">
          <div class="c-courseList-horizontal__items__item__thumb">
            <img src="/assets/images/sampleImage_slides_01.png" alt="">
          </div>
          <div class="c-courseList-horizontal__items__item__title">
            損しないための社会人マナー
          </div>
        </div>
        <div class="c-courseList-horizontal__items__item">
          <div class="c-courseList-horizontal__items__item__thumb">
            <img src="/assets/images/sampleImage_video_02.png" alt="">
          </div>
          <div class="c-courseList-horizontal__items__item__title">
            損しないための社会人マナー
          </div>
        </div>
      </div>-->
    </div>
  </div>
</div>
<app-footer></app-footer>

<app-chat-user
  (isOpenModalEmitter)="changeOpenChatModal($event)"
  [isOpenModal]="isOpenChatModal"
  [onUser]="false"
  [targetAccount]="chatAccount"
  [channel]="chatChannel"
  [user]="user"
></app-chat-user>

