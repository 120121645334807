<app-header-contents-channel></app-header-contents-channel>
<div
  *ngIf="isPublished"
  class="l-content-front">
  <app-breadcrumb-front></app-breadcrumb-front>
  <app-notification></app-notification>
  <div class="l-content-front__inner">
    <div class="l-top">
      <div class="p-channelTop">
        <div class="p-channelTop__head">
          <div class="p-channelTop__head__kv">
            <ng-container *ngFor="let pr of currentCourse.prImages;index as i">
              <img
                *ngIf="!pr?.src && kvIndex === i + 1"
                ngSrc="no-image"
                width="1280"
                height="720"
                ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                alt="">
              <img
                *ngIf="pr?.src && kvIndex === i + 1"
                ngSrc="{{pr?.src}}"
                width="1280"
                height="720"
                ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
                alt="">
            </ng-container>

            <img
              *ngIf="!currentCourse?.eyeCatchImage?.src && kvIndex === 0"
              ngSrc="no-image"
              width="1280"
              height="720"
              ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
              alt="">
            <img
              *ngIf="currentCourse?.eyeCatchImage?.src && kvIndex === 0"
              ngSrc="{{currentCourse?.eyeCatchImage?.src}}"
              width="1280"
              height="720"
              ngSrcset="160w,240w,320w,480w,640w,768w,960w,1024w,1156w,1280w"
              alt="">
          </div>


          <div class="p-channelTop__promotion">
            <div class="c-courseList-horizontal">
              <div class="c-courseList-horizontal__items">

                <div
                  (click)="changeEyeCatch(0)"
                  class="c-courseList-horizontal__items__item">
                  <div class="c-courseList-horizontal__items__item__thumb">
                    <img
                      *ngIf="!currentCourse?.eyeCatchImage?.src"
                      ngSrc="no-image"
                      width="240"
                      height="135"
                      ngSrcset="160w,240w"
                      alt="">
                    <img
                      *ngIf="currentCourse?.eyeCatchImage?.src"
                      ngSrc="{{currentCourse?.eyeCatchImage?.src}}"
                      width="240"
                      height="135"
                      ngSrcset="160w,240w"
                      alt="">
                  </div>
                </div>

                <div *ngFor="let prImage of currentCourse?.prImages;index as i"
                     (click)="changeEyeCatch(i + 1)"
                     class="c-courseList-horizontal__items__item">
                  <div class="c-courseList-horizontal__items__item__thumb">
                    <img
                      [ngSrc]="prImage?.src"
                      width="240"
                      height="135"
                      ngSrcset="160w,240w"
                      alt="">>
                  </div>
                </div>

                <div *ngIf="currentCourse?.prImages?.length < 1"
                     class="c-courseList-horizontal__items__item">
                  <div class="c-courseList-horizontal__items__item__thumb">
                    <img
                      ngSrc="no-image"
                      width="240"
                      height="135"
                      ngSrcset="160w,240w"
                      alt="">
                  </div>
                </div>

                <div *ngIf="currentCourse?.prImages?.length < 2"
                     class="c-courseList-horizontal__items__item">
                  <div class="c-courseList-horizontal__items__item__thumb">
                    <img
                      ngSrc="no-image"
                      width="240"
                      height="135"
                      ngSrcset="160w,240w"
                      alt="">
                  </div>
                </div>

                <div *ngIf="currentCourse?.prImages?.length < 3"
                     class="c-courseList-horizontal__items__item">
                  <div class="c-courseList-horizontal__items__item__thumb">
                    <img
                      ngSrc="no-image"
                      width="240"
                      height="135"
                      ngSrcset="160w,240w"
                      alt="">
                  </div>
                </div>

                <div *ngIf="currentCourse?.prImages?.length < 4"
                     class="c-courseList-horizontal__items__item">
                  <div class="c-courseList-horizontal__items__item__thumb">
                    <img
                      ngSrc="no-image"
                      width="240"
                      height="135"
                      ngSrcset="160w,240w"
                      alt="">
                  </div>
                </div>

                <div *ngIf="currentCourse?.prImages?.length < 5"
                     class="c-courseList-horizontal__items__item">
                  <div class="c-courseList-horizontal__items__item__thumb">
                    <img
                      ngSrc="no-image"
                      width="240"
                      height="135"
                      ngSrcset="160w,240w"
                      alt="">
                  </div>
                </div>

                <div *ngIf="currentCourse?.prImages?.length < 6"
                     class="c-courseList-horizontal__items__item">
                  <div class="c-courseList-horizontal__items__item__thumb">
                    <img
                      ngSrc="no-image"
                      width="240"
                      height="135"
                      ngSrcset="160w,240w"
                      alt="">
                  </div>
                </div>

                <div *ngIf="currentCourse?.prImages?.length < 7"
                     class="c-courseList-horizontal__items__item">
                  <div class="c-courseList-horizontal__items__item__thumb">
                    <img
                      ngSrc="no-image"
                      width="240"
                      height="135"
                      ngSrcset="160w,240w"
                      alt="">
                  </div>
                </div>

                <div *ngIf="currentCourse?.prImages?.length < 8"
                     class="c-courseList-horizontal__items__item">
                  <div class="c-courseList-horizontal__items__item__thumb">
                    <img
                      ngSrc="no-image"
                      width="240"
                      height="135"
                      ngSrcset="160w,240w"
                      alt="">
                  </div>
                </div>

              </div>
            </div>
          </div>





          <div class="p-channelTop__head__title">
            <div
              routerLink="/channel/{{currentChannel.slug}}"
              class="p-channelTop__head__title__channelName">
              {{currentChannel?.name}}
            </div>
            <div class="p-channelTop__head__title__courseName">
              {{currentCourse?.name}}
            </div>
          </div>





          <div
            *ngIf="tagPayload.length > 0"
            class="p-channelTop__head__tags">
            <div *ngFor="let tag of tagPayload"
                 routerLink="/tag/{{tag.name}}"
                 class="p-channelTop__head__tags__item"><span>#{{tag.name}}</span></div>
          </div>



          <ng-container>
            <div
              *ngIf="!courseSubscriptionStatus?.activated"
              class="p-channelTop__head__button">
              <button
                (click)="openModal()"
                type="button"
                class="c-button c-button-primary"
              ><span translate>このコースを受講する</span></button>
            </div>
            <div class="p-channelTop__head__cancelPolicy" translate>キャンセルポリシー</div>
          </ng-container>

          <ng-container *ngIf="courseSubscriptionStatus?.activated">
            <div class="p-channelTop__head__button">
              <button
                routerLink="/channel/course/index/{{currentChannel.slug}}/{{currentCourse.id}}"
                type="button"
                class="c-button c-button-secondary"
              ><span translate>受講を開始する</span></button>
            </div>
          </ng-container>
        </div>

        <ng-container *ngIf="isMyChannel">
          <div routerLink="/manager/channel/{{channelId}}"
               class="p-channelTop__head__button">
            <button
              type="button"
              class="c-button c-button-secondary"
            ><span translate>管理へ移動</span></button>
          </div>
        </ng-container>

        <!--<div class="p-channelTop__suggestButton">
          <button type="button" class="c-button c-button-disable">今すぐ受講する</button>
        </div>-->

        <dl class="p-channelTop__main">
          <dt class="p-channelTop__main__title" translate>コース概要</dt>
          <dd class="p-channelTop__main__body">
            <div class="p-channelTop__main__body__content">
              <p class="p-channelTop__main__body__content__description" [innerHTML]="currentChannel?.description | nl2br"></p>
            </div>
          </dd>
          <dt class="p-channelTop__main__title" translate>提供者</dt>
          <dd class="p-channelTop__main__body">
            <div class="p-channelTop__main__body__wrap">
              <div class="p-user">
                <div class="p-user__avatar">
                  <img
                    *ngIf="!currentChannel.profileImage?.src"
                    ngSrc="no-image"
                    width="240"
                    height="135"
                    ngSrcset="160w,240w,320w"
                    alt="">
                  <img
                    *ngIf="currentChannel.profileImage?.src"
                    [ngSrc]="currentChannel.profileImage.src"
                    width="240"
                    height="135"
                    ngSrcset="160w,240w,320w"
                    alt="">
                </div>
                <div class="p-user__profile">
                  <div class="p-user__profile__name">
                    {{currentChannel?.name}}
                  </div>
                  <div class="p-user__profile__id">
                    @{{currentChannel?.slug}}
                  </div>
                </div>
              </div>
            </div>
          </dd>

          <ng-container *ngIf="currentCourse.monetized">
            <dt class="p-channelTop__main__title" translate>コースの価格</dt>
            <dd class="p-channelTop__main__body">
              <div class="p-channelTop__main__body__content">
                <dl class="p-channelTop__main__body__content__plans">
                  <dt class="p-channelTop__main__body__content__plans__name" translate>講座単体の価格</dt>
                  <dd class="p-channelTop__main__body__content__plans__price">
                    ¥980
                    <span translate>/月</span>
                  </dd>
                  <dt class="p-channelTop__main__body__content__plans__name" translate>チャンネルメンバーシップ</dt>
                  <dd class="p-channelTop__main__body__content__plans__price">
                    ¥{{currentChannel.channelSubscriptionPrice}}
                    <span translate>/月</span></dd>
                  <dt class="p-channelTop__main__body__content__plans__name" translate>チャンネルプレミアムメンバーシップ</dt>
                  <dd class="p-channelTop__main__body__content__plans__price">
                    ¥{{currentChannel.channelSubscriptionPriceForPremium}}
                    <span translate>/月</span></dd>
                </dl>
                <p class="p-channelTop__main__body__content__notes">
                  <span translate>この講座は月額固定料金のサブスクリプションメンバーシップ制です。メンバーシップに参加すると全ての一般コースを受講できます。</span><br>
                  <span translate>プレミアム受講にアップグレードすると限定講座を含む全ての講座を受講できます。</span>
                </p>
              </div>
            </dd>
          </ng-container>

          <ng-container *ngIf="!currentCourse.monetized && channelSubscriptionStatus.activated">
            <dt class="p-channelTop__main__title" translate>コースの価格</dt>
            <dd class="p-channelTop__main__body">
              <div class="p-channelTop__main__body__content">
                <p class="p-channelTop__main__body__content__notes" translate>
                  この講座はメンバーシップ対象です。
                </p>
              </div>
            </dd>
          </ng-container>
        </dl>


        <div class="p-channelTop__promotion">
          <div class="c-courseList-horizontal">
            <div class="c-courseList-horizontal__head">
              <div class="c-courseList-horizontal__head__title" translate>人気のコース</div>
              <div class="c-courseList-horizontal__head__more" translate>すべてを見る</div>
            </div>
            <app-favorites></app-favorites>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer *ngIf="channelOwnerAccount?.accountType === accountType.general"></app-footer>
<app-footer-business *ngIf="channelOwnerAccount?.accountType === accountType.oem"></app-footer-business>


<section class="l-modal" *ngIf="isOpenModal">
  <div class="c-modal">
    <div class="c-modal__head">
      <div class="c-modal__head__title" translate>コースの受講</div>
      <div class="c-modal__head__closeButton" (click)="closeModal()"></div>
    </div>
    <div class="c-modal__main">
      <div class="c-modal__main__description">

        <ng-container *ngIf="!currentCourse.monetized">
          <div class="c-modal__main__description__title" translate>コースの価格</div>
          <div class="c-modal__main__description__body">
            <p translate>このコースは無料体験で利用出来ます。</p>
            <p>
              <span translate>コース体験</span><br>
              <strong>¥0<span translate>/月</span></strong>
            </p>
            <div class="c-modal__main__description__body__caption" translate>
              このコースは無料体験用として無料で提供されています。
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="currentCourse.monetized && currentCourse.monetizeMethod === MonetizeMethods.channelSubscription">
          <div class="c-modal__main__description__title" translate>コースの価格</div>
          <div class="c-modal__main__description__body">
            <p translate>この講座はメンバーシップの対象です。いますぐ受講を開始できます。</p>
            <p>
              <span translate>メンバーシップ対象</span><br>
              <strong>¥0<span translate>/月</span></strong>
            </p>
            <div class="c-modal__main__description__body__caption" translate>
              メンバーシップに参加中のため、コースを受講できます。
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="currentCourse.monetized && currentCourse.monetizeMethod === MonetizeMethods.premiumChannelSubscription">
          <div class="c-modal__main__description__title" translate>コースの価格</div>
          <div class="c-modal__main__description__body">
            <p translate>この講座はプレミアムメンバーシップの対象です。いますぐ受講を開始できます。</p>
            <p>
              <span translate>プレミアムメンバーシップ対象</span><br>
              <strong>¥0<span translate>/月</span></strong>
            </p>
            <div class="c-modal__main__description__body__caption" translate>
              プレミアムメンバーシップに参加中のため、コースを受講できます。
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="currentCourse.monetized && currentCourse.monetizeMethod === MonetizeMethods.courseSubscription">
          <div class="c-modal__main__description__title" translate>コースの価格</div>
          <div class="c-modal__main__description__body">
            <p translate>このコースは月額制の有料購読対象です。</p>
            <p>
              <span translate>コースの有料購読</span><br>
              <strong>¥{{currentCourse.courseSubscriptionPrice}}<span translate>/月</span></strong>
            </p>
            <div class="c-modal__main__description__body__caption" translate>
              このコースは月額固定料金のサブスクリプションメンバーシップ制です。
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="currentCourse.monetized && currentCourse.monetizeMethod === MonetizeMethods.single">
          <div class="c-modal__main__description__title" translate>コースの価格</div>
          <div class="c-modal__main__description__body">
            <p translate>この講座は単体での購入となります。</p>
            <p>
              <span translate>コースの単体購入</span><br>
              <strong>¥{{currentCourse.unitPrice}}<span translate>/月</span></strong>
            </p>
            <div class="c-modal__main__description__body__caption" translate>この講座は一度購入すると、提供が終わるまでの間、閲覧することが可能です。</div>
          </div>
        </ng-container>

      </div>


      <div class="c-modal__main__subscription">
        <div class="c-modal__main__subscription__buttons">

          <ng-container *ngIf="!currentCourse.monetized">
            <div
              (click)="applySubscriptionFree()"
              class="c-modal__main__subscription__buttons__button">
              <button class="c-button c-button-primary"
              ><span translate>このコースをを体験する</span></button>
            </div>
          </ng-container>

          <ng-container *ngIf="currentCourse.monetized
          && currentCourse.monetizeMethod === MonetizeMethods.courseSubscription
          && !courseSubscriptionStatus?.activated">
            <div
              (click)="applySubscription()"
              class="c-modal__main__subscription__buttons__button">
              <button class="c-button c-button-primary"
              ><span translate>このコースをを受講する</span></button>
            </div>
          </ng-container>

          <ng-container *ngIf="currentCourse.monetized
          && currentCourse.monetizeMethod === MonetizeMethods.single
          && !courseSubscriptionStatus?.activated">
            <div
              (click)="applySubscriptionPurchase()"
              class="c-modal__main__subscription__buttons__button">
              <button class="c-button c-button-primary"
              ><span translate>このコースをを購入する</span></button>
            </div>
          </ng-container>

        </div>
        <div class="c-modal__main__subscription__policy" translate>キャンセルポリシー</div>
      </div>
    </div>
  </div>
</section>
<app-modal-login (isOpenLoginModalEmitter)="changeLoginModal($event)"  [isOpenLoginModal]="isOpenLoginModal"></app-modal-login>
<app-modal-payment-credit [isOpenCreditModal]="isOpenCreditModal" ></app-modal-payment-credit>
<app-not-found *ngIf="isNotFound"></app-not-found>
