import { NgModule } from '@angular/core';
import {RouterModule, Routes, TitleStrategy} from '@angular/router';
import {AuthGuard} from "./guard/auth.guard";
import {environment} from "../environments/environment";
import {ListComponent} from "./pages/list/list.component"; // ページリスト
import {TopComponent} from "./pages/top/top.component";
import {NotFoundPageComponent} from "./pages/not-found-page/not-found-page.component";
import {SignupComponent} from "./pages/signup/signup.component";
import {LoginComponent} from "./pages/login/login.component";
import {LogoutComponent} from "./pages/logout/logout.component";
import {PasswordResetComponent} from "./pages/password-reset/password-reset.component";
import { ManagerChannelCourseMetaComponent } from './pages/manager-channel-course-meta/manager-channel-course-meta.component';
import { ManagerChannelCourseUsersComponent } from './pages/manager-channel-course-users/manager-channel-course-users.component';
import { ContractsComponent } from './pages/contracts/contracts.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { BusinessContractComponent } from './pages/business-contract/business-contract.component';
import { ManagerChannelCreateComponent } from './pages/manager-channel-create/manager-channel-create.component';
import { ManagerChannelTopComponent } from './pages/manager-channel-top/manager-channel-top.component';
import { ManagerChannelCoursesComponent } from './pages/manager-channel-courses/manager-channel-courses.component';
import { ManagerChannelMetaComponent } from './pages/manager-channel-meta/manager-channel-meta.component';
import { MyPagePaymentCreditComponent } from './pages/my-page-payment-credit/my-page-payment-credit.component';
import { MyPageTopComponent } from './pages/my-page-top/my-page-top.component';
import { MyPagePaymentHistoryComponent } from './pages/my-page-payment-history/my-page-payment-history.component';
import { MyPageNotificationsComponent } from './pages/my-page-notifications/my-page-notifications.component';
import { MyPagePasswordComponent } from './pages/my-page-password/my-page-password.component';
import { MyPagePersonalComponent } from './pages/my-page-personal/my-page-personal.component';
import { ChannelCourseTopComponent } from './pages/channel-course-top/channel-course-top.component';
import { ChannelCourseRemoteReservationComponent } from './pages/channel-course-remote-reservation/channel-course-remote-reservation.component';
import { MyPageQuitComponent } from './pages/my-page-quit/my-page-quit.component';
import {EmailLinkComponent} from "./pages/email-link/email-link.component";
import {EmailVerifyComponent} from "./pages/email-verify/email-verify.component";
import {ManagerChannelsComponent} from "./pages/manager-channels/manager-channels.component";
import {
  ManagerChannelCourseCreateComponent
} from "./pages/manager-channel-course-create/manager-channel-course-create.component";
import {PublicGuard} from "./guard/public.guard";
import { ChannelCourseSurveyComponent } from './pages/channel-course-survey/channel-course-survey.component';
import { MyPageAuthSetupComponent } from './pages/my-page-auth-setup/my-page-auth-setup.component';
import { ManagerChannelCourseInspectionApplyComponent } from './pages/manager-channel-course-inspection-apply/manager-channel-course-inspection-apply.component';
import { ManagerChannelCourseInspectionResultComponent } from './pages/manager-channel-course-inspection-result/manager-channel-course-inspection-result.component';
import { ManagerChannelRemoveComponent } from './pages/manager-channel-remove/manager-channel-remove.component';
import { ManagerChannelPaymentComponent } from './pages/manager-channel-payment/manager-channel-payment.component';
import { ManagerChannelReportsComponent } from './pages/manager-channel-reports/manager-channel-reports.component';
import { ManagerChannelPassbookComponent } from './pages/manager-channel-passbook/manager-channel-passbook.component';
import {CategoriesComponent} from "./pages/categories/categories.component";
import { CategoryTopComponent } from './pages/category-top/category-top.component';
import {ChannelTopComponent} from "./pages/channel-top/channel-top.component";
import { ManagerBusinessComponent } from './pages/manager-business/manager-business.component';
import {ManagerUsersComponent} from "./pages/manager-users/manager-users.component";
import {ManagerAccountsComponent} from "./pages/manager-accounts/manager-accounts.component";
import {
  ManagerChannelCourseCertComponent
} from "./pages/manager-channel-course-cert/manager-channel-course-cert.component";
import {InquiryComponent} from "./pages/inquiry/inquiry.component";
import { ContentsTopComponent } from './pages/contents-top/contents-top.component';
import { ChannelCourseIndexComponent } from './pages/channel-course-index/channel-course-index.component';
import {ManagerChannelUsersComponent} from "./pages/manager-channel-users/manager-channel-users.component";
import {
  ManagerChannelCourseSurveyViewComponent
} from "./pages/manager-channel-course-survey-view/manager-channel-course-survey-view.component";
import {
  ChannelCourseExaminationComponent
} from "./pages/channel-course-examination/channel-course-examination.component";
import {
  ManagerChannelCourseRemoveComponent
} from "./pages/manager-channel-course-remove/manager-channel-course-remove.component";
import { SystemRequirementsComponent } from './pages/system-requirements/system-requirements.component';
import { SloComponent } from './pages/slo/slo.component';
import {AppTitleStrategy} from "./app-title-strategy";
import {SsoComponent} from "./sso/sso/sso.component";
import {LoginByEntraComponent} from "./sso/login-by-entra/login-by-entra.component";

const siteName = environment.siteName;
export const routes: Routes = [
  // 「管理」アカウント
  { path: 'manager/business', component: ManagerBusinessComponent, title: '[manager]ビジネスアカウント情報編集（事業者情報と銀行口座登録） |' + siteName, canActivate: [AuthGuard] },
  { path: 'manager/accounts', component: ManagerAccountsComponent, title: '[manager]ビジネスアカウント切替 |' + siteName, canActivate: [AuthGuard] },
  { path: 'manager/users', component: ManagerUsersComponent, title: '[manager]アカウントユーザー管理 |' + siteName, canActivate: [AuthGuard] },
  // 「管理」チャンネル
  { path: 'manager/channels', component: ManagerChannelsComponent, title: '[manager]チャンネル選択 |' + siteName, canActivate: [AuthGuard] },
  { path: 'manager/channel/create', component: ManagerChannelCreateComponent, title: '[manager]チャンネル作成 |' + siteName, canActivate: [AuthGuard] },
  { path: 'manager/channel/:channelId', component: ManagerChannelTopComponent, title: '[manager]チャンネルサマリー |' + siteName, canActivate: [AuthGuard] },
  { path: 'manager/channel/users/:channelId', component: ManagerChannelUsersComponent, title: '[manager]チャンネル受講者 |' + siteName, canActivate: [AuthGuard] },
  { path: 'manager/channel/meta/:channelId', component: ManagerChannelMetaComponent, title: '[manager]チャンネル概要情報の編集 |' + siteName, canActivate: [AuthGuard] },
  { path: 'manager/channel/remove/:channelId', component: ManagerChannelRemoveComponent, title: '[manager]チャンネル削除 |' + siteName, canActivate: [AuthGuard] },
  { path: 'manager/channel/passbook/:channelId', component: ManagerChannelPassbookComponent, title: '[manager]売上通帳 |' + siteName, canActivate: [AuthGuard] },
  { path: 'manager/channel/payment/:channelId/:userId', component: ManagerChannelPaymentComponent, title: '[manager]プラン変更･支払い管理 |' + siteName, canActivate: [AuthGuard] },
  // 「管理」コース
  { path: 'manager/channel/courses/:channelId/:courseId', component: ManagerChannelCoursesComponent, title: '[manager]コース一覧 |' + siteName, canActivate: [AuthGuard] },
  { path: 'manager/channel/course/create/:channelId', component: ManagerChannelCourseCreateComponent, title: '[manager]コース新規作成 |' + siteName, canActivate: [AuthGuard] },
  { path: 'manager/channel/course/meta/:channelId/:courseId', component: ManagerChannelCourseMetaComponent, title: '[manager]コース概要情報の編集 |' + siteName, canActivate: [AuthGuard] },
  { path: 'manager/channel/course/remove/:channelId/:courseId', component: ManagerChannelCourseRemoveComponent, title: '[manager]コースの削除 |' + siteName, canActivate: [AuthGuard] },
  { path: 'manager/channel/course/users/:channelId/:courseId', component: ManagerChannelCourseUsersComponent, title: '[manager]コース受講中ユーザーリスト |' + siteName, canActivate: [AuthGuard] },
  { path: 'manager/channel/course/reports/:channelId', component: ManagerChannelReportsComponent, title: '[manager]売上レポート |' + siteName, canActivate: [AuthGuard] },
  { path: 'manager/channel/course/inspection/apply/:channelId/:courseId', component: ManagerChannelCourseInspectionApplyComponent, title: '[manager]講座 公開審査申込 |' + siteName, canActivate: [AuthGuard] },
  { path: 'manager/channel/course/inspection/result/:channelId/:courseId', component: ManagerChannelCourseInspectionResultComponent, title: '[manager]講座 公開審査結果通知 |' + siteName, canActivate: [AuthGuard] },
  { path: 'manager/channel/course/survey/view/:slug/:courseId/:partId/:userId', component: ManagerChannelCourseSurveyViewComponent, title: 'アンケート回答 |' + siteName , canActivate: [AuthGuard]},
  {
    path: 'manager/channel/course/index/:channelId/:courseId',
    loadChildren: () => import('./pages/manager-channel-course-index/manager-channel-course-index.module')
      .then(m => m.ManagerChannelCoursesIndexModule),
    title: '[manager]コース内容の編集 |' + siteName,
    canActivate: [AuthGuard]
  },
  // { path: 'manager/channel/course/remotes/:channelId/:courseId', component: ManagerChannelCourseRemotesComponent, title: '[manager]オンライン講座 |' + siteName, canActivate: [AuthGuard] },
  // { path: 'manager/channel/course/remotes/reservations/:channelId/:courseId', component: ManagerChannelCourseRemotesReservationsComponent, title: '[manager]オンライン講座予約管理 |' + siteName, canActivate: [AuthGuard] },
  // { path: 'manager/channel/course/lives/:channelId/:courseId', component: ManagerChannelCourseLivesComponent, title: '[manager]集合研修（リアル研修/講義） |' + siteName, canActivate: [AuthGuard] },
  // { path: 'manager/channel/course/lives/reservations/:channelId/:courseId', component: ManagerChannelCourseLivesReservationsComponent, title: '[manager]集合研修（リアル研修/講義）予約管理 |' + siteName, canActivate: [AuthGuard] },
  // { path: 'manager/channel/course/tryouts/create/:channelId/:courseId', component: ManagerChannelCourseExaminationCreateComponent, title: '[manager]テスト作成 |' + siteName, canActivate: [AuthGuard] },
  { path: 'manager/channel/course/cert/edit/:channelId/:courseId/:id', component: ManagerChannelCourseCertComponent, title: '[manager]修了証設定 |' + siteName, canActivate: [AuthGuard] },
  // マイページ
  { path: 'my-page', component: MyPageTopComponent, title: 'マイページトップ |' + siteName, canActivate: [AuthGuard] },
  { path: 'my-page/notifications', component: MyPageNotificationsComponent, title: '通知設定 |' + siteName, canActivate: [AuthGuard] },
  { path: 'my-page/password', component: MyPagePasswordComponent, title: 'パスワード変更 |' + siteName, canActivate: [AuthGuard] },
  { path: 'my-page/personal', component: MyPagePersonalComponent, title: 'プロフィール |' + siteName, canActivate: [AuthGuard] },
  { path: 'my-page/quit', component: MyPageQuitComponent, title: 'ユーザー削除 |' + siteName, canActivate: [AuthGuard] },
  { path: 'my-page/auth-setup', component: MyPageAuthSetupComponent, title: '2段階認証 |' + siteName, canActivate: [AuthGuard] },
  { path: 'my-page/payment/credit', component: MyPagePaymentCreditComponent, title: 'クレジットカード登録・変更 |' + siteName, canActivate: [AuthGuard] },
  { path: 'my-page/payment/history', component: MyPagePaymentHistoryComponent, title: '購入履歴 |' + siteName, canActivate: [AuthGuard] },
  { path: 'my-page/email-verify', component: EmailVerifyComponent, title: 'メールアドレスの有効性確認 |' + siteName, canActivate: [AuthGuard] },

  // SSO 対応(Microsoft)
  { path: 'login-by-entra', component: LoginByEntraComponent, title: 'ログイン(EntraId) |' + siteName},
  { path: 'sso', component: SsoComponent, title: '' + siteName},


  // サービスページ
  {
    path: 'video-player',
    loadChildren: () => import('./components/video-player-inner/video-player-inner.module')
      .then(m => m.VideoPlayerInnerModule),
    title: siteName,
    canActivate: [PublicGuard]
  },
  {
    path: 'channel/course/video/:slug/:courseId/:partId',
    loadChildren: () => import('./pages/channel-course-video/channel-course-video.module')
      .then(m => m.ChannelCoursesVideosModule),
    title: '動画講座 |' + siteName,
    canActivate: [PublicGuard]
  },
  {
    path: 'channel/course/slide/:slug/:courseId/:partId',
    loadChildren: () => import('./pages/channel-course-slide/channel-course-slide.module')
      .then(m => m.ChannelCoursesSlidesModule),
    title: 'スライド講座 |' + siteName,
    canActivate: [PublicGuard]
  },
  {
    path: 'channel/course/examination/:slug/:courseId/:partId/:questionIndex',
    component: ChannelCourseExaminationComponent,
    title: '学習テスト |' + siteName,
    canActivate: [PublicGuard]
  },
  { path: 'channel/course/survey/:slug/:courseId/:partId', component: ChannelCourseSurveyComponent, title: 'アンケート |' + siteName , canActivate: [PublicGuard]},
  { path: 'channel/course/remote/reservation/:slug/:courseId/:contentKey', component: ChannelCourseRemoteReservationComponent, title: 'オンライン講座予約 |' + siteName , canActivate: [PublicGuard]},
  { path: 'channel/course/index/:slug/:courseId', component: ChannelCourseIndexComponent, title: 'コース目次 |' + siteName , canActivate: [PublicGuard]},
  { path: 'channel/course/:slug/:courseId', component: ChannelCourseTopComponent, title: 'コースTOP |' + siteName , canActivate: [PublicGuard]},
  { path: 'categories', component: CategoriesComponent, title: 'カテゴリー一覧 |' + siteName  , canActivate: [PublicGuard]},
  { path: 'channel/:slug', component: ChannelTopComponent, title: 'チャンネルTOP |' + siteName , canActivate: [PublicGuard]},
  // { path: 'channels', component: ChannelsComponent, title: 'チャンネル一覧 |' + siteName  , canActivate: [PublicGuard]},
  { path: 'email-link', component: EmailLinkComponent, title: 'メールリンクログイン |' + siteName},
  { path: 'logout', component: LogoutComponent, title: 'ログアウト |' + siteName,  canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent, title: 'ログイン |' + siteName },
  { path: 'signup', component: SignupComponent, title: 'サインアップ |' + siteName },
  { path: 'inquiry', component: InquiryComponent, title: 'お問い合わせ |' + siteName },
  { path: 'password/reset', component: PasswordResetComponent, title: 'パスワードリセット |' + siteName },
  { path: 'business-contract', component: BusinessContractComponent, title: '特定商取引法に基づく表記 |' + siteName },
  { path: 'privacy-policy', component: PrivacyComponent, title: 'プライバシーポリシー |' + siteName },
  { path: 'contracts', component: ContractsComponent, title: '利用規約 |' + siteName },
  { path: 'slo', component: SloComponent, title: 'サービスレベル目標（SLO） |' + siteName },
  { path: 'system-requirements', component: SystemRequirementsComponent, title: '推奨動作環境 |' + siteName },
  { path: 'category/:id', component: CategoryTopComponent, title: 'カテゴリTOP |' + siteName },
  { path: 'list', component: ListComponent, title: 'ルーティングリスト |' + siteName },
  { path: 'lp', component: TopComponent, title: 'TOP |' + siteName  , canActivate: [PublicGuard]},
  { path: '', component: ContentsTopComponent, title: '' + siteName, canActivate: [PublicGuard]},
  { path: '**', component: NotFoundPageComponent, title: 'ページがみつかりません |' + siteName },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule],
  providers: [{ provide: TitleStrategy, useClass: AppTitleStrategy }]
})
export class AppRoutingModule { }
