import { createAction, union } from '@ngrx/store';
export const switchTrueShowBreadcrumb = createAction('[breadcrumb Component] switchTrueShowBreadcrumb');
export const switchFalseShowBreadcrumb = createAction('[Sessions Component] switchFalseShowBreadcrumb');
export const storeUrlParams = createAction(
  '[breadcrumb Component] storeUrlParams',
  (payload?: { urlParams: string[] }) => ({ payload: payload.urlParams }),
);
const actions = union({
  switchTrueShowBreadcrumb: switchTrueShowBreadcrumb,
  switchFalseShowBreadcrumb: switchFalseShowBreadcrumb,
  storeUrlParams: storeUrlParams,
});

export type BreadcrumbActions = typeof actions;
