<div class="l-breadcrumb-front" *ngIf="isShow">
  <div class="p-breadcrumb-front">
    <ul>
      <li
        *ngFor="let page of tree"
        (click)="move(page)"
        translate
      >{{page.title}}</li>
    </ul>
  </div>
</div>
