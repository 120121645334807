export enum counters {
  // データ作成数
  channels = 'channels', // current
  courses = 'courses', // current
  tags = 'tags', // current
  followed = 'followed', // current
  users = 'users', // current
  unread = 'unread',  // current

  // 期間計測
  joinChannelTerm = 'joinChannelTerm',
  favorites = 'favorites',
  quited = 'quited',
  completed = 'completed',
  memberships = 'memberships',
  amountOfSales = 'amountOfSales',

  // 期間中の利用回数カウント
  bulkUpload = 'bulkUpload', // term
  bulkDownload = 'bulkDownload', // term

  // 累計カウント
  joinChannel = 'joinChannel', // 総登録者数
  channelNumOfTotal = 'channelNumOfTotal',
　courseJoinTotal = 'courseJoinTotal',// コースのべ受講人数
  courseNunOfCurrent = 'courseNunOfCurrent' //コース現在の受講者数


}
