<div class="l-top">
  <div class="p-top">
    <div class="p-top__notfound">
      <h2 class="p-top__notfound__statusCode">404</h2>
      <p class="p-top__notfound__title" translate>お探しのページまたはコンテンツが見つかりません。</p>
      <p class="p-top__notfound__detail"
         [innerHTML]="'コンテンツ所有者の設定により一時的にアクセスできない状況にあるか、移動もしくは削除、またはアップロードされていない可能性があります。\nブラウザの戻るボタンを押して前のページに戻り、URLのご提供者にご確認ください。'| translate | nl2br">
      </p>

      <div class="p-top__notfound__button">
        <button
          class="c-button c-button-returnTop"
          routerLink="/"
        ><span translate>TOPに戻る</span></button>
      </div>
    </div>
  </div>
</div>
