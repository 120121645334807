import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {
  PaymentInput,
  PaymentTokenResponse
} from "../../models/payment";
import {AuthService} from "../../services/auth/auth.service";
import {TranslateService} from "@ngx-translate/core";
declare var Fincode: any;
@Component({
  selector: 'app-modal-payment-credit',
  templateUrl: './modal-payment-credit.component.html',
  styleUrls: ['./modal-payment-credit.component.scss']
})
export class ModalPaymentCreditComponent implements OnInit {
  @Input() isOpenCreditModal = true;
  private scriptUrl = environment.gmoFincodeScriptUrl;
  fincode: any;
  constructor(
    private formBuilder: FormBuilder,
    private toasterService: ToastrService,
    private authService: AuthService,
    private translateService: TranslateService,
  ) { }
  cardNumber = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
      Validators.minLength(14),
      Validators.maxLength(16),
    ]
  });
  expireY = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(2),
    ]
  });
  expireM = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(2),
    ]
  });
  holderName = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(100),
    ]
  });
  cvc = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(4),
    ]
  });
  form = this.formBuilder.group({
    cardNumber: this.cardNumber,
    expireY: this.expireY,
    expireM: this.expireM,
    holderName: this.holderName,
    cvc: this.cvc,
  });
  async ngOnInit(): Promise<void> {
    await this.prepareFincode();
    this.cardNumber.setValue('4242424242424242');
    this.expireY.setValue('24');
    this.expireM.setValue('08');
    this.holderName.setValue('TEST CARD');
    this.cvc.setValue('123');
  }
  async prepareFincode(): Promise<void> {
    if (!environment.gmoFincodePublicKey) {
      return
    }
    const script = document.createElement('script');
    script.async = true;
    script.src = this.scriptUrl;
    const div = document.getElementById('fincodeScript');
    div.insertAdjacentElement('afterend', script);
    // 確実にロードが終わったことを確認する
    const intervalId = setInterval(() => {
        if (typeof Fincode === "function") {
          this.fincode = Fincode(environment.gmoFincodePublicKey);
          clearInterval(intervalId);
        }
      },
      100
    );
  }
  async submit(): Promise<void> {
    console.log(this.form.controls.cardNumber.value);
    console.log(this.form.controls.expireY.value);
    console.log(this.form.controls.expireM.value);
    console.log(this.form.controls.holderName.value);
    console.log(this.form.controls.cvc.value);
    if (this.form.invalid) {
      this.toasterService.warning(this.translateService.instant('入力されていない項目があります。'), this.translateService.instant('カード情報'));
      return;
    }
    const res = await this.createToken()
      .catch(() => {
        this.toasterService.warning(this.translateService.instant('カードの登録に失敗しました。入力された情報をご確認ください。'));
      });
    console.log(res);
    // 顧客登録があるかどうか調べる
    // 顧客を登録する
    // カードを登録する
    // カードリストを取得する
  }
  async createToken(): Promise<PaymentTokenResponse> {
    return new Promise((resolve, reject) => {
      const expire = `${this.expireY.value}${this.expireM.value}`
      const card: PaymentInput = {
        card_no : this.cardNumber.value,
        expire : expire,
        holder_name: this.holderName.value,
        security_code: this.cvc.value,
        number: 1,
      }
      this.fincode.tokens(card, (status: number, response: PaymentTokenResponse) => {
        if (200 === status) {
          resolve(response);
        } else {
          reject();
        }
      });
    })
  }
  closeModal(): void {
    this.isOpenCreditModal = false;
  }
}
