import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {environment} from "../../../environments/environment";
import {AppConfig} from "../../models/app-config";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  CollectionReference, Query
} from "@angular/fire/compat/firestore";

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  dbDocument: AngularFirestoreDocument<AppConfig>;
  currentDocument: Observable<AppConfig> = of(new AppConfig(null));
  collectionRef: AngularFirestoreCollection;
  query: CollectionReference | Query;
  constructor(
    private fireStore: AngularFirestore,
  ) { }
  fetch(accountId: string): Promise<AppConfig> {
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('accounts')
        .doc(accountId)
        .collection('apps')
        .doc(environment.appId)
        .collection('configs')
        .doc('appConfig')
        .get()
        .toPromise()
        .then((data) => {
          const appConfig = data.data() as AppConfig;
          resolve(appConfig);
        })
        .catch((e) => {
          console.log('reject');
          reject(e);
        });
    })
  }
}
