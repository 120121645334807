import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  CollectionReference,
  DocumentData,
  Query
} from "@angular/fire/compat/firestore";
import {Observable} from "rxjs";
import {Bookmark} from "../../models/bookmark";

@Injectable({
  providedIn: 'root'
})
export class HistoriesService {
  collectionRef: AngularFirestoreCollection;
  docs: Observable<DocumentData[]>;
  query: Query;
  constructor(
    private fireStore: AngularFirestore,
  ) { }
  add(uid: string, favorite: Bookmark): void {
    this.fireStore
      .collection('users')
      .doc(uid)
      .collection('apps')
      .doc(environment.appId)
      .collection<{}>('histories')
      .add(favorite)
      .then(() => {})
      .catch(() => {});
  }
  connectSnapShots(uid: string): void {
    this.collectionRef = this.fireStore
      .collection('users')
      .doc(uid)
      .collection('apps')
      .doc(environment.appId)
      .collection<{}>('histories' , ref => {
        let query: CollectionReference | Query = ref;
        query = query.orderBy('createdAt', 'desc');
        this.query = query;
        return query;
      });
    this.docs = this.collectionRef.valueChanges({ idField: 'id' });
  }
  remove(uid: string, historyId: string): void {
    this.fireStore
      .collection('users')
      .doc(uid)
      .collection('apps')
      .doc(environment.appId)
      .collection<{}>('histories')
      .doc(historyId)
      .delete()
      .then(() => {})
      .catch(() => {});
  }
}
