<app-forbidden *ngIf="forbidden"></app-forbidden>
<div class="l-main" *ngIf="!forbidden">
  <app-navigations></app-navigations>
  <div class="l-content">
    <app-breadcrumb></app-breadcrumb>
    <div class="l-content__inner" (click)="closeSubMenu()">
      <div class="c-content-header">
        <div
          *ngIf="currentChannel.name"
          class="c-content-header__channelName"
        ><span translate>チャンネル名称</span>{{currentChannel.name}} ({{currentChannel.slug}})</div>
        <div class="c-content-header__title" translate>コース一覧</div>
      </div>
      <div class="l-templates">
        <div class="p-templates">
          <div class="p-templates__content">
            <div class="p-templates__content__createButton">
              <button
                routerLink="/manager/channel/course/create/{{channelId}}"
                class="c-button c-button-create"><span translate>コース作成</span></button>
            </div>
            <div class="p-templates__content__list">
              <ng-container *ngFor="let c of courses">
                <div class="c-card-courses">
                  <div class="c-card-courses__inner">
                    <div class="c-card-courses__inner__left">
                      <div class="c-card-courses__inner__left__thumb">
                        <img
                          *ngIf="!c?.eyeCatchImage?.src"
                          ngSrc="no-image"
                          width="160"
                          height="90"
                          ngSrcset="160w,240w,320w,480w"
                          alt="">
                        <img
                          *ngIf="c?.eyeCatchImage?.src"
                          ngSrc="{{c?.eyeCatchImage?.src}}"
                          width="160"
                          height="90"
                          ngSrcset="160w,240w,320w,480w"
                          alt="">
                      </div>
                      <div class="c-card-courses__inner__left__title">
                        <div class="c-card-courses__inner__left__title__textBody">{{c.name}}</div>
                      </div>
                    </div>
                    <div class="c-card-courses__inner__right">
                      <ng-container *ngIf="!currentChannel?.isOem">
                        <ul class="c-card-courses__inner__right__meta">
                          <li><span translate>のべ受講人数</span>：---- <span translate>人</span></li>
                          <li><span translate>現在の受講数</span>：---- <span translate>人</span></li>
                        </ul>
                      </ng-container>
                      <ul class="c-card-courses__inner__right__meta">
                        <li><span translate>最終更新</span>:{{ c.updatedAt | datetimeFormatFirestore}}</li>
                        <li><span translate>作成日時</span>:{{ c.createdAt | datetimeFormatFirestore}}</li>
                      </ul>
                      <div
                        *ngIf="c.published"
                        class="c-card-courses__inner__right__status"
                        translate
                      >公開中</div>
                      <div
                        *ngIf="!c.published"
                        class="c-card-courses__inner__right__status"
                        translate
                      >非公開</div>
                      <div class="c-card-courses__inner__right__buttons">
                        <input type="checkbox" class="c-card-courses__inner__right__buttons__opener" id="action-buttons-{{c.id}}">
                        <label
                          class="c-card-courses__inner__right__buttons__head"
                          for="action-buttons-{{c.id}}"
                          translate
                        >操作選択</label>
                        <ul class="c-card-courses__inner__right__buttons__body">
                          <li>
                            <a routerLink="/manager/channel/course/meta/{{channelId}}/{{c.id}}" translate>
                              コース設定
                            </a>
                          </li>
                          <li>
                            <a routerLink="/manager/channel/course/index/{{channelId}}/{{c.id}}" translate>
                              目次編集
                            </a>
                          </li>
                          <li>
                            <a routerLink="/manager/channel/course/users/{{channelId}}/{{c.id}}" translate>
                              受講者
                            </a>
                          </li>
                          <li>
                            <a routerLink="/channel/course/{{currentChannel.slug}}/{{c.id}}" translate>
                              プレビュー
                            </a>
                          </li>

                        </ul>
                      </div>
                    </div>




                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
