import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nl2br'
})
export class Nl2brPipe implements PipeTransform {
  transform(body: string) {
    if (!body) {
      return '';
    }
    body = body.replace(/\n/g, '<br/>');
    const regexpUrl = /(https?:\/\/[\w/:%#\$&\?\(\)~\.=\+\-]+)/g;
    body = body.replace(regexpUrl, '<a href="$1" target="_blank">$1</a>');
    return body;
  }
}
