import {nanoid} from "nanoid";
import {
  ContentFrame, FrameTypes,
} from "./course-frames";
import {PublicImage} from "./public-image";
export class CoursePart {
  id: string | null;
  name: string |null;
  frameType: FrameTypes;
  constructor(frameType: FrameTypes) {
    this.id = nanoid();
    this.name = '';
    this.frameType = frameType;
  }
}
export class LoadingFrames {
  id: string;
  sections: LoadingSection[];
  constructor() {
    this.id = null;
    this.sections = [];
  }
}
export class LoadingSection {
  id: string;
  parts: LoadingPart[];
  constructor() {
    this.id = null;
    this.parts = [];
  }
}
export class LoadingPart {
  id: string;
  frameType: FrameTypes;
  frame: ContentFrame | null;
  constructor(
    frameType: FrameTypes
  ) {
    this.id = null;
    this.frameType = frameType;
    this.frame = null;
  }
}
