export class MonetizeMethod {
  key: MonetizeMethods;
  nameJa: string;
}
export enum MonetizeMethods {
  freeTrial = 'freeTrial',
  single = 'single',
  courseSubscription = 'courseSubscription',
  channelSubscription = 'channelSubscription',
  premiumChannelSubscription = 'premiumChannelSubscription',
}
export const monetizeMethods: MonetizeMethod[] = [
  {
    key: MonetizeMethods.single,
    nameJa: 'コース単体販売',
  },
  {
    key: MonetizeMethods.courseSubscription,
    nameJa: 'コース単体サブスクリプション',
  },
  {
    key: MonetizeMethods.channelSubscription,
    nameJa: 'チャンネルサブスクリプション対象にする',
  },
];
