import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {categories} from "../../models/categories";
import {SessionState} from "../../store/sessions/sessions.reducer";
import {Channel} from "../../models/channel";

@Component({
  selector: 'app-modal-search',
  templateUrl: './modal-search.component.html',
  styleUrls: ['./modal-search.component.scss']
})
export class ModalSearchComponent implements OnInit {
  @Input() isOpenSearchModal = false;
  @Output() isOpenSearchModalEmitter = new EventEmitter<boolean>();

  constructor() { }
  protected readonly categories = categories;
  currentSession: SessionState | undefined;
  listOfChannels: Channel[] = [];
  async ngOnInit(): Promise<void> {}
  changeSearchModal(status: boolean): void {
    this.isOpenSearchModal = status;
  }
}
