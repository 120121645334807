<div class="l-header">
  <div class="p-header" [ngClass]="isOpenSearchModal ? 'p-header-fixed' : ''">
    <div class="p-header__left">
      <!--<div (click)="toggleSearchModal()" class="p-header__left__search"></div>-->
    </div>
    <div class="p-header__logo"></div>
    <div class="p-header__right">
      <div class="p-header__right__login"></div>
      <div class="p-header__right__menu"></div>
    </div>
  </div>
</div>


