import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {AuthService} from '../services/auth/auth.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PublicGuard implements CanActivate {
  sessionsSubscription: Subscription;
  constructor(
    private authService: AuthService,
  ){}
  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> {
    return this.authService
      .checkLoginState()
      .pipe(
        map(  (auth) => {
          this.authService.currentAuth = auth;
          if (!auth) {
            return true;
          }
          if (auth.isAnonymous) {
            console.log('isAnonymous');
            return true;
          }
          // ログインOK
          return true;
        })
      );
  }
}
