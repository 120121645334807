export class Tag {
  id: string;
  count: number;
  name: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  constructor(
    id: string = '',
    tag: Tag = null
  ) {
    if (tag) {
      this.id = id;
      this.count = tag.count;
      this.name = tag.name;
      this.createdAt = tag.createdAt;
      this.createdBy = tag.createdBy;
      this.updatedAt = tag.updatedAt;
    } else {
      this.id = null;
      this.count = 0;
      this.name = null;
      this.createdAt = new Date();
      this.createdBy = null;
      this.updatedAt = new Date();
    }
  }
  deserialize(): any {
    const json = JSON.stringify(this)
    return JSON.parse(json)
  }
}
