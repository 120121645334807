import {Injectable} from '@angular/core';
import {
  AngularFirestore,
  CollectionReference,
  Query
} from "@angular/fire/compat/firestore";
import {environment} from '../../../environments/environment';
import {SurveyAnswers} from "../../models/answer";
import {nanoid} from "nanoid";

@Injectable({
  providedIn: 'root'
})
export class CourseSurveyAnswersService {
  constructor(
    private fireStore: AngularFirestore,
  ) { }
  async create(
    courseId: string,
    uid: string,
    surveyAnswers: SurveyAnswers
  ): Promise<void> {
    surveyAnswers.uid = uid;
    surveyAnswers.createdAt = new Date();
    surveyAnswers.createdBy = uid;
    surveyAnswers.updatedAt = new Date();
    surveyAnswers.updatedBy = uid;
    const copied = JSON.parse(JSON.stringify(surveyAnswers));
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('apps')
        .doc(environment.appId)
        .collection('courses')
        .doc(courseId)
        .collection('courseSurveyAnswers')
        .doc(copied.id)
        .set(copied)
        .then(() => {
          console.log('created');
          resolve();
        })
        .catch((e) => {
          console.log('reject create progress');
          reject(e);
        });
    })
  }
  async fetchByUser(
    courseId: string,
    contentKey: string,
    uid: string
  ): Promise<SurveyAnswers[]|undefined> {
    return new Promise((resolve, reject) => {
      const ref = this.fireStore
        .collection('apps')
        .doc(environment.appId)
        .collection('courses')
        .doc(courseId);
        ref.collection(
          'courseSurveyAnswers',
          ref => {
            let query: CollectionReference | Query = ref;
            query = query.where('uid', '==', uid);
            query = query.where('contentKey', '==', contentKey);
            query = query.orderBy('createdAt', 'desc');
            return query;
          })
        .get()
        .toPromise()
        .then((data) => {
          const result: SurveyAnswers[] = [];
          data.forEach(doc => {
            const newDoc = doc.data() as SurveyAnswers;
            result.push(newDoc);
          })
          resolve(result);
        })
        .catch((e) => {
          console.log('reject fetchByUser SurveyAnswers');
          reject(e);
        });
    })
  }
  async fetchByUserLatest(
    courseId: string,
    uid: string
  ): Promise<SurveyAnswers|undefined> {
    return new Promise((resolve, reject) => {
      const ref = this.fireStore
        .collection('apps')
        .doc(environment.appId)
        .collection('courses')
        .doc(courseId);
      ref.collection(
        'courseSurveyAnswers',
        ref => {
          let query: CollectionReference | Query = ref;
          query = query.where('uid', '==', uid);
          query = query.orderBy('createdAt', 'desc');
          query = query.limit(1);
          return query;
        })
        .get()
        .toPromise()
        .then((data) => {
          data.forEach(doc => {
            const newDoc = doc.data() as SurveyAnswers;
            resolve(newDoc);
          })
        })
        .catch((e) => {
          console.log('reject fetchByMy SurveyAnswers');
          reject(e);
        });
    })
  }
  async update(
    courseId: string,
    uid: string,
    surveyAnswers: SurveyAnswers
  ): Promise<any> {
    surveyAnswers.updatedAt = new Date();
    surveyAnswers.updatedBy = uid;
    const forUpdate = JSON.parse(JSON.stringify(surveyAnswers));
    console.log(forUpdate);
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('apps')
        .doc(environment.appId)
        .collection('courses')
        .doc(courseId)
        .collection('courseSurveyAnswers')
        .doc(surveyAnswers.id)
        .set(forUpdate)
        .then((data) => {
          resolve(data);
        })
        .catch((e) => {
          console.log('reject');
          reject(e);
        });
    })
  }
  async remove(
    accountId: string,
    courseId: string,
    id: string,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.fireStore
        .collection('accounts')
        .doc(accountId)
        .collection('apps')
        .doc(environment.appId)
        .collection('courses')
        .doc(courseId)
        .collection('courseSurveyAnswers')
        .doc(id)
        .delete()
        .then((data) => {
          resolve(data);
        })
        .catch((e) => {
          console.log('reject');
          reject(e);
        });
    })
  }
}
