import {PublicImage} from "./public-image";
import {nanoid} from "nanoid";

export enum ChatWays {
  received = 'received',
  system = 'system',
  send = 'send',
}
export class Chat {
  id?: string;
  accountId: string;
  channelId: string;
  uid: string;
  content?: Content
  way: ChatWays
  createdAt: Date;
  timestamp: number;
  constructor(way: ChatWays) {
    this.id = nanoid();
    this.accountId = null;
    this.channelId = null;
    this.uid = null;
    this.content = null;
    this.way = way;
    this.createdAt = null;
    this.timestamp = null;
  }
}
export enum ContentTypes {
  text = 'text',
  image = 'image',
  download = 'download'
}
export class Content {
  body: string;
  image: PublicImage;
  type: ContentTypes;
  constructor(type: ContentTypes) {
    this.body = '';
    this.image = null;
    this.type = type
  }
}
export class ChatThread {
  id?: string;
  accountId: string
  channelId: string;
  courseId: string;
  uid: string;
  body: string;
  unread: boolean;
  postedAt: Date;
  readAt: Date;
  constructor() {
    this.id = null;
    this.accountId = null;
    this.channelId = null;
    this.courseId = null;
    this.uid = null;
    this.postedAt = null;
    this.readAt = null;
    this.body = null;
    this.unread = true;
  }
}
