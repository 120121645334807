<app-forbidden *ngIf="forbidden"></app-forbidden>
<div class="l-main" *ngIf="!forbidden">
  <app-navigations></app-navigations>
  <div class="l-content">
    <app-breadcrumb></app-breadcrumb>
    <div class="l-content__inner">
      <div class="c-content-header">
        <div class="c-content-header__title" translate>コース受講中のユーザーリスト</div>
      </div>
      <div class="l-templates">
        <div class="p-templates">
          <div class="p-templates__content">
            <div class="p-profile">

              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="name" translate>名前</label>
                  <input [formControl]="name" class="c-form-input" type="text" name="name" id="name" placeholder=>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="email" translate>メールアドレス</label>
                  <input [formControl]="email" class="c-form-input" type="text" name="email" id="email" placeholder="">
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label" for="companyName" translate>会社名</label>
                  <input [formControl]="companyName" class="c-form-input" type="text" name="companyName" id="companyName" placeholder="">
                </div>
              </div>
              <div class="p-profile__row">
                <label class="c-form-label" translate>学習進捗率</label>
                <div class="c-form-radio">
                  <div class="c-form-radio__wrap">
                    <input [formControl]="progress" (click)="changeProgress(0)" type="radio" name="visibility" [value]="0" id="visibility_zero">
                    <label class="c-form-label" for="visibility_zero">0%</label>
                  </div>
                  <div class="c-form-radio__wrap">
                    <input [formControl]="progress" (click)="changeProgress(0.3)" type="radio" name="visibility" [value]="0.3" id="visibility_thirty">
                    <label class="c-form-label" for="visibility_thirty" translate>0以上〜30%以内</label>
                  </div>
                  <div class="c-form-radio__wrap">
                    <input [formControl]="progress" (click)="changeProgress(0.5)" type="radio" name="visibility" [value]="0.5" id="visibility_fifty">
                    <label class="c-form-label" for="visibility_fifty" translate>50%以上</label>
                  </div>
                  <div class="c-form-radio__wrap">
                    <input [formControl]="progress" (click)="changeProgress(0.7)" type="radio" name="visibility" [value]="0.7" id="visibility_seventy">
                    <label class="c-form-label" for="visibility_seventy" translate>70%以上</label>
                  </div>
                  <div class="c-form-radio__wrap">
                    <input [formControl]="progress" (click)="changeProgress(0.9)" type="radio" name="visibility" [value]="0.9" id="visibility_ninety">
                    <label class="c-form-label" for="visibility_ninety" translate>90%以上</label>
                  </div>
                  <div class="c-form-radio__wrap">
                    <input [formControl]="progress" (click)="changeProgress(1)" type="radio" name="visibility" [value]="1" id="visibility_full">
                    <label class="c-form-label" for="visibility_full">100%</label>
                  </div>
                </div>
              </div>
              <div class="p-profile__row">
                <label class="c-form-label" for="companyName" translate>受講開始日</label>
                <div class="p-profile__row__range">
                  <div class="p-profile__row__range__input">
                    <label class="c-form-label c-form-label-light" for="day-from">From</label>
                    <input [formControl]="from" class="c-form-input" type="date" name="day-from" id="day-from">
                  </div>
                  <div class="p-profile__row__range__waveDash"></div>
                  <div class="p-profile__row__range__input">
                    <label class="c-form-label c-form-label-light" for="day-to">To</label>
                    <input [formControl]="to" class="c-form-input" type="date" name="day-to" id="day-to">
                  </div>
                </div>

              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__searchButton">
                  <div class="p-profile__row__searchButton__wrap">
                    <div class="p-profile__row__searchButton__wrap__button">
                      <button (click)="resetForm()" class="c-button c-button-cancel"><span translate>条件をリセット</span></button>
                    </div>
                    <div class="p-profile__row__searchButton__wrap__button">
                      <button (click)="search()" class="c-button c-button-primary"><span translate>検索する</span></button>
                    </div>
                  </div>
                  <div class="p-profile__row__searchButton__wrap">
                    <div class="p-profile__row__searchButton__wrap__button p-profile__row__searchButton__wrap__button-alignRight">
                      <button (click)="downloadUserCsv()" class="c-button c-button-download"><span translate>CSVダウンロード</span></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-templates__content__list">
              <div class="c-list-user">
                <div class="c-list-user__head">
                  <div class="c-list-user__head__name" translate>会社名</div>
                  <div class="c-list-user__head__name" translate>名前</div>
                  <div class="c-list-user__head__date" translate>受講開始日</div>
                  <div class="c-list-user__head__date" translate>最終受講日</div>
                  <div class="c-list-user__head__progress" translate>進捗率</div>
                  <div class="c-list-user__head__online" translate>回答</div>
                  <div class="c-list-user__head__talk" translate>チャット</div>
                  <div class="c-list-user__head__edit" translate>情報</div>
                </div>
                <div class="c-list-user__body">
                  <ng-container *ngFor="let user of showUsers">
                    <div *ngIf="user?.user?.id" class="c-list-user__body__row">
                      <div class="c-list-user__body__row__name">{{user?.user?.companyName}}</div>
                      <div class="c-list-user__body__row__name">{{user?.user?.fullName}}</div>
                      <div class="c-list-user__body__row__date">{{user?.subscription?.activatedAt | dateFormatFirestore}}</div>
                      <div class="c-list-user__body__row__date">{{user?.courseProgress?.updatedAt | dateFormatFirestore}}</div>
                      <div class="c-list-user__body__row__progress">{{ roundProgress(user?.subscription?.totalProgress) }}%</div>
                      <div  class="c-list-user__body__row__certificate">
                        <div *ngIf="user?.user" (click)="openSurveysModal(user?.user)" class="c-list-user__body__row__edit__button"></div>
                      </div>
                      <div class="c-list-user__body__row__talk">
                        <button *ngIf="user?.user" (click)="openChatModal(user?.user)" class="c-list-user__body__row__talk__button"></button>
                      </div>
                      <div class="c-list-user__body__row__edit">
                        <button *ngIf="user?.user" (click)="openEditModal(user?.user)" class="c-list-user__body__row__edit__button"></button>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="isOpenSurveysModal">
  <section class="l-modal">
    <div class="c-modal">
      <div class="c-modal__head">
        <div class="c-modal__head__title" translate>回答を選択</div>
        <div class="c-modal__head__closeButton" (click)="closeSurveysModal()"></div>
      </div>
      <div class="c-modal__main">
        <div *ngIf="answers.length === 0" translate>回答はありません。</div>
        <div class="c-modal__main__list">
          <div class="c-simpleList">
            <ng-container *ngFor="let chapter of currentCourse?.courseIndex?.chapters; index as chapterIndex">
              <ng-container *ngFor="let section of chapter?.sections;index as sectionIndex">
                <ng-container *ngFor="let frame of frames[chapterIndex]?.sections[sectionIndex]?.parts;index as partIndex">
                  <div
                    *ngIf="frame?.id && frame.frameType === frameTypes.SurveyFrame && showLastAnswerDateTime(frame.id)"
                    (click)="showAnswer(frame?.id)"
                    class="c-simpleList__row">
                    <div class="c-simpleList__row__date">{{chapterIndex + 1}}-{{sectionIndex + 1}}-{{partIndex + 1}}</div>
                    <div class="c-simpleList__row__message">
                      {{frame.frame.name}}(<span translate>回答日</span>：
                      {{showLastAnswerDateTime(frame.id)}} )
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>

          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>



<app-edit-oem-user-modal
  (isOpenModalEmitter)="changeOpenEditUserModal($event)"
  (removedEmitter)="afterRemovedUser($event)"
  [isOpen]="isOpenEditModal"
  [editUser]="editUser"
  [account]="currentAccount"
  [channel]="currentChannel"
></app-edit-oem-user-modal>

<app-chat
  (isOpenModalEmitter)="changeOpenChatModal($event)"
  [isOpenModal]="isOpenChatModal"
  [onUser]="false"
  [targetUser]="chatTargetUser"
  [channel]="currentChannel"
  [account]="currentAccount"
></app-chat>

