import { Injectable } from '@angular/core';
import {User} from "../../models/user";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {firstValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private fireStore: AngularFirestore,
  ) { }
  createUser(user: User): Promise<void> {
    return this.fireStore
      .collection('users')
      .doc(user.id)
      .set(user);
  }
  update(user: User): Promise<void> {
    return this.fireStore
      .collection('users')
      .doc(user.id)
      .set(user);
  }
  async fetchUser(id: string): Promise<User | null> {
    const doc = this.fireStore
      .collection('users')
      .doc(id)
      .get();
    const data = await firstValueFrom(doc);
    if (!data.data()) {
      return null;
    }
    const user = data.data() as User;
    user.id = data.id as string;
    return user;
  }
}
