import {Plan, PlanNames} from "./plan";

export class AppConfig {
  activated: boolean;
  activatedAt: Date | null;
  suspended: boolean;
  suspendedAt: Date | null;
  quited: boolean;
  quitedAt: Date | null;
  plan: Plan;
  constructor(planName: PlanNames) {
    this.activated = false;
    this.activatedAt = null;
    this.suspended = false;
    this.suspendedAt = null;
    this.quited = false;
    this.quitedAt = null;
    this.plan = new Plan(planName);
  }
  deserialize(): any {
    const json = JSON.stringify(this)
    return JSON.parse(json)
  }
}
