import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  email = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  showError = false;
  passwordForm = this.builder.group({
    email: this.email,
  });
  constructor(
    private router: Router,
    private builder: FormBuilder,
    private afAuth: AngularFireAuth,
    private toaster: ToastrService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
  }
  async onSubmit(): Promise<void> {
    this.showError = false;
    if (!this.passwordForm.valid) {
      this.showError = true;
      return;
    }
    this.afAuth.sendPasswordResetEmail(this.email.value)
      .then(() => {
        this.router.navigate(['/login']);
        this.toaster.success(this.translateService.instant('パスワードリセットのメールを送信しました。'), this.translateService.instant('パスワード'));
      })
      .catch((e) => {
        this.showError = true;
        console.log(e);
      })
  }
}
