export enum PlanNames {
  free = 'free',
  standard = 'standard',
  professional = 'professional',
  enterprise = 'enterprise',
  corporate = 'corporate',
}
export class Plan {
  name: PlanNames;
  planDetail: PlanDetail;
  appOptions: AppOptions[];
  secureOptions: SecureOptions[];
  price: number;
  constructor(planNam: PlanNames) {
    switch (planNam) {
      case PlanNames.free:
        this.planDetail = planFree;
        break;
      case PlanNames.standard:
        this.planDetail = planStandard;
        break;
      case PlanNames.professional:
        this.planDetail = planProfessional;
        break;
      case PlanNames.enterprise:
        this.planDetail = planEnterprise;
        break;
      case PlanNames.corporate:
        this.planDetail = planCorporate;
        break;
      default:
        this.planDetail = planFree;
        break;
    }
  }
  deserialize(): any {
    const json = JSON.stringify(this)
    return JSON.parse(json)
  }
}
export enum AppDetailName {
  appMain = 'appMain',
}
export enum AppMenus {}
export enum AppFunctions {}
export enum SecureOptions {
  auth2fa = 'auth2fa',
  grantIpAddresses = 'grantIpAddresses',
  history = 'history',
}
export enum AppOptions {
  googleConnect = 'googleConnect',
  analysis = 'analysis',
  promotion = 'promotion',
  fullAnalytics = 'fullAnalytics',
  crm = 'crm',
  isolation = 'isolation',
  csv = 'csv',
  bulkSendMail = 'bulkSendMail',
  movieUrl = 'movieUrl',
  tracking = 'tracking', // QR ・ ショートURLからの経路分析
}
export class Limits {
  users: number;
  customers: number;
  channels: number;
  tags: number;
  constructor() {
  }
  deserialize(): any {
    const json = JSON.stringify(this)
    return JSON.parse(json)
  }
}
export type PlanDetail = {
  name: PlanNames,
  basePrice: number,
  pricePerCustomer: number,
  shareRate: number,
  limits: {
    users: number;
    customers: number;
    channels: number;
    tags: number;
  },
  denyMenu: AppMenus[],
  denyFunction: AppFunctions[],
  terminateToCreate: AppMenus[],
  appOptions: AppOptions[],
  secureOptions: SecureOptions[],
};
export const planFree: PlanDetail = {
  name: PlanNames.free,
  basePrice: 0,
  pricePerCustomer: 0,
  shareRate: 1,
  limits: {
    users: 1,
    customers: 0,
    channels: 1,
    tags: 100,
  },
  denyMenu: [],
  denyFunction: [],
  terminateToCreate: [],
  appOptions: [],
  secureOptions: [],
};
export const planStandard: PlanDetail = {
  name: PlanNames.standard,
  basePrice: 0,
  pricePerCustomer: 0,
  shareRate: 0.6,
  limits: {
    users: 1,
    customers: 0,
    channels: 1,
    tags: 100,
  },
  denyMenu: [],
  denyFunction: [],
  terminateToCreate: [],
  appOptions: [],
  secureOptions: [],
};
export const planProfessional: PlanDetail = {
  name: PlanNames.professional,
  basePrice: 0,
  pricePerCustomer: 0,
  shareRate: 0.5,
  limits: {
    users: 1,
    customers: 0,
    channels: 1,
    tags: 100,
  },
  denyMenu: [],
  denyFunction: [],
  terminateToCreate: [],
  appOptions: [],
  secureOptions: [],
};
export const planEnterprise: PlanDetail = {
  name: PlanNames.enterprise,
  basePrice: 15000,
  pricePerCustomer: 0,
  shareRate: 0.5,
  limits: {
    users: 1,
    customers: 0,
    channels: 1,
    tags: 100,
  },
  denyMenu: [],
  denyFunction: [],
  terminateToCreate: [],
  appOptions: [],
  secureOptions: [],
};
export const planCorporate: PlanDetail = {
  name: PlanNames.corporate,
  basePrice: 2000,
  pricePerCustomer: 500,
  shareRate: 0,
  limits: {
    users: 30,
    customers: 9999999,
    channels: 1,
    tags: 100,
  },
  denyMenu: [],
  denyFunction: [],
  terminateToCreate: [],
  appOptions: [],
  secureOptions: [],
};
