import { Component, OnInit } from '@angular/core';
import {
  storeUrlState,
  switchFalseLoading,
  switchTrueLoading,
  switchTrueShowMenuInSideNavi,
  switchTrueShowSideNavigation
} from "../../store/ui/ui.actions";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../services/auth/auth.service";
import {Store} from "@ngrx/store";
import {UiState, UrlState} from "../../store/ui/ui.reducer";
import {ChannelsService} from "../../services/channels/channels.service";
import {Subscription} from "rxjs";
import {Channel} from "../../models/channel";
import {BreadcrumbState} from "../../store/breadcrumb/breadcrumb.reducer";
import {storeUrlParams, switchTrueShowBreadcrumb} from "../../store/breadcrumb/breadcrumb.actions";
import {map} from "rxjs/operators";
import {Account, AccountType} from "../../models/account";
import {AccountsService} from "../../services/accounts/accounts.service";
import {Unread} from "../../models/unread";
import {User} from "../../models/user";
import {BackendService} from "../../services/backend/backend.service";
import {UnreadService} from "../../services/unreads/unreads.service";

@Component({
  selector: 'app-manager-channel-top',
  templateUrl: './manager-channel-top.component.html',
  styleUrls: ['./manager-channel-top.component.scss']
})
export class ManagerChannelTopComponent implements OnInit {
  forbidden: boolean = false;
  private unreadSubscription: Subscription;
  private channelSubscription: Subscription;
  private readonly channelId: string;
  currentChannel: Channel = new Channel();
  channelOwnerAccount: Account;
  accountTypes = AccountType;
  isOpenChatModal: boolean = false;
  chatTargetUser: User;
  unreadPosts: Unread[] =[];
  users: User[] = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private breadcrumbStore: Store<{ breadcrumb: BreadcrumbState }>,
    private uiStore: Store<{ ui: UiState }>,
    private channelsService: ChannelsService,
    private accountService: AccountsService,
    private unreadService: UnreadService,
    private backendService: BackendService,
  ) {
    window.scrollTo(0, 0);
    this.channelId = this.route.snapshot.paramMap.get('channelId');
    this.uiStore.dispatch(switchTrueLoading());
    this.uiStore.dispatch(switchTrueShowSideNavigation());
    this.uiStore.dispatch(switchTrueShowMenuInSideNavi());
    this.breadcrumbStore.dispatch(storeUrlParams({ urlParams: [ this.channelId ]}));
    this.breadcrumbStore.dispatch(switchTrueShowBreadcrumb());
  }
  async ngOnInit(): Promise<void> {
    await this.authService.refresh();
    await new Promise(resolve => setTimeout(resolve, 200));
    const account = await this.accountService.fetchAccount(this.authService.currentSession.currentUser.currentAccountId);
    if (!await this.accountService.isAcceptByClientIp(account)) {
      this.uiStore.dispatch(switchFalseLoading());
      this.forbidden = true;
      return;
    }    await this.channelsService.connectSnapShot(this.channelId);
    this.channelSubscription = this.channelsService.currentChannel
      .pipe(
        map((channel: Channel) => channel )
      )
      .subscribe( async (channel) => {
        this.currentChannel = channel;
        if (!channel.name) {
          this.router.navigate(['**'])
            .then(() => {
              return;
            })
        }
        this.channelOwnerAccount = await this.accountService.fetchAccount(this.currentChannel.accountId);
        const urlState = new UrlState();
        urlState.channelId = this.channelId;
        urlState.slug = this.currentChannel.slug;
        this.uiStore.dispatch(storeUrlState({urlState: urlState}));
        this.unreadService.connectSnapShots(
          this.channelOwnerAccount.id,
          this.channelId
        );
        this.unreadSubscription = this.unreadService.docs
          .pipe(
            map((docs) => docs)
          )
          .subscribe(async (docs) => {
            docs.forEach((doc) => {
              const newDoc = doc as Unread;
              if (this.unreadPosts.findIndex(c => c.id === newDoc.id) === -1) {
                this.unreadPosts.push(newDoc as Unread);
                if (this.users.findIndex(user => user.id === newDoc.uid) === -1) {
                  this.fetchOneUser(newDoc.uid);
                }
              }
            });
          });
        this.uiStore.dispatch(switchFalseLoading());
      });
  }
  ngOnDestroy(): void{
    this.channelSubscription?.unsubscribe();
    this.unreadSubscription?.unsubscribe();
    this.unreadPosts.map(u => {
      if (u.read) {
        this.unreadService.remove(
          this.channelOwnerAccount.id,
          this.channelId,
          u.id
        )
      }
    });
  }
  // chat
  async clickUnread(u: Unread): Promise<void> {
    this.chatTargetUser = this.users.find(user => user.id === u.uid);
    u.read = true;
    this.isOpenChatModal = true;
  }
  changeOpenChatModal($event: boolean): void {
    this.isOpenChatModal = $event;
  }
  fetchOneUser(uid: string): void {
    this.backendService.setIdToken(
      this.authService.currentSession.idToken
    )
    const data = {
      accountId: this.channelOwnerAccount.id,
      uid: uid,
    }
    this.backendService.post('lms/channel-manager/fetch_user', data)
      .then((user) => {
        this.users.push(user);
      })
  }
  returnUnread(u: Unread): void {
    u.read = false;
  }
  getUserName(uid: string): string {
    if (!uid) {
      return '';
    }
    const user = this.users.find(u => u.id === uid);
    if (user?.displayName) {
      return user.displayName;
    }
    if (user?.fullName) {
      return user.fullName;
    }
    if (user?.familyName) {
      return user.familyName + ' ' + user.givenName;
    }
    return '';
  }
}
