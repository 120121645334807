import {Component,  OnInit} from '@angular/core';
import {User} from "../../models/user";
import {environment} from "../../../environments/environment";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {AuthService} from "../../services/auth/auth.service";
import {Store} from "@ngrx/store";
import {UiState} from "../../store/ui/ui.reducer";
import {BackendService} from "../../services/backend/backend.service";
import {
  switchFalseLoading, switchTrueLoading,
} from "../../store/ui/ui.actions";
import {CurrentUser} from "../../store/sessions/sessions.reducer";
import {SexValues} from "../../models/common";
import {PostalCodesService} from "../../services/postal-codes/postal-codes.service";
import {PostalCode} from "../../models/zip-code";
import {FileUpload} from "../../models/file-upload";
import {FileUploaderToStorageService} from "../../services/file-uploader-to-storage/file-uploader-to-storage.service";
import {ImageConverterService} from "../../services/image-converter/image-converter.service";
import {storeUser} from "../../store/sessions/sessions.actions";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-my-page-personal',
  templateUrl: './my-page-personal.component.html',
  styleUrls: ['./my-page-personal.component.scss'],
  providers: [],
})
export class MyPagePersonalComponent implements OnInit {
  isOpenZipCodeSelectorModal = false;
  isOpenProfileImageUploadModal = false;
  user: User = new User();
  noImage = environment.noImageThumb;
  postalCodes: PostalCode[] = [];
  private publicPath = 'public';
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toasterService: ToastrService,
    private authService: AuthService,
    private sessionsStore: Store<{ sessions: CurrentUser}>,
    private uiStore: Store<{ ui: UiState }>,
    private backendService: BackendService,
    private postalCodesService: PostalCodesService,
    private fileUploaderToStorageService: FileUploaderToStorageService,
    private imageConverterService: ImageConverterService,
    private translateService: TranslateService,
  ) {
    window.scrollTo(0, 0);
  }
  displayName = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(16)
      // TODO 正規表現追加する
    ]
  });
  familyName = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(16)
      // TODO 正規表現追加する
    ]
  });
  givenName = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(16)
      // TODO 正規表現追加する
    ]
  });
  familyNamePhonetic = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(16)
      // TODO 正規表現追加する
    ]
  });
  givenNamPhonetic = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(16)
      // TODO 正規表現追加する
    ]
  });
  zip = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
      Validators.minLength(7),
      Validators.maxLength(7)
    ]
  });
  pref = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  address1 = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  address2 = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  address3 = new FormControl<string|null>(null, {
    validators: []
  });
  phoneNumber = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  email = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  sex = new FormControl<SexValues|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  birthday = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  form = this.formBuilder.group({
    displayName: this.displayName,
    familyName: this.familyName,
    givenName: this.givenName,
    familyNamePhonetic: this.familyNamePhonetic,
    givenNamPhonetic: this.givenNamPhonetic,
    zip: this.zip,
    pref: this.pref,
    address1: this.address1,
    address2: this.address2,
    address3: this.address3,
    phoneNumber: this.phoneNumber,
    email: this.email,
    sex: this.sex,
    birthday: this.birthday,
  });
  selectedPostalCode = new FormControl(null, []);
  async ngOnInit(): Promise<void> {
    await this.authService.refresh();
    await new Promise(resolve => setTimeout(resolve, 200));
    this.uiStore.dispatch(switchTrueLoading());
    this.user = await this.authService.fetchUser(this.authService.currentSession.currentUser.id);
    if (!this.user) {
      return;
    }
    if (!this.user.email) {
      this.user.email = this.authService.currentAuth.email;
    }
    this.formReset();
    this.uiStore.dispatch(switchFalseLoading());
  }
  formReset(): void {
    this.displayName.setValue(this.user.displayName);
    this.familyName.setValue(this.user.familyName);
    this.givenName.setValue(this.user.givenName);
    this.familyNamePhonetic.setValue(this.user.familyNamePhonetic);
    this.givenNamPhonetic.setValue(this.user.givenNamPhonetic);
    this.zip.setValue(this.user.zip);
    this.pref.setValue(this.user.pref);
    this.address1.setValue(this.user.address1);
    this.address2.setValue(this.user.address2);
    this.address3.setValue(this.user.address3);
    this.phoneNumber.setValue(this.user.phoneNumber);
    this.email.setValue(this.user.email);
    this.sex.setValue(this.user.sex);
    this.birthday.setValue(this.user.birthday);
  }
  openImageUploadModal(): void {
    this.isOpenProfileImageUploadModal = true;
  }
  closeImageUploadModal(): void {
    this.isOpenProfileImageUploadModal = false;
  }
  async save(): Promise<void> {
    if (!this.form.valid) {
      this.toasterService.warning(this.translateService.instant('未入力の項目があります。'));
      return;
    }
    this.uiStore.dispatch(switchTrueLoading());
    this.user.displayName = this.displayName.value;
    this.user.familyName = this.familyName.value;
    this.user.givenName = this.givenName.value;
    this.user.familyNamePhonetic = this.familyNamePhonetic.value;
    this.user.givenNamPhonetic = this.givenNamPhonetic.value;
    this.user.zip = this.zip.value;
    this.user.pref = this.pref.value;
    this.user.address1 = this.address1.value;
    this.user.address2 = this.address2.value;
    this.user.address3 = this.address3.value;
    this.user.phoneNumber = this.phoneNumber.value;
    this.user.sex = this.sex.value;
    this.user.phoneNumber = this.phoneNumber.value;
    this.user.birthday = this.birthday.value;
    if (this.user.birthday) {
      this.user.birthdayWithoutYear = this.user.birthday.slice(5, 10);
    } else {
      this.user.birthdayWithoutYear = null;
    }
    await this.authService.updateUser(this.user);
    this.toasterService.success(this.translateService.instant('保存しました。'));
    this.uiStore.dispatch(switchFalseLoading());
    this.router.navigate(['/my-page']);
  }
  back(): void {
    history.back();
  }
  async uploadImageProfile(evt: any): Promise<void> {
    this.uiStore.dispatch(switchTrueLoading());
    const file: File = evt.target.files[0];
    const fileInfo = new FileUpload(file);
    const processSubscription = this.fileUploaderToStorageService.process$.subscribe((process) => {
      console.log(process);
    });
    const basePath = this.publicPath;
    const originalDestinationPath = `${basePath}/${fileInfo.file.name}`;
    await this.fileUploaderToStorageService.upload(
      originalDestinationPath,
      fileInfo,
    );
    processSubscription.unsubscribe();
    this.user.profileImage = await this.imageConverterService.uploadPublicImage(
      this.authService.currentSession.currentUser.id,
      'personal',
      file,
      this.authService.currentSession.currentUser.id,
      this.authService.currentSession.idToken,
      true
    );
    this.toasterService.success(this.translateService.instant('アップロードしました。'));
    await this.save();
    const currentUser: CurrentUser = {
      displayName: this.user.displayName,
      photoUrl: null,
      id: this.user.id,
      currentAccountId: this.user.currentAccountId,
    }
    this.sessionsStore.dispatch(storeUser({currentUser: currentUser}));
    this.isOpenProfileImageUploadModal = false;
    this.uiStore.dispatch(switchFalseLoading());
  }
  async removeImageProfile(): Promise<void> {
    if (!window.confirm(this.translateService.instant('プロフィール画像を削除します。よろしいですか？'))) {
      return;
    }
    this.uiStore.dispatch(switchTrueLoading());
    await this.imageConverterService.removePublicImage(
      this.user.profileImage,
      this.authService.currentSession.idToken
    );
    this.user.profileImage = null;
    const currentUser: CurrentUser = {
      displayName: this.user.displayName,
      photoUrl: null,
      id: this.user.id,
      currentAccountId: this.user.currentAccountId,
    }
    this.sessionsStore.dispatch(storeUser({currentUser: currentUser}));
    await this.save();
    this.toasterService.success(this.translateService.instant('削除しました。'))
    this.uiStore.dispatch(switchFalseLoading());
  }
  clickUploadFile(domId: string): void {
    const file = document
      .getElementById(domId);
    file.click();
  }
  /*
   * 郵便番号
   */
  chosePostalCode(): void {
    this.isOpenZipCodeSelectorModal = false;
    const choice = this.postalCodes[this.selectedPostalCode.value];
    if (choice) {
      this.pref.setValue(choice.pref);
      this.address1.setValue(choice.address1);
      this.address2.setValue(choice.address2);
      this.address3.setValue(choice.address3 + ' ' + choice.bizName);
    }
  }
  updatePostalCode(): void {
    if (this.zip.value.length === 7) {
      this.postalCodesService.fetch(this.zip.value)
        .then((res: PostalCode[]) => {
          if (res.length > 1) {
            this.isOpenZipCodeSelectorModal = true;
            this.postalCodes = res;
          } else {
            this.pref.setValue(res[0].pref);
            this.address1.setValue(res[0].address1);
            if (res[0].address2) {
              this.address2.setValue(res[0].address2);
            } else {
              this.address2.setValue(res[0].address3);
              this.address3.setValue(res[0].bizName);
            }
          }
        })
        .catch((e) => console.log(e))
    }
  }
}
