import { Pipe, PipeTransform } from '@angular/core';
import {DateFormat} from '../../models/date-format';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    const df = new DateFormat();
    const d = new Date(value.replace(/-/g, '/'));
    return df.getDateStringJa(d);
  }

}
