<app-header-contents-public></app-header-contents-public>
<div class="l-content-front">
  <app-breadcrumb-front></app-breadcrumb-front>
  <div class="l-content-front__inner">
    <div class="p-contracts">
      <div class="p-contracts__title">
        <h2 class="p-contracts__title__main" translate>FireRocketサービス使用許諾契約書（利用規約）</h2>
        <div class="p-contracts__title__note" translate>本サービスをご使用される前に必ずお読みください。</div>
      </div>

      <p translate>
        お客様（以下「契約者」といいます。）は、当社（株式会社グラブデザイン）が提供する学習管理プラットフォームサービス「FireRocket」（以下「本サービス」といいます。）の利用について、以下の通り同意するものとします。
      </p>

      <h3 translate>第1章　総則</h3>

      <h4 translate>第1条（利用規約の運用）</h4>
      <ol class="p-contracts__orderedList">
        <li translate>
          FireRocketサービス使用許諾契約書（以下「利用規約」といいます。）は、本サービスをご利用いただく際の、契約者と当社間の一切の関係に適用されるものとします。
        </li>
        <li translate>契約者は利用規約のほか当社が別に本サービスに関する利用条件を提示した場合は、それらの利用条件にしたがって本サービスを利用するものとします。</li>
        <li>
          <span translate>当社は、以下の各号のいずれかの場合には、この利用規約を変更することができるものとします。</span><br>
          <span translate>その場合、当社は、利用規約を変更する旨、変更後の利用規約の内容および効力発生日を、当社WEBサイトへの掲載もしくはeメールの送信、チャットツール、その他当社が適当と認める方法により契約者に通知することで契約者に周知します。第2号の場合には、その変更の周知は効力発生日から相当な期間前までに行うものとします。変更後の利用規約は、効力発生日から効力を生じるものとします。</span>
          <ul class="p-contracts__subList-none">
            <li translate>(1) 利用規約の変更が、契約者の一般の利益に適合するとき</li>
            <li translate>(2) 利用規約の変更が、契約の目的に反するものではなく、かつ変更の必要性、変更後の内容の相当性、その他の変更に係る事情に照らして合理性があるとき</li>
          </ul>
        </li>
      </ol>

      <h4 translate>第2条（用語の定義等）</h4>
      <ul class="p-contracts__unOrderedList">
        <li>
          <span translate>この利用規約においては、次の用語はそれぞれ次の意味で使用します。</span><br><span translate>(用語：用語の意味)</span>
          <ul class="p-contracts__subList-disc">
            <li translate>契約者：利用契約を締結し、本サービスの提供を受ける者。</li>
            <li translate>運営会社：本サービスを運営する株式会社グラブデザイン。</li>
            <li translate>利用契約：利用規約に基づき当社と契約者との間に締結される本サービスの提供に関する契約。</li>
            <li translate>提供サーバ：運営会社が本サービスを提供するため契約者に供するサーバ。</li>
            <li translate>利用制限値：月間配信時間、月間トランスコード時間、およびディスク容量の各制限値。</li>
          </ul>
        </li>
      </ul>

      <h4 translate>第3条 （通知）</h4>
      <ol class="p-contracts__orderedList">
        <li translate>当社から契約者への通知は、当社WEBサイトへの掲載もしくはeメールの送信、チャットツール、その他当社が適当と認める方法により行います。</li>
        <li translate>
          前項の通知は、当社WEBサイトへの掲載により行われたときは、当該WEBサイトへの掲載の時点で、電子メールの送信により行われたときは、当該電子メールの発信の時点で、契約者に到達したものとみなします。
        </li>
        <li translate>
          第１項における電子メールの送信先は、第24条（利用責任者）に定める利用責任者のeメールアドレスとして予め当社に届出のあったeメールアドレスまたは、当社が別途送信先として適当と認めたeメールアドレスとします。
        </li>
      </ol>

      <h3 translate>第2章　契約</h3>

      <h4 translate>第4条 （利用契約の成立・期間・更新・特約）</h4>
      <ol class="p-contracts__orderedList">
        <li translate>
          当社は、契約者から本サービス利用申込画面からの利用契約申し込みまたは別途書面等による契約申し込み（以下「利用申込」といいます。）をもって 本サービス利用申し込みを受け付け、当社がこれに対し承諾した時点で利用契約が成立します（以下「本利用契約」といいます。）。利用申込に際し、契約者は利用規約を確認し、同意した上で、当社所定の手続きに従って利用申込みを行うものとします。
        </li>
        <li translate>利用申込みにおいて、別途当社が定める本人確認のための資料等を申込者から提出していただくことがあります。</li>
        <li translate>
          当社が本サービスの利用申し込みを承諾した場合は、利用開始日を記載した電子メール等により通知いたします。本サービスはこの通知日より利用を開始出来ます。
        </li>
        <li translate>
          当社は、利用規約の他、必要に応じて特約を定める場合があります。この場合、契約者は利用規約とともに特約を遵守するものとします。但し特約と利用規約の内容が競合する場合は特約の内容を優先します。
        </li>
      </ol>

      <h4 translate>第5条 （利用期間）</h4>
      <ol class="p-contracts__orderedList">
        <li translate>本サービスの利用期間は、本利用契約に定めるものとします。</li>
        <li translate>本サービスの最短利用期間は、契約者に本サービスの提供を開始した日から起算して1か月とします。</li>
        <li translate>
          契約者は、前項の最短利用期間内に本利用契約の解約を行う場合は、当社が定める期限までに、解約日以降最短利用期間満了日までの残余の期間に対応する利用料金に相当する額及びその消費税相当額を一括して当社に支払うものとします。
        </li>
      </ol>

      <h4 translate>第6条 （申込みの拒否）</h4>
      <ul class="p-contracts__unOrderedList">
        <li>
          <span translate>次の各号の何れかの一に該当する場合に、当社は本サービスの利用申し込みに対し承諾をお断りすることがあります。また、契約期間中においてもつぎの各号の何れか一に該当することとなった場合又は該当していることが判明した場合は、当社は何ら一切の催告を要せず利用契約を解除する場合があります。</span>
          <ol class="p-contracts__subList-decimal">
            <li translate>利用申込に際し記載すべき必要事項が記入されていないか、虚偽の事実が記入されている場合。</li>
            <li translate>当社の業務上、技術上の理由により、本サービスが提供できない場合。</li>
            <li translate>契約者が利用契約上の債務を怠るおそれがあるとき。</li>
            <li translate>その他、当社が利用申込みを適当でないと判断した場合。</li>
          </ol>
        </li>
      </ul>

      <h4 translate>第7条（利用申込の記載事項の変更）</h4>
      <ol class="p-contracts__orderedList">
        <li translate>
          契約者が利用申込記載事項の変更を行う場合は、速やかに変更内容を当社所定の手続きに従って届け出るものとし、当社がこれを承諾した時点で本利用変更契約が成立するものとします。
        </li>
        <li translate>前項の届け出があった場合は、当社は届け出のあった事実を証明する書類を契約者から提出していただく場合があります。</li>
        <li translate>
          契約者が本条第1項に従った通知を怠ったことにより、当社からの通知の不到達その他の事由により損害を被った場合、当社は一切責任を負わないものとします。
        </li>
      </ol>

      <h4 translate>第8条（本サービスの内容及び利用料金）</h4>
      <ol class="p-contracts__orderedList">
        <li translate>
          本サービス形態は、当社がサーバ上で提供する機能を、契約者単独あるいは他の契約者と共用する形態（学習管理プラットフォームサービス）により、主にインターネット経由でご利用いただくサービス、およびその附帯サービスです。
        </li>
        <li translate>
          本サービス内容は、契約者単独あるいは他の契約者と情報を共有するための学習教材の作成、配信、受講、受講履歴、受講スケジュールの管理及びそれらに付随する行為を行うサービスです。
        </li>
        <li translate>
          なお、当社は本サービスに関して、契約者に事前の通知の上、サービス仕様の変更（ユーザインタフェースの変更を伴うバージョンアップを含む）、追加、削減等を行うことがあり、契約者はこれを予め承諾するものとします。
        </li>
        <li translate>本サービスの利用料金は本利用契約に定めるものとし、契約者は、当社に対して、利用料金を支払うものとします。</li>
      </ol>

      <h4 translate>第9条（請求、支払方法）</h4>
      <ol class="p-contracts__orderedList">
        <li translate>
          当社は、本サービスの利用料金について、契約者に対し本サービス利用月の翌月末の営業日までに請求書を送付します。契約者は請求書受領月翌月の末日までに、当社指定の銀行口座に利用料金を振り込むものとします。
        </li>
        <li translate>振り込み手数料は契約者が負担するものとします。</li>
        <li translate>契約者が本条に定める支払義務を完了しない場合、当社は第17条の定めに従い、本サービスの提供を停止できるものとします。</li>
        <li translate>利用料金は1か月単位で発生します。第５条第３項の解約に伴う清算の場合を除き利用料金の日割り計算はいたしません。</li>
        <li translate>
          契約者は、第17条に定める本サービスの提供の中断、停止その他の事由により本サービスを利用することができない状態が生じた場合であっても、利用期間中の利用料金及びこれにかかる消費税等の支払を要するものとします。
        </li>
        <li translate>
          契約者は、本サービスの利用料金その他の利用契約に基づく債務を支払期限までに支払うことを怠ったときは、当該支払期限の日から支払済みに至るまでの期間につき年率14. 6%（年365日の日割計算）の割合で計算される遅延損害金を支払うものとします。
        </li>
      </ol>

      <h4 translate>第10条（利用制限値）</h4>
      <ul class="p-contracts__unOrderedList">
        <li translate>本サービスは、基本サービスに利用制限値を定めます。利用制限値は、本利用契約に記載された値とします。</li>
      </ul>

      <h4 translate>第11条（契約者からの利用契約の解約）</h4>
      <ol class="p-contracts__orderedList">
        <li translate>
          契約者は、本利用契約の利用期間満了前に解約を希望する場合、利用契約期間終了希望日の属する月の前月の30日迄に契約者から書面（eメールを含む）による解約の申出を行うことにより、同申し出の翌月末日をもって利用契約を解約することができるものとします。
        </li>
        <li translate>利用契約は月の途中で解約を行うことはできず、第９条第４項規定のとおり、利用料金の日割り清算はいたしません。</li>
        <li translate>契約者は、本条第１項の解約の申し出時点において未払いの利用料金又は支払遅延損害金がある場合には、直ちにこれを支払うものとします。</li>
      </ol>

      <h4 translate>第12条（当社からの利用契約の解約）</h4>
      <ol class="p-contracts__orderedList">
        <li translate>
          <span translate>契約者に、次の各号の何れかに該当する事由が生じた場合には、当社は事前の通知及び勧告なく、本利用契約の全部または一部を解除することができることとします。この場合、当社は契約者に対して、違約金、損害賠償等その他一切の責を負いません。</span>
          <ul class="p-contracts__subList-none">
            <li translate>
              ① 本利用契約に基づき発生した債務の全部または一部について不履行があり、相当の期間を定めた催告を受けたにもかかわらず、当該期間内に履行しないとき。
            </li>
            <li translate>② 第23条に定める禁止事項を行ったとき。</li>
            <li translate>③ 監督官庁により営業取消、停止等の処分を受けたとき。</li>
            <li translate>④ 第三者より仮差押、仮処分または強制処分を受け、利用契約の履行が困難と認められるとき。</li>
            <li translate>⑤ 破産、特別清算、民事再生、会社更生手続開始等の申立があったとき。</li>
            <li translate>⑥ 解散の決議、または他の会社と合併したとき。</li>
            <li translate>⑦ その他資産、信用、または支払能力に重大な変更、疑義が生じたとき。</li>
            <li translate>⑧ 契約者、その代表者又は役員が、いわゆる反社会的勢力に所属していたとき、並びに反社会的活動を行っていたとき。</li>
          </ul>
        </li>
        <li translate>
          契約者は、前項による本利用契約の解約があった時点において未払いの利用料金等又は支払遅延損害金がある場合には、直ちにこれを支払うものとします。
        </li>
      </ol>

      <h3 translate>第3章　サービス</h3>

      <h4 translate>第13条（本サービスの利用）</h4>
      <ol class="p-contracts__orderedList">
        <li translate>当社は契約者に対し、契約者が必要と判断した本サービスへのアクセス権限として、チャンネル管理者ＩＤ、およびパスワードを付与するものとします。</li>
        <li translate>契約者の不適切な操作の結果、本件サービスが停止または毀損した場合、当社は契約者に対して損害賠償請求を行う場合もあります。</li>
        <li translate>契約者は、本サービスの使い方に関する質問の応答等のサポートを、チャットツール、eメールに限定して行うことを承諾するものとします。</li>
      </ol>

      <h4 translate>第14条（チャンネル管理者ID・パスワード情報の管理義務）</h4>
      <ol class="p-contracts__orderedList">
        <li translate>当社は利用契約ごとにチャンネル管理者ID及びパスワードを定めます。なお、チャンネル管理者IDの発行数は１契約あたり申込みID数を上限とします。</li>
        <li translate>
          契約者は前項のチャンネル管理者ID及びパスワードを厳重に管理するものとし、これらの不正使用により当社あるいは第三者に損害を与えることのないように万全の配慮を講じるものとします。また、契約者は不正使用に起因するすべての損害について責任を負うものとし、当社を免責するものとします。
        </li>
        <li translate>
          契約者は、チャンネル管理者ID及びパスワードが第三者によって不正に使用されたことが判明した場合には、直ちに当社にその旨を連絡することを遵守してください。
        </li>
      </ol>

      <h4 translate>第15条（利用者用ID及びパスワードの管理）</h4>
      <ol class="p-contracts__orderedList">
        <li translate>当社は、契約者に対し、利用者ID、各種管理者ID及びパスワード等を発行します。</li>
        <li translate>
          契約者は、利用者ID及びパスワードの管理不十分、使用上の過誤、第三者の使用等による損害の責任は契約者が負うものとし、当社は一切責任を負わないものとします。
        </li>
        <li translate>
          契約者は、利用者ID及びパスワードの盗難があった場合、利用者ID及びパスワードの失念があった場合、又は利用者ID及びパスワードが第三者に使用されていることが判明した場合には、直ちに当社にその旨を連絡するとともに、当社からの指示がある場合にはこれに従うものとします。
        </li>
      </ol>

      <h4 translate>第16条（第三者への利用許諾）</h4>
      <ul class="p-contracts__unOrderedList">
        <li translate>
          契約者は、事前の当社の承諾なしに利用者ID及びパスワードを本利用契約で許諾された利用者以外の第三者に利用させ、貸与、譲渡、名義変更、売買等をしてはならないものとします。
        </li>
      </ul>

      <h4 translate>第17条（本サービスの提供、一時中断）</h4>
      <ol class="p-contracts__orderedList">
        <li>
          <span translate>本サービスの提供時間は、サポートを除き１日24時間年中無休とします。但し、次の各号の何れかに該当する場合には、契約者への事前の通知又は承諾を要することなく、当社は本サービスの一部または全部を必要な期間中止または停止することがあります。</span>
          <ul class="p-contracts__subList-none">
            <li translate>① システムの点検。この場合、緊急時を除いて契約者への事前のメール又は当社ホームページへの掲示を持ってその旨を連絡します。</li>
            <li translate>② 本サービスを提供するためのシステムに障害が発生した場合</li>
            <li translate>③ 第三者の故意、過失による不具合に対策を講じる必要がある場合</li>
            <li translate>④ 伝送路設備・伝送交換設備・付帯設備を設置し、利用者の用に供する</li>
            <li translate>⑤ 電気通信事業者が電気通信役務の提供を停止することにより 利用契約に基づくサービスの提供を行うことが困難になった場合</li>
            <li translate>⑥ 天災等の不可抗力、電気通信設備にやむを得ない障害または当社の責に帰することができない事由により本サービスを提供できない場合</li>
            <li translate>⑦ 契約者が第12条第1項各号のいずれかに該当する場合又は利用料金未払いその他利用契約に違反した場合</li>
          </ul>
        </li>
        <li translate>当社は前項各号の事由に基づく本サービスの中止または停止によって生じた契約者、利用者及び第三者の損害につき、一切の責任を負いません。</li>
      </ol>

      <h4 translate>第18条（サービスの終了）</h4>
      <ul class="p-contracts__unOrderedList">
        <li translate>
          当社は、サービス終了日の３か月前までに契約者に通知することにより（無償サービスに関しては契約者に対する事前の通知なく）、本サービスを終了することができるものとします。契約者は、これに関していかなる反論、異議申立、損害賠償請求その他一切の責任追及を当社に対して行わないものとします。
        </li>
      </ul>

      <h4 translate>第19条（委託）</h4>
      <ul class="p-contracts__unOrderedList">
        <li translate>当社は、本サービスの提供に必要となる業務の全部又は一部を第三者に委託することができるものとします。</li>
      </ul>

      <h3 translate>第4章　データの取扱い</h3>

      <h4 translate>第20条（データの消去）</h4>
      <ol class="p-contracts__orderedList">
        <li translate>
          第11条（契約者からの利用契約の解約）、第12条(当社からの利用契約の解約)、または第18条（サービスの終了）により、サービスを解除または終了した場合、当社は、当該契約者への事前の通知を行うことなく、提供サーバのデータを完全に消去できるものとします。
        </li>
        <li translate>
          当社は、提供サーバのデータが、当社の定める所定の基準を超えた場合は、契約者に対し、何らの通知なく、当該データを削除し、又は当該データの転送もしくは配送を停止することがあります。
        </li>
        <li translate>第17条（本サービスの提供、一時中断）第１項第②号から⑥号の場合、当社は提供サーバのデータをやむを得ず削除することがあります。</li>
        <li translate>
          契約者の故意によるものか否かにかかわらず、第23条（禁止事項、利用の停止）第１項各号に違反して掲載されているデータについて、当社は事前に契約者に通知して削除することができるものとします。ただし、緊急を要すると当社が判断した場合は、通知は事後となる場合があります。
        </li>
        <li translate>
          第17条（本サービスの提供、一時中断）の１項各号または、第23条（禁止条項、利用の停止）により本サービスの提供を停止した場合、契約者は提供サーバのデータに対するアクセスの権利を失い、当社はいかなる形態であれ、それらのデータあるいはそのコピーを利用させる義務を負わないものとします。
        </li>
        <li translate>当社は前各項の措置により契約者に損害が生じたとしても、一切その責任を負わないものとします。</li>
      </ol>

      <h4 translate>第21条（データの取扱い）</h4>
      <ul class="p-contracts__unOrderedList">
        <li translate>
          本サービスを用いて契約者が提供するデータが、滅失、毀損、漏洩した結果発生する直接あるいは間接の損害について、当社はいかなる責任も負わないものとします。
        </li>
      </ul>

      <h4 translate>第22条（データの利用・複写及び保管）</h4>
      <ol class="p-contracts__orderedList">
        <li translate>本サービスにおいて、当社はサーバ設備の故障又は停止等の復旧に対応するため、提供サーバ上のデータを複写及び保管する場合があります。</li>
        <li translate>当社は、本サービスにおいて蓄積される受講履歴データ等の統計情報を本サービスの改善のために利用することがあります。</li>
      </ol>

      <h3 translate>第5章　契約者の義務</h3>

      <h4 translate>第23条（禁止事項、利用の停止）</h4>
      <ol class="p-contracts__orderedList">
        <li translate>
          <span translate>契約者は、本サービスの利用にあたって次の各号に該当する事項（以下「禁止事項」といいます。）を行ってはなりません。当社は、契約者が禁止事項を行ったことを発見した場合には、契約者に事前に通告及び勧告することなく、本サービスの利用を停止することができます。なお、当社は契約者が行った禁止事項により損害を被ったときは、契約者に賠償を請求することができます。</span>
          <ul class="p-contracts__subList-none">
            <li translate>① 利用規約の規定に違反する行為</li>
            <li translate>② 日本の法律（関連する施行規則、政令、各種ガイドライン、準則等を含みます）に反する違法行為</li>
            <li translate>③ 当社あるいは第三者（第三者が運用するコンピュータその他財産等を含みます）に損失または損害を与える行為</li>
            <li translate>
              ④ 当社あるいは第三者を差別もしくは誹謗中傷し、またはその名誉、信用、プライバシー等の人格的利益を侵害する行為、またはそのおそれのある行為
            </li>
            <li translate>⑤ 当社あるいは第三者の法的保護に値する一切の利益を侵害する行為、またはそのおそれのある行為</li>
            <li translate>⑥ ポルノや宗教、ネットワークビジネス、反社会的行為、犯罪的行為、犯罪的行為に結びつく教育や行為、またはそのおそれのある行為</li>
            <li translate>⑦ 本サービスの運営を妨げる行為、またはそのおそれのある行為</li>
            <li translate>
              ⑧ 当社が提供するサービスを直接または間接に利用する者のその当該利用に対し過大な負荷または重大な支障を与える態様（本サービスを構成する当社のシステムやデータ等の損壊を含みますがそれに限定されません）において本サービスを利用する行為、あるいはそのおそれのある行為
            </li>
            <li translate>⑨ 第三者の通信に支障を与える方法あるいは態様において本サービスを利用する行為、あるいはそのおそれのある行為</li>
            <li translate>⑩ 本サービスの逆アセンブル、逆コンパイル、その他リバースエンジニアリング技法による解析行為、またはそのおそれのある行為</li>
            <li translate>
              ⑪ 契約者の故意によるものか否かにかかわらず、提供サーバに対して、不正アクセス、クラッキング、アタック行為などの何らかの不正な攻撃や不正中継が行われた場合
            </li>
            <li translate>
              ⑫ コンピュータウィルス等有害なプログラムその他違法なファイル等を、本サービスを通じて、もしくは本サービスに関連して使用、送信、掲載、または提供する行為
            </li>
            <li translate>⑬ 本サービス利用で知り得た、当社及び第三者の営業秘密を漏洩する行為</li>
            <li translate>
              ⑭ 当社が公序良俗に反すると判断したサービス、違法なわいせつ画像、文章その他のコンテンツを配信するサービスに利用する行為、不特定多数、無作為に勧誘もしくは案内をメール送信する行為
            </li>
            <li translate>⑮ 事実に反する情報を提供する行為</li>
            <li translate>
              ⑯ 契約者が当社に届け出た連絡先との連絡がとれない場合（当社が契約者宛てに発送した郵便物が宛先不明で当社に返送された場合を含みます。）
            </li>
            <li translate>
              ⑰ 第三者または当社の著作権、その他の権利（知的財産権、プライバシー権、パブリシティ権、名誉権等を含みます）を侵害する行為または侵害するおそれのある行為
            </li>
            <li translate>⑱ 利用規約に違反する行為</li>
            <li translate>⑲ その行為が前各号のいずれかに該当することを知りつつ、その行為を助長する態様でリンクをはる行為</li>
            <li translate>⑳ その他、当社が契約者または利用者として不適切と判断する行為</li>
          </ul>
        </li>
        <li translate>契約者が前項で禁止する行為を行った場合、その行為に関わる責任は契約者が負うものとし、当社は一切責任を負いません。</li>
        <li translate>
          また、当社は、前項の規定により、本サービスの提供を停止する場合は、あらかじめ、その理由、提供を停止する日及び期間を契約者に通知します。但し、緊急の場合、その他やむを得ない場合は事後に契約者へ通知します。
        </li>
      </ol>

      <h4 translate>第24条（利用責任者）</h4>
      <ol class="p-contracts__orderedList">
        <li translate>
          本サービスの利用にあたり、契約者はあらかじめ利用責任者を選任し、当社に書面で届け出るものとします。利用責任者が交代した場合は、直ちに当社に書面で通知するものとします。
        </li>
        <li translate>利用責任者は当社との連絡、協議の任に当たると共に、利用規約に基づく情報通信サービスの利用適正化を図るものとします。</li>
      </ol>

      <h4 translate>第25条（契約譲渡）</h4>
      <ul class="p-contracts__unOrderedList">
        <li translate>
          契約者は、当社の書面による事前同意なくして、本利用契約上の権利を第三者に譲渡、再許諾し、あるいは担保に供してはならず、または第三者に義務を承継できません。
        </li>
      </ul>

      <h3 translate>第6章　一般条項</h3>

      <h4 translate>第26条（損害賠償）</h4>
      <ol class="p-contracts__orderedList">
        <li translate>
          本サービスの提供に関して、当社の責に帰すべき事由により契約者が本サービスを利用できない（当社が本サービスを全く提供しない場合、または当社による本サービスの提供方法の不備により契約者が利用できない場合をいい、利用規約第17条（本サービスの提供、一時中断）の定めに基づき本サービスを中止する場合は含まれません。以下「利用不能」といいます）ために契約者に損害が発生した場合、直接かつ通常の現実に生じた損害に限り、第8条に基づき支払われた直近1ヶ月分の利用料金を限度として、当社はその賠償責任を負うものとします。
        </li>
        <li translate>当社が利用者に対して負う責任は、法律上の瑕疵担保責任、債務不履行責任、不法行為責任を問わず、前項の範囲に限られるものとします。</li>
        <li translate>
          当社は、利用規約に明示的に定める事項を除き、当社の責に帰すべからざる事由から契約者に生じた損害、当社の予見の有無または可・不可にかかわらず、特別の事情から生じた損害、逸失利益及び第三者からの損害賠償請求に基づく契約者の損害等（損害となる弁護士費用を含みます） については、当社は一切の責任を負いません。
        </li>
        <li translate>
          契約者が本サービスの利用に関し、当社または第三者に損害を及ぼした場合、契約者は、当社または当該第三者に対し、その損害を賠償しなければなりません。
        </li>
        <li translate>
          契約者は、本サービスの利用に関し、他の契約者または第三者に対して損害を与えたものとして、他の契約者または第三者から何らかの請求がなされ、または訴訟が提起された場合、契約者は、自らの費用（弁護士費用を含む）と責任において当該請求または訴訟を解決するものとし、当社は一切の責任を負いません。
        </li>
      </ol>

      <h4 translate>第27条（第三者との紛争）</h4>
      <ol class="p-contracts__orderedList">
        <li translate>
          契約者による本サービスの利用に関連して、第三者から損害賠償請求された場合、または第三者との間で何らかの問題が発生した場合、契約者は責任をもって当該第三者と対応するものとします。
        </li>
        <li translate>
          前項の定めにかかわらず、前項に関連して、第三者が当社に対して何らかの請求を行い、または訴訟を提起した場合、契約者は当社が当該第三者に対応するに際し、あらゆる協力を行い、可能な限り自ら対応するものとします。
        </li>
      </ol>

      <h4 translate>第28条（補償）</h4>
      <ol class="p-contracts__orderedList">
        <li>
          <span translate>契約者は自らの費用負担により、以下の各号のいずれかを原因として、または以下の各号のいずれかに関連して、当社がいかなる損害、損失及び費用負担（弁護士費用含む）を負うことがないよう保護し、損害、損失及び費用負担を負った場合は補償するものとします。</span>
          <Ul class="p-contracts__subList-none">
            <li translate>
              ① 契約者が提供したデータが第三者の特許、著作権、商標権、営業秘密、肖像権、プライバシーの権利、命名権またはそれに類するものを侵害したり、不正利用したり、違反したとするクレームまたは契約者が提供したデータが誹謗中傷もしくは名誉毀損に該当するとのクレームがあった場合
            </li>
            <li translate>
              ② 契約者が、当社の書面による指示なく本サービスを改造した場合で、かつこのような権利侵害が改造していないバージョンの本サービスを利用していれば避けることができた場合
            </li>
            <li translate>
              ③ 契約者が、当社から供給されたものでないプログラム、データ、設備または素材を利用したり、本サービスと組み合わせたりした場合で、かつそのようなことをしなければこのような権利侵害が避けられた場合
            </li>
          </Ul>
        </li>
        <li translate>利用規約に定められた他のいかなる規定に関わらず、前項の補償義務はいかなる責任の制限も受けないものとします。</li>
      </ol>

      <h4 translate>第29条（天災等についての免責）</h4>
      <ul class="p-contracts__unOrderedList">
        <li translate>
          当社は、天災、火災、騒乱等の不可抗力、当社が利用する電気通信事業者の提供する電気通信役務の不具合、またはその他当社の責に帰すことのできない事由により、利用規約上の義務を履行できない場合には、その責を免れます。
        </li>
      </ul>

      <h4 translate>第30条（設備の修理又は復旧）</h4>
      <ol class="p-contracts__orderedList">
        <li translate>
          本サービスの利用中に、契約者が当社の設備又は本サービスに異常を発見したときは、契約者は自身の設備に故障がないことを確認の上、当社に修理又は復旧の旨を要求するものとします。
        </li>
        <li translate>当社は、当社の設備もしくは本サービスに障害が生じ、又はその設備が滅失したことを当社が知ったときは、速やかにその設備を修理・復旧するものとします。</li>
      </ol>

      <h4 translate>第31条（著作権等）</h4>
      <ul class="p-contracts__unOrderedList">
        <li translate>
          本サービス（本件システム、その他本サービスを構成する全てのプログラミング等、機能を含みます）に関するアイデア（発明、考案を含みます）、ノウハウ、著作権（著作権法27条及び28条の権利を含む）、特許権、商標権その他一切の知的財産権は当社又は正当な権利を有する第三者に帰属するものとします。
        </li>
      </ul>

      <h4 translate>第32条（秘密保持）</h4>
      <ol class="p-contracts__orderedList">
        <li>
          <span translate>契約者及び当社は、相手方の書面による事前の承諾なくして、本利用契約に基づき知り得た相手方の業務上、技術上、販売上の秘密情報を第三者に開示、漏洩しないものとします。なお、秘密情報を相手方に開示する場合には、秘密である旨の表示をするものとします。但し、次の各号に該当する情報については、秘密情報から除くものとします。</span>
          <ul class="p-contracts__subList-none">
            <li translate>① 開示の時点で既に公知のもの、または開示後秘密情報を受領した当事者（以下「受領者」といいます。）の責によらずして公知となったもの</li>
            <li translate>② 受領者が第三者から秘密保持責任を負うことなく正当に入手したもの</li>
            <li translate>③ 開示の時点で既に受領者がすでに保有しているもの</li>
            <li translate>④ 開示された秘密情報によらずして、独自に受領者が開発したもの</li>
          </ul>
        </li>
        <li translate>
          受領者は、前項にかかわらず、秘密情報につき行政機関または裁判所から法令に基づき開示を命じられた場合は、 その旨を事前に開示者に通知し、適法に開示を命じられた部分に限り、当該情報が秘密情報である旨を明示した上で開示することができるものとします。
        </li>
        <li translate>
          当社が必要と認めた場合には、契約者からの承諾を受けることなく秘密情報を、当社及び第19条に基づき業務を委託する事業者を含む第三者に開示等できるものとします。
        </li>
      </ol>

      <h4 translate>第33条（個人情報）</h4>
      <ol class="p-contracts__orderedList">
        <li translate>
          当社は本サービスの提供に伴い、当社が取得したお客様の個人情報は、下記目的（以下「本目的」といいます。）で当社が利用いたします。その他の取扱いについては当社プライバシーポリシーに従います。
          <ul class="p-contracts__subList-none">
            <li translate>① 当社のサービス又は製品のご案内のため(電子メールによるものを含む)</li>
            <li translate>② 当社によるアンケート、各種セミナーのご案内、市場調査のため</li>
            <li translate>③ 当社サービスにおける利用分析及び統計データ作成のため</li>
            <li translate>④ 本サービスに関するご連絡のため</li>
            <li translate>⑤ 当社のサービス提供及び品質改善のため</li>
            <li translate>⑥ その他重要なご連絡のため</li>
          </ul>
        </li>
      </ol>

      <h4 translate>第34条（免責）</h4>
      <ol class="p-contracts__orderedList">
        <li translate>
          当社は、契約者に対し、契約者が使用する機器に関して被った損害について、その原因の如何に関わらずその損害を賠償する責を負いません。
        </li>
        <li translate>
          当社は、利用規約で特に定める場合を除き、契約者が本サービスの利用に関して被った損害については、その原因の如何によらず、いかなる賠償の責任も負わないものとします。
        </li>
        <li translate>
          当社は、契約者及び第三者が本サービスを通じて得る情報等について、その完全性、正確性、確実性、有用性等いかなる保証も負うものではありません。
        </li>
        <li translate>
          当社は、通信サービスの性質上、その原因に関わらず、契約者が本サービスの利用に関して被った速度の低下、復旧不能なデータ破壊などの損害について賠償の責任を負いません。
        </li>
      </ol>

      <h4 translate>第35条（無保証）</h4>
      <ol class="p-contracts__orderedList">
        <li translate>
          当社が契約者に対して提供する本サービスは、当社がその時点で保有している状態で提供しており、契約者が予定している利用目的への適合性、バグ等の不具合が無いことを保証するものではないことを契約者は承諾するものとします。また、契約者は、本サービスの利用結果については当社に対して一切損害賠償を請求しないことを承諾するものとします。
        </li>
        <li translate>
          当社は、契約者に対して提供する本サービスについてのバグ等の不具合の修正、改良等の実施を行うが、被った損害を賠償するものではないことを、契約者は承諾するものとします。
        </li>
        <li translate>
          利用者が本サービスに入力したデータの損失に関する契約者の不利益については、当社は損害賠償責任を負わないものとします。契約者は、本サービスにおいて、利用、作成、保管記録等するファイル、データ等のすべてを自らの責任において利用、保管、管理するものとし、且つバックアップをするものとします。
        </li>
        <li translate>
          終了事由に関わらず、利用契約が終了した場合、当社は契約者が保有するデータの返還または保管等を行う義務を負わず、契約者に何ら通知することなく、契約者の保有するデータ及び契約者のテナント管理者ＩＤ等を削除できるものとします。この場合に契約者が損害を被った場合でも、当社は何ら責任を負わないものとします。
        </li>
      </ol>

      <h4 translate>第36条（分離独立条項）</h4>
      <ul class="p-contracts__unOrderedList">
        <li translate>本規約の条項の一部が、管轄権を有する裁判所によって違法又は無効と判断されたとしても、残部の条項は、その後も有効に存続する。</li>
      </ul>

      <h4 translate>第37条（反社会的勢力の排除）</h4>
      <ol class="p-contracts__orderedList">
        <li>
          <span translate>当社及び契約者は、次の各号のいずれか一にも該当しないことを表明し、かつ将来にわたっても該当しないことを表明し、保証するものとします。</span>
          <ul class="p-contracts__subList-none">
            <li translate>
              ① 自ら又は自らの役員が、暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋、社会運動等標ぼうゴロ又は特殊知能暴力集団等その他これらに準じる者（以下総称して「暴力団員等」という）であること
            </li>
            <li translate>② 暴力団員等が経営を支配していると認められる関係を有すること</li>
            <li translate>③ 暴力団員等が経営に実質的に関与していると認められる関係を有すること</li>
            <li translate>
              ④ 自ら若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってするなど、暴力団員等を利用していると認められる関係を有すること
            </li>
            <li translate>⑤ 暴力団員等に対して資金等を提供し、又は便宜を供与するなどの関与をしていると認められる関係を有すること</li>
            <li translate>⑥ 自らの役員又は自らの経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること</li>
            <li translate>
              ⑦ 当社及び契約者は、自ら又は第三者を利用して次の各号のいずれか一にでも該当する行為を行わないことを保証するものとします。
              <ol class="p-contracts__subList-decimal">
                <li translate>暴力的な要求行為</li>
                <li translate>法的な責任を超えた不当な要求行為</li>
                <li translate>取引に関して、脅迫的な言動をし、又は暴力を用いる行為</li>
                <li translate>風説を流布し、偽計を用い又は威力を用いて相手方の信用を毀損し、又は相手方の業務を妨害する行為</li>
                <li translate>その他前各号に準ずる行為</li>
              </ol>
            </li>
          </ul>
        </li>
        <li translate>
          当社及び契約者は、相手方が前二項に違反した場合は、通知又は催告等何らの手続きを要しないで直ちに利用契約を解除することができるものとします。
        </li>
        <li translate>当社及び契約者は、本条違反を理由として本契約を解除した場合、相手方に損害が生じても、その賠償責任を負わないものとします。</li>
      </ol>

      <h3 translate>第7章　その他</h3>

      <h4 translate>第38条（協議解決）</h4>
      <ul class="p-contracts__unOrderedList">
        <li translate>
          本利用契約及び利用規約の解釈に疑義が生じた場合、またはこれらに定めのない事項については、契約者および当社は誠意をもって協議し、解決しなければなりません。
        </li>
      </ul>

      <h4 translate>第39条（管轄裁判所）</h4>
      <ul class="p-contracts__unOrderedList">
        <li translate>本利用契約及び利用規約に関わる一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</li>
      </ul>

      <h4 translate>第40条（準拠法）</h4>
      <ul class="p-contracts__unOrderedList">
        <li translate>本利用契約及び利用規約は、特に定める場合を除き、日本国の諸法令、諸規則に準拠し、同法に基づいて解釈されるものとします。</li>
      </ul>

      <p><span translate>附則（2023年7月14日）</span><br><span translate>この利用規約は、2023年7月14日より有効となります。</span></p>
      <br>
      <p><span translate>改訂履歴</span><br><span translate>2023年7月14日　新規作成</span><br></p>
      <br>
      <p translate>利用規約に関する不明な点がございましたら、当社まで書面にてお問い合わせ頂くようお願い申し上げます。</p>
      <br>

      <p>〒102-0073<br>
        <span translate>東京都千代田区九段北1丁目2番1号九段北1丁目ビル6F</span><br><span translate>株式会社グラブデザイン</span>
      </p>
      <br>
      <p translate>以上</p>
    </div>
  </div>
</div>
<app-footer></app-footer>
