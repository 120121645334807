import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import firebase from "firebase/compat";
import ActionCodeSettings = firebase.auth.ActionCodeSettings;
import {environment} from "../../../environments/environment";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-email-link',
  templateUrl: './email-link.component.html',
  styleUrls: ['./email-link.component.scss']
})
export class EmailLinkComponent implements OnInit {
  email = new FormControl<string|null>(null, {
    validators: [
      Validators.required,
    ]
  });
  showError = false;
  form = this.builder.group({
    email: this.email,
  });
  constructor(
    private router: Router,
    private builder: FormBuilder,
    private afAuth: AngularFireAuth,
    private toaster: ToastrService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
  }
  async onSubmit(): Promise<void> {
    this.showError = false;
    if (!this.form.valid) {
      this.showError = true;
      return;
    }
    const action: ActionCodeSettings = {
      url: environment.hostName,
      handleCodeInApp: false,
    }
    this.afAuth.sendSignInLinkToEmail(this.email.value, action)
      .then(() => {
        this.toaster.success(this.translateService.instant('ログインリンクをEメールアドレスで送信しました。'), this.translateService.instant('メールアドレス'));
      })
      .catch((e) => {
        this.toaster.warning(this.translateService.instant('メールリンクを送信出来ませんでした。登録済みのメールアドレスかどうかをご確認ください。またメールアドレスの有効性確認がお済みでない場合はメールリンクのログイン機能をご使用になれません。'));
        console.log(e);
      })
  }
}
