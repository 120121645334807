import { Pipe, PipeTransform } from '@angular/core';
import {DateFormat} from '../../models/date-format';
import firebase from 'firebase/compat';
import Timestamp = firebase.firestore.Timestamp;

@Pipe({
  name: 'datetimeFormatFirestore'
})
export class DatetimeFormatFirestorePipe implements PipeTransform {

  transform(value: Timestamp | string | Date | { seconds: number, nanoseconds: number  }): string {
    const df = new DateFormat();
    if (typeof value === 'string') {
      // const d = new Date(value.replace(/-/g, '/'));
      const d = new Date(value);
      return df.getDateTimeString(d);
    } else if (typeof value === 'object') {
      if (value instanceof Date) {
        return df.getDateTimeString(value);
      } else {
        if (value === null) {
          return '';
        }
        if (value.seconds === undefined) {
          return '';
        }
        if (value.seconds === null) {
          return '';
        }
        const d = new Date(value.seconds * 1000);
        const year = d.getFullYear();
        const month = (`0${d.getMonth() + 1}`).slice(-2);
        const day = (`0${d.getDate()}`).slice(-2);
        const hour = (`0${d.getHours()}`).slice(-2);
        const min = (`0${d.getMinutes()}`).slice(-2);
        const sec = (`0${d.getSeconds()}`).slice(-2);
        return `${year}/${month}/${day} ${hour}:${min}:${sec}`;
      }
    } else {
      return '';
    }
  }

}
