<app-header-contents-channel></app-header-contents-channel>
<div class="l-content-front">
  <app-breadcrumb-front></app-breadcrumb-front>
  <div class="l-content-front__inner">
    <div class="p-course">
      <div class="p-course__head">
        <div class="p-course__head__player">
          <img src="assets/images/sample_contentsChannelTop.png" alt="">
        </div>
        <div class="p-course__head__courseMetaWrap">
          <div class="p-courseMeta">
            <div class="p-courseMeta__lectureType" translate>オンライン講義</div>
            <div class="p-courseMeta__title">オンライン講義タイトル</div>
            <div class="p-courseMeta__detail">オンライン講義の解説文〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●〇〇〇〇●</div>
            <button class="p-courseMeta__moreButton"><span translate>もっと見る</span></button>
            <div class="p-courseMeta__channelName">「共感講座Ⓡ」シリーズ</div>
            <div class="p-courseMeta__courseName">損しないための社会人マナー</div>
            <div class="p-courseMeta__user">
              <div class="p-user">
                <div class="p-user__avatar p-user__avatar-small">
                  <img src="assets/images/avatar/grabdesign.png" alt="">
                </div>
                <div class="p-user__profile">
                  <div class="p-user__profile__name">
                    グラブデザイン
                  </div>
                  <div class="p-user__profile__id">
                    @grabdesign
                  </div>
                </div>
              </div>
            </div>
            <div class="p-courseMeta__actionButtons">
              <button class="p-courseMeta__actionButtons__good">123</button>
              <button class="p-courseMeta__actionButtons__bookMark"><span translate>ブックマーク</span></button>
              <button class="p-courseMeta__actionButtons__share"><span translate>共有</span></button>
              <div class="p-courseMeta__actionButtons__badge">
                <div class="p-courseMeta__actionButtons__badge__rate">100%</div>
                <div class="p-courseMeta__actionButtons__badge__icon">
                  <img src="../../../assets/images/badge_complete.png" alt="">
                </div>
              </div>
            </div>
            <div class="p-courseMeta__progress">
              <div class="p-courseMeta__progress__caption" translate>このコンテンツの試聴進捗</div>
              <div class="p-courseMeta__progress__cell">
                <div class="p-courseMeta__progress__cell__column p-courseMeta__progress__cell__column__watched"></div>
                <div class="p-courseMeta__progress__cell__column p-courseMeta__progress__cell__column__watched"></div>
                <div class="p-courseMeta__progress__cell__column p-courseMeta__progress__cell__column__watched"></div>
                <div class="p-courseMeta__progress__cell__column p-courseMeta__progress__cell__column__watched"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column p-courseMeta__progress__cell__column__watched"></div>
                <div class="p-courseMeta__progress__cell__column p-courseMeta__progress__cell__column__watched"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
                <div class="p-courseMeta__progress__cell__column"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-course__lecture">
        <div class="p-course__lecture__title" translate>講義予約</div>
        <div class="p-course__lecture__list">
          <div class="c-card-front-reserve">
            <div class="c-card-front-reserve__thumb">
              <img src="assets/images/sampleImage_video_01.png" alt="">
            </div>
            <div class="c-card-front-reserve__body">
              <div class="c-card-front-reserve__body__title">損しないための損しないための損しないた損しないための損しないための損しないた</div>
              <div class="c-card-front-reserve__body__caption" translate>受講完了</div>
            </div>
            <div class="c-card-front-reserve__actionButton" translate>日程確認</div>
          </div>
          <div class="c-card-front-reserve">
            <div class="c-card-front-reserve__thumb">
              <img src="assets/images/sampleImage_video_01.png" alt="">
            </div>
            <div class="c-card-front-reserve__body">
              <div class="c-card-front-reserve__body__title">損しないための損しないための損しないた損しないための損しないための損しないた</div>
              <div class="c-card-front-reserve__body__caption" translate>受講完了</div>
            </div>
            <button (click)="openReservationModal()" class="c-card-front-reserve__actionButton c-card-front-reserve__actionButton-booking"><span translate>予約する</span></button>
          </div>
          <div class="c-card-front-reserve">
            <div class="c-card-front-reserve__thumb">
              <img src="assets/images/sampleImage_video_01.png" alt="">
            </div>
            <div class="c-card-front-reserve__body">
              <div class="c-card-front-reserve__body__title">損しないための損しないための損しないた損しないための損しないための損しないた</div>
              <div class="c-card-front-reserve__body__caption" translate>受講完了</div>
            </div>
            <div class="c-card-front-reserve__actionButton c-card-front-reserve__actionButton-reserved" translate>予約済み</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

<section class="l-modal" *ngIf="isOpenReservationModal">
  <div class="c-modal">
    <div class="c-modal__head">
      <div class="c-modal__head__title" translate>
        オンライン講義の予約
      </div>
      <div class="c-modal__head__closeButton" (click)="closeReservationModal()"></div>
    </div>
    <div *ngIf="isShowConfirm === false" class="c-modal__main">
      <div class="c-modal__main__description">
        <div class="c-modal__main__description__title" translate>講義名</div>
        <div class="c-modal__main__description__body">講義タイトル講義タイトル講義タイトル講義タイトル講義タイトル</div>
      </div>
      <div class="p-calender">
        <div class="p-calender__title" translate>日程選択</div>
        <div class="p-calender__header">
          <button class="p-calender__header__prevButton"></button>
          <div class="p-calender__header__title">2023<span translate>年</span>3<span translate>月</span></div>
          <button class="p-calender__header__nextButton"></button>
        </div>
        <div class="p-calender__week">
          <div class="p-calender__week__item p-calender__week__item-sun">日</div>
          <div class="p-calender__week__item">月</div>
          <div class="p-calender__week__item">火</div>
          <div class="p-calender__week__item">水</div>
          <div class="p-calender__week__item">木</div>
          <div class="p-calender__week__item">金</div>
          <div class="p-calender__week__item p-calender__week__item-sat">土</div>
        </div>
        <div class="p-calender__body">
          <div class="p-calender__body__row">
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date"></span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date"></span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date"></span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date">1</span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date">2</span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date">3</span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date p-calender__body__row__item__button__date-sat">4</span>
              </button>
            </div>
          </div>
          <div class="p-calender__body__row">
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date p-calender__body__row__item__button__date-sun">5</span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date">6</span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date">7</span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date">8</span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date">9</span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date">10</span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date p-calender__body__row__item__button__date-sat">11</span>
              </button>
            </div>
          </div>
          <div class="p-calender__body__row">
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date p-calender__body__row__item__button__date-sun">12</span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date">13</span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date">14</span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date">15</span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date p-calender__body__row__item__button__date-today">16</span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date">17</span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date p-calender__body__row__item__button__date-sat">18</span>
              </button>
            </div>
          </div>
          <div class="p-calender__body__row">
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date p-calender__body__row__item__button__date-sun">19</span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date">20</span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date">21</span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date p-calender__body__row__item__button__date-selected">22</span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date p-calender__body__row__item__button__date-available">23</span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date">24</span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date p-calender__body__row__item__button__date-sat">25</span>
              </button>
            </div>
          </div>
          <div class="p-calender__body__row">
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date p-calender__body__row__item__button__date-sun">26</span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date">27</span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date">28</span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date">29</span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date">30</span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date">31</span>
              </button>
            </div>
            <div class="p-calender__body__row__item">
              <button class="p-calender__body__row__item__button">
                <span class="p-calender__body__row__item__button__date"></span>
              </button>
            </div>
          </div>
        </div>
        <div class="p-calender__info" translate>以下のご予約が可能です</div>
        <div class="p-calender__vacancyList">
          <div class="p-calender__vacancyList__row">
            <div class="p-calender__vacancyList__row__schedule">
              <div class="p-calender__vacancyList__row__schedule__date">3月22日</div>
              <div class="p-calender__vacancyList__row__schedule__time">10:00 - 12:00</div>
            </div>
            <div class="p-calender__vacancyList__row__bookingButton">
              <button class="c-button c-button-primary"><span translate>予約する</span>(<span translate>残り</span>:10<span translate>枠</span>)</button>
            </div>
          </div>
          <div class="p-calender__vacancyList__row">
            <div class="p-calender__vacancyList__row__schedule">
              <div class="p-calender__vacancyList__row__schedule__date">3月22日</div>
              <div class="p-calender__vacancyList__row__schedule__time">10:00 - 12:00</div>
            </div>
            <div class="p-calender__vacancyList__row__bookingButton">
              <button class="c-button c-button-primary"><span translate>予約する</span>(<span translate>残り</span>:10<span translate>枠</span>)</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isShowConfirm" class="c-modal__main">
      <div class="c-modal__main__description">
        <div class="c-modal__main__description__title" translate>講義名</div>
        <div class="c-modal__main__description__body">講義タイトル講義タイトル講義タイトル講義タイトル講義タイトル</div>
      </div>
      <div class="c-modal__main__description">
        <div class="c-modal__main__description__title" translate>選択した日程</div>
        <div class="c-modal__main__description__body">2023年3月28日 10:00 ~ 12:00</div>
      </div>
      <div class="c-modal__main__description">
        <div class="c-modal__main__description__title" translate>受講方法</div>
        <div class="c-modal__main__description__body">ZOOM</div>
      </div>
      <div class="c-modal__main__description">
        <div class="c-modal__main__description__title" translate>連絡事項</div>
        <div class="c-modal__main__description__body">れんらくれんらくれんらくれんらくれんらくれんらくれんらくれんらくれんらくれんらくれんらくれんらくれんらくれんらくれんらくれんらくれんらくれんらくれんらくれんらく</div>
      </div>
      <div class="c-modal__main__control">
        <div class="c-modal__main__control__buttons">
          <div class="c-modal__main__control__buttons__button">
            <button class="c-button c-button-cancel"><span translate>キャンセル</span></button>
          </div>
          <div class="c-modal__main__control__buttons__button">
            <button class="c-button c-button-primary"><span translate>予約を確定する</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

