import {Component, OnInit} from '@angular/core';
import firebase from 'firebase/compat/app';
import { getAuth, OAuthProvider, signInWithRedirect, getRedirectResult } from "firebase/auth";
import {environment} from "../../../environments/environment";
import {AuthService} from "../../services/auth/auth.service";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {UiState} from "../../store/ui/ui.reducer";
import {switchFalseLoading, switchTrueLoading} from "../../store/ui/ui.actions";

@Component({
  selector: 'app-login-by-entra',
  templateUrl: './login-by-entra.component.html',
  styleUrls: ['./login-by-entra.component.scss']
})
export class LoginByEntraComponent implements OnInit {
  showView = false;
  constructor(
    private authService: AuthService,
    private uiStore: Store<{ ui: UiState}>,
    private router: Router,
  ) {}
  async ngOnInit(): Promise<void> {
    this.uiStore.dispatch(switchTrueLoading());
    this.showView = false;
    firebase.initializeApp(environment.firebase);
    const provider = new OAuthProvider('microsoft.com');
    provider.setCustomParameters({
      prompt: 'consent',
      login_hint: 'user@firstadd.onmicrosoft.com',
      tenant: environment.ssoMicrosoftTenantId
    });
    const auth = getAuth();
    getRedirectResult(auth)
      .then(async (result) => {
        if (!result) {
          this.showView = true;
          this.uiStore.dispatch(switchFalseLoading());
          return;
        }
        const credential = OAuthProvider.credentialFromResult(result);
        this.authService.accessToken = credential.accessToken;
        sessionStorage.setItem('accessToken', credential.accessToken);
        await this.authService.refresh()
          .then(() => {
            this.uiStore.dispatch(switchFalseLoading());
            this.router.navigate(['/']);
          })
          .catch(() => {
            this.uiStore.dispatch(switchFalseLoading());
            this.authService.logout();
            this.router.navigate(['/']);
          });
      })
      .catch((error) => {
        console.log(error);
        this.showView = true;
      });
  }
  async login(): Promise<void> {
    this.uiStore.dispatch(switchTrueLoading());
    const provider = new OAuthProvider('microsoft.com');
    provider.setCustomParameters({
      prompt: 'consent',
      login_hint: 'user@firstadd.onmicrosoft.com',
      tenant: environment.ssoMicrosoftTenantId
    });
    provider.addScope('mail.read');
    provider.addScope('calendars.read');
    const auth = getAuth();
    await signInWithRedirect(auth, provider);
  }
}
