import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-channel-course-remote-reservation',
  templateUrl: './channel-course-remote-reservation.component.html',
  styleUrls: ['./channel-course-remote-reservation.component.scss']
})
export class ChannelCourseRemoteReservationComponent implements OnInit {
  isOpenReservationModal = false;
  isShowConfirm = true;

  constructor() { }

  ngOnInit(): void {
  }
  openReservationModal(): void {
    this.isOpenReservationModal = true;
  }
  closeReservationModal(): void {
    this.isOpenReservationModal = false;
  }
}
