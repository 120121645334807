export class EmailSender {
  customerId: string;
  email: string;
  subject: string;
  body: string;
  fromName: string;
  fromAddress: string;
  constructor() {
    this.customerId = null;
    this.email = null;
    this.subject = null;
    this.body = null;
    this.fromName = null;
    this.fromAddress = null;
  }
}

export const emailSignature = {
  body:  ' \n' +
    ' \n' +
    ' \n' +
    ' -----------------------------------------------------------------------\n' +
    ' FireRocket LMS\n' +
    ' \n'
}
export const emailSignatureOem = {
  body:  ' \n' +
    ' \n' +
    ' __%oem_signature%__\n' +
    ' \n'
}
export const emailRegisterComplete = {
  subject: '【FireRocket LMS】本登録完了のお知らせ',
  body: '-----------------------------------------------------------------------\n' +
    ' このメールは送信専用です。\n' +
    ' 直接返信いただきましても回答は致しかねますのでご注意ください。\n' +
    ' -----------------------------------------------------------------------\n' +
    '\n' +
    ' __%user_name%__様\n' +
    '   \n' +
    'FireRocket LMSをご利用頂きありがとうございます。\n' +
    '\n' +
    ' ご登録ありがとうございます。\n' +
    ' 会員登録が完了いたしましたのでご案内申し上げます。\n' +
    ' \n' +
    ' ログインURLはこちら\n' +
    ' __%url%__\n' +
    ' \n' +
    ' 本メールに心当たりが無い場合はお手数ですが下記問い合わせ先までご連絡ください。\n' +
    ' その他何かご不明な点等ございましたら、お気軽にお問い合わせください。\n' +
    ' ',
}
export const emailRegisterCompleteForOem = {
  subject: '【__%channel_name%__】登録完了のお知らせ',
  body: '-----------------------------------------------------------------------\n' +
    ' このメールは送信専用です。\n' +
    ' 直接返信いただきましても回答は致しかねますのでご注意ください。\n' +
    ' -----------------------------------------------------------------------\n' +
    '\n' +
    ' __%user_name%__様\n' +
    '   \n' +
    '__%channel_name%__をご利用頂きありがとうございます。\n' +
    '\n' +
    ' ご登録ありがとうございます。\n' +
    ' 会員登録が完了いたしましたのでご案内申し上げます。\n' +
    '\n' +
    ' ログインID（メールアドレス）\n' +
    ' __%email%__\n' +
    ' \n' +
    ' 初期パスワード\n' +
    ' __%password%__\n' +
    ' \n' +
    ' ログインURLはこちら\n' +
    ' __%url%__\n' +
    ' \n' +
    ' 本メールに心当たりが無い場合はお手数ですが下記問い合わせ先までご連絡ください。\n' +
    ' その他何かご不明な点等ございましたら、お気軽にお問い合わせください。\n' +
    ' ',
}
export const emailInquiry = {
  subject: '【FireRocket LMS】お問い合わせいただきありがとうございます',
  body: '-----------------------------------------------------------------------\n' +
    ' このメールは送信専用です。\n' +
    ' 直接返信いただきましても回答は致しかねますのでご注意ください。\n' +
    ' -----------------------------------------------------------------------\n' +
    ' \n' +
    'FireRocket LMSをご利用頂きありがとうございます。\n' +
    '\n' +
    ' この度はFireRocket LMSへのお問い合わせありがとうございました。\n' +
    ' 以下の内容でお問い合わせを受付いたしました。\n' +
    ' 後日、担当よりご連絡いたしますので今しばらくお待ちください。\n' +
    ' \n' +
    ' ◆お問い合わせ内容◆\n' +
    ' お問い合わせ内容本文\n' +
    ' \n' +
    ' ・氏名\n' +
    ' 生徒 太郎\n' +
    ' \n' +
    ' ・メールアドレス\n' +
    ' acc@mail.com\n' +
    ' \n' +
    ' \n' +
    ' 本メールに心当たりが無い場合はお手数ですが下記問い合わせ先までご連絡ください。\n' +
    ' その他何かご不明な点等ございましたら、お気軽にお問い合わせください。\n' +
    ' \n' +
    ' ',
}
export const emailQuit = {
  subject: '【FireRocket LMS】退会完了のお知らせ',
  body: '-----------------------------------------------------------------------\n' +
    ' このメールは送信専用です。\n' +
    ' 直接返信いただきましても回答は致しかねますのでご注意ください。\n' +
    ' -----------------------------------------------------------------------\n' +
    '   \n' +
    ' __%user_name%__様\n' +
    '    \n' +
    'FireRocket LMSをご利用頂きありがとうございます。\n' +
    '\n' +
    ' この度退会手続きが完了しましたことをお知らせいたします。\n' +
    ' またのご利用をお待ちしております。\n' +
    ' \n' +
    ' \n' +
    ' 本メールに心当たりが無い場合はお手数ですが下記問い合わせ先までご連絡ください。\n' +
    ' その他何かご不明な点等ございましたら、お気軽にお問い合わせください。\n' +
    ' \n' +
    ' \n' +
    ' ',
}
export const emailOnlineReserved = {
  subject: '【ACC】オンライン授業予約完了のお知らせ ',
  body: '-----------------------------------------------------------------------\n' +
    ' このメールは送信専用です。\n' +
    ' 直接返信いただきましても回答は致しかねますのでご注意ください。\n' +
    ' -----------------------------------------------------------------------\n' +
    '   \n' +
    ' __%user_name%__様\n' +
    '    \n' +
    'FireRocket LMSをご利用頂きありがとうございます。\n' +
    '\n' +
    ' ご予約いただきありがとうございます。\n' +
    ' 下記の内容で承りましたのでご確認ください。\n' +
    ' \n' +
    ' ◆ご予約内容◆\n' +
    ' __%course_title%__◆\n' +
    ' __%frame_title%__◆\n' +
    ' __%frame_date_time%__\n' +
    ' \n' +
    ' 当日は下記URLからご参加ください。\n' +
    ' __%teams_url%__\n' +
    ' \n' +
    ' 本メールに心当たりが無い場合はお手数ですが下記問い合わせ先までご連絡ください。\n' +
    ' その他何かご不明な点等ございましたら、お気軽にお問い合わせください。\n'
}
export const emailReportSaved = {
  subject: '【FireRocket LMS】レポート登録完了のお知らせ',
  body: '-----------------------------------------------------------------------\n' +
    ' このメールは送信専用です。\n' +
    ' 直接返信いただきましても回答は致しかねますのでご注意ください。\n' +
    ' -----------------------------------------------------------------------\n' +
    '\n' +
    ' __%user_name%__様\n' +
    '    \n' +
    'FireRocket LMSをご利用頂きありがとうございます。\n' +
    '\n' +
    ' レポートの登録が完了しました。\n' +
    ' 採点結果が出るまで今しばらくお待ちください。\n' +
    ' \n' +
    ' \n' +
    ' 本メールに心当たりが無い場合はお手数ですが下記問い合わせ先までご連絡ください。\n' +
    ' その他何かご不明な点等ございましたら、お気軽にお問い合わせください。\n' +
    ' ',
}

export const emailLiveReserved = {
  subject: '【FireRocket LMS】実地授業予約完了のお知らせ',
  body: '-----------------------------------------------------------------------\n' +
    ' このメールは送信専用です。\n' +
    ' 直接返信いただきましても回答は致しかねますのでご注意ください。\n' +
    ' -----------------------------------------------------------------------\n' +
    '  \n' +
    ' __%user_name%__様\n' +
    '    \n' +
    'FireRocket LMSをご利用頂きありがとうございます。\n' +
    '\n' +
    ' ご予約いただきありがとうございます。\n' +
    ' 下記の内容で承りましたのでご確認ください。\n' +
    ' \n' +
    ' ◆ご予約内容◆\n' +
    ' __%course_title%__◆\n' +
    ' __%frame_title%__◆\n' +
    ' __%frame_date_time%__\n' +
    ' \n' +
    ' 当日は下記会場にお越しください。\n' +
    ' __%address%__\n' +
    ' 研修会場の住所GoogleマップURL\n' +
    ' __%map_url%__\n' +
    ' \n' +
    ' 本メールに心当たりが無い場合はお手数ですが下記問い合わせ先までご連絡ください。\n' +
    ' その他何かご不明な点等ございましたら、お気軽にお問い合わせください。',
}
export const emailSurveyTxs = {
  subject: '【FireRocket LMS】アンケートにご協力いただきありがとうございました',
  body: '-----------------------------------------------------------------------\n' +
    ' このメールは送信専用です。\n' +
    ' 直接返信いただきましても回答は致しかねますのでご注意ください。\n' +
    ' -----------------------------------------------------------------------\n' +
    '\n' +
    ' __%user_name%__様\n' +
    '    \n' +
    'FireRocket LMSをご利用頂きありがとうございます。\n' +
    '\n' +
    ' アンケートに回答いただきありがとうございます。\n' +
    ' いただきました貴重なご意見は、今後のサービス向上の参考とさせていただきます。\n' +
    ' \n' +
    ' \n' +
    ' なお、アンケートの回答は特定の個人が識別できる情報として、公表されることはありません。\n' +
    ' \n' +
    ' 本メールに心当たりが無い場合はお手数ですが下記問い合わせ先までご連絡ください。\n' +
    ' その他何かご不明な点等ございましたら、お気軽にお問い合わせください。\n' +
    ' ',
}
export const emailAddMyChannel = {
  subject: '【FireRocket LMS】チャンネル登録をしました。',
  body: '-----------------------------------------------------------------------\n' +
    ' このメールは送信専用です。\n' +
    ' 直接返信いただきましても回答は致しかねますのでご注意ください。\n' +
    ' -----------------------------------------------------------------------\n' +
    '   \n' +
    ' __%user_name%__様\n' +
    '    \n' +
    'FireRocket LMSをご利用頂きありがとうございます。\n' +
    '\n' +
    ' 「チャンネル名」\n' +
    ' 上記の受講申し込みを受け付けました。\n' +
    ' \n' +
    ' 本メールに心当たりが無い場合はお手数ですが下記問い合わせ先までご連絡ください。\n' +
    ' その他何かご不明な点等ございましたら、お気軽にお問い合わせください。\n' +
    ' \n' +
    ' ',
}
export const emailCourseActivation = {
  subject: '【FireRocket LMS】受講申込みを受け付けました',
  body: '-----------------------------------------------------------------------\n' +
    ' このメールは送信専用です。\n' +
    ' 直接返信いただきましても回答は致しかねますのでご注意ください。\n' +
    ' -----------------------------------------------------------------------\n' +
    '   \n' +
    ' __%user_name%__様\n' +
    '    \n' +
    'FireRocket LMSをご利用頂きありがとうございます。\n' +
    '\n' +
    ' 「受講コース名」\n' +
    ' 上記の受講申し込みを受け付けました。\n' +
    ' \n' +
    ' 以下の内容になりますので、改めてご確認ください。\n' +
    ' ◆受講コース内容◆\n' +
    ' ・__%course_title%__\n' +
    ' \n' +
    ' \n' +
    ' 本メールに心当たりが無い場合はお手数ですが下記問い合わせ先までご連絡ください。\n' +
    ' その他何かご不明な点等ございましたら、お気軽にお問い合わせください。\n' +
    ' \n' +
    ' ',
}
export const emailCourseActivationApply = {
  subject: '【FireRocket LMS】受講申込みを受け付けました',
  body: '-----------------------------------------------------------------------\n' +
    ' このメールは送信専用です。\n' +
    ' 直接返信いただきましても回答は致しかねますのでご注意ください。\n' +
    ' -----------------------------------------------------------------------\n' +
    '   \n' +
    ' __%user_name%__様\n' +
    '    \n' +
    'FireRocket LMSをご利用頂きありがとうございます。\n' +
    '\n' +
    ' 「受講コース名」\n' +
    ' 上記の受講申し込みを受け付けました。\n' +
    ' 下記URLよりアクセスしてください。\n' +
    ' ・__%course_url%__\n' +
    ' \n' +
    ' 以下の内容になりますので、改めてご確認ください。\n' +
    ' ◆受講コース内容◆\n' +
    ' ・__%course_title%__\n' +
    ' \n' +
    ' \n' +
    ' 本メールに心当たりが無い場合はお手数ですが下記問い合わせ先までご連絡ください。\n' +
    ' その他何かご不明な点等ございましたら、お気軽にお問い合わせください。',
}
export const emailCourseActivationReject = {
  subject: '【FireRocket LMS】再申込みのお願い',
  body: '-----------------------------------------------------------------------\n' +
    ' このメールは送信専用です。\n' +
    ' 直接返信いただきましても回答は致しかねますのでご注意ください。\n' +
    ' -----------------------------------------------------------------------\n' +
    '\n' +
    ' __%user_name%__様\n' +
    '    \n' +
    'FireRocket LMSをご利用頂きありがとうございます。\n' +
    '\n' +
    ' 「受講コース名」\n' +
    ' 上記コースの受講を申し込み頂きましたが、\n' +
    ' 申し込み内容に不備がありましたので再度申し込みをお願いします。\n' +
    ' \n' +
    ' ・__%course_title%__\n' +
    ' \n' +
    ' ◆不備について◆\n' +
    ' ・__%reject_note%__\n' +
    ' \n' +
    ' 本メールに心当たりが無い場合はお手数ですが下記問い合わせ先までご連絡ください。\n' +
    ' その他何かご不明な点等ございましたら、お気軽にお問い合わせください。\n' +
    ' \n' +
    ' ',
}

export const emailGetComment = {
  subject: '【FireRocket LMS】新着コメントがありました',
  body: '-----------------------------------------------------------------------\n' +
    ' このメールは送信専用です。\n' +
    ' 直接返信いただきましても回答は致しかねますのでご注意ください。\n' +
    ' -----------------------------------------------------------------------\n' +
    '   \n' +
    ' __%user_name%__様\n' +
    '    \n' +
    'FireRocket LMSをご利用頂きありがとうございます。\n' +
    '\n' +
    ' 新着コメントがありました。\n' +
    ' \n' +
    ' ◆コメント情報◆\n' +
    ' ・コース名\n' +
    ' ・日時\n' +
    ' ・コメント先動画講義URLを表示\n' +
    ' \n' +
    ' \n' +
    ' 本メールに心当たりが無い場合はお手数ですが下記問い合わせ先までご連絡ください。\n' +
    ' その他何かご不明な点等ございましたら、お気軽にお問い合わせください。',
}
export const emailOnlineDidNotAttendant = {
  subject: '【FireRocket LMS】オンライン授業への出席が確認できませんでした',
  body: '-----------------------------------------------------------------------\n' +
    ' このメールは送信専用です。\n' +
    ' 直接返信いただきましても回答は致しかねますのでご注意ください。\n' +
    ' -----------------------------------------------------------------------\n' +
    '\n' +
    '__%user_name%__様\n' +
    '    \n' +
    'FireRocket LMSをご利用頂きありがとうございます。\n' +
    '\n' +
    ' 「__%frame_name%__」\n' +
    ' 上記の授業への出席が確認できませんでした。\n' +
    ' 再度別日程での受講申請をしてください。\n' +
    ' __%url%__\n' +
    ' \n' +
    ' \n' +
    ' ◆LIVE授業内容◆\n' +
    ' ・コース名 __%course_name%__\n' +
    ' ・受講コースタイトル __%frame_name%__\n' +
    ' \n' +
    ' \n' +
    ' 本メールに心当たりが無い場合はお手数ですが下記問い合わせ先までご連絡ください。\n' +
    ' その他何かご不明な点等ございましたら、お気軽にお問い合わせください。\n' +
    ' \n' +
    ' ',
}
export const emailLiveDidAttendant = {
  subject: '【FireRocket LMS】実地授業への出席が確認できませんでした',
  body: '-----------------------------------------------------------------------\n' +
    ' このメールは送信専用です。\n' +
    ' 直接返信いただきましても回答は致しかねますのでご注意ください。\n' +
    ' -----------------------------------------------------------------------\n' +
    '\n' +
    ' __%user_name%__様\n' +
    '    \n' +
    'FireRocket LMSをご利用頂きありがとうございます。\n' +
    '\n' +
    ' 「実地授業名」\n' +
    ' 上記の授業への出席が確認できませんでした。\n' +
    ' 再度別日程での受講申請をしてください。\n' +
    ' __%url%__\n' +
    ' \n' +
    ' \n' +
    ' ◆実地授業内容◆\n' +
    ' ・コース名 __%course_name%__\n' +
    ' ・受講コースタイトル __%frame_name%__\n' +
    ' \n' +
    ' \n' +
    ' 本メールに心当たりが無い場合はお手数ですが下記問い合わせ先までご連絡ください。\n' +
    ' その他何かご不明な点等ございましたら、お気軽にお問い合わせください。\n' +
    ' \n' +
    ' ',
}

export const emailTestFinished = {
  subject: '【FireRocket LMS】WEBテスト採点終了のお知らせ ',
  body: '-----------------------------------------------------------------------\n' +
    ' このメールは送信専用です。\n' +
    ' 直接返信いただきましても回答は致しかねますのでご注意ください。\n' +
    ' -----------------------------------------------------------------------\n' +
    '   \n' +
    ' __%user_name%__様\n' +
    '    \n' +
    'FireRocket LMSをご利用頂きありがとうございます。\n' +
    '\n' +
    ' WEBテストの提出ありがとうございました。\n' +
    ' 採点が終了いたしましたので、下記URLから結果をご確認ください。\n' +
    ' ・コース名 __%course_name%__\n' +
    ' \n' +
    ' \n' +
    ' 本メールに心当たりが無い場合はお手数ですが下記問い合わせ先までご連絡ください。\n' +
    ' その他何かご不明な点等ございましたら、お気軽にお問い合わせください。',
}
export const emailReportFinished = {
  subject: '【FireRocket LMS】レポート採点終了のお知らせ',
  body: '-----------------------------------------------------------------------\n' +
    ' このメールは送信専用です。\n' +
    ' 直接返信いただきましても回答は致しかねますのでご注意ください。\n' +
    ' -----------------------------------------------------------------------\n' +
    '\n' +
    ' __%user_name%__様\n' +
    '    \n' +
    'FireRocket LMSをご利用頂きありがとうございます。\n' +
    '\n' +
    ' レポートのご提出ありがとうございました。\n' +
    ' 採点が終了いたしましたので、下記URLから結果をご確認ください。\n' +
    ' ・コース名 __%course_name%__\n' +
    ' \n' +
    ' \n' +
    ' 本メールに心当たりが無い場合はお手数ですが下記問い合わせ先までご連絡ください。\n' +
    ' その他何かご不明な点等ございましたら、お気軽にお問い合わせください。\n' +
    ' \n' +
    ' ',
}
export const emailChangeProfile = {
  subject: '【FireRocket LMS】プロフィール登録内容変更のお知らせ',
  body: '-----------------------------------------------------------------------\n' +
    ' このメールは送信専用です。\n' +
    ' 直接返信いただきましても回答は致しかねますのでご注意ください。\n' +
    ' -----------------------------------------------------------------------\n' +
    '   \n' +
    ' __%user_name%__様\n' +
    '    \n' +
    'FireRocket LMSをご利用頂きありがとうございます。\n' +
    '\n' +
    ' プロフィール登録内容を変更いたしました。\n' +
    ' 変更内容はサイトにてご確認ください。\n' +
    ' __%url%__\n' +
    ' \n' +
    ' \n' +
    ' 本メールに心当たりが無い場合はお手数ですが下記問い合わせ先までご連絡ください。\n' +
    ' その他何かご不明な点等ございましたら、お気軽にお問い合わせください。\n' +
    ' \n' +
    ' \n' +
    ' ',
}
export const email = {
  subject: '',
  body: '',
}
