  <div class="c-courseList-horizontal__items">

    <ng-container *ngFor="let channel of channels">
      <div
        routerLink="/channel/{{channel.slug}}"
        class="c-courseList-horizontal__items__item">
        <div class="c-courseList-horizontal__items__item__thumb">
          <img *ngIf="!channel?.eyeCatchImage?.src"
               ngSrc="no-image"
               width="240"
               height="135"
               ngSrcset="160w,240w,320w,480w"
               alt="">
          <img
            *ngIf="channel?.eyeCatchImage?.src"
            [ngSrc]="channel?.eyeCatchImage.src"
            width="240"
            height="135"
            ngSrcset="160w,240w,320w,480w"
            alt="">
        </div>
        <div class="c-courseList-horizontal__items__item__title">
          {{channel.name}}
        </div>
      </div>

    </ng-container>


  </div>
