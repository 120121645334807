import {User} from "./user";

export enum AttendanceStatus {
  did = 'did',
  didNot = 'didNot',
  undecided = 'undecided',
  canceled = 'canceled'
}
export interface ReservationTimeFrameInterface {
  id?: string;
  capacity: number;
  reserved: number;
  sold: number;
  accepting: boolean;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  [propertyNames: string]: any
}
export class ReservationTimeFrame implements ReservationTimeFrameInterface {
  id?: string;
  capacity: number; // 予約可能数
  reserved: number; // 予約受付数
  sold: number; // 実売数
  accepting: boolean;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  constructor() {
    this.capacity = 0;
    this.reserved = 0;
    this.sold = 0;
    this.accepting = true;
    this.createdAt = new Date();
    this.updatedAt = new Date();
    this.createdBy = null;
    this.updatedBy = null;
  }
  deserialize(): any {
    const json = JSON.stringify(this)
    return JSON.parse(json)
  }
}
export interface ReservationInterface {
  id?: string;
  uid: string;
  attendance: AttendanceStatus;
  canceled: boolean;
  canceledAt: Date;
  cert1ObjectName: string;
  cert2ObjectName: string;
  cert1FileName: string;
  cert2FileName: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  user: User;
  [propertyNames: string]: any
}
export class Reservation implements ReservationInterface {
  id?: string;
  uid: string;
  attendance: AttendanceStatus;
  canceled: boolean;
  canceledAt: Date;
  cert1ObjectName: string;
  cert2ObjectName: string;
  cert1FileName: string;
  cert2FileName: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  user: User;
  constructor() {
    this.uid = null;
    this.attendance = AttendanceStatus.undecided;
    this.canceled = false;
    this.canceledAt = null;
    this.cert2ObjectName = null;
    this.canceledAt = null;
    this.createdAt = new Date();
    this.updatedAt = new Date();
    this.createdBy = null;
    this.updatedBy = null;
    this.user = null;
  }
  deserialize(): any {
    const json = JSON.stringify(this)
    return JSON.parse(json)
  }
}
