<section class="l-modal" *ngIf="isOpen">
  <div class="c-modal">
    <div class="c-modal__head">
      <div class="c-modal__head__title" translate>受講者登録</div>
      <div class="c-modal__head__closeButton" (click)="close()"></div>
    </div>
    <div class="c-modal__main">
      <div class="c-modal__form">
        <div class="c-modal__main__form__accordion">
          <div class="c-accordion">
            <input class="c-accordion__opener" type="checkbox" id="opener-1" checked>
            <label class="c-accordion__head" for="opener-1" translate>名前</label>
            <div class="c-accordion__body">
              <div class="c-accordion__body__groupTitle" translate>お名前(漢字)</div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="familyName" translate>姓</label>
                <input [formControl]="familyName" class="c-form-input" type="text" name="familyName" id="familyName" value="山田">
              </div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="firstName" translate>名</label>
                <input [formControl]="givenName" class="c-form-input" type="text" name="firstName" id="firstName" value="太郎">
              </div>
              <div class="c-accordion__body__groupTitle" translate>お名前(ふりがな)</div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="familyNamePhonetic" translate>せい</label>
                <input [formControl]="familyNamePhonetic" class="c-form-input" type="text" name="familyNamePhonetic" id="familyNamePhonetic" value="やまだ">
              </div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="firstNamePhonetic" translate>めい</label>
                <input [formControl]="givenNamPhonetic" class="c-form-input" type="text" name="firstNamePhonetic" id="firstNamePhonetic" value="たろう">
              </div>
            </div>
          </div>
          <div class="c-accordion">
            <input class="c-accordion__opener" type="checkbox" id="opener-3" checked>
            <label class="c-accordion__head" for="opener-3" translate>法人</label>
            <div class="c-accordion__body">
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="companyName" translate>会社名</label>
                <input [formControl]="companyName" class="c-form-input" type="text" name="companyName" id="companyName" value="株式会社ファイア">
              </div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="branchName" translate>支店名</label>
                <input [formControl]="branchName" class="c-form-input" type="text" name="branchName" id="branchName" value="四谷支店">
              </div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="divisionName" translate>部署名</label>
                <input [formControl]="divisionName" class="c-form-input" type="text" name="divisionName" id="divisionName" value="営業部">
              </div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="jobTitle" translate>役職名</label>
                <input [formControl]="jobTitle" class="c-form-input" type="text" name="jobTitle" id="jobTitle" value="営業部">
              </div>
            </div>
          </div>
          <div class="c-accordion">
            <input class="c-accordion__opener" type="checkbox" id="opener-4" checked>
            <label class="c-accordion__head" for="opener-4" translate>連絡先</label>
            <div class="c-accordion__body">
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="email" translate>メールアドレス</label>
                <input [formControl]="email" class="c-form-input" type="text" name="email" id="email" value="abc@defg.jp">
              </div>
              <div class="c-accordion__body__row">
                <label class="c-form-label" for="phoneNumber" translate>電話番号</label>
                <input [formControl]="phoneNumber" class="c-form-input" type="text" name="phoneNumber" id="phoneNumber" value="090-1234-5678">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-modal__main__control">
        <div class="c-modal__main__control__status"></div>
        <div class="c-modal__main__control__buttons">
          <div class="c-modal__main__control__buttons__button">
            <button
              class="c-button c-button-cancel"
              (click)="close()"
            ><span translate>キャンセル</span></button>
          </div>
          <div class="c-modal__main__control__buttons__button">
            <button
              class="c-button c-button-primary"
              (click)="save()"
            ><span translate>保存する</span></button>
          </div>
        </div>
        <div class="c-modal__main__control__buttons">
          <div class="c-modal__main__control__buttons__deleteButton">
            <button
              class="c-button c-button-warning c-button-warning-bgWhite"
              (click)="remove()"
            ><span translate>この受講者を削除する</span></button></div>
        </div>
      </div>
    </div>
  </div>　
</section>
