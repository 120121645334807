<app-header-contents-channel></app-header-contents-channel>
<div class="l-content-front">
  <app-breadcrumb-front></app-breadcrumb-front>
  <div class="l-content-front__inner">
    <div class="l-top">
      <div class="p-channelTop">
        <div class="p-channelTop__head">
          <div class="p-channelTop__head__kv">
            <img src="../../../assets/images/kv_categoriesComponent.svg" alt="">
          </div>
          <div class="p-channelTop__head__title">
            <div class="p-channelTop__head__title__channelName">
              FireRocketⓇ LMS
            </div>
            <div class="p-channelTop__head__title__courseName" translate>
              コースカテゴリー一覧
            </div>
          </div>
        </div>

        <dl class="p-channelTop__main">
          <dd class="p-channelTop__main__body">
            <div class="p-channelTop__main__body__content">
              <div class="c-list-categories">
                <ul class="c-list-categories__box">
                  <li
                    class="c-list-categories__box__row"
                    *ngFor="let c of categories">
                    <div class="c-list-categories__box__row__title">
                      <p routerLink="/category/{{c.slug }}">{{c.name}}</p>
                    </div>
                    <div class="c-list-categories__box__row__body">{{c.description}}</div>
                  </li>
                </ul>
              </div>
            </div>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

