<section
  (click)="closeModal()"
  class="l-modalTopLayer" *ngIf="isOpenModal">
  <div class="c-modal">
    <div class="c-modal__head">
      <div class="c-modal__head__title" [innerHTML]="modalData.title | translate"></div>
      <div class="c-modal__head__closeButton" (click)="closeModal()"></div>
    </div>
    <div class="c-modal__main">
      <div class="c-modal__main__body" [innerHTML]="modalData.message | translate"></div>

      <div class="c-modal__main__control">
        <div class="c-modal__main__control__status"></div>
        <div class="c-modal__main__control__buttons">
          <div class="c-modal__main__control__buttons__button">
            <button class="c-button c-button-cancel" (click)="cancel()"><span translate>{{ modalData.falseButtonName }}</span></button>
          </div>
          <div class="c-modal__main__control__buttons__button">
            <button class="c-button c-button-primary" (click)="apply()"><span translate>{{ modalData.trueButtonName }}</span></button>
          </div>
        </div>
      </div>

    </div>


  </div>
</section>
