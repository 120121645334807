<app-header-contents-public></app-header-contents-public>
<div class="l-content-front">
  <app-breadcrumb-front></app-breadcrumb-front>
  <div class="l-content-front__inner">
    <div class="p-contracts">
      <div class="p-contracts__title">
        <h2 class="p-contracts__title__main" translate>FireRocket　サービスレベル目標（SLO）</h2>
        <div class="p-contracts__title__sub" translate>製品ブランド：FireRocket</div>
      </div>

      <div class="p-contracts__table">
        <p translate>公開日時：2023/07/10</p>
        <h3 translate>契約 ※有償契約のお客様のみ</h3>
        <table>
          <tbody>
          <tr>
            <td class="p-contracts__table__rowHeader" translate>提供リードタイム</td>
            <td class="p-contracts__table__rowData" translate>
              新規お申込み、追加オプションともに、各種設定内容確定後、当社が申込書を受領した日から、最短5営業日で可能です。
            </td>
          </tr>
          <tr>
            <td translate>最低契約期間</td>
            <td translate>3ヶ月(3ヶ月毎に自動更新)</td>
          </tr>
          <tr>
            <td translate>解約</td>
            <td translate>契約期間満了30日前までに解約申込み</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="p-contracts__table">
        <h3 translate>可用性・信頼性</h3>
        <table>
          <tbody>
          <tr>
            <td class="p-contracts__table__rowHeader" translate>サービス時間</td>
            <td class="p-contracts__table__rowData">
              <span translate>24時間365日（計画停止／定期保守を除く）</span><br>
            <span translate>セキュリティ対応やシステム稼働に重要な影響がある場合は、有償契約のお客様に対しメールにて事前通知の上、緊急メンテナンスを実施</span>
            </td>
          </tr>
          <tr>
            <td translate>計画停止予定通知</td>
            <td translate>2週間以上前に、グラブデザインのサイト内（https://grab-design.com）での情報公開、および、有償契約のお客様に対しメール通知</td>
          </tr>
          <tr>
            <td translate>サーバー可用性</td>
            <td>
              <span translate>99.9%の可用性（稼働率）を目標</span><br>
              <span translate>※ 計画作業は除く</span>
            </td>
          </tr>
          <tr>
            <td translate>バージョンアップ方針</td>
            <td translate>サービスのバージョンアップは全チャンネルに一斉実施</td>
          </tr>
          <tr>
            <td translate>障害時対応</td>
            <td translate>障害発生時は24時間365日で障害対応を実施</td>
          </tr>
          <tr>
            <td translate>システム監視基準</td>
            <td translate>弊社が定める監視項目・監視間隔にて監視を実施</td>
          </tr>
          <tr>
            <td translate>障害時報告</td>
            <td translate>障害発生時には、速やか(30分以内を目安)にグラブデザインのサイト内（https://grab-design.com）での情報公開、および、有償契約のお客様に対しメール通知</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="p-contracts__table">
        <h3 translate>セキュリティ</h3>
        <table>
          <tbody>
          <tr>
            <td class="p-contracts__table__rowHeader" translate>公的認証取得</td>
            <td class="p-contracts__table__rowData">
              <span translate>年1回外部審査を行い認証登録を維持継続します。</span><br>
              <span translate>対応状況は以下のページをご確認ください。</span><br>
              https://www.grab-design.com/security/
            </td>
          <tr>
            <td translate>暗号化</td>
            <td translate>通信データについては全て暗号化</td>
          </tr>
          <tr>
            <td translate>脆弱性診断</td>
            <td translate>第三者機関による脆弱性診断を実施　(1年毎および適宜実施)</td>
          </tr>
          <tr>
            <td translate>情報セキュリティ内部監査</td>
            <td translate>情報セキュリティの独立したレビューを実施し、リスクへの改善対応を実施</td>
          </tr>
          <tr>
            <td translate>クロック同期</td>
            <td translate>Googleが提供するクロックに基づくタイムスタンプをログに利用</td>
          </tr>
          <tr>
            <td translate>脆弱性の管理</td>
            <td translate>OS、ミドルウェア、OSS等のセキュリティ情報を収集し、リスクに応じてパッチ適用を実施</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="p-contracts__table">
        <h3 translate>データ管理</h3>
        <table>
          <tbody>
          <tr>
            <td class="p-contracts__table__rowHeader" translate>バックアップ</td>
            <td class="p-contracts__table__rowData">
              <span translate>保管対象と保管期間</span><br>
              <span translate>毎日午前1時（日本時間）にバックアップ</span><br>
              <span translate>保管期間は7日間(7世代)とし 保管期間経過後に自動削除</span><br>
              <span translate>1）データベース（契約情報、設定情報、文書情報）</span><br>
              <span translate>2）FireRocketにアップロードしたお客様データファイル（PDFファイルなど）</span><br>
              <span translate>追加でお客様データファイルの滅失のリスクを低減される場合には、お客様の責任でバックアップを実施いただきますようお願いいたします。</span>
            </td>
          <tr>
            <td translate>データセンター</td>
            <td translate>日本国内のリージョンを使用</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="p-contracts__table">
        <h3 translate>サポート　平日10：00～17：00（土日祝日、年末年始、GWを除く）</h3>
        <table>
          <tbody>
          <tr>
            <td class="p-contracts__table__rowHeader" translate>ログの保管期間</td>
            <td class="p-contracts__table__rowData">
              <span translate>アクセスログを取得し、5年間保管</span><br>
              <span translate>ログの調査が必要な場合は、弊社が代行で調査を実施</span><br>
              <span translate>保管期間を経過したものは、自動削除。</span>
            </td>
          </tbody>
        </table>
      </div>

      <div class="p-contracts__table">
        <h3 translate>サービス開発方針</h3>
        <table>
          <tbody>
          <tr>
            <td class="p-contracts__table__rowHeader" translate>開発ガイドラインの整備</td>
            <td class="p-contracts__table__rowData" translate>社内で定める開発ガイドラインに則り、安定かつ安全なサービスの開発を実施</td>
          </tbody>
        </table>
      </div>

      <div class="p-contracts__table">
        <h3 translate>対象外事項</h3>
        <table>
          <tbody>
          <tr>
            <td class="p-contracts__table__rowHeader" translate>対象外事項</td>
            <td class="p-contracts__table__rowData">
              <span translate>他社サービスとの連携により提供される機能については、本SLOの対象外となります。</span><br>
              ・<span translate>タイムスタンプ機能</span><br>
              ・<span translate>通知（メールサーバー未設定のデフォルトの場合）</span><br>
              ・<span translate>ヘルプナビ機能</span><br>
              ・<span translate>チャットボット機能</span>
            </td>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</div>
<app-footer></app-footer>
