<div class="l-header-top">
  <div class="p-header-top">
    <div class="p-header-top__left">
      <div class="p-header-top__left__search"></div>
    </div>
    <div routerLink="/" class="p-header-top__logo"></div>
    <div class="p-header-top__right">
      <div routerLink="/login" class="p-header-top__right__login">ログイン</div>
      <div routerLink="/signup" class="p-header-top__right__signUp">無料会員登録</div>
      <div (click)="openSpMenu()" class="p-header-top__right__spMenu"></div>
    </div>
  </div>
</div>

<div *ngIf="isShowFixedHeader" class="l-header-top l-header-top-fixed">
  <div class="p-header-top p-header-top-bgWhite">
    <div class="p-header-top__left">
      <div class="p-header-top__left__search p-header-top__left__search-yellow"></div>
    </div>
    <div routerLink="/" class="p-header-top__logo p-header-top__logo-gray"></div>
    <div class="p-header-top__right">
      <div routerLink="/login" class="p-header-top__right__login">ログイン</div>
      <div routerLink="/signup" class="p-header-top__right__signUp">無料会員登録</div>
      <div (click)="openSpMenu()" class="p-header-top__right__spMenu"></div>
    </div>
  </div>
</div>

<div [ngClass]="isOpenSpMenu ? 'p-sp-menu-opened' : ''" class="p-sp-menu">
  <div class="p-sp-menu__closeButton">
    <button (click)="closeSpMenu()" ></button>
  </div>
  <button routerLink="/login" class="p-sp-menu__login">ログイン</button>
  <button routerLink="/signup" class="p-sp-menu__signUp">無料会員登録</button>
  <div class="p-sp-menu__items">
    <li class="p-sp-menu__items__single" routerLink="/" (click)="closeSpMenu()">
      <div class="p-sp-menu__items__single__title">運営会社</div>
    </li>
    <li  class="p-sp-menu__items__single" routerLink="/" (click)="closeSpMenu()">
      <div class="p-sp-menu__items__single__title">お問い合わせ</div>
    </li>
    <li class="p-sp-menu__items__single" routerLink="/" (click)="closeSpMenu()">
      <div class="p-sp-menu__items__single__title">情報セキュリティ方針</div>
    </li>
    <li class="p-sp-menu__items__single" routerLink="/" (click)="closeSpMenu()">
      <div class="p-sp-menu__items__single__title">プライバシーポリシー利用規約</div>
    </li>
    <li class="p-sp-menu__items__single" routerLink="/" (click)="closeSpMenu()">
      <div class="p-sp-menu__items__single__title">特定商取引法に基づく表記</div>
    </li>
    <li class="p-sp-menu__items__single" routerLink="/" (click)="closeSpMenu()">
      <div class="p-sp-menu__items__single__title">推奨動作環境</div>
    </li>
    <li class="p-sp-menu__items__single" routerLink="/" (click)="closeSpMenu()">
      <div class="p-sp-menu__items__single__title">＜セキュリティ＞SLO</div>
    </li>
  </div>
</div>
<div (click)="closeSpMenu()" [ngClass]="isOpenSpMenu ? 'mask-on' : 'mask-off'"></div>
