<ng-container>
  <div class="l-signup" *ngIf="!isComplete">
    <div class="p-signup">
      <div class="p-signup__head">
        <div class="p-signup__head__kv"></div>
      </div>
      <div class="p-signup__main">
        <div class="p-signup__main__title" translate>メンバー登録をする</div>
        <div class="p-signup__main__input">
          <label>
            <span translate>ユーザーネーム</span>
            <span class="c-form-required" translate>必須</span>
          </label>
          <input
            [formControl]="displayName"
            type="text"
            name="userName"
            id="userName"
            [placeholder]="'ユーザーネーム' | translate"
          >
        </div>
        <div class="p-signup__main__input">
          <label>
            <span translate>メールアドレス</span>
            <span class="c-form-required" translate>必須</span>
          </label>
          <input
            [formControl]="email"
            type="text"
            name="email"
            id="email"
            [placeholder]="'メールアドレス' | translate"
          >
        </div>
        <div class="p-signup__main__input">
          <label>
            <span translate>パスワード</span>
            <span class="c-form-required" translate>必須</span>
          </label>
          <input
            [formControl]="password"
            [type]="showPassword ? 'text' : 'password'"
            name="password"
            id="password"
            [placeholder]="'パスワード6文字以上' | translate"
          >
          <div
            class="p-signup__main__input__password"
            [ngClass]="showPassword ? 'p-signup__main__input__password-hide':'p-signup__main__input__password-show'"
            (click)="showPasswordToggle()">
          </div>
        </div>
        <div class="p-signup__main__input">
          <label>
            <span translate>パスワード再入力</span>
            <span class="c-form-required" translate>必須</span>
          </label>
          <input
            [formControl]="passwordConfirmation"
            [type]="showPassword ? 'text' : 'password'"
            name="passwordConfirmation"
            id="passwordConfirmation"
            [placeholder]="'パスワード再入力' | translate"
          >
          <div
            class="p-signup__main__input__password"
            [ngClass]="showPassword ? 'p-signup__main__input__password-hide':'p-signup__main__input__password-show'"
            (click)="showPasswordToggle()">
          </div>
        </div>
        <div class="p-signup__main__consent">
          <div class="p-signup__main__consent__wrap">
            <input
              [formControl]="privacy"
              type="checkbox" name="privacy" id="privacy">
            <label
              class="p-signup__main__consent__wrap__label"
              for="privacy"
              translate
            >プライバシーポリシーに同意する</label>
            <div class="p-signup__main__consent__wrap__link" (click)="openPrivacyLink()"></div>
          </div>
          <div class="p-signup__main__consent__wrap">
            <input
              [formControl]="contract"
              type="checkbox" name="rules" id="rules">
            <label
              class="p-signup__main__consent__wrap__label"
              for="rules"
              translate
            >FireRocketサービス規約に同意する</label>
            <div class="p-signup__main__consent__wrap__link" (click)="openContractsLink()"></div>
          </div>
        </div>
        <div
          class="c-form-error"
          *ngIf="showError"
          translate
        >入力されていない項目があるか、パスワードが一致していない可能性があります。または既に登録済みのメールアドレスの可能性があります。</div>

        <div class="p-signup__main__registerButton">
          <button
            type="button"
            class="c-button c-button-authCode"
            (click)="onSubmit()"
          ><span translate>新規登録</span></button>
        </div>
        <div class="p-signup__main__notes">
          <p>
            <span translate>「登録する」ボタンを押すと、ご入力されたメールアドレス宛にアドレスの確認メールが届きます。</span><br>
            「{{baseUrl}}」<span translate>ドメインより届きます。</span><br>
            <span translate>メールが届かない場合は受信の可否設定や、迷惑メールフォルダーに届いていないかご確認ください。</span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="l-signup" *ngIf="isComplete">
    <div class="p-signup">
      <div class="p-signup__head">
        <div class="p-signup__head__kv"></div>
      </div>
      <div class="p-signup__main">
        <div
          class="p-signup__main__success"
          [innerHTML]="'ユーザー登録が完了しました。\n登録したアドレスに確認メールが届きますので、\nメールの到着確認を行ってから、ログインしてください。' | translate | nl2br"
        ></div>
        <div class="p-signup__main__button">
          <button
            type="button"
            class="c-button c-button-login"
            routerLink="/login"
          ><span translate>ログイン画面へ</span></button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
