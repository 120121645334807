import {Component, EventEmitter, Input, Output} from '@angular/core';
import {User} from "../../models/user";
import {Account} from "../../models/account";
import {Channel} from "../../models/channel";
import {Course} from "../../models/course";

@Component({
  selector: 'app-add-user-modal',
  templateUrl: './add-user-modal.component.html',
  styleUrls: ['./add-user-modal.component.scss']
})
export class AddUserModalComponent {
  @Input() isOpen = true;
  @Input() user: User;
  @Input() account: Account;
  @Input() channel: Channel;
  @Output() isOpenModalEmitter = new EventEmitter<boolean>();
  courses: Course[] = [];
  close(): void {
    this.isOpen = false;
    this.isOpenModalEmitter.emit(this.isOpen);
  }

  save(): void {}
  createUser(): void {}
  createSubscription(): void {}

}
