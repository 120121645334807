<div class="l-main">
  <app-navigations></app-navigations>
  <div class="l-content">
    <app-breadcrumb></app-breadcrumb>
    <div class="l-content__inner">
      <div class="c-content-header">
        <div class="c-content-header__title">
          講座　公開審査申込
        </div>
      </div>
      <div class="l-templates">
        <div class="p-templates">
          <div class="p-templates__content">
            <div class="p-inspection">
              <div class="p-inspection__message">
                <div class="p-inspection__message__heading"></div>
                <div class="p-inspection__message__detail"></div>
              </div>
              <div class="p-inspection__apply">
                <div class="p-inspection__apply__thumb">
                  <img src="assets/images/sampleImage_slides_01.png" alt="">
                </div>
                <div class="p-inspection__apply__courseInfo">
                  <div class="p-inspection__apply__courseInfo__row">
                    <div class="p-inspection__apply__courseInfo__row__head">コース名</div>
                    <div class="p-inspection__apply__courseInfo__row__body">損しないための社会人マナー損しないための社会人マナー</div>
                  </div>
                  <div class="p-inspection__apply__courseInfo__row">
                    <div class="p-inspection__apply__courseInfo__row__head">販売方法</div>
                    <div class="p-inspection__apply__courseInfo__row__body">コース単体販売</div>
                  </div>
                  <div class="p-inspection__apply__courseInfo__row">
                    <div class="p-inspection__apply__courseInfo__row__head">販売形態</div>
                    <div class="p-inspection__apply__courseInfo__row__body">有料</div>
                  </div>
                  <div class="p-inspection__apply__courseInfo__row">
                    <div class="p-inspection__apply__courseInfo__row__head">価格</div>
                    <div class="p-inspection__apply__courseInfo__row__body">1234円</div>
                  </div>
                </div>
                <div class="p-inspection__apply__checkList">
                  <div class="p-inspection__apply__checkList__row">
                    <div class="p-inspection__apply__checkList__row__left">
                      <div class="p-inspection__apply__checkList__row__left__head">ビジネス情報登録</div>
                      <div class="p-inspection__apply__checkList__row__left__buttons">
                        <button class="p-inspection__apply__checkList__row__left__buttons__edit">編集</button>
                      </div>
                    </div>
                    <div class="p-inspection__apply__checkList__row__right">
                      <div class="p-inspection__apply__checkList__row__right__status p-inspection__apply__checkList__row__right__status-registered">登録済</div>
                    </div>
                  </div>
                  <div class="p-inspection__apply__checkList__row">
                    <div class="p-inspection__apply__checkList__row__left">
                      <div class="p-inspection__apply__checkList__row__left__head">本人確認書類</div>
                      <div class="p-inspection__apply__checkList__row__left__buttons">
                        <button class="p-inspection__apply__checkList__row__left__buttons__edit">編集</button>
                      </div>
                    </div>
                    <div class="p-inspection__apply__checkList__row__right">
                      <div class="p-inspection__apply__checkList__row__right__status">未登録</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-inspection__buttons">
                <div class="p-inspection__buttons__button">
                  <button class="c-button c-button-primary">審査を申し込む</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

