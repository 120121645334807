import firebase from "firebase/compat/app";
import Timestamp = firebase.firestore.Timestamp;

export class DateFormat {
  public getTermString(d: Date): string {
    const year = d.getFullYear();
    const month = (`0${d.getMonth() + 1}`).slice(-2);
    return `${year}-${month}`;
  }
  public getTermStringWithoutDelimiter(d: Date): string {
    const year = d.getFullYear();
    const month = (`0${d.getMonth() + 1}`).slice(-2);
    return `${year}${month}`;
  }
  public getTermStringExceptDelimiter(d: Date): string {
    const year = d.getFullYear();
    const month = (`0${d.getMonth() + 1}`).slice(-2);
    return `${year}${month}`;
  }
  public getDateString(date: string | number | Date): string {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = (`0${d.getMonth() + 1}`).slice(-2);
    const day = (`0${d.getDate()}`).slice(-2);
    return `${year}-${month}-${day}`;
  }
  public getDateStringFs(firebaseTime: { seconds: number; }): string {
    const d = new Date(firebaseTime.seconds * 1000);
    const year = d.getFullYear();
    const month = (`0${d.getMonth() + 1}`).slice(-2);
    const day = (`0${d.getDate()}`).slice(-2);
    const hour = (`0${d.getHours()}`).slice(-2);
    const min = (`0${d.getMinutes()}`).slice(-2);
    const sec = (`0${d.getSeconds()}`).slice(-2);
    // return `${year}-${month}-${day} T${hour}:${min}:${sec}`;
    return `${year}-${month}-${day} ${hour}:${min}:${sec}`;
  }
  public getFormatString(firebaseTime: { seconds: number; }): string {
    const d = new Date(firebaseTime.seconds * 1000);
    const year = d.getFullYear();
    const month = (`0${d.getMonth() + 1}`).slice(-2);
    const day = (`0${d.getDate()}`).slice(-2);
    const hour = (`0${d.getHours()}`).slice(-2);
    const min = (`0${d.getMinutes()}`).slice(-2);
    const sec = (`0${d.getSeconds()}`).slice(-2);
    // return `${year}-${month}-${day} T${hour}:${min}:${sec}`;
    return `${year}-${month}-${day} ${hour}:${min}:${sec}`;
  }
  public getFormatStringJa(firebaseTime: { seconds: number; }): string {
    const d = new Date(firebaseTime.seconds * 1000);
    const year = d.getFullYear();
    const month = (`0${d.getMonth() + 1}`).slice(-2);
    const day = (`0${d.getDate()}`).slice(-2);
    const hour = (`0${d.getHours()}`).slice(-2);
    const min = (`0${d.getMinutes()}`).slice(-2);
    const sec = (`0${d.getSeconds()}`).slice(-2);
    // return `${year}-${month}-${day} T${hour}:${min}:${sec}`;
    return `${year}年${month}月${day}日 ${hour}:${min}:${sec}`;
  }
  public getFormatStringJaD(targetDate: Date): string {
    const d = new Date(targetDate);
    const year = d.getFullYear();
    const month = (`0${d.getMonth() + 1}`).slice(-2);
    const day = (`0${d.getDate()}`).slice(-2);
    const hour = (`0${d.getHours()}`).slice(-2);
    const min = (`0${d.getMinutes()}`).slice(-2);
    const sec = (`0${d.getSeconds()}`).slice(-2);
    // return `${year}-${month}-${day} T${hour}:${min}:${sec}`;
    return `${year}年${month}月${day}日 ${hour}:${min}:${sec}`;
  }
  public getDateStringJa(date: Date): string {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = (`0${d.getMonth() + 1}`).slice(-2);
    const day = (`0${d.getDate()}`).slice(-2);
    return `${year}年${month}月${day}日`;
  }
  public getDateTimeStringJa(date: Date): string {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = (`0${d.getMonth() + 1}`).slice(-2);
    const day = (`0${d.getDate()}`).slice(-2);
    const hour = (`0${d.getHours()}`).slice(-2);
    const min = (`0${d.getMinutes()}`).slice(-2);
    return `${year}年${month}月${day}日 ${hour}時${min}分`;
  }
  public getDateTimeString(date: Date): string {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = (`0${d.getMonth() + 1}`).slice(-2);
    const day = (`0${d.getDate()}`).slice(-2);
    const hour = (`0${d.getHours()}`).slice(-2);
    const min = (`0${d.getMinutes()}`).slice(-2);
    return `${year}/${month}/${day} ${hour}:${min}`;
  }
  public getDateTimeStringForFileName(date: Date): string {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = (`0${d.getMonth() + 1}`).slice(-2);
    const day = (`0${d.getDate()}`).slice(-2);
    const hour = (`0${d.getHours()}`).slice(-2);
    const min = (`0${d.getMinutes()}`).slice(-2);
    return `${year}-${month}-${day}T${hour}-${min}`;
  }
  public getRelativeTime(baseDateStr: string | number | Date, targetDateStr: string | number | Date): string{
    const baseDate = new Date(baseDateStr);
    const targetDate = new Date(targetDateStr);
    const elapsedTime = Math.ceil((baseDate.getTime() - targetDate.getTime()) / 1000);
    let message = null;
    if (elapsedTime < 60) { //  1 分未満
      message =  'たった今';
    } else if (elapsedTime < 120) { //  2 分未満
      message =  '約 1分前';
    } else if (elapsedTime < (60 * 60)) { //  1 時間未満
      message =  '約' + (Math.floor(elapsedTime / 60) < 10 ? ' ' : '') + Math.floor(elapsedTime / 60) + '分前';
    } else if (elapsedTime < (120 * 60)) { //  2 時間未満
      message =  '約 1時間前';
    } else if (elapsedTime < (24 * 60 * 60)) { //  1 日未満
      message =  '約' + (Math.floor(elapsedTime / 3600) < 10 ? ' ' : '') + Math.floor(elapsedTime / 3600) + '時間前';
    } else { // 1 週間以上
      // message =  '約' + (Math.floor(elapsedTime / 604800) < 10 ? ' ' : '') + Math.floor(elapsedTime / 604800) + '週間前';
      message = this.getDateString(targetDateStr);
    }
    return message;
  }
  convertTimeStamp(value: Timestamp | string | Date, ...args: unknown[]): any {
    const df = new DateFormat();
    if (typeof value === 'string') {
      const d = new Date(value);
      return d.getTime();
    } else if (typeof value === 'object') {
      if (value instanceof Date) {
        const d = new Date(value);
        return d.getTime();
      } else {
        if (value === null) {
          return null;
        }
        if (value.seconds === undefined) {
          return null;
        }
        if (value.seconds === null) {
          return null;
        }
        const d = new Date(value.seconds * 1000);
        return d.getTime();
      }
    }
  }
}
export enum eraNames {
  meiji = '明治',
  taisho = '大正',
  showa = '昭和',
  heisei = '平成',
  reiwa = '令和',
}
export const eras = [
  {
    name: eraNames.meiji,
    beginYear: 1868,
    beginMonth: 9,
    beginDay: 4
  },
  {
    name: eraNames.taisho,
    beginYear: 1912,
    beginMonth: 7,
    beginDay: 30
  },
  {
    name: eraNames.showa,
    beginYear: 1926,
    beginMonth: 12,
    beginDay: 26
  },
  {
    name: eraNames.heisei,
    beginYear: 1989,
    beginMonth: 1,
    beginDay: 8
  },
  {
    name: eraNames.reiwa,
    beginYear: 2019,
    beginMonth: 5,
    beginDay: 1
  },
];
