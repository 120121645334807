import { Component, OnInit } from '@angular/core';
import {SessionState} from "../../store/sessions/sessions.reducer";
import {Account} from "../../models/account";
import {Channel} from "../../models/channel";
import {Confirm} from "../../models/confirm";
import {User} from "../../models/user";
import {
  storeUrlState,
  switchFalseLoading,
  switchTrueLoading,
  switchTrueShowMenuInSideNavi,
  switchTrueShowSideNavigation
} from "../../store/ui/ui.actions";
import {storeUrlParams, switchTrueShowBreadcrumb} from "../../store/breadcrumb/breadcrumb.actions";
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {BreadcrumbState} from "../../store/breadcrumb/breadcrumb.reducer";
import {UiState, UrlState} from "../../store/ui/ui.reducer";
import {ChannelsService} from "../../services/channels/channels.service";
import {AccountsService} from "../../services/accounts/accounts.service";
import {AuthService} from "../../services/auth/auth.service";
import {CourseSubscriptionsService} from "../../services/course-subscriptions/course-subscriptions.service";
import {CourseUser} from "../../models/course-subscription";
import {BackendService} from "../../services/backend/backend.service";
import {BehaviorSubject} from "rxjs";
import {FormControl} from "@angular/forms";
import {DateFormat} from "../../models/date-format";
import {LoadingFrames, LoadingPart, LoadingSection} from "../../models/course-part";
import {FrameTypes} from "../../models/course-frames";
import {CoursesService} from "../../services/courses/courses.service";
import {Course} from "../../models/course";
import {CourseIndexPartService} from "../../services/course-index/course-index-part.service";
import {SurveyAnswers} from "../../models/answer";
import {CourseSurveyAnswersService} from "../../services/course-suevey-answers/course-survey-answers.service";
import {DateFormatFirestorePipe} from "../../pipes/date-format-firestore/date-format-firestore.pipe";
import {ToastrService} from "ngx-toastr";
import {CourseProgressesService} from "../../services/course-progresses/course-progresses.service";
import {CourseProgress} from "../../models/course-progress";
import * as iconvlite from "iconv-lite";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-manager-channel-course-users',
  templateUrl: './manager-channel-course-users.component.html',
  styleUrls: ['./manager-channel-course-users.component.scss']
})
export class ManagerChannelCourseUsersComponent implements OnInit {
  forbidden: boolean = false;
  isNotFound = false;
  currentSession:SessionState | undefined;
  currentAccount: Account;
  channelId: string;
  courseId: string;
  currentChannel: Channel;
  currentCourse: Course = new Course();
  showUsers: CourseUser[] = [];
  fetchedUsers: CourseUser[] = [];
  users: User[] = [];
  limit = 20;
  editUser = new BehaviorSubject<User>(null);
  chatTargetUser: User;
  surveyTargetUser: User;
  isOpenEditModal = false;
  isOpenChatModal = false;
  isOpenConfirmModal = false;
  isOpenSurveysModal = false;
  frames: LoadingFrames[] = [];
  confirmModalData: Confirm;
  frameTypes = FrameTypes;
  answers: {
    partContentKey: string,
    surveyAnswers: SurveyAnswers[];
  }[] =[];
  protected csvHead: string = `"メールアドレス","会社名","名前","名前(姓)","名前(名)","受講開始日時","最終受講日","進捗率"\n`;
  name = new FormControl<string>(null);
  email = new FormControl<string>(null);
  companyName = new FormControl<string>(null);
  progress = new FormControl<number>(null);
  from = new FormControl<string>(null);
  to = new FormControl<string>(null);
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private breadcrumbStore: Store<{ breadcrumb: BreadcrumbState }>,
    private uiStore: Store<{ ui: UiState }>,
    private channelsService: ChannelsService,
    private coursesService: CoursesService,
    private courseSubscriptionsService:CourseSubscriptionsService,
    private accountsService: AccountsService,
    private authService: AuthService,
    private backendService: BackendService,
    private partService: CourseIndexPartService,
    private courseSurveyAnswersService: CourseSurveyAnswersService,
    private courseProgressesService: CourseProgressesService,
    private toasterService: ToastrService,
    private accountService: AccountsService,
    private translateService: TranslateService,
  ) {
    window.scrollTo(0, 0);
    this.channelId = this.route.snapshot.paramMap.get('channelId');
    this.courseId = this.route.snapshot.paramMap.get('courseId');
    this.uiStore.dispatch(switchTrueShowSideNavigation());
    this.uiStore.dispatch(switchTrueShowMenuInSideNavi());
    this.breadcrumbStore.dispatch(storeUrlParams({ urlParams: [ this.channelId, this.courseId ]}));
    this.breadcrumbStore.dispatch(switchTrueShowBreadcrumb());
    this.uiStore.dispatch(switchTrueLoading());
  }
  async ngOnInit(): Promise<void> {
    // 認証
    await this.authService.refresh();
    await new Promise(resolve => setTimeout(resolve, 200));
    const account = await this.accountService.fetchAccount(this.authService.currentSession.currentUser.currentAccountId);
    if (!await this.accountService.isAcceptByClientIp(account)) {
      this.uiStore.dispatch(switchFalseLoading());
      this.forbidden = true;
      return;
    }
    this.currentSession = this.authService.currentSession;
    this.currentChannel = await this.channelsService.fetch(this.channelId);
    this.currentAccount = await this.accountsService.fetchAccount(this.currentSession.currentUser.currentAccountId);
    this.currentCourse = await this.coursesService.fetch(this.courseId);
    if (this.currentChannel.accountId !== this.currentAccount.id) {
      this.isNotFound = true;
      this.uiStore.dispatch(switchFalseLoading());
      return;
    }
    const urlState = new UrlState();
    urlState.channelId = this.channelId;
    urlState.slug = this.currentChannel.slug;
    urlState.courseId = this.courseId;
    this.uiStore.dispatch(storeUrlState({urlState: urlState}));
    this.uiStore.dispatch(switchFalseLoading());
    this.showUsers = [];
    this.fetchedUsers = [];
    this.loadBySubscription().catch();
    this.loadAllFrames().catch();
  }
  async loadBySubscription(): Promise<void> {
    const subs = await this.courseSubscriptionsService.fetchAll(
      this.courseId
    );
    subs.map(async s => {
      this.fetchedUsers.push(
        {
          uid: s.uid,
          subscription: s,
          courseProgress: null,
          user: null,
        }
      );
    });
    this.search();
    this.fetchedUsers.map(async u => {
      u.user = await this.fetchUser(u.uid);
      u.courseProgress = await this.fetchOneProgress(u.uid);
    })
  }
  resetForm(): void {
    this.name.setValue(null);
    this.email.setValue(null);
    this.companyName.setValue(null);
    this.from.setValue(null);
    this.to.setValue(null);
    this.progress.setValue(null);
    this.search();
  }
  search(): void {
    this.showUsers = [];
    let searched = false;
    const df = new DateFormat();
    if (this.name.value) {
      this.showUsers = this.fetchedUsers.filter(u => u?.user?.fullName && u?.user?.fullName?.indexOf(this.name.value) !== -1);
      searched = true;
    }
    if (this.email.value) {
      if (this.showUsers.length === 0) {
        this.showUsers = this.fetchedUsers.filter(u => u?.user?.email && u?.user?.email.indexOf(this.email.value) !== -1);
      } else {
        this.showUsers = this.showUsers.filter(u => u?.user?.email && u?.user?.email.indexOf(this.email.value) !== -1);
      }
      searched = true;
    }
    if (this.companyName.value) {
      if (this.showUsers.length === 0) {
        this.showUsers = this.fetchedUsers.filter(u => u?.user?.companyName && u?.user?.companyName.indexOf(this.companyName.value) !== -1);
      } else {
        this.showUsers = this.showUsers.filter(u => u?.user?.companyName && u?.user?.companyName.indexOf(this.companyName.value) !== -1);
      }
      searched = true;
    }
    if (this.progress.value || this.progress.value === 0) {
      if (this.showUsers.length === 0) {
        if (this.progress.value === 0) {
          this.showUsers = this.fetchedUsers.filter(u => u?.subscription.totalProgress === this.progress.value);
        }else if (this.progress.value === 0.3) {
          this.showUsers = this.fetchedUsers.filter(u => (u?.subscription.totalProgress <= this.progress.value && u?.subscription.totalProgress > 0));
        } else {
          this.showUsers = this.fetchedUsers.filter(u => u?.subscription.totalProgress >= this.progress.value);
        }
      } else {
        this.showUsers = this.showUsers.filter(u => u?.subscription.totalProgress >= this.progress.value);
      }
      searched = true;
    }
    if (this.from.value) {
      // TODO バックエンド経由とフロントで自分で申し込んだ場合は activatedAt の形式が異なる
      let users: CourseUser[] = [];
      const from = new Date(this.from.value + 'T00:00:00.000Z');
      if (this.showUsers.length === 0) {
        users = this.fetchedUsers;
      } else {
        users = this.showUsers;
      }
      this.showUsers = users.filter(u  => {
        if (u?.subscription?.activated) {
          const activatedAtConverted = JSON.parse(JSON.stringify(u?.subscription?.activatedAt));
          const activatedAt = new Date(df.getDateStringFs( { seconds: activatedAtConverted.seconds}));
          return activatedAt.getTime() >= from.getTime();
        } else  {
          return false;
        }
      });
      searched = true;
    }
    if (this.to.value) {
      let users: CourseUser[] = [];
      const to = new Date(this.to.value + 'T23:59:59.999Z').getTime();
      if (this.showUsers.length === 0) {
        users = this.fetchedUsers;
      } else {
        users = this.showUsers;
      }
      this.showUsers = users.filter(u => {
        if (u?.subscription?.activatedAt) {
          const activatedAtConverted = JSON.parse(JSON.stringify(u?.subscription?.activatedAt));
          const activatedAt = new Date(df.getDateStringFs( { seconds: activatedAtConverted.seconds}));
          return activatedAt.getTime() <= to;
        } else {
          return false;
        }
      });
      searched = true;
    }
    if (!searched) {
      this.showUsers = this.fetchedUsers;
    }
  }
  changeProgress(progress: number): void {
    this.progress.setValue(progress);
  }
  async loadProgress(uid: string): Promise<CourseProgress> {
    return await this.courseProgressesService.fetchByMy(
      this.courseId,
      uid
    )
  }
  async fetchUser(uid: string): Promise<User> {
    return new Promise((resolve) => {
      const user = this.users.find(u => u.id === uid);
      if (user) {
        resolve(user);
      }
      this.fetchOneUser(uid).then((user => {
        resolve(user);
      }))
        .catch(() => {
          resolve(null);
        });
    });
  }
  async fetchOneUser(uid: string): Promise<User> {
    this.backendService.setIdToken(
      this.authService.currentSession.idToken
    )
    const data = {
      accountId: this.currentAccount.id,
      uid: uid,
    }
    const user = await this.backendService.post('lms/channel-manager/fetch_user', data)
      .then((user) => {
        return user as User;
      })
    return user;
  }
  async fetchOneProgress(uid: string): Promise<CourseProgress> {
    this.backendService.setIdToken(
      this.authService.currentSession.idToken
    )
    const data = {
      accountId: this.currentAccount.id,
      uid: uid,
      courseId: this.courseId
    }
    const progress = await this.backendService.post('lms/channel-manager/fetch_progress', data)
      .then((p) => {
        return p as CourseProgress;
      })
    return progress;
  }
  openEditModal(user: User): void {
    if (!user?.id) {
      return;
    }
    this.editUser.next( user);
    this.isOpenEditModal = true;
  }
  changeOpenEditUserModal($event: boolean): void {
    this.isOpenEditModal = $event;
  }
  afterRemovedUser(userId: string): void {
    this.fetchedUsers = this.fetchedUsers.filter(u => u.uid !== userId);
    this.search();
  }
  openChatModal(user: User): void {
    if (!user?.id) {
      return;
    }
    this.chatTargetUser = user;
    this.isOpenChatModal = true;
  }
  changeOpenChatModal($event: boolean): void {
    this.isOpenChatModal = $event;
  }
  roundProgress(progress: number | null | undefined): string {
    if (!progress) {
      return '--';
    }
    return (Math.round(progress * 100)).toString();
  }
  // 回答リスト関係
  openSurveysModal(user: User): void {
    if (!user) {
      return;
    }
    this.answers = [];
    this.surveyTargetUser = user;
    this.isOpenSurveysModal = true;
    // 回答をローディング
    this.frames.map(c => {
      c.sections.map( s => {
        s.parts.map(async p => {
          if (p.frameType === this.frameTypes.SurveyFrame) {
            const surveyAnswers = await this.courseSurveyAnswersService.fetchByUser(
              this.courseId,
              p.id,
              user.id
            );
            if (surveyAnswers.length > 0) {
              this.answers.push({
                partContentKey: p.id,
                surveyAnswers: surveyAnswers
              });
            }
          }
        })
      })
    });
  }
  showLastAnswerDateTime(contentKey: string): string {
    if (!this.answers) {
      return null;
    }
    const current = this.answers.find(a => a.partContentKey === contentKey);
    if (!current) {
      return null;
    }
    let latestDateTime: Date;
    for (let a of current.surveyAnswers) {
      const appliedAt = new Date(a.appliedAt);
      if (!latestDateTime) {
        latestDateTime = appliedAt;
      } else if (latestDateTime?.getTime() < appliedAt.getTime()) {
        latestDateTime = appliedAt
      }
    }
    if (latestDateTime) {
      const datePipe = new DateFormatFirestorePipe();
      return datePipe.transform(latestDateTime);
    } else {
      return null;
    }
  }
  closeSurveysModal(): void {
    this.isOpenSurveysModal = false;
  }
  showAnswer(contentKey: string): void {
    if (!contentKey) {
      return;
    }
    if (!this.surveyTargetUser) {
      return;
    }
    const current = this.answers.find(a => a.partContentKey === contentKey);
    if (current.surveyAnswers.length === 0) {
      this.toasterService.warning(this.translateService.instant('回答はありません。'));
      return;
    }
    this.router.navigate([`manager/channel/course/survey/view/${this.currentChannel.slug}/${this.courseId}/${contentKey}/${this.surveyTargetUser.id}`]).catch();
    return;
  }
  private async loadAllFrames(): Promise<void> {
    const loader: {
      chapterIndex: number,
      sectionIndex: number,
      partIndex: number,
      partId: string,
      frameType: FrameTypes,
    }[] = [];
    this.frames = [];
    let chapterIndex = 0;
    for (const chapter of this.currentCourse.courseIndex.chapters) {
      this.frames[chapterIndex] = new LoadingFrames();
      this.frames[chapterIndex].id = chapter.id;
      let sectionIndex = 0;
      for (const section of chapter.sections) {
        this.frames[chapterIndex].sections[sectionIndex] = new LoadingSection();
        this.frames[chapterIndex].sections[sectionIndex].id = section.id;
        let partIndex = 0;
        for (const part of section.parts ) {
          this.frames[chapterIndex].sections[sectionIndex].parts[partIndex] = new LoadingPart(part.frameType);
          this.frames[chapterIndex].sections[sectionIndex].parts[partIndex].id = part.id;
          loader.push({
            chapterIndex: chapterIndex,
            sectionIndex: sectionIndex,
            partIndex: partIndex,
            partId: part.id,
            frameType: part.frameType,
          });
          partIndex++;
        }
        sectionIndex++;
      }
      chapterIndex++;
    }
    loader.map((load => {
      this.partService.loadFrameBody(
        this.currentChannel.accountId,
        this.currentCourse.id,
        load.partId,
        load.frameType
      ).then((res) => {
        this.frames[load.chapterIndex].sections[load.sectionIndex].parts[load.partIndex].frame = res;
      });
    }));
  }
  createDisplayName(user: User): string {
    // 表示名設定
    if (user?.displayName) {
      return user.displayName;
    } else if (user?.fullName) {
      return user.fullName;
    } else if (user?.familyName) {
      return user.familyName;
    } else {
      return '';
    }
  }
  downloadUserCsv(): void {
    if (this.fetchedUsers.length === 0) {
      this.toasterService.warning(this.translateService.instant('対象となるデータがありません。'));
      return;
    }
    this.uiStore.dispatch(switchTrueLoading());
    const csvText = this.parseUserCsv();
    const d = new DateFormat();
    const encoded = iconvlite.encode(csvText, 'cp932');
    const blob = new Blob([ encoded ], { "type" : "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.download = `course-users-${d.getDateTimeStringForFileName(new Date())}.csv`;
    a.href = url;
    a.target = '_blank';
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
    this.uiStore.dispatch(switchFalseLoading());
  }
  parseUserCsv(): string {
    let csvText = this.csvHead;
    const d = new DateFormatFirestorePipe();
    for (let u of this.fetchedUsers) {
      let email = '';
      if (u?.user?.email) {
        email = u.user.email;
      }
      let companyName = '';
      if (u?.user?.companyName) {
        companyName = u.user.companyName;
      }
      const displayName = this.createDisplayName(u.user);
      let familyName = '';
      if (u?.user?.familyName) {
        familyName = u.user.familyName;
      }
      let givenName = '';
      if (u?.user?.givenName) {
        givenName = u.user.givenName;
      }
      let activatedAt = '';
      if (u?.subscription?.activatedAt) {
        activatedAt = d.transform(u?.subscription.activatedAt);
      }
      let updatedAt = '';
      if (u?.courseProgress?.updatedAt) {
        updatedAt = d.transform(u?.courseProgress.updatedAt);
      }
      const progress = this.roundProgress(u.subscription.totalProgress);
      csvText += `"${email}","${companyName}","${displayName}","${familyName}","${givenName}","${activatedAt}","${updatedAt}","${progress}"\n`;
    }
    return csvText;
  }
}
