const ENV_NAME = 'STAGE';
const SITE_NAME = '[stage] FireRocket';
const BASE_ACCOUNT_ID = 'NJAqmr5o2U3FvtxzqEZI';
const APP_ID = 'lms';
const HOST_NAME = 'stage.fire-rocket.jp';
const EMAIL_FROM_ADDRESS = 'develop@i72.jp';
const EMAIL_FROM_NAME = 'FireRocketステージング環境メール';
const BACKEND_APP_TOKEN = 'poquee5uPhohtoed0ohquai1ceuNie7bie9Iechahf0ohyeisuteovie5coo7iwe'
const SERVICE_RELEASE_YEAR = 2023;
const SERVICE_RELEASE_MONTH = 0; // 0 to 11
const APP_NAME_SPACE = 'firerocket';
const BANTO3_API_URL = 'https://api-stage.fire-rocket.jp/';
const ELASTIC_URL = 'https://xxxxxxxxxx.ent.asia-northeast1.gcp.cloud.es.io';
const COUNTER_API_URL = 'https://api-stage.banto3.net/';
const PUBLIC_ASSETS_BUCKET_NAME = 'firerocket-stage-assets';
const NETA_FB_APP_ID = '';
const META_TWITTER_SITE = '@';
const META_DESCRIPTION_DEFAULT = '';
const META_KEYWORD_DEFAULT = '';
const NO_IMAGE_THUMB = '../../../assets/images/logo_alert_monochrome.small.svg';
const GMO_FINCODE_PUBLIC_KEY = "p_test_ZGJkYzYxYjEtNTE2NS00NmY1LWIyMmUtODljMmZiYzZiMWY2NDBiYWVkOGQtOWQ4ZS00Yjc3LWFmZDEtYzFjZDVlMDk3ZDY0c18yMzAxMjY0NDE5Nw";
const GMO_FINCODE_SCRIPT_URL = 'https://js.test.fincode.jp/v1/fincode.js';
const PRODUCT_MAIN_LOGO_SRC = '../../../assets/images/logo_header.png';
// SSO Microsoft
const SSO_MICROSOFT_OEM_ACCOUNT_ID = '';
const SSO_MICROSOFT_TENANT_ID = 'd609b5ec-47e7-4d04-949b-3568f616f81d';

export const environment = {
  production: false,
  envName: ENV_NAME,
  baseAccountId: BASE_ACCOUNT_ID,
  appId: APP_ID,
  hostName: HOST_NAME,
  siteName: SITE_NAME,
  appNameSpace: APP_NAME_SPACE,
  apiHost: BANTO3_API_URL,
  counterApiHost: COUNTER_API_URL,
  appApiToken: BACKEND_APP_TOKEN,
  metaFbAppID: NETA_FB_APP_ID,
  metaTwitterSite: META_TWITTER_SITE,
  metaDescriptionDefault: META_DESCRIPTION_DEFAULT,
  metaKeywordDefault: META_KEYWORD_DEFAULT,
  emailFromAddress: EMAIL_FROM_ADDRESS,
  emailFromName: EMAIL_FROM_NAME,
  serviceReleaseYear: SERVICE_RELEASE_YEAR,
  serviceReleaseMonth: SERVICE_RELEASE_MONTH,
  noImageThumb: NO_IMAGE_THUMB,
  productMainLogoSrc: PRODUCT_MAIN_LOGO_SRC,
  gmoFincodePublicKey: GMO_FINCODE_PUBLIC_KEY,
  gmoFincodeScriptUrl: GMO_FINCODE_SCRIPT_URL,
  publicAssetsBucketName: PUBLIC_ASSETS_BUCKET_NAME,
  ssoMicrosoftOemId: SSO_MICROSOFT_OEM_ACCOUNT_ID,
  ssoMicrosoftTenantId: SSO_MICROSOFT_TENANT_ID,
  GTM_ID: 'G-9657RG55V9',
  firebase: {
    apiKey: 'AIzaSyA0yvRhhD3pRmyh8AQ2RurANF0T7dgznAI',
    authDomain: 'stage.fire-rocket.jp',
    projectId: 'firerocketstage',
    storageBucket: 'firerocketstage.appspot.com',
    messagingSenderId: '131836119105',
    appId: '1:936617830999:web:be0ad08d59c4c30225d112',
    measurementId: 'G-9657RG55V9'
  }
};
