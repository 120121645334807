<app-forbidden *ngIf="forbidden"></app-forbidden>
<div class="l-main" *ngIf="!forbidden">
  <app-navigations></app-navigations>
  <div class="l-content">
    <app-breadcrumb></app-breadcrumb>
    <div class="l-content__inner">
      <div class="c-content-header">
        <div class="c-content-header__title" translate>コース新規作成</div>
      </div>
      <div class="l-templates">
        <div class="p-templates">
          <div class="p-templates__content">
            <div
              *ngIf="currentChannel.name"
              class="p-templates__content__info">
              <span translate>チャンネル名</span>「{{currentChannel.name}} ({{currentChannel.slug}})」
              <span translate>内に新しいコースを作成します。</span>
            </div>
            <div class="p-profile">
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label">
                    <span translate>コース名（タイトル）</span>
                    <span class="c-form-required" translate>必須</span>
                  </label>
                  <input
                    [formControl]="name"
                    class="c-form-input"
                    type="text"
                    name="courseName"
                    [placeholder]="'例）●●●●講座' | translate"
                  >
                  <div
                    *ngIf="name.dirty && name.invalid"
                    class="c-form-error"
                    translate
                  >コース名は4文字〜100文字以内で入力してください。</div>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__input">
                  <label class="c-form-label">
                    <span translate>カテゴリーを選択</span>
                    <span class="c-form-required" translate>必須</span>
                  </label>
                  <select
                    [formControl]="category"
                    class="c-form-select"
                    name="channelCategory">
                    <option [ngValue]="null" selected><span translate>選択</span> ▼</option>
                    <option
                      *ngFor="let category of formCategories"
                      [ngValue]="category.id"
                      translate
                    >{{category.name}}</option>
                  </select>
                  <div
                    *ngIf="category.dirty && category.invalid"
                    class="c-form-error"
                    translate
                  >カテゴリーを選択してください。</div>
                </div>
                <div class="p-profile__row__notes">
                  <span translate>カテゴリーとは？</span><br>
                  <span translate>受講者がチャンネルやコースを探したり、サイト上での分類に使用されます。コースにもカテゴリーが設定できるので、あくまでもメインとなるカテゴリーを選択してください。</span>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="p-profile__row__consent">
                  <div class="p-signup__main__consent p-signup__main__consent-alignLeft">
                    <div class="p-signup__main__consent__wrap">
                      <input
                        [formControl]="privacy"
                        type="checkbox" name="privacy" value="" id="privacy">
                      <label
                        class="p-signup__main__consent__wrap__label"
                        for="privacy"
                        translate
                      >プライバシーポリシーに同意する</label>
                      <div class="p-signup__main__consent__wrap__link" (click)="openPrivacyLink()"></div>
                    </div>
                    <div
                      *ngIf="privacy.dirty && privacy.invalid"
                      class="c-form-error"
                      translate
                    >プライバシーポリシーへの同意が必要です。</div>
                  </div>
                  <div class="p-signup__main__consent p-signup__main__consent-alignLeft">
                    <div class="p-signup__main__consent__wrap">
                      <input
                        [formControl]="contract"
                        type="checkbox" name="contract" value="" id="contract">
                      <label
                        class="p-signup__main__consent__wrap__label"
                        for="contract"
                        translate
                      >FireRocketサービス規約に同意する</label>
                      <div class="p-signup__main__consent__wrap__link" (click)="openContractsLink()"></div>
                    </div>
                    <div
                      *ngIf="contract.dirty && contract.invalid"
                      class="c-form-error"
                      translate
                    >サービス規約への同意が必要です。</div>
                  </div>
                </div>
              </div>
              <div class="p-profile__row">
                <div class="c-form-error" *ngIf="showError" translate>入力されていない項目があります。</div>
                <div class="p-profile__row__createButton">
                  <button
                    (click)="onSubmit()"
                    class="c-button c-button-create"
                  ><span translate>コースを作成する</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

