import { Injectable } from '@angular/core';
import {BackendService} from "../backend/backend.service";
import {environment} from "../../../environments/environment";
import {SlideUpload} from "../../models/slide-upload";
import {SlideFrame, VideoFrame} from "../../models/course-frames";

@Injectable({
  providedIn: 'root'
})
export class SlideUploaderService {
  slideStatus: SlideUpload;
  constructor(
    private backendService: BackendService,
  ) { }
  async convertRequest(
    accountId: string,
    idToken: string,
    contentKey: string,
    fileName: string,
    objectNamePath: string,
    destinationPath: string,
  ): Promise<void> {
    this.slideStatus = new SlideUpload();
    this.backendService.setIdToken(idToken);
    const data = {
      accountId: accountId,
      storageBucket: environment.firebase.storageBucket,
      contentKey: contentKey,
      fileName: fileName,
      filePath: objectNamePath,
      destinationPath: destinationPath,
    }
    this.slideStatus = await this.backendService.post('converter/pdf/pdf_to_slide', data);
  }
  async removeAll(
    accountId: string,
    idToken: string,
    frame: SlideFrame,
  ): Promise<void> {
    const data = {
      accountId: accountId,
      storageBucket: environment.firebase.storageBucket,
      frame: frame,
    }
    return new Promise((async (resolve, reject) => {
      this.backendService.setIdToken(idToken);
      await this.backendService.post('converter/pdf/remove', data);
      resolve();
    }));
  }
  async removeSlide(
    accountId: string,
    idToken: string,
    frame: SlideFrame,
  ): Promise<void> {
    const data = {
      accountId: accountId,
      storageBucket: environment.firebase.storageBucket,
      frame: frame,
    }
    return new Promise((async (resolve, reject) => {
      this.backendService.setIdToken(idToken);
      await this.backendService.post('converter/pdf/remove_slide', data);
      resolve();
    }));
  }

}
