import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.scss']
})
export class TopComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  moveToForm(): void {
    location.href='https://form.run/@umetaku-1683538214';
    return;
  }

}
