<app-header-contents-channel></app-header-contents-channel>
<div class="l-content-front">
  <app-breadcrumb-front></app-breadcrumb-front>
  <div class="l-content-front__inner">
    <div class="c-content-header">
      <div class="c-content-header__title">
        通知設定
      </div>
    </div>
    <div class="l-myPage">
      <div class="p-myPage">
        <div class="c-tab">
          <input type="radio" id="tab_selector-1" name="tab_selector" class="c-tab__selector" checked>
          <label for="tab_selector-1" class="c-tab__label">
            <div class="c-tab__label__icon c-tab__label__icon-notification_channel"></div>
            <span>チャンネル</span>
          </label>
          <input type="radio" id="tab_selector-2" name="tab_selector" class="c-tab__selector">
          <label for="tab_selector-2" class="c-tab__label">
            <div class="c-tab__label__icon c-tab__label__icon-notification_contact"></div>
            <span>個別の連絡</span>
          </label>
          <hr>
          <!--　1　購読中タブ　-->
          <div class="c-tab__content" id="tab_selector-1_content">
            <p>個別に通知のオン/オフを設定できます。</p>
            <div class="p-notification-toggle">
              <div class="p-notification-toggle__inner">
                <div class="p-notification-toggle__inner__title">チャンネル名チャンネル名チャンネル名チャンネル名</div>
                <div class="p-notification-toggle__inner__toggleSwitch">
                  <div class="p-notification-toggle__inner__toggleSwitch__label">OFF</div>
                  <label class="c-form-switch">
                    <input type="checkbox" name="toggle-1" value="" id="toggle-1">
                    <span class="c-form-switch__content"></span>
                    <span class="c-form-switch__circle"></span>
                  </label>
                  <div class="p-notification-toggle__inner__toggleSwitch__label">ON</div>
                </div>
              </div>
              <div class="p-notification-toggle__inner">
                <div class="p-notification-toggle__inner__title">チャンネル名チャンネル名チャンネル名チャンネル名</div>
                <div class="p-notification-toggle__inner__toggleSwitch">
                  <div class="p-notification-toggle__inner__toggleSwitch__label">OFF</div>
                  <label class="c-form-switch">
                    <input type="checkbox" name="toggle-2" value="" id="toggle-2">
                    <span class="c-form-switch__content"></span>
                    <span class="c-form-switch__circle"></span>
                  </label>
                  <div class="p-notification-toggle__inner__toggleSwitch__label">ON</div>
                </div>
              </div>
              <div class="p-notification-toggle__inner">
                <div class="p-notification-toggle__inner__title">チャンネル名チャンネル名チャンネル名チャンネル名</div>
                <div class="p-notification-toggle__inner__toggleSwitch">
                  <div class="p-notification-toggle__inner__toggleSwitch__label">OFF</div>
                  <label class="c-form-switch">
                    <input type="checkbox" name="toggle-3" value="" id="toggle-3">
                    <span class="c-form-switch__content"></span>
                    <span class="c-form-switch__circle"></span>
                  </label>
                  <div class="p-notification-toggle__inner__toggleSwitch__label">ON</div>
                </div>
              </div>
            </div>
          </div>
          <!--　2　コース購入タブ　-->
          <div class="c-tab__content" id="tab_selector-2_content">
            <p>個別に通知のオン/オフを設定できます。</p>
            <div class="p-notification-toggle">
              <div class="p-notification-toggle__inner">
                <div class="p-notification-toggle__inner__title">個別の連絡個別の連絡個別の連絡個別の連絡個別の連絡個別の連絡</div>
                <div class="p-notification-toggle__inner__toggleSwitch">
                  <div class="p-notification-toggle__inner__toggleSwitch__label">OFF</div>
                  <label class="c-form-switch">
                    <input type="checkbox" name="toggle-4" value="" id="toggle-4">
                    <span class="c-form-switch__content"></span>
                    <span class="c-form-switch__circle"></span>
                  </label>
                  <div class="p-notification-toggle__inner__toggleSwitch__label">ON</div>
                </div>
              </div>
              <div class="p-notification-toggle__inner">
                <div class="p-notification-toggle__inner__title">個別の連絡個別の連絡個別の連絡個別の連絡個別の連絡個別の連絡</div>
                <div class="p-notification-toggle__inner__toggleSwitch">
                  <div class="p-notification-toggle__inner__toggleSwitch__label">OFF</div>
                  <label class="c-form-switch">
                    <input type="checkbox" name="toggle-5" value="" id="toggle-5">
                    <span class="c-form-switch__content"></span>
                    <span class="c-form-switch__circle"></span>
                  </label>
                  <div class="p-notification-toggle__inner__toggleSwitch__label">ON</div>
                </div>
              </div>
              <div class="p-notification-toggle__inner">
                <div class="p-notification-toggle__inner__title">個別の連絡個別の連絡個別の連絡個別の連絡個別の連絡個別の連絡</div>
                <div class="p-notification-toggle__inner__toggleSwitch">
                  <div class="p-notification-toggle__inner__toggleSwitch__label">OFF</div>
                  <label class="c-form-switch">
                    <input type="checkbox" name="toggle-6" value="" id="toggle-6">
                    <span class="c-form-switch__content"></span>
                    <span class="c-form-switch__circle"></span>
                  </label>
                  <div class="p-notification-toggle__inner__toggleSwitch__label">ON</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
