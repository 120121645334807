import {MonetizeMethods} from "./monetize-methods";
import {PublishRangesKeys} from "./publish-range";
import {VideoFrame} from "./course-frames";
import {PublicImage} from "./public-image";
export class Thumbs {
  original: PublicImage;
  thumb: PublicImage;
}
export class Channel {
  id: string | null;
  accountId: string;
  isOem: boolean;
  name: string;
  description: string;
  eyeCatchImage: PublicImage | null;
  prImages: PublicImage[];
  profileImage: PublicImage | null;
  samples: VideoFrame[];
  slug: string;
  categoryId: string;
  tags: string[];
  published: boolean;
  monetized: boolean;
  monetizeMethod: MonetizeMethods;
  unitPrice: number;
  channelSubscriptionPrice: number;
  channelSubscriptionPriceForPremium: number;
  publishRange: PublishRangesKeys;
  recommended: boolean;
  recommendedRate: number;
  createdAt: Date;
  updatedAt: Date;
  createdBy: string;
  updatedBy: string;
  constructor() {
    this.id = null;
    this.isOem = false;
    this.accountId = null;
    this.name = null;
    this.description = null;
    this.eyeCatchImage = null;
    this.prImages = [];
    this.samples = [];
    this.slug = null;
    this.categoryId = null;
    this.tags = [];
    this.published = false;
    this.monetized = false;
    this.monetizeMethod = null;
    this.unitPrice = 0;
    this.channelSubscriptionPrice = 0;
    this.channelSubscriptionPriceForPremium = 0;
    this.recommended = false;
    this.recommendedRate = 0;
    this.createdAt = null;
    this.updatedAt = null;
    this.createdBy = null;
    this.updatedBy = null;
  }
  deserialize(): JSON {
    const json = JSON.stringify(this)
    return JSON.parse(json)
  }
}
