import { Component } from '@angular/core';

@Component({
  selector: 'app-manager-channel-reports',
  templateUrl: './manager-channel-reports.component.html',
  styleUrls: ['./manager-channel-reports.component.scss']
})
export class ManagerChannelReportsComponent {

}
