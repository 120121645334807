export class Category {
  id: string;
  name: string;
  name_en: string;
  slug: string;
  description: string;
  parentId: string | null;
}
export const categories: Category[] = [
  {
    id: '489b0015cb4f05afa58d',
    name: 'ビジネス',
    name_en: 'Business',
    slug: 'business',
    description: 'some text...',
    parentId: null
  },
  {
    id: '0added8b3f1e27f167a5',
    name: 'マーケティング',
    name_en: 'Marketing',
    slug: 'marketing',
    description: 'some text...',
    parentId: null
  },
  {
    id: '4192a20153f3800d8bef',
    name: 'ソフトウェア開発',
    name_en: 'Software Development',
    slug: 'software-development',
    description: 'some text...',
    parentId: null
  },
  {
    id: '91b64072bd6ce083dfc3',
    name: 'ハードウェア開発',
    name_en: 'Hardware Development',
    slug: 'hardware-development',
    description: '電子工作',
    parentId: null
  },
  {
    id: '9562786caba55aff9f56',
    name: 'ネットワークインフラ',
    name_en: 'Network Infrastructure',
    slug: 'network-infrastructure',
    description: 'some text...',
    parentId: null
  },
  {
    id: '37990cf6c8e90aa60047',
    name: 'IoT',
    name_en: 'Internet of Things (IoT)',
    slug: 'internet-of-things',
    description: 'some text...',
    parentId: null
  },
  {
    id: 'e2883d0ea53cca4488dc',
    name: '音楽',
    name_en: 'Music',
    slug: 'music',
    description: '作曲、楽器演奏、歴史、評論、解説',
    parentId: null
  },
  {
    id: '3dbb98c348e12a7459b2',
    name: '美術・アート',
    name_en: 'Art',
    slug: 'art',
    description: '絵画、アート全般、制作、歴史、評論、解説',
    parentId: null
  },
  {
    id: 'cbd895854a85a06d3f4d',
    name: '歴史',
    name_en: 'History',
    slug: 'history',
    description: '歴史学、解説、評論',
    parentId: null
  },
  {
    id: 'c9d48782c1b31e01dd27',
    name: '言語',
    name_en: 'Languages',
    slug: 'languages',
    description: '会話、ライティング、ヒアリング、翻訳、実用、応用、Tips',
    parentId: null
  },
  {
    id: '0264d03f6b9c18bb552c',
    name: '料理',
    name_en: 'Cooking',
    slug: 'cooking',
    description: '専門料理、家庭料理、自炊テクニック、予算管理、食材処理・加工',
    parentId: null
  },
  {
    id: 'e124880538f1e9f7db16',
    name: 'ペット',
    name_en: 'Pets',
    slug: 'pets',
    description: '選び方、飼育方法',
    parentId: null
  },
  {
    id: '7c61de527c43d7a0788f',
    name: 'グラフィックデザイン',
    name_en: 'Graphic Design',
    slug: 'graphic-design',
    description: 'デザイン全般、イラスト、ソフトウェア使用方法、色彩、実用、応用、Tips',
    parentId: null
  },
  {
    id: '72bbcbf1793a590ccdf6',
    name: '漫画',
    name_en: 'Manga',
    slug: 'manga',
    description: '漫画制作全般、評論、解説',
    parentId: null
  },
  {
    id: 'ca72aba75734fe2ec07d',
    name: 'アニメーション',
    name_en: 'Animation',
    slug: 'animation',
    description: 'アニメーション制作全般、評論、解説',
    parentId: null
  },
  {
    id: '6659b1f9d63f0b2b1414',
    name: '3Dグラフィックス',
    name_en: '3D Graphics',
    slug: '3d-graphics',
    description: 'some text...',
    parentId: null
  },
  {
    id: 'f76bdc48c1b60b873e5f',
    name: 'ITリテラシー',
    name_en: 'IT Literacy',
    slug: 'it-literacy',
    description: 'ネットリテラシー、SNS活用、セキュリティー、開発者ではない人のコンピューター知識',
    parentId: null
  },
  {
    id: 'fa212e6ca96297b1cee9',
    name: 'DIY',
    name_en: 'Do-It-Yourself (DIY)',
    slug: 'do-it-yourself',
    description: 'some text...',
    parentId: null
  },
  {
    id: '92ebf5ec0a0c2747f867',
    name: '美容・健康',
    name_en: 'Beauty and Health',
    slug: 'beauty-and-health',
    description: 'ダイエット、サプリ、ストレッチ、栄養管理',
    parentId: null
  },
  {
    id: 'ba2b74f0a040b32fe57e',
    name: 'ライフスタイル向上術',
    name_en: 'Lifestyle Improvement',
    slug: 'lifestyle-improvement',
    description: 'some text...',
    parentId: null
  },
  {
    id: '67ff70e4ffdedc90b4d6',
    name: '旅行',
    name_en: 'Travel',
    slug: 'travel',
    description: '旅行テクニック、旅行記',
    parentId: null
  },
  {
    id: 'e309735e98ed22e35b09',
    name: '資格取得',
    name_en: 'Certification Acquisition',
    slug: 'certification-acquisition',
    description: 'some text...',
    parentId: null
  },
  {
    id: 'a00ee6769c2995949956',
    name: 'ガーデニング',
    name_en: 'Gardening',
    slug: 'gardening',
    description: 'some text...',
    parentId: null
  },
  {
    id: '7aa036c6b57475e7b244',
    name: 'アウトドア',
    name_en: 'Outdoor Activities',
    slug: 'outdoor-activities',
    description: 'some text...',
    parentId: null
  },
  {
    id: '4f58f9fd13ca806e3e8b',
    name: '車・バイク',
    name_en: 'Cars and Motorcycles',
    slug: 'cars-and-motorcycles',
    description: '運転、整備、交通ルール、ツーリング・ドライブ、マナー',
    parentId: null
  },
  {
    id: 'dd06c08e2d0368c898bb',
    name: '船舶',
    name_en: 'Marine Vessels',
    slug: 'marine-vessels',
    description: '操船、整備、海上交通ルール、マナー',
    parentId: null
  },
  {
    id: '589a891dd410982bba41',
    name: '航空機・ドローン操縦',
    name_en: 'Aircraft and Drone Operation',
    slug: 'aircraft-and-drone-operation',
    description: '操縦、ルール、ドローン撮影',
    parentId: null
  },
  {
    id: '25ce649f0d5871bfa730',
    name: '造形',
    name_en: 'Sculpture',
    slug: 'sculpture',
    description: 'プラモデル、ジオラマ、3Dプリンター、フィギア',
    parentId: null
  },
  {
    id: '57f31c05a5ef66b044a8',
    name: '動画制作',
    name_en: 'Video Production',
    slug: 'video-production',
    description: 'some text...',
    parentId: null
  },
  {
    id: '4e22f41e0d38238871cd',
    name: 'スポーツ',
    name_en: 'Sports',
    slug: 'sports',
    description: 'some text...',
    parentId: null
  },
  {
    id: '4bc8dd50ae349530c15b',
    name: '国際・経済・政治・ニュース',
    name_en: 'International, Economic, Political, and News',
    slug: 'international-economic-political-and-news',
    description: 'ニュース配信、ニュース解説、政治学、情報リテラシー、軍事戦略、戦略理論',
    parentId: null
  },
  {
    id: 'bc70f9c4fb2d186fd6e1',
    name: 'ライティング',
    name_en: 'Writing',
    slug: 'writing',
    description: '小説、エッセイ、ブログ',
    parentId: null
  },
  {
    id: 'c4569d5a5ce7ccf3a1cd',
    name: 'サイエンス',
    name_en: 'Science',
    slug: 'science',
    description: '科学全般、コンピューターサイエンス、物理学、素粒子物理学、化学、経済学、金融工学、自然科学',
    parentId: null
  },
  {
    id: 'fb80d9eabaee30fe47b3',
    name: '街作り',
    name_en: 'City Planning',
    slug: 'city-planning',
    description: 'some text...',
    parentId: null
  },
  {
    id: '7e3612de76590244d582',
    name: 'ゲーム制作',
    name_en: 'Game Development',
    slug: 'game-development',
    description: 'ゲームデザイン、レベルデザイン、キャラクターデザイン、シナリオライティング',
    parentId: null
  },
  {
    id: 'd291cf36c145343a76bf',
    name: '軍事・ミリタリー',
    name_en: 'Military and Defense',
    slug: 'military-and-defense',
    description: '護身術、軍事、戦闘、兵器知識',
    parentId: null
  },
  {
    id: '27feba67f641f2cd0abd',
    name: '経営・マネジメント',
    name_en: 'Management',
    slug: 'management',
    description: 'some text...',
    parentId: null
  },
  {
    id: 'a326d0585508df5e2c6d',
    name: '教育',
    name_en: 'Education',
    slug: 'education',
    description: 'some text...',
    parentId: null
  },
  {
    id: 'cb3fd188656cabddd7b6',
    name: '子育て',
    name_en: 'Childcare',
    slug: 'childcare',
    description: 'some text...',
    parentId: null
  },
  {
    id: 'bf4cb04ec1cfd19f30b9',
    name: '自己向上・スキルアップ',
    name_en: 'Skill enhancement',
    slug: 'skill-enhancement',
    description: 'some text...',
    parentId: null
  },
  {
    id: '98a33194badab30ad208',
    name: '農林水産業',
    name_en: 'agriculture, forestry, and fisheries',
    slug: 'agriculture-forestry-and-fisheries',
    description: 'some text...',
    parentId: null
  },
  {
    id: 'eb555f9a21139fabc8bf',
    name: '建築・開発',
    name_en: 'construction and development',
    slug: 'construction-and-development',
    description: 'some text...',
    parentId: null
  },
  {
    id: '3810539d7b895943474e',
    name: 'マイホーム',
    name_en: 'homeownership',
    slug: 'homeownership',
    description: 'some text...',
    parentId: null
  },
  {
    id: 'fc0963dd02025e5f5491',
    name: '投資・株式投資・資産運用',
    name_en: 'investment, stock trading, asset managemen',
    slug: 'investment-stock-trading-asset-management',
    description: 'some text...',
    parentId: null
  }
];
