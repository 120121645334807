import { Component } from '@angular/core';
import {routes} from "../../app-routing.module";

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent {
  pageList = routes;
  // TODO !env.prod の時だけ開けるようにする
}
