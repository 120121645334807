import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreDocument, CollectionReference, Query} from "@angular/fire/compat/firestore";
import {environment} from "../../../environments/environment";
import {Observable, of} from "rxjs";
import {Course} from "../../models/course";
import {Channel} from "../../models/channel";
import {ChannelSubscription} from "../../models/channel-subscription";
import {PublishRangesKeys} from "../../models/publish-range";
import {switchFalseLoading} from "../../store/ui/ui.actions";
import {CourseSubscription} from "../../models/course-subscription";
import {CurrentUser} from "../../store/sessions/sessions.reducer";
import {ChannelsService} from "../channels/channels.service";

@Injectable({
  providedIn: 'root'
})
export class CoursesService {

  constructor(
    private fireStore: AngularFirestore,
    private channelService: ChannelsService,
  ) {}
  dbDocument: AngularFirestoreDocument<Course>
  fetched: Course[] = [];
  current: Observable<Course> = of(new Course());
  async create(
    course: Course
  ): Promise<string> {
    const collectionRef =
      this.fireStore.collection('apps')
        .doc(environment.appId)
        .collection('courses')
    const docRef = await collectionRef.add(course.deserialize());
    return docRef.id;
  }
  async remove(
    course: Course
  ): Promise<void> {
    const collectionRef =
      this.fireStore.collection('apps')
        .doc(environment.appId)
        .collection('courses')
    await collectionRef.doc(course.id).delete();
  }
  async fetch(
    courseId: string
  ): Promise<Course | undefined> {
    return new Promise((resolve, reject) => {
      const collRef =
        this.fireStore.collection('apps')
          .doc(environment.appId)
          .collection('courses')
      collRef.doc(courseId)
        .get()
        .toPromise()
        .then((doc) => {
          if (doc.data() === undefined) {
            resolve(undefined);
            return;
          }
          const newDoc = doc.data() as Course;
          newDoc.id = doc?.id;
          resolve(newDoc);
          return;
        })
        .catch(() => {
          console.log('not found');
        });
    });
  }
  async fetchByAccountId(
    accountId: string
  ): Promise<void> {
    this.fetched = [];
    return new Promise((resolve, reject) => {
      const docRef =
        this.fireStore.collection('apps')
          .doc(environment.appId);
      docRef.collection(
        'courses',
        ref => {
          let query: CollectionReference | Query = ref;
          query = query.where('accountId', '==', accountId);
          return query;
        })
        .get()
        .toPromise()
        .then((querySnapshot) => {
          const docs: Course[] = [];
          querySnapshot.forEach((doc) => {
            const newDoc = doc.data() as Course;
            newDoc.id = doc.id;
            this.fetched.push(newDoc);
          })
          resolve();
        })
        .catch();
    });
  }
  async fetchByChannelIdAndAccountId(
    channelId: string,
    accountId: string
  ): Promise<void> {
    this.fetched = [];
    return new Promise((resolve, reject) => {
      const docRef =
        this.fireStore.collection('apps')
          .doc(environment.appId);
      docRef.collection(
        'courses',
        ref => {
          let query: CollectionReference | Query = ref;
          query = query.where('channelId', '==', channelId);
          query = query.where('accountId', '==', accountId);
          return query;
        })
        .get()
        .toPromise()
        .then((querySnapshot) => {
          const docs: Course[] = [];
          querySnapshot.forEach((doc) => {
            const newDoc = doc.data() as Course;
            newDoc.id = doc.id;
            this.fetched.push(newDoc);
          })
          resolve();
        })
        .catch();
    });
  }
  async fetchByChannelId(
    channelId: string,
  ): Promise<void> {
    this.fetched = [];
    return new Promise((resolve, reject) => {
      const docRef =
        this.fireStore.collection('apps')
          .doc(environment.appId);
      docRef.collection(
        'courses',
        ref => {
          let query: CollectionReference | Query = ref;
          query = query.where('channelId', '==', channelId);
          return query;
        })
        .get()
        .toPromise()
        .then((querySnapshot) => {
          const docs: Course[] = [];
          querySnapshot.forEach((doc) => {
            const newDoc = doc.data() as Course;
            newDoc.id = doc.id;
            this.fetched.push(newDoc);
          })
          resolve();
        })
        .catch();
    });
  }
  async connectSnapShot(
    id: string
  ): Promise<void> {
    this.dbDocument = this.fireStore
      .collection('apps')
      .doc(environment.appId)
      .collection('courses')
      .doc(id);
    await this.dbDocument.get().toPromise().then();
    this.current = this.dbDocument.valueChanges({ idField: 'id' });
  }
  async update(course: Course): Promise<void> {
    const jsonCourse = JSON.stringify(course)
    await this.dbDocument.update(JSON.parse(jsonCourse));
  }
  hasPermission(
    currentCourse: Course,
    courseSubscriptionStatus: CourseSubscription
  ): boolean {
    return(currentCourse.published && currentCourse.publishRange === PublishRangesKeys.public)
      || (currentCourse.published && currentCourse.publishRange === PublishRangesKeys.forInvitationOnly && courseSubscriptionStatus?.activated)
      || (currentCourse.published && currentCourse.publishRange === PublishRangesKeys.forChannelMembershipOnly && courseSubscriptionStatus?.activated);
  }
  canShow(
    currentChannel: Channel,
    channelSubscriptionStatus: ChannelSubscription,
    currentCourse: Course,
    courseSubscriptionStatus: CourseSubscription,
    currentUser: CurrentUser,
  ): boolean {
    let isMyChannel = false;
    if (!currentChannel) {
      // console.log('404 チャンネルデータが読み込めない');
      return false;
    }
    // チャンネルの管理者かどうかをチェックする
    if (currentUser?.currentAccountId === currentChannel?.accountId) {
      return true;
    }
    // チャンネルの公開範囲をチェックする
    if (!this.channelService.hasPermission(isMyChannel, currentChannel, channelSubscriptionStatus)) {
      // console.log('404 チャンネルの公開範囲をチェックする');
      return false;
    }
    if (!currentCourse) {
      // console.log('404 コースデータが読み込めない（存在しない）');
      return false;
    }
    // コースの公開範囲をチェックする
    if (!this.hasPermission( currentCourse, courseSubscriptionStatus)) {
      // console.log('404 コースの公開範囲をチェックする');
      return false;
    }
    return true;
  }
}
