<app-header-contents-channel></app-header-contents-channel>
<div class="l-content-front">
  <app-breadcrumb-front></app-breadcrumb-front>
  <div class="l-content-front__inner">
    <div class="c-content-header">
      <div class="c-content-header__title" translate>クレジットカード登録・変更</div>
    </div>
    <div class="l-myPage">
      <div class="p-myPage">
        <div class="p-myPage__notes" translate>お支払いに使用するクレジットカードを登録・変更します。</div>
        <div class="p-myPage__info">
          <div class="p-myPage__info__title" translate>登録済みのクレジットカード</div>
          <div class="p-myPage__info__detail">VISA xxxx-xxxx-xxxx-1234</div>
        </div>
        <div class="p-myPage__subTitle" translate>カード情報変更</div>
        <div class="p-myPage__form">
          <div class="p-myPage__form__input">
            <label
              class="c-form-label"
              for="number"
            >
              <span translate>カード番号</span>
              <span class="c-form-required" translate>必須</span>
            </label>
            <input
              class="c-form-input"
              type="text"
              name="number"
              id="number"
              placeholder="1234 1234 1234 1234"
            >
            <div class="c-form-error" translate>カード番号を入力してください。</div>
          </div>
          <div class="p-myPage__form__input">
            <label
              class="c-form-label"
              for="dateOfExpiry"
            >
              <span translate>有効期限</span>
              <span class="c-form-required" translate>必須</span>
            </label>
            <input
              class="c-form-input"
              type="text"
              name="dateOfExpiry"
              id="dateOfExpiry"
              [placeholder]="'MM(月）／YY(年）' | translate"
            >
            <div class="c-form-error" translate>有効期限を入力してください。</div>
          </div>
          <div class="p-myPage__form__input">
            <label
              class="c-form-label"
              for="cvcNumber"
            >
              <span translate>CVC番号(セキュリティコード）</span>
              <span class="c-form-required" translate>必須</span>
            </label>
            <input
              class="c-form-input"
              type="text"
              name="cvcNumber"
              id="cvcNumber"
              placeholder="123"
            >
            <div class="c-form-error" translate>CVC番号(セキュリティコード）を入力してください。</div>
          </div>
          <div class="p-myPage__form__input">
            <label
              class="c-form-label"
              for="name"
            >
              <span translate>お名前</span>
              <span class="c-form-required" translate>必須</span>
            </label>
            <input
              class="c-form-input"
              type="text"
              name="name"
              id="name"
              placeholder="ICHIRO YAMADA"
            >
            <div class="c-form-error" translate>お名前を入力してください。</div>
          </div>
        </div>
        <div class="p-myPage__buttons">
          <div class="p-myPage__buttons__button">
            <button class="c-button c-button-cancel"
            ><span translate>キャンセルして戻る</span></button>
          </div>
          <div class="p-myPage__buttons__button">
            <button class="c-button c-button-primary"
            ><span translate>保存する</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
